import { jsx as _jsx } from "react/jsx-runtime";
import { RegistrationTemplate } from './RegistrationTemplate';
import { SettingsTemplate } from './SettingsTemplate';
import { Stack } from '@mui/material';
const SPACING = 2.5;
export const Template = ({ name, children, only, setValidity, resetFormValues, context, }) => {
    if (name === 'registration') {
        return (_jsx(RegistrationTemplate, { setValidity: setValidity, resetFormValues: resetFormValues, context: context, children: children }));
    }
    if (name === 'settings') {
        return (_jsx(SettingsTemplate, { only: only, setValidity: setValidity, children: children }));
    }
    return (_jsx(Stack, { spacing: SPACING, sx: { margin: 'auto' }, children: children }));
};
