import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { School, ViewColumnOutlined } from '@mui/icons-material';
import { Card, CardContent, CardHeader, Chip, Stack, Typography, } from '@mui/material';
import { Tooltip } from 'components/custom';
import { useWidgetContext, Widget } from 'components/custom/WidgetProvider';
import { useTranslation } from 'react-i18next';
import { getTextColor } from 'utils/color';
import { formatDateTime } from 'utils/date';
const Info = ({ lesson, isLessonShared }) => {
    const { t } = useTranslation('lesson');
    const { setWidget } = useWidgetContext();
    const courseNameTextColor = getTextColor(lesson.courseColor);
    return (_jsxs(Card, { children: [_jsx(CardHeader, { avatar: _jsx(ViewColumnOutlined, {}), title: _jsx(Typography, { children: isLessonShared
                        ? t('body.header.titleForSharedLesson')
                        : t('body.header.title') }) }), _jsx(CardContent, { children: _jsxs(Stack, { direction: "row", spacing: 1, justifyContent: "space-between", children: [_jsx(Chip, { sx: {
                                '& .MuiChip-label': {
                                    color: courseNameTextColor,
                                },
                                backgroundColor: lesson.courseColor,
                            }, label: lesson.courseName, "data-testid": "courseName" }), _jsxs(Stack, { direction: "row", spacing: 1, justifyContent: "flex-end", children: [_jsx(Chip, { color: "info", label: lesson.schoolYearName, "data-testid": "schoolYearName" }), _jsx(Chip, { color: "success", label: lesson.educationalYearName, "data-testid": "educationalYearName" }), _jsx(Chip, { color: "primary", label: lesson.subjectName, "data-testid": "subjectName" }), isLessonShared ? (_jsx(Chip, { color: "error", label: formatDateTime(lesson.startDate, true), "data-testid": "startDate", icon: _jsx(School, {}) })) : (_jsx(Tooltip, { title: t('body.info.startDateTooltip'), children: _jsx(Chip, { color: "error", label: formatDateTime(lesson.startDate, true), "data-testid": "startDate", icon: _jsx(School, {}), onClick: () => {
                                            setWidget({
                                                active: Widget.LESSONS,
                                                params: {
                                                    date: lesson.startDate,
                                                },
                                            });
                                        } }) }))] })] }) })] }));
};
export default Info;
