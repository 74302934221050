import { jsx as _jsx } from "react/jsx-runtime";
import { isUiNodeAnchorAttributes, isUiNodeImageAttributes, isUiNodeInputAttributes, isUiNodeScriptAttributes, isUiNodeTextAttributes, } from '../integrations/ui';
import { NodeAnchor } from './NodeAnchor';
import { NodeImage } from './NodeImage';
import { NodeInput } from './NodeInput';
import { NodeScript } from './NodeScript';
import { NodeText } from './NodeText';
export const Node = ({ node, value, setValue, disabled, dispatchSubmit, context, }) => {
    if (isUiNodeImageAttributes(node.attributes)) {
        return _jsx(NodeImage, { node: node, attributes: node.attributes });
    }
    if (isUiNodeScriptAttributes(node.attributes)) {
        return _jsx(NodeScript, { node: node, attributes: node.attributes });
    }
    if (isUiNodeTextAttributes(node.attributes)) {
        return _jsx(NodeText, { node: node, attributes: node.attributes });
    }
    if (isUiNodeAnchorAttributes(node.attributes)) {
        return _jsx(NodeAnchor, { node: node, attributes: node.attributes });
    }
    if (isUiNodeInputAttributes(node.attributes)) {
        return (_jsx(NodeInput, { dispatchSubmit: dispatchSubmit, value: value, setValue: setValue, node: node, disabled: disabled, attributes: node.attributes, context: context }));
    }
    return null;
};
