import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { Tooltip } from 'components/custom';
import { addDays, format, isToday, nextMonday } from 'date-fns';
import { formatDate, formatDateToDateTimeAttribute } from 'utils/date';
const DayOfTheWeekCell = ({ date, day }) => {
    const today = date && isToday(date);
    const weekday = format(date !== null && date !== void 0 ? date : addDays(nextMonday(new Date()), day - 1), 'EEEEEE');
    return (_jsx(Tooltip, { title: date && formatDate(date), placement: "top", describeChild: true, children: _jsxs(Box, { role: "columnheader", sx: {
                alignItems: 'center',
                backgroundColor: today ? 'secondary.light' : 'none',
                borderColor: today ? 'secondary.main' : 'none',
                borderStyle: today ? 'solid' : 'none',
                borderWidth: (theme) => theme.spacing(theme.spacingFactors.quarter),
                color: today ? 'secondary.main' : 'inherit',
                display: 'flex',
                flexDirection: 'column',
                gridColumn: day + 1,
                gridRow: 1,
                justifyContent: 'center',
            }, children: [_jsx(Typography, { sx: { fontSize: '1.1rem' }, children: date ? (_jsx("time", { dateTime: formatDateToDateTimeAttribute(date), children: date.getDate() })) : (weekday) }), date && (_jsx(Typography, { sx: {
                        color: today ? 'secondary.main' : 'secondary.light',
                        fontSize: '0.8rem',
                    }, children: weekday }))] }, day) }));
};
export default DayOfTheWeekCell;
