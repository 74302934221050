/**
 * Returns the current coordinates of the element relative to the document.
 * Default all zeros.
 * @param el HTML element.
 * @returns Coordinates.
 */
export const getOffset = (el) => {
    const rect = el === null || el === void 0 ? void 0 : el.getBoundingClientRect();
    const win = el === null || el === void 0 ? void 0 : el.ownerDocument.defaultView;
    if (!rect || !win) {
        return {
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
        };
    }
    return {
        bottom: win.innerHeight - rect.top - rect.height - win.scrollY,
        left: rect.left + win.scrollX,
        right: win.innerWidth - rect.left - rect.width - win.scrollX,
        top: rect.top + win.scrollY,
    };
};
