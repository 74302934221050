import { getApiUrl } from 'utils/config';
import { PLANS_AND_PAYMENTS } from 'utils/links';
export const HOMEPAGE = 'https://skyla-school.de';
export const HELP_AND_ADVICE = 'https://skyla-school.de/hilfe-und-beratung';
export const IMPRINT = 'https://skyla-school.de/impressum/';
export const FALLBACK_URL = 'https://fallback.envs.skyla-school.de';
export const PRIVACY_POLICY = 'https://skyla-school.de/datenschutzerklaerung/';
export const TERMS_AND_CONDITIONS_BUSINESS = 'https://skyla-school.de/nutzungsbedingungen-schule/';
export const TERMS_AND_CONDITIONS = 'https://skyla-school.de/nutzungsbedingungen/';
export const GENERAL_TERMS_AND_CONDITIONS = 'https://skyla-school.de/agb/';
export const GENERAL_TERMS_AND_CONDITIONS_BUSINESS = 'https://skyla-school.de/einwilligung-zur-datenverarbeitung-schule/';
export const LEGAL = 'https://skyla-school.de/rechtliches/';
export const SENTRY = 'https://7959e176e3084600b6dad7ad518f9b78@sentry.monitoring.xboard.x-ion.org/5';
/**
 * Returns a URL which can be used to redirect to
 * a URL saved in file object (MIME type: url/text).
 * @param fileId File object ID.
 * @param sharingToken Sharing token if the object is from a shared lesson.
 * @returns URL.
 */
export const getObjectRedirectUrl = (fileId, sharingToken) => {
    const subPathForSharedLink = sharingToken
        ? `/shared/lesson/${sharingToken}`
        : '';
    return `${getApiUrl()}/api/v1${subPathForSharedLink}/redirect/${fileId}`;
};
/**
 * Returns a URL which redirects user to
 * Stripe customer portal dashboard.
 * @returns URL.
 */
export const getStripeCustomerPortalRedirectUrl = () => {
    const returnTo = encodeURIComponent(PLANS_AND_PAYMENTS);
    return `${getApiUrl()}/api/v1/payment/portal?returnTo=${returnTo}`;
};
/**
 * Returns a URL which redirects user to
 * subscription management page in Stripe customer portal.
 * @returns URL.
 */
export const getChangeStripeSubscriptionRedirectUrl = () => {
    const returnTo = encodeURIComponent(PLANS_AND_PAYMENTS);
    return `${getApiUrl()}/api/v1/payment/portal?flow=subscription_update&returnTo=${returnTo}`;
};
