import { useRestMutation } from 'api/wrappers';
import { getApiUrl } from 'utils/config';
import { getApiStringFromDate } from 'utils/date';
const createAccountTermination = async (params) => {
    return fetch(`${getApiUrl()}/api/v1/accounts/termination`, {
        body: JSON.stringify(params),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
};
export const getAccountTerminationMutationOptions = (params) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- We only need to pull type from params
    const { date, type, ...rest } = params;
    if (date) {
        return {
            ...rest,
            date: getApiStringFromDate(date),
        };
    }
    return rest;
};
export const useAccountTerminationMutation = () => {
    return useRestMutation(createAccountTermination);
};
const createAccountTerminationConfirmation = async (params) => {
    return fetch(`${getApiUrl()}/api/v1/accounts/confirm_termination`, {
        body: JSON.stringify(params),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
};
export const getAccountTerminationConfirmationMutationOptions = (params) => {
    var _a, _b;
    return {
        id: (_a = params.get('id')) !== null && _a !== void 0 ? _a : '',
        token: (_b = params.get('token')) !== null && _b !== void 0 ? _b : '',
    };
};
export const useAccountTerminationConfirmationMutation = () => {
    return useRestMutation(createAccountTerminationConfirmation);
};
