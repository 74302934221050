import { getApiUrl } from 'utils/config';
/**
 * Returns a URL to download all given file objects.
 * @param files File objects to download.
 * @returns Download URL.
 */
export const getMultiFileDownloadUrl = (files) => {
    const ids = files.map((file) => file.id);
    return `${getApiUrl()}/api/v1/object-data?ids=${ids.join(',')}`;
};
