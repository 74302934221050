import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FileCopy, NoteAlt, School } from '@mui/icons-material';
import { Stack, Tabs } from '@mui/material';
import { Drawer } from 'components/custom';
import { useWidgetContext, Widget } from 'components/custom/WidgetProvider';
import React, { useState } from 'react';
import Lessons from './Lessons';
import Notes from './Notes';
import DraggableFileObjectGrid from './Objects';
import { WidgetTab } from './WidgetTab';
import { WidgetTabPanel } from './WidgetTabPanel';
// This has to match the component order in JSX.
const tabOrder = [
    Widget.NOTES,
    Widget.FILES,
    Widget.LESSONS,
];
const WidgetDrawer = ({ user, ...props }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [activeTab, setActiveTab] = React.useState(undefined);
    const { widget } = useWidgetContext();
    React.useEffect(() => {
        if (widget === null || widget === void 0 ? void 0 : widget.active) {
            setDrawerOpen(true);
            setActiveTab(widget.active);
        }
    }, [widget, setDrawerOpen]);
    const getActiveIndex = () => {
        const index = tabOrder.findIndex((name) => {
            return name === activeTab;
        });
        return index > -1 ? index : false;
    };
    const isActive = (name) => {
        return activeTab === name;
    };
    const onTabClick = (name) => {
        const active = isActive(name);
        if (active || !drawerOpen) {
            setDrawerOpen(!drawerOpen);
        }
        if (active && drawerOpen) {
            setActiveTab(undefined);
            return;
        }
        setActiveTab(name);
    };
    const handleChange = (event, index) => {
        setActiveTab(tabOrder[index]);
    };
    return (_jsx(Drawer, { "data-testid": "widgetDrawer", open: drawerOpen, anchor: "right", variant: "permanent", width: (theme) => drawerOpen
            ? theme.layout.openWidgetDrawerWidth
            : theme.layout.closedWidgetDrawerWidth, 
        // Height of AppBar
        paperSx: {
            height: 'calc(100% - 4rem)',
            marginTop: '4rem',
        }, ...props, children: _jsxs(Stack, { direction: "row", flexGrow: "1", children: [_jsxs(Stack, { flexGrow: "1", sx: {
                        visibility: drawerOpen ? 'visible' : 'hidden',
                        width: drawerOpen
                            ? (theme) => `calc(${theme.layout.openWidgetDrawerWidth} - ${theme.layout.closedWidgetDrawerWidth})`
                            : 0,
                    }, children: [_jsx(WidgetTabPanel, { active: isActive(Widget.NOTES), name: Widget.NOTES, children: _jsx(Notes, { user: user }) }), _jsx(WidgetTabPanel, { active: isActive(Widget.FILES), name: Widget.FILES, children: _jsx(DraggableFileObjectGrid, {}) }), _jsx(WidgetTabPanel, { active: isActive(Widget.LESSONS), name: Widget.LESSONS, children: _jsx(Lessons, {}) })] }), _jsxs(Tabs, { orientation: "vertical", indicatorColor: "secondary", onChange: handleChange, value: getActiveIndex(), sx: {
                        '& .MuiTabs-indicator': {
                            display: drawerOpen ? 'block' : 'none',
                        },
                        minWidth: (theme) => theme.layout.closedWidgetDrawerWidth,
                        width: (theme) => theme.layout.closedWidgetDrawerWidth,
                    }, children: [_jsx(WidgetTab, { name: Widget.NOTES, active: isActive(Widget.NOTES), Icon: NoteAlt, onClick: onTabClick }), _jsx(WidgetTab, { name: Widget.FILES, active: isActive(Widget.FILES), Icon: FileCopy, onClick: onTabClick }), _jsx(WidgetTab, { name: Widget.LESSONS, active: isActive(Widget.LESSONS), Icon: School, onClick: onTabClick })] })] }) }));
};
export default WidgetDrawer;
