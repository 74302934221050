import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Stack, Typography } from '@mui/material';
import { SKIP, useAccountStorageQueryApi } from 'api';
import { Alert, ConfirmationDialog, Dialog, LinearProgress, } from 'components/custom';
import { useSession } from 'components/kratos';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InternalErrorCodes, MAX_FILE_SIZE_IN_BYTES, uploadFiles, validateFileList, } from 'utils/file-upload';
import { formatBytes } from 'utils/format';
import { log, Severity } from 'utils/logger';
const FileUploadDialog = (props) => {
    var _a, _b, _c, _d;
    const { fileList, lessonId, onFinally } = props;
    const { t } = useTranslation('object', { keyPrefix: 'cmp.FileUpload' });
    const { t: apiTranslate } = useTranslation('api');
    const { enqueueSnackbar } = useSnackbar();
    const [uploadingSession, setUploadingSession] = React.useState(false);
    const [currentFileProgress, setCurrentFileProgress] = React.useState(0);
    const [currentFileName, setCurrentFileName] = React.useState('');
    const [currentFileIndex, setCurrentFileIndex] = React.useState(0);
    const [fileCount, setFileCount] = React.useState(0);
    const [dialogVisibility, setDialogVisibility] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const abort = React.useRef(false);
    const [confirmationDialogVisibility, setConfirmationDialogVisibility] = React.useState(false);
    const [readyForUpload, setReadyForUpload] = React.useState(false);
    const { user } = useSession();
    const storageStatus = useAccountStorageQueryApi(user && fileList ? { id: user.id } : SKIP);
    const storageUsed = (_b = (_a = storageStatus.data) === null || _a === void 0 ? void 0 : _a.used) !== null && _b !== void 0 ? _b : 0;
    const storageTotal = (_d = (_c = storageStatus.data) === null || _c === void 0 ? void 0 : _c.total) !== null && _d !== void 0 ? _d : 0;
    const closeDialog = React.useCallback(() => {
        setReadyForUpload(false);
        setDialogVisibility(false);
        setConfirmationDialogVisibility(false);
        setErrors([]);
        setFileCount(0);
        setCurrentFileName('');
        setCurrentFileIndex(0);
        setCurrentFileProgress(0);
        setUploadingSession(false);
        abort.current = true;
        onFinally === null || onFinally === void 0 ? void 0 : onFinally();
    }, [onFinally]);
    const addError = (error) => {
        setErrors((prevErrors) => [...prevErrors, error]);
    };
    const beforeFileUpload = React.useCallback((file, index, totalBytesUploaded) => {
        const preventUpload = file.size + totalBytesUploaded + storageUsed > storageTotal;
        setCurrentFileName(file.name);
        setCurrentFileIndex(index);
        if (preventUpload) {
            addError({
                fileName: file.name,
                message: t('uploadFileExceedsQuota'),
            });
        }
        return preventUpload;
    }, [t, storageUsed, storageTotal]);
    const onFileProgress = ({ progress }, abortFileUpload) => {
        if (abort.current) {
            abortFileUpload();
            return;
        }
        setCurrentFileProgress(progress);
    };
    const onFileError = React.useCallback(({ fileName, errorCode, status }) => {
        if (abort.current) {
            return;
        }
        if (errorCode === InternalErrorCodes.FILE_SIZE_EXCEEDED) {
            addError({
                fileName,
                message: t('uploadExceedsAllowedFileSize', {
                    maxSize: formatBytes(MAX_FILE_SIZE_IN_BYTES),
                }),
            });
            return;
        }
        if (errorCode) {
            addError({ fileName, message: apiTranslate(errorCode) });
            return;
        }
        if (status) {
            addError({
                fileName,
                message: status.code && status.text
                    ? `${status.code}: ${status.text}`
                    : t('uploadNetworkError'),
            });
            return;
        }
        addError({ fileName, message: t('uploadPreparationError') });
    }, [apiTranslate, t]);
    const onSuccess = React.useCallback(() => {
        closeDialog();
        enqueueSnackbar(t('uploadSuccessful'), { variant: 'info' });
    }, [enqueueSnackbar, t, closeDialog]);
    const upload = React.useCallback(() => {
        if (!fileList) {
            return;
        }
        const filesCount = fileList.length;
        if (filesCount < 1) {
            return;
        }
        setFileCount(filesCount);
        abort.current = false;
        setUploadingSession(true);
        void uploadFiles(fileList, {
            beforeFileUpload,
            headers: lessonId
                ? {
                    'Lesson-Id': lessonId,
                }
                : {},
            onFileError,
            onFileProgress,
            onSuccess,
        });
        setDialogVisibility(true);
    }, [lessonId, fileList, onFileError, onSuccess, beforeFileUpload]);
    const maxPercent = 100;
    const totalProgress = fileCount > 0 ? Math.floor((currentFileIndex / fileCount) * maxPercent) : 0;
    React.useEffect(() => {
        if (!uploadingSession && fileList) {
            const validated = validateFileList(fileList, {
                total: storageTotal,
                used: storageUsed,
            });
            if (validated === true) {
                setReadyForUpload(true);
            }
            else if (fileList.length === 1) {
                switch (validated) {
                    case InternalErrorCodes.FILE_SIZE_EXCEEDED: {
                        enqueueSnackbar(t('uploadExceedsAllowedFileSize', {
                            maxSize: formatBytes(MAX_FILE_SIZE_IN_BYTES),
                        }), {
                            variant: 'error',
                        });
                        return;
                    }
                    case InternalErrorCodes.STORAGE_QUOTA_EXCEEDED: {
                        enqueueSnackbar(t('uploadBlockedNotEnoughSpace'), {
                            variant: 'error',
                        });
                        return;
                    }
                    default: {
                        enqueueSnackbar(t('uploadUnknownValidationError'), {
                            variant: 'error',
                        });
                        log('Unhandled file upload validation error', {
                            dump: validated,
                            scope: 'FileUploadDialog',
                            severity: Severity.ERROR,
                        });
                    }
                }
            }
            else {
                setConfirmationDialogVisibility(true);
            }
        }
    }, [
        enqueueSnackbar,
        t,
        fileList,
        storageUsed,
        storageTotal,
        uploadingSession,
    ]);
    React.useEffect(() => {
        if (!uploadingSession && readyForUpload && fileList) {
            upload();
        }
    }, [upload, fileList, readyForUpload, uploadingSession]);
    return (_jsxs(_Fragment, { children: [_jsx(Dialog, { maxWidth: 'xs', fullWidth: true, onClose: closeDialog, titleProps: { text: t('uploadDialogTitle') }, open: dialogVisibility, children: _jsxs(Stack, { children: [_jsx(Typography, { sx: { mx: 2 }, children: currentFileName }), _jsx(LinearProgress, { color: "secondary", label: `${currentFileProgress}%`, value: currentFileProgress }), fileCount > 1 && (_jsx(LinearProgress, { label: `${currentFileIndex}/${fileCount}`, value: totalProgress })), _jsx(Box, { children: errors.map(({ fileName, message }, index) => (_jsxs(Alert, { severity: "error", sx: { m: 2 }, children: [fileName, ": ", message] }, `${fileName}-${index}`))) })] }) }), _jsx(ConfirmationDialog, { cancelButtonProps: {
                    children: t('uploadPartialList.cancelButton'),
                    onClick: closeDialog,
                }, confirmButtonProps: {
                    children: t('uploadPartialList.confirmButton'),
                    onClick: () => {
                        setConfirmationDialogVisibility(false);
                        setReadyForUpload(true);
                    },
                }, onClose: closeDialog, titleProps: { text: t('uploadPartialList.title') }, open: confirmationDialogVisibility, children: _jsx(Trans, { i18nKey: "uploadPartialList.text", values: { maxSize: formatBytes(MAX_FILE_SIZE_IN_BYTES) }, t: t }) })] }));
};
export default FileUploadDialog;
