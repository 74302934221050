import { jsx as _jsx } from "react/jsx-runtime";
import { getMoveObjectsToAnotherLessonMutationOptions, getRemoveObjectsFromLessonMutationOptions, getUpdateObjectMutationOptions, isLoading, useLessonFileObjectsQueryApi, useOperationErrorHandler, useRemoveObjectsFromLessonMutation, } from 'api';
import { useMoveObjectsToAnotherLessonMutation, useUpdateObjectMutation, } from 'api/generated';
import { FileObjectDataGrid, FileObjectNameCell, FileObjectTagCell, } from 'components/custom';
import { ActionTypes } from 'components/custom/FileObjectDataGrid';
import { isArray } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useObjectPaginationModel, useObjectSortModel } from 'utils/object';
import { log } from 'utils/logger';
const LessonFileObjectGrid = ({ lessonId, nextLessonId, onNameClick, onUnlinked, ...rest }) => {
    const translationNamespace = 'lesson';
    const { t } = useTranslation(translationNamespace, {
        keyPrefix: 'cmp.LessonFileObjectGrid',
    });
    const { enqueueSnackbar } = useSnackbar();
    const operationErrorHandler = useOperationErrorHandler();
    const [paginationModel, setPaginationModel] = useObjectPaginationModel();
    const [sortModel, setSortModel] = useObjectSortModel();
    const status = useLessonFileObjectsQueryApi({
        filter: { lessonId, ...paginationModel, sorting: sortModel },
    });
    const [updateObject, statusUpdateObject] = useUpdateObjectMutation();
    const [unlinkObject, statusUnlinkObject] = useRemoveObjectsFromLessonMutation();
    const [moveObjectsToAnotherLesson] = useMoveObjectsToAnotherLessonMutation();
    const actionRef = React.useRef({});
    const onRename = (fileObject) => {
        void updateObject(getUpdateObjectMutationOptions({
            id: fileObject.id,
            name: fileObject.name,
        }));
    };
    const onDelete = (obj, options) => {
        const ids = (isArray(obj) ? obj : [obj]).map((item) => item.id);
        unlinkObject(getRemoveObjectsFromLessonMutationOptions(lessonId, ids))
            .then(() => {
            options.deselectAll();
            if (isArray(obj)) {
                enqueueSnackbar(t('alerts.deleteMultiple.success'), {
                    variant: 'success',
                });
            }
            else {
                enqueueSnackbar(t('alerts.deleteSingle.success', { name: obj.name }), {
                    variant: 'success',
                });
            }
            onUnlinked === null || onUnlinked === void 0 ? void 0 : onUnlinked(ids);
        })
            .catch((error) => {
            operationErrorHandler(error);
        });
    };
    const onMoveToNextLesson = (item) => {
        if (!nextLessonId) {
            log('Failed to move objects to next lesson: nextLessonId is missing');
            return;
        }
        moveObjectsToAnotherLesson(getMoveObjectsToAnotherLessonMutationOptions(lessonId, nextLessonId, item))
            .then(() => {
            if (isArray(item)) {
                enqueueSnackbar(t('alerts.moveToNextLessonMultiple.success'), {
                    variant: 'success',
                });
            }
            else {
                enqueueSnackbar(t('alerts.moveToNextLessonSingle.success', { name: item.name }), {
                    variant: 'success',
                });
            }
        })
            .catch((error) => {
            operationErrorHandler(error);
        });
    };
    const columns = [
        {
            field: 'name',
            flex: 0.2,
            headerName: t('column.header.name'),
            renderCell: (params) => {
                const { id, mimeType, name } = params.row;
                return (_jsx(FileObjectNameCell, { fileId: id, mimeType: mimeType, name: name, onClick: () => {
                        onNameClick === null || onNameClick === void 0 ? void 0 : onNameClick(params.row);
                    } }));
            },
        },
        {
            field: 'tags',
            flex: 0.2,
            headerName: t('column.header.tags'),
            renderCell: (params) => (_jsx(FileObjectTagCell, { tags: params.row.tags, onEditClick: () => {
                    var _a, _b;
                    (_b = (_a = actionRef.current).setAction) === null || _b === void 0 ? void 0 : _b.call(_a, {
                        item: params.row,
                        type: ActionTypes.TAG,
                    });
                } })),
            sortable: false,
        },
    ];
    const rows = status.data;
    const loading = isLoading([
        status,
        statusUnlinkObject,
        statusUpdateObject,
    ]);
    return (_jsx(FileObjectDataGrid, { actionRef: actionRef, rows: rows, columns: columns, loading: loading, disableRowSelectionOnClick: true, disableMoveToNextLesson: !nextLessonId, onDelete: onDelete, onRename: onRename, onMoveToNextLesson: onMoveToNextLesson, onOperationError: operationErrorHandler, onSortModelChange: setSortModel, totalCount: status.totalCount, translationNamespace: translationNamespace, onPaginationModelChange: setPaginationModel, ...rest }));
};
export default LessonFileObjectGrid;
