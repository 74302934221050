import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import { Button, LoadingWrapper, TimetableDataGrid } from 'components/custom';
import { AddCircle } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SaveValidityPeriodDialog from './SaveValidityPeriodDialog';
import DeleteValidityPeriodDialog from './DeleteValidityPeriodDialog';
const TimetableValidityPeriodsSection = ({ timetableStatus, }) => {
    const { t } = useTranslation('timetable', {
        keyPrefix: 'steps.vacation.timetableValiditySection',
    });
    const [activeValidityPeriod, setActiveValidityPeriod] = React.useState(null);
    const [validityPeriodToDelete, setValidityPeriodToDelete] = React.useState(null);
    return (_jsx(LoadingWrapper, { status: timetableStatus, children: _jsxs(Stack, { spacing: 2, children: [_jsx(TimetableDataGrid, { "data-testid": "validityPeriods", label: _jsx("strong", { children: t('title') }), rows: timetableStatus.data, onEditClick: setActiveValidityPeriod, onDeleteClick: setValidityPeriodToDelete, hasTimeRange: true, noItemsMessage: t('noItemsMessage') }), _jsx(Button, { color: "secondary", "data-testid": "addValidityPeriodButton", startIcon: _jsx(AddCircle, {}), onClick: () => {
                        setActiveValidityPeriod({});
                    }, sx: { alignSelf: 'end' }, children: t('addButton') }), _jsx(SaveValidityPeriodDialog, { close: () => {
                        setActiveValidityPeriod(null);
                    }, activeValidityPeriod: activeValidityPeriod }), _jsx(DeleteValidityPeriodDialog, { validityPeriodToDelete: validityPeriodToDelete, setValidityPeriodToDelete: setValidityPeriodToDelete })] }) }));
};
export default TimetableValidityPeriodsSection;
