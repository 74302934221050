import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { DataGrid, FileObjectNameCell, FileObjectTagCell, GridActionsCellLinkItem, } from 'components/custom';
import { useTranslation } from 'react-i18next';
import { isEqualPureMimeType, MimeTypes } from '../../../utils/mime-types';
import { Download, OpenInNew } from '@mui/icons-material';
import { getObjectRedirectUrl } from '../../../utils/external-links';
const SharedLessonFileObjectGrid = ({ onNameClick, rows, sharingToken, ...rest }) => {
    const translationNamespace = 'lesson';
    const { t } = useTranslation(translationNamespace, {
        keyPrefix: 'cmp.SharedLessonFileObjectGrid',
    });
    const columns = [
        {
            field: 'name',
            flex: 0.2,
            headerName: t('column.header.name'),
            renderCell: (params) => {
                const { id, mimeType, currentVersion } = params.row;
                return (_jsx(FileObjectNameCell, { fileId: id, mimeType: mimeType, name: currentVersion.name, onClick: () => {
                        onNameClick === null || onNameClick === void 0 ? void 0 : onNameClick(params.row);
                    }, sharingToken: sharingToken }));
            },
            sortable: false,
        },
        {
            field: 'tags',
            flex: 0.2,
            headerName: t('column.header.tags'),
            renderCell: (params) => (_jsx(FileObjectTagCell, { tags: params.row.tags })),
            sortable: false,
        },
        {
            align: 'right',
            field: 'actions',
            getActions: (params) => isEqualPureMimeType(params.row.mimeType, MimeTypes.URL)
                ? [
                    _jsx(GridActionsCellLinkItem, { icon: _jsx(OpenInNew, {}), label: t('action.open'), href: getObjectRedirectUrl(params.row.id, sharingToken), showInMenu: true }, "open"),
                ]
                : [
                    _jsx(GridActionsCellLinkItem, { icon: _jsx(Download, {}), label: t('action.download'), href: params.row.currentVersion.downloadUrl, download: params.row.currentVersion.name, showInMenu: true }, "download"),
                ],
            renderHeader: () => null,
            sortable: false,
            type: 'actions',
            width: 30,
        },
    ];
    return (_jsx(Box, { sx: { display: 'grid', height: '100%', width: '100%' }, children: _jsx(DataGrid, { rows: rows, columns: columns, ...rest }) }));
};
export default SharedLessonFileObjectGrid;
