import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { Greeting } from 'components/custom';
import { useOutletContext } from 'react-router-dom';
import { formatUserName } from 'utils/session';
import { setTitle } from 'utils/head';
import Timetable from './Timetable';
const Home = () => {
    const { t } = useTranslation('home');
    const { user } = useOutletContext();
    const [userFullName, setUserFullName] = useState('');
    useEffect(() => {
        setTitle(t('head.title'));
        setUserFullName(formatUserName(user));
    }, [t, user]);
    return (_jsxs(Stack, { spacing: 2, children: [_jsx(Greeting, { translationNamespace: "home", name: userFullName }), _jsx(Timetable, {})] }));
};
export default Home;
