import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Link } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { onValidationError } from 'api';
import { Alert, ConfirmationDialog, LoadingWrapper, TextFormItem, } from 'components/custom';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { uploadFile } from 'utils/file-upload';
import { onSubmitError, yup } from 'utils/forms';
const LinkUploadDialog = (props) => {
    const { t } = useTranslation('object', { keyPrefix: 'cmp.LinkUpload' });
    const { t: apiTranslate } = useTranslation('api');
    const [error, setError] = React.useState();
    const id = React.useId();
    const [loading, setLoading] = React.useState(false);
    const operation = { loading };
    const schema = React.useMemo(() => yup.object({
        content: yup
            .string()
            .trim()
            .url()
            .label(t('contentField.label'))
            .required(),
        name: yup.string().label(t('nameField.label')).required(),
    }), [t]);
    const { handleSubmit, control, formState: { errors, isSubmitting }, reset, } = useForm({
        resolver: yupResolver(schema),
    });
    const closeDialog = () => {
        props.closeDialog();
        reset();
        setError(null);
    };
    const onSuccess = () => {
        closeDialog();
        props.onSuccess();
        enqueueSnackbar(t('uploadSuccessful'), { variant: 'info' });
    };
    const onError = ({ fileName, errorCode, status, }) => {
        if (errorCode) {
            setError({ fileName, message: apiTranslate(errorCode) });
            return;
        }
        if (status) {
            setError({ fileName, message: `${status.code}: ${status.text}` });
            return;
        }
        setError({ fileName, message: t('uploadPreparationError') });
    };
    const onSubmit = async (values) => {
        setLoading(true);
        const file = new File([values.content], values.name, { type: 'url/text' });
        return uploadFile(file, {
            headers: props.lessonId
                ? {
                    'Lesson-Id': props.lessonId,
                }
                : {},
            onError,
        })
            .then(onSuccess)
            .catch(onValidationError)
            .finally(() => {
            setLoading(false);
        });
    };
    return (_jsx(ConfirmationDialog, { maxWidth: 'sm', fullWidth: true, onClose: closeDialog, titleProps: { Icon: Link, text: t('uploadDialogTitle') }, open: props.isDialogOpen, cancelButtonProps: {
            onClick: closeDialog,
        }, confirmButtonProps: {
            disabled: isSubmitting || loading,
            form: id,
            type: 'submit',
        }, children: _jsx(LoadingWrapper, { mutation: operation, children: _jsx("form", { id: id, onSubmit: (e) => {
                    handleSubmit(onSubmit)(e).catch(onSubmitError);
                }, children: _jsxs(Stack, { spacing: 2, children: [error && (_jsxs(Alert, { severity: "error", sx: { m: 0 }, children: [error.fileName, " : ", error.message] })), _jsx(Controller, { control: control, name: "name", render: ({ field }) => {
                                var _a;
                                return (_jsx(TextFormItem, { ...field, label: t('nameField.label'), placeholder: t('nameField.placeholder'), errorMessage: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message }));
                            } }), _jsx(Controller, { control: control, name: "content", render: ({ field }) => {
                                var _a;
                                return (_jsx(TextFormItem, { ...field, label: t('contentField.label'), placeholder: t('contentField.placeholder'), errorMessage: (_a = errors.content) === null || _a === void 0 ? void 0 : _a.message, inputProps: { inputMode: 'url' } }));
                            } })] }) }) }) }));
};
export default LinkUploadDialog;
