import { useAccountPaymentPlanQuery, useAccountQuery, useAccountStorageQuery, useContractNumberQuery, useUpdateAccountMutation, } from 'api/generated';
import { getBaseOptions } from 'api/wrappers';
import { getDateFromApiString } from 'utils/date';
export const useAccountNotesQueryApi = (variables) => {
    var _a, _b;
    const { data, ...rest } = useAccountQuery(getBaseOptions(variables));
    const notes = (_b = (_a = data === null || data === void 0 ? void 0 : data.account) === null || _a === void 0 ? void 0 : _a.notes) !== null && _b !== void 0 ? _b : '';
    return { ...rest, data: notes };
};
export const useAccountQueryApi = (variables) => {
    const { data, ...rest } = useAccountQuery(getBaseOptions(variables));
    return { ...rest, data: data === null || data === void 0 ? void 0 : data.account };
};
export const useAccountStorageQueryApi = (variables, options) => {
    var _a;
    const { data, ...rest } = useAccountStorageQuery(getBaseOptions(variables, options));
    return { ...rest, data: (_a = data === null || data === void 0 ? void 0 : data.account) === null || _a === void 0 ? void 0 : _a.storage };
};
export const useUpdateAccountNotesMutation = useUpdateAccountMutation;
export const getAccountNotesMutationOptions = (id, notes) => ({
    variables: {
        account: {
            id,
            notes,
        },
    },
});
export const useContractNumberQueryApi = (variables) => {
    var _a;
    const { data, ...rest } = useContractNumberQuery(getBaseOptions(variables));
    return { ...rest, data: (_a = data === null || data === void 0 ? void 0 : data.account) === null || _a === void 0 ? void 0 : _a.contractNumber };
};
export const useAccountPaymentPlanQueryApi = (variables, options) => {
    var _a;
    const { data, ...rest } = useAccountPaymentPlanQuery(getBaseOptions(variables, options));
    const { activeSubscription } = (_a = data === null || data === void 0 ? void 0 : data.account) !== null && _a !== void 0 ? _a : {};
    if (!activeSubscription) {
        return { ...rest, data: undefined };
    }
    return {
        ...rest,
        data: {
            ...activeSubscription,
            endDate: activeSubscription.endDate
                ? getDateFromApiString(activeSubscription.endDate)
                : undefined,
        },
    };
};
export { useCancelTerminationMutation } from 'api/generated';
export const getTerminationMutationOptions = (id) => ({
    variables: {
        accountId: id,
    },
});
