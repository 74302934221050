import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AccountCircle, FileDownload, Key } from '@mui/icons-material';
import { Card, CardContent, CardHeader, Link as MuiLink, Stack, Typography, } from '@mui/material';
import { SKIP, useAccountQueryApi, useContractNumberQueryApi, useProviderQuery, } from 'api';
import { Button, LabelValueItem, LoadingWrapper, Tooltip, } from 'components/custom';
import { Flow, handleErrorFallback, handleFlowError, ory, } from 'components/kratos';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useSearchParams, } from 'react-router-dom';
import { getApiUrl } from 'utils/config';
import { setTitle } from 'utils/head';
import { HttpStatusCodes } from 'utils/http-status-codes';
import { ACCOUNT_DATA } from 'utils/links';
import { useResponsiveness } from 'utils/responsiveness';
import { RouterStateKeys, useRouterState } from 'utils/routing';
const CARD_MIN_WIDTH = 500;
/**
 * Timeout after which the export button can be used again.
 * This prevents accidental double clicks that would trigger multiple downloads.
 */
const EXPORT_BUTTON_TIMEOUT = 5000;
/**
 * Displays user account settings.
 */
const AccountData = () => {
    var _a;
    const { t: headTranslate } = useTranslation('settings', {
        keyPrefix: 'head',
    });
    React.useEffect(() => {
        setTitle(headTranslate('title.accountData'));
    }, [headTranslate]);
    const { t } = useTranslation('settings', { keyPrefix: 'body.accountData' });
    const { t: authTranslate } = useTranslation('auth');
    const [flow, setFlow] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const [submittedFlowMethod, setSubmittedFlowMethod] = React.useState('password');
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { getStateObject, clearRouterState } = useRouterState();
    const state = getStateObject();
    const freshAuthenticated = (_a = state[RouterStateKeys.AUTH]) !== null && _a !== void 0 ? _a : false;
    const [contractNumberVisibility, setContractNumberVisibility] = React.useState(freshAuthenticated);
    const [disabledExportButton, setDisabledExportButton] = React.useState(false);
    React.useEffect(() => {
        if (freshAuthenticated) {
            clearRouterState();
        }
    }, [freshAuthenticated, clearRouterState]);
    // Get ?flow=... from the URL
    const flowId = searchParams.get('flow');
    const returnTo = searchParams.get('return_to');
    React.useEffect(() => {
        // If we already have a flow, do nothing.
        if (flow) {
            return;
        }
        setLoading(true);
        // If ?flow=.. was in the URL, we fetch it
        if (flowId) {
            ory
                .getSettingsFlow({ id: String(flowId) })
                .then(({ data }) => {
                setFlow(data);
            })
                .catch(handleFlowError(navigate, 'settings', setFlow, authTranslate))
                .catch(handleErrorFallback(authTranslate))
                .finally(() => {
                setLoading(false);
            });
            return;
        }
        // Otherwise we initialize it
        ory
            .createBrowserSettingsFlow({
            returnTo: returnTo ? String(returnTo) : undefined,
        })
            .then(({ data }) => {
            setFlow(data);
        })
            .catch(handleFlowError(navigate, 'settings', setFlow, authTranslate))
            .catch(handleErrorFallback(authTranslate))
            .finally(() => {
            setLoading(false);
        });
    }, [flowId, navigate, returnTo, flow, authTranslate]);
    const onSubmit = (only) => async (values) => {
        setSubmittedFlowMethod(only);
        setLoading(true);
        /*
         * On submission, add the flow ID to the URL but do not navigate. This prevents the user loosing
         * his data when she/he reloads the page.
         */
        navigate(`${ACCOUNT_DATA}?flow=${String(flow === null || flow === void 0 ? void 0 : flow.id)}`, {
            replace: true,
        });
        return ory
            .updateSettingsFlow({
            flow: String(flow === null || flow === void 0 ? void 0 : flow.id),
            updateSettingsFlowBody: values,
        })
            .then(({ data }) => {
            // The settings have been saved and the flow was updated. Let's show it to the user!
            setFlow(data);
        })
            .catch(handleFlowError(navigate, 'settings', setFlow, authTranslate))
            .catch(async (err) => {
            var _a;
            // If the previous handler did not catch the error it's most likely a form validation error
            if (((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === HttpStatusCodes.BAD_REQUEST) {
                // Yup, it is!
                setFlow(err.response.data);
                return Promise.resolve();
            }
            return Promise.reject(err);
        })
            .catch(handleErrorFallback(authTranslate))
            .finally(() => {
            setLoading(false);
        });
    };
    const { user } = useOutletContext();
    const accountStatus = useAccountQueryApi({ id: user.id });
    const [providerQuery, providerQueryStatus] = useProviderQuery();
    React.useEffect(() => {
        providerQuery({ email: user.email });
    }, [providerQuery, user]);
    const contractNumberStatus = useContractNumberQueryApi(contractNumberVisibility ? { id: user.id } : SKIP);
    const { isLargeViewport } = useResponsiveness();
    const cardDefaultSx = {
        minWidth: isLargeViewport ? CARD_MIN_WIDTH : 'auto',
    };
    return (_jsx(LoadingWrapper, { status: [
            { loading },
            accountStatus,
            contractNumberStatus,
            providerQueryStatus,
        ], children: _jsxs(Stack, { direction: { lg: 'row', xs: 'column' }, spacing: 3, sx: {
                margin: 'auto',
                maxWidth: '60rem',
                ml: 0,
            }, children: [_jsxs(Card, { sx: cardDefaultSx, children: [_jsx(CardHeader, { avatar: _jsx(AccountCircle, {}), title: _jsx(Typography, { variant: "h6", children: authTranslate('body.step.settings.cardTitles.profile') }) }), _jsx(CardContent, { children: _jsxs(Stack, { spacing: 1, children: [providerQueryStatus.data &&
                                        !providerQueryStatus.data.is_school && (_jsx(_Fragment, { children: contractNumberVisibility ? (_jsx(LabelValueItem, { sx: { fontSize: '0.9rem' }, label: authTranslate('body.step.settings.customerNumber'), value: contractNumberStatus.data })) : (_jsx(Button, { variant: "contained", color: "success", onClick: () => {
                                                setContractNumberVisibility(true);
                                            }, children: authTranslate('body.step.settings.customerNumberButton') })) })), _jsx(Flow, { hideGlobalMessages: submittedFlowMethod !== 'profile', onSubmit: onSubmit('profile'), flow: flow, only: "profile", templateName: "settings" })] }) })] }), _jsxs(Card, { sx: cardDefaultSx, children: [_jsx(CardHeader, { avatar: _jsx(Key, {}), title: _jsx(Typography, { variant: "h6", children: authTranslate('body.step.settings.cardTitles.password') }) }), _jsx(CardContent, { children: _jsx(Flow, { hideGlobalMessages: submittedFlowMethod !== 'password', onSubmit: onSubmit('password'), flow: flow, only: "password", templateName: "settings" }) })] }), _jsxs(Card, { sx: cardDefaultSx, children: [_jsx(CardHeader, { avatar: _jsx(FileDownload, {}), title: _jsx(Typography, { variant: "h6", children: t('exportUserData.cardTitle') }) }), _jsx(CardContent, { children: _jsx(Tooltip, { title: t('exportUserData.buttonTooltip'), describeChild: true, children: _jsx(Button, { LinkComponent: MuiLink, href: `${getApiUrl()}/api/v1/data-export`, onClick: () => {
                                        setDisabledExportButton(true);
                                        window.setTimeout(() => {
                                            setDisabledExportButton(false);
                                        }, EXPORT_BUTTON_TIMEOUT);
                                    }, disabled: disabledExportButton, size: "large", color: "primary", variant: "contained", fullWidth: true, download: true, role: "button", children: t('exportUserData.button') }) }) })] })] }) }));
};
export default AccountData;
