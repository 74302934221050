/**
 * Maps different key/value pairs to valid Select Option object.
 * If the object contains `name` and `id` fields they will be used per default.
 * @param list Array with key/value pairs to map.
 * @param defaultLabelKey Key name to use as a label.
 * @param defaultValueKey Key name to use as a value.
 * @returns An array of objects with `label` and `value` keys.
 */
export const getSelectOptions = (list, defaultLabelKey, defaultValueKey) => {
    if (!list) {
        return [];
    }
    const labelKey = defaultLabelKey !== null && defaultLabelKey !== void 0 ? defaultLabelKey : 'name';
    const valueKey = defaultValueKey !== null && defaultValueKey !== void 0 ? defaultValueKey : 'id';
    return list.map((item) => {
        return {
            label: item[labelKey],
            value: item[valueKey],
        };
    });
};
