import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
export var Widget;
(function (Widget) {
    Widget["NOTES"] = "notes";
    Widget["FILES"] = "files";
    Widget["LESSONS"] = "lessons";
})(Widget || (Widget = {}));
const WidgetContext = React.createContext({
    setWidget: () => undefined,
    widget: undefined,
});
const WidgetProvider = ({ children, }) => {
    const [widget, setWidget] = React.useState(undefined);
    return (_jsx(WidgetContext.Provider, { value: {
            setWidget,
            widget,
        }, children: children }));
};
export const useWidgetContext = () => React.useContext(WidgetContext);
export default WidgetProvider;
