import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormLabel, FormHelperText, TextField, } from '@mui/material';
import { ClearIconButton } from 'components/custom';
import React from 'react';
const TextFormItem = (props, ref) => {
    var _a;
    const { errorMessage, StartAdornmentIcon, clearButtonAriaLabel, onClear, InputProps, ...cmpProps } = props;
    const isError = !!errorMessage;
    const id = React.useId();
    const errorId = React.useId();
    return (_jsxs(FormControl, { disabled: cmpProps.disabled, variant: "outlined", size: "small", fullWidth: true, error: isError, children: [_jsx(FormLabel, { htmlFor: id, children: cmpProps.label }), _jsx(TextField, { color: isError ? 'error' : 'primary', InputProps: {
                    'aria-describedby': errorId,
                    startAdornment: StartAdornmentIcon && (_jsx(StartAdornmentIcon, { sx: { mr: '0.5rem' } })),
                    ...(onClear
                        ? {
                            endAdornment: (_jsx(ClearIconButton, { clearButtonAriaLabel: clearButtonAriaLabel, onClick: onClear })),
                        }
                        : {}),
                    ...InputProps,
                }, ...cmpProps, id: id, label: "", ref: ref, size: "small", variant: "outlined", value: (_a = cmpProps.value) !== null && _a !== void 0 ? _a : '' }), _jsx(FormHelperText, { id: errorId, error: isError, children: errorMessage })] }));
};
export default React.forwardRef(TextFormItem);
