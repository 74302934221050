import { useEducationalYearsQuery } from 'api/generated';
import { getBaseOptions } from 'api/wrappers';
export const useEducationalYearsQueryApi = (variables, options) => {
    var _a;
    const { data, ...rest } = useEducationalYearsQuery(getBaseOptions(variables !== null && variables !== void 0 ? variables : {}, {
        ...options,
        fetchPolicy: 'cache-first',
    }));
    const educationalYears = (_a = data === null || data === void 0 ? void 0 : data.educationalYears) !== null && _a !== void 0 ? _a : [];
    return { ...rest, data: educationalYears };
};
