import { isEmpty } from 'lodash-es';
/**
 * Appends a query parameter to the URL and returns the new URL.
 * @param url The URL to which the parameter would be appended.
 * @param params Parameters which will be appended to the URL.
 * @returns URL with appended query params.
 */
export const appendParams = (url, params) => {
    if (!url || isEmpty(params)) {
        return url;
    }
    const param = Object.keys(params)
        .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    })
        .join('&');
    return url + (url.includes('?') ? '&' : '?') + param;
};
