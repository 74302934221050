import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Close, Check, Share, Launch, ContentCopy, Edit, WarningAmberOutlined, Delete, } from '@mui/icons-material';
import { Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Dialog, LoadingWrapper } from 'components/custom';
import { Controller, useForm } from 'react-hook-form';
import { getLessonMutationOptions, useUpdateLessonMutation } from 'api';
import { onSubmitError, yup } from 'utils/forms';
import { yupResolver } from '@hookform/resolvers/yup';
import { addWeeks, endOfDay, isFuture, startOfDay } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { formatDate, getApiStringFromDate } from 'utils/date';
import { getSharedLessonUrl } from 'utils/links';
const DEFAULT_WEEKS_SHARING = 2;
const MAX_WEEKS_SHARING = 8;
const ShareLessonButton = ({ lessonId, sharingEndDateTime, sharingToken = '', }) => {
    const { t } = useTranslation('lesson', {
        keyPrefix: 'cmp.ShareLesson',
    });
    const formId = React.useId();
    const maxDate = addWeeks(new Date(), MAX_WEEKS_SHARING);
    const isSharingValid = sharingEndDateTime && isFuture(sharingEndDateTime);
    const [openStatus, setOpenStatus] = React.useState(null);
    const schema = yup.object({
        sharingEndDateTime: yup
            .date()
            .typeError(t('dateErrorInvalid'))
            .min(startOfDay(new Date()), t('dateErrorMin'))
            .max(maxDate, t('dateErrorMax', { maxWeeksSharing: MAX_WEEKS_SHARING }))
            .label(t('datePickerLabel'))
            .required(t('dateErrorIsEmpty')),
    });
    const { control, formState: { errors, isSubmitting }, handleSubmit, reset, } = useForm({
        resolver: yupResolver(schema),
    });
    React.useEffect(() => {
        if (isSharingValid) {
            reset({ sharingEndDateTime });
        }
        else {
            reset({
                sharingEndDateTime: addWeeks(new Date(), DEFAULT_WEEKS_SHARING),
            });
        }
    }, [reset, sharingEndDateTime, openStatus, isSharingValid]);
    const [updateLesson, statusLesson] = useUpdateLessonMutation();
    const onSubmit = async (values) => {
        return updateLesson(getLessonMutationOptions({
            id: lessonId,
            sharingEndDateTime: getApiStringFromDate(endOfDay(values.sharingEndDateTime)),
        })).then(() => {
            enqueueSnackbar(t('createSuccessMessage'));
            setOpenStatus('info');
        });
    };
    const onDelete = async () => {
        return updateLesson(getLessonMutationOptions({
            id: lessonId,
            sharingEndDateTime: '',
        })).then(() => {
            enqueueSnackbar(t('deleteSuccessMessage'));
            setOpenStatus(null);
        });
    };
    const handleCopyLink = () => {
        // eslint-disable-next-line compat/compat -- not supported by iOS Safari 12.2-12.5
        navigator.clipboard
            .writeText(getSharedLessonUrl(sharingToken))
            .then(() => {
            enqueueSnackbar(t('copyLinkSuccessMessage'), {
                preventDuplicate: true,
            });
        })
            .catch(() => {
            enqueueSnackbar(t('copyLinkErrorMessage', {
                preventDuplicate: true,
                variant: 'error',
            }));
        });
    };
    const closeButton = (_jsx(Button, { "data-testid": "cancel", fullWidth: true, variant: "contained", color: openStatus === 'edit' ? 'error' : 'secondary', startIcon: _jsx(Close, {}), onClick: () => {
            setOpenStatus(null);
        }, children: openStatus === 'edit' ? t('cancelButton') : t('closeButton') }, "cancel"));
    const editButton = (_jsx(Button, { "data-testid": "edit", variant: "contained", color: "secondary", startIcon: _jsx(Edit, {}), disabled: isSubmitting || statusLesson.loading, form: lessonId, type: "submit", onClick: () => {
            setOpenStatus('edit');
        }, children: t('editButton') }, "edit"));
    const submitButton = (_jsx(Button, { "data-testid": "submit", variant: "contained", color: "success", startIcon: _jsx(Check, {}), disabled: isSubmitting || statusLesson.loading, form: formId, type: "submit", children: t('submitButton') }, "submit"));
    const copyLinkButton = (_jsx(Button, { "data-testid": "copyLink", variant: "contained", color: "success", startIcon: _jsx(ContentCopy, {}), onClick: handleCopyLink, children: t('copyLinkButton') }, "copyLink"));
    const openLinkButton = (_jsx(Button, { "data-testid": "openLink", variant: "contained", color: "secondary", startIcon: _jsx(Launch, {}), component: Link, href: getSharedLessonUrl(sharingToken), target: "_blank", onClick: () => {
            setOpenStatus(null);
        }, children: t('openLinkButton') }, "openLink"));
    const getActiveButtons = () => {
        switch (openStatus) {
            case 'edit':
                return [closeButton, submitButton];
            case 'expired':
                return [closeButton, editButton];
            case 'info':
                return [closeButton, copyLinkButton, openLinkButton];
            default:
                return [closeButton];
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { "data-testid": "linkUpload", variant: "contained", color: isSharingValid ? 'success' : 'secondary', startIcon: _jsx(Share, {}), onClick: () => {
                    if (!sharingEndDateTime) {
                        setOpenStatus('edit');
                    }
                    else if (isSharingValid) {
                        setOpenStatus('info');
                    }
                    else {
                        setOpenStatus('expired');
                    }
                }, children: isSharingValid ? t('buttonTextActive') : t('buttonText') }), _jsxs(Dialog, { open: !!openStatus, titleProps: {
                    Icon: Share,
                    text: t('dialogTitle'),
                }, onClose: () => {
                    setOpenStatus(null);
                }, onTransitionExited: () => {
                    statusLesson.reset();
                }, actions: getActiveButtons(), children: [_jsx(Typography, { sx: { paddingBottom: '1rem' }, children: t('description') }), openStatus === 'edit' && (_jsx(LoadingWrapper, { mutation: statusLesson, children: _jsx("form", { id: formId, onSubmit: (e) => {
                                handleSubmit(onSubmit)(e).catch(onSubmitError);
                            }, children: _jsx(Controller, { control: control, name: "sharingEndDateTime", render: ({ field }) => {
                                    var _a;
                                    return (_jsx(DatePicker, { ...field, label: t('datePickerLabel'), disablePast: true, maxDate: maxDate, errorMessage: (_a = errors.sharingEndDateTime) === null || _a === void 0 ? void 0 : _a.message }));
                                } }) }) })), ' ', openStatus === 'info' && (_jsxs(Stack, { direction: "row", alignItems: "center", children: [_jsx(Typography, { fontWeight: "bold", children: t('validityPeriod', {
                                    endDate: sharingEndDateTime && formatDate(sharingEndDateTime),
                                }) }), _jsx(Button, { disabled: isSubmitting || statusLesson.loading, color: "secondary", startIcon: _jsx(Delete, {}), sx: { marginLeft: '1rem' }, onClick: () => {
                                    void onDelete();
                                }, children: t('deleteButton') })] })), openStatus === 'expired' && (_jsxs(Stack, { direction: "row", alignItems: "center", gap: "1rem", children: [_jsx(WarningAmberOutlined, {}), _jsx(Typography, { fontWeight: "bold", children: t('expiredValidityPeriod', {
                                    endDate: sharingEndDateTime && formatDate(sharingEndDateTime),
                                }) })] }))] })] }));
};
export default ShareLessonButton;
