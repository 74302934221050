import { TimetablesDocument, useTimetableExistsQuery, useTimetablesQuery, } from 'api/generated';
import { getBaseOptions } from 'api/wrappers';
import { getApiStringFromDate, getDateFromApiString } from 'utils/date';
export const useTimetablesQueryApi = (variables, options) => {
    var _a;
    const { data, ...rest } = useTimetablesQuery(getBaseOptions(variables, options));
    const timetables = ((_a = data === null || data === void 0 ? void 0 : data.timetables) !== null && _a !== void 0 ? _a : [])
        .slice()
        .sort((a, b) => (a.startDate > b.startDate ? 1 : -1))
        .map((timetable) => {
        return {
            ...timetable,
            endDate: getDateFromApiString(timetable.endDate, 'endOfDay'),
            startDate: getDateFromApiString(timetable.startDate),
        };
    });
    return { ...rest, data: timetables };
};
export const useTimetableExistsQueryApi = () => {
    var _a;
    const { data, ...rest } = useTimetableExistsQuery();
    return { data: (_a = data === null || data === void 0 ? void 0 : data.timetableExists) !== null && _a !== void 0 ? _a : false, ...rest };
};
export const getCreateTimetableMutationOptions = (timetable) => ({
    refetchQueries: [TimetablesDocument],
    variables: {
        input: {
            ...timetable,
            endDate: getApiStringFromDate(timetable.endDate),
            startDate: getApiStringFromDate(timetable.startDate),
        },
    },
});
export const getUpdateTimetableMutationOptions = (timetable) => {
    return {
        variables: {
            input: {
                ...timetable,
                endDate: getApiStringFromDate(timetable.endDate),
                startDate: getApiStringFromDate(timetable.startDate),
            },
        },
    };
};
export const getDeleteTimetableMutationOptions = (id) => ({
    refetchQueries: [TimetablesDocument],
    variables: {
        id,
    },
});
export { useCreateTimetableMutation, useDeleteTimetableMutation, useUpdateTimetableMutation, } from 'api/generated';
