import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, Typography } from '@mui/material';
import { PasswordValidation } from 'components/kratos/ui';
import { getLayoutItems } from './items';
const SPACING = 3;
/**
 * Mapping of Kratos UI node name to key.
 * Keeps away Kratos naming from the template code.
 */
const nameToKeyMap = {
    method: 'submit',
    password: 'password',
    'traits.accepted_gtc': 'gtc',
    'traits.accepted_tos': 'tos',
    'traits.email': 'email',
    'traits.name.first': 'firstName',
    'traits.name.last': 'lastName',
};
export const SettingsTemplate = ({ children, only, setValidity, }) => {
    var _a, _b, _c, _d;
    const items = getLayoutItems(children, nameToKeyMap);
    return (_jsxs(Stack, { spacing: SPACING, width: "30rem", maxWidth: "100%", children: [items.messages, only === 'password' ? null : (_jsxs(Typography, { sx: { fontSize: '0.9rem' }, children: ["E-Mail: ", _jsx("i", { children: (_b = (_a = items.email) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.value })] })), only === 'password' ? null : (_jsxs(Stack, { direction: "row", spacing: 3, children: [items.firstName, items.lastName] })), _jsxs(Box, { children: [items.password, only === 'password' ? (_jsx(PasswordValidation, { setValidity: setValidity, passwordValue: (_d = (_c = items.password) === null || _c === void 0 ? void 0 : _c.props) === null || _d === void 0 ? void 0 : _d.value, spacing: SPACING })) : null] }), items.rest, items.submit] }));
};
