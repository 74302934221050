import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Edit } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { Tooltip } from 'components/custom';
import { isEmpty, map } from 'lodash-es';
import { useTranslation } from 'react-i18next';
const FileObjectTagCell = ({ tags, onEditClick, }) => {
    const { t } = useTranslation('object');
    const tagList = isEmpty(tags) ? '-' : map(tags, 'value').join(', ');
    return (_jsxs(Box, { sx: {
            '& .MuiIconButton-root': {
                visibility: 'hidden',
            },
            '&:hover .MuiIconButton-root': {
                visibility: 'visible',
            },
            alignItems: 'center',
            display: 'flex',
            width: '100%',
        }, children: [_jsx(Box, { title: tagList, sx: { mr: 0.5, overflow: 'hidden', textOverflow: 'ellipsis' }, children: tagList }), onEditClick && (_jsx(Tooltip, { title: t('cmp.FileObjectDataGrid.action.editTags'), children: _jsx(IconButton, { size: "small", onClick: onEditClick, children: _jsx(Edit, { fontSize: "inherit" }) }) }))] }));
};
export default FileObjectTagCell;
