import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useSnackbar } from 'notistack';
import { Alert } from 'components/custom';
const mapNotistackVariantToAlertSeverity = (notistackVariant) => {
    switch (notistackVariant) {
        case 'error':
        case 'success':
        case 'warning':
            return notistackVariant;
        default:
            return 'info';
    }
};
const SnackbarAlert = (props, ref) => {
    const { message, id, variant, style } = props;
    const { closeSnackbar } = useSnackbar();
    const handleClose = () => {
        closeSnackbar(id);
    };
    return (_jsx(Alert, { ref: ref, onClose: handleClose, severity: mapNotistackVariantToAlertSeverity(variant), variant: "standard", style: style, children: message }));
};
export default React.forwardRef(SnackbarAlert);
