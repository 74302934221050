var _a, _b;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { DragAndDropProvider, SnackbarProvider, WidgetProvider, } from 'components/custom';
import { setDefaultOptions } from 'date-fns';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import theme from 'theme';
import { getEnvironment, getVersion } from 'utils/config';
import { DATE_LOCALE_MAPPING } from 'utils/date';
import { SENTRY } from 'utils/external-links';
import i18n from 'utils/i18n';
import { useResponsiveness } from 'utils/responsiveness';
import { getRoutes } from './routes';
const rootElement = document.getElementById('root');
if (!rootElement) {
    throw new Error('Root element not found.');
}
setDefaultOptions({ locale: DATE_LOCALE_MAPPING[i18n.language] });
const router = createBrowserRouter(getRoutes(), {
    basename: (_a = process.env.ROUTER_BASE_NAME) !== null && _a !== void 0 ? _a : '',
});
const env = getEnvironment();
if (env === 'production' || env === 'staging' || env === 'main') {
    Sentry.init({
        dsn: SENTRY,
        environment: env,
        integrations: [
            new Sentry.BrowserTracing(),
            new CaptureConsole({
                levels: ['error'],
            }),
        ],
        release: getVersion(),
        tracesSampleRate: 1.0,
    });
}
const App = () => {
    const { isExtraSmallViewport } = useResponsiveness();
    return (_jsx(ThemeProvider, { theme: theme, children: _jsx(DragAndDropProvider, { children: _jsx(WidgetProvider, { children: _jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, adapterLocale: DATE_LOCALE_MAPPING.de, children: _jsxs(SnackbarProvider, { children: [_jsx(CssBaseline, {}), _jsx(Box, { onDragOver: (e) => {
                                    e.preventDefault();
                                }, onDrop: (e) => {
                                    e.preventDefault();
                                }, sx: {
                                    display: isExtraSmallViewport ? 'block' : 'flex',
                                    minHeight: '100vh',
                                }, children: _jsx(RouterProvider, { router: router }) })] }) }) }) }) }));
};
createRoot(rootElement).render(_jsx(App, {}));
(_b = module.hot) === null || _b === void 0 ? void 0 : _b.accept();
