import { addDays } from 'date-fns';
import { throttle } from 'lodash-es';
import React from 'react';
import { log, Severity } from 'utils/logger';
const keyPrefix = 'temp-';
var Keys;
(function (Keys) {
    Keys["EXPIRATION_DATE"] = "exp";
    Keys["DATA"] = "data";
})(Keys || (Keys = {}));
/**
 * Parses given data string to JSON.
 * @param data Data string.
 * @returns Item object.
 */
const parseJson = (data) => {
    if (data === null) {
        return undefined;
    }
    try {
        return JSON.parse(data);
    }
    catch (error) {
        return undefined;
    }
};
/**
 * Removes all expired items.
 * @param force Forces removal of all items. Not only expired ones.
 */
const cleanUp = (force = false) => {
    Object.entries(window.localStorage).forEach(([key, value]) => {
        var _a;
        if (key.startsWith(keyPrefix) && typeof value === 'string') {
            try {
                const item = parseJson(value);
                if (force ||
                    ((_a = (item && item[Keys.EXPIRATION_DATE] < Date.now())) !== null && _a !== void 0 ? _a : !item)) {
                    window.localStorage.removeItem(key);
                }
            }
            catch (error) {
                log(`Cannot clear temporary data for key: ${key}`, {
                    dump: error,
                    severity: Severity.ERROR,
                });
            }
        }
    });
};
/**
 * Returns stored data.
 * @param key Key under which data is stored.
 * @returns String data.
 */
export const getData = (key) => {
    const item = parseJson(window.localStorage.getItem(keyPrefix + key));
    return item ? item[Keys.DATA] : '';
};
/**
 * Sets data to storage.
 * @param key Key under which data will be stored.
 * @param data Data to store.
 * @param expirationDate Unix timestamp after which the data can be auto-removed.
 */
export const setData = (key, data, expirationDate) => {
    cleanUp();
    const item = {
        [Keys.DATA]: data,
        [Keys.EXPIRATION_DATE]: expirationDate.valueOf(),
    };
    window.localStorage.setItem(keyPrefix + key, JSON.stringify(item));
};
/**
 * Removes data from storage.
 * @param key Key under which data is stored.
 */
export const removeData = (key) => {
    window.localStorage.removeItem(keyPrefix + key);
};
/**
 * Removes all temporary data from storage.
 * Use case: logout.
 */
export const removeAllData = () => {
    cleanUp(true);
};
const DEFAULT_DELAY_MS = 1000;
const DEFAULT_KEEP_DAYS = 3;
/**
 * Provides basic function to use temporary storage with a component.
 * @param key Key under which data will be stored.
 * @param delayMs Store delay used to throttle data flow.
 * @param daysToKeepTempData Count of days for how long to keep data.
 */
export const useTemporaryStorageEditFlow = (key, delayMs = DEFAULT_DELAY_MS, daysToKeepTempData = DEFAULT_KEEP_DAYS) => {
    const [editData, setEditData] = React.useState('');
    const [editMode, setEditMode] = React.useState(false);
    React.useEffect(() => {
        const prevData = getData(key);
        if (prevData) {
            setEditData(prevData);
            setEditMode(true);
        }
    }, [key]);
    const setDataThrottled = React.useMemo(() => throttle(setData, delayMs), [delayMs]);
    const onChange = (event) => {
        const { value } = event.target;
        setEditData(value);
        setDataThrottled(key, value, addDays(new Date(), daysToKeepTempData));
    };
    const startEdit = (data) => {
        setEditMode(true);
        setEditData(data);
    };
    const stopEdit = () => {
        setDataThrottled.cancel();
        removeData(key);
        setEditMode(false);
    };
    return {
        editData,
        editMode,
        onChange,
        setEditData,
        setEditMode,
        startEdit,
        stopEdit,
    };
};
