import { AudioFileOutlined, BackupTableOutlined, CodeOutlined, DescriptionOutlined, InsertDriveFileOutlined, Inventory2Outlined, Link, PhotoOutlined, PictureAsPdfOutlined, SlideshowOutlined, TextSnippetOutlined, VideoFileOutlined, } from '@mui/icons-material';
import { extractType, extractPureMimeType } from 'utils/mime-types';
// eslint-disable-next-line complexity -- Complexity? Two switch-case blocks?
export const getFileIcon = (mimeType) => {
    switch (extractPureMimeType(mimeType)) {
        case 'url/text': {
            return Link;
        }
        case 'application/pdf': {
            return PictureAsPdfOutlined;
        }
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/vnd.oasis.opendocument.presentation': {
            return SlideshowOutlined;
        }
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.oasis.opendocument.spreadsheet':
        case 'text/csv': {
            return BackupTableOutlined;
        }
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.oasis.opendocument.text':
        case 'application/epub+zip':
        case 'application/rtf': {
            return DescriptionOutlined;
        }
        case 'application/zip':
        case 'application/vnd.rar':
        case 'application/x-7z-compressed':
        case 'application/x-bzip':
        case 'application/x-bzip2':
        case 'application/gzip':
        case 'application/java-archive': {
            return Inventory2Outlined;
        }
        case 'text/css':
        case 'text/html':
        case 'text/javascript':
        case 'application/json':
        case 'application/xml':
        case 'application/xhtml+xml': {
            return CodeOutlined;
        }
        default: {
            switch (extractType(mimeType)) {
                case 'audio': {
                    return AudioFileOutlined;
                }
                case 'image': {
                    return PhotoOutlined;
                }
                case 'video': {
                    return VideoFileOutlined;
                }
                case 'text': {
                    return TextSnippetOutlined;
                }
                default: {
                    return InsertDriveFileOutlined;
                }
            }
        }
    }
};
