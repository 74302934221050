import React from 'react';
import { get, has } from 'lodash-es';
/**
 * Checks if given element has a Kratos UI node attached.
 * @param element React element.
 * @returns `true` if node is found; otherwise `false`.
 */
const hasUiNode = (element) => {
    return has(element, 'props.node');
};
/**
 * Extracts Kratos UI node from React element.
 * @param element React element.
 * @returns Kratos UI node or `undefined`.
 */
const getUiNode = (element) => {
    var _a;
    return (_a = element.props) === null || _a === void 0 ? void 0 : _a.node;
};
/**
 * Returns default empty items for given keys.
 * @param keys Item IDs.
 * @returns Default items.
 */
const getDefaultItems = (keys) => {
    const defaultItems = {};
    keys.forEach((key) => {
        defaultItems[key] = null;
    });
    return defaultItems;
};
/**
 * Returns layout items to use in the template.
 * Remember to always use:
 * - The `rest` property in the template, so that unknown fields are still visible.
 * - The `messages` property in the template, so that feedback messages are displayed.
 * @param children React node used as children for a template.
 * @param nameToKeyMap Ory Kratos UI node name to key used in template mapping.
 * @returns Layout items for all keys.
 */
export const getLayoutItems = (children, nameToKeyMap) => {
    const rest = [];
    const feedback = {
        messages: null,
    };
    const items = getDefaultItems(Object.values(nameToKeyMap));
    React.Children.forEach(children, (element) => {
        if (!React.isValidElement(element)) {
            return;
        }
        if (!hasUiNode(element)) {
            if (has(element, 'props.messages')) {
                feedback.messages = element;
            }
            else {
                rest.push(element);
            }
            return;
        }
        const node = getUiNode(element);
        const name = get(node, 'attributes.name', '');
        const itemId = nameToKeyMap[name];
        if (itemId) {
            items[itemId] = element;
        }
        else {
            rest.push(element);
        }
    });
    return { ...items, ...feedback, rest };
};
