import { format } from 'date-fns';
import AfternoonImage from 'public/img/illustrations/day_period/afternoon.svg';
import EveningImage from 'public/img/illustrations/day_period/evening.svg';
import MorningImage from 'public/img/illustrations/day_period/morning.svg';
/**
 * Returns illustration source for given day period.
 * @param dayPeriod Day period.
 * @returns Image file path.
 */
export const getIllustrationSrc = (dayPeriod) => {
    switch (dayPeriod) {
        case 'morning':
            return MorningImage;
        case 'afternoon':
            return AfternoonImage;
        default:
            return EveningImage;
    }
};
/**
 * Returns illustration alternative-text for given day period.
 * @param dayPeriod Day period.
 * @param t Translation function with `index` namespace.
 * @returns Translated alternative-text.
 */
export const getIllustrationAlt = (dayPeriod, t) => {
    switch (dayPeriod) {
        case 'afternoon':
            return t('cmp.Greeting.afternoon.imgAlt');
        case 'morning':
            return t('cmp.Greeting.morning.imgAlt');
        default:
            return t('cmp.Greeting.evening.imgAlt');
    }
};
/**
 * Returns greeting for given day period.
 * @param dayPeriod Day period.
 * @param t Translation function with `index` namespace.
 * @returns Translated greeting.
 */
export const getGreeting = (dayPeriod, t) => {
    switch (dayPeriod) {
        case 'afternoon':
            return t('cmp.Greeting.afternoon.greeting');
        case 'morning':
            return t('cmp.Greeting.morning.greeting');
        default:
            return t('cmp.Greeting.evening.greeting');
    }
};
/**
 * Returns date in format for the Greeting component.
 * @param date Date object.
 * @returns Date in Greeting component format.
 */
export const formatGreetingDate = (date) => format(date, 'EEEE, dd. MMMM yyyy, H:mm');
