import { jsx as _jsx } from "react/jsx-runtime";
import { Step, StepLabel } from '@mui/material';
import StyledStepper from './Styled';
const Stepper = ({ steps, activeStep, onStepClick, isStepDisabled, ...props }) => {
    const getIconProps = (index, disabled) => {
        return onStepClick && !disabled
            ? {
                onClick: () => {
                    onStepClick(index);
                },
                role: 'button',
                sx: { cursor: 'pointer' },
            }
            : {};
    };
    return (_jsx(StyledStepper, { activeStep: activeStep, alternativeLabel: true, ...props, children: steps.map(({ label, Icon }, index) => {
            const disabled = isStepDisabled === null || isStepDisabled === void 0 ? void 0 : isStepDisabled(index, activeStep);
            return (_jsx(Step, { completed: index < activeStep, disabled: disabled, children: _jsx(StepLabel, { icon: _jsx(Icon, { ...getIconProps(index, disabled) }), children: label }) }, label));
        }) }));
};
export default Stepper;
