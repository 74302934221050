import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddCircle, Edit } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { getCreateCourseMutationOptions, getUpdateCourseMutationOptions, isLoading, onValidationError, useCreateCourseMutation, useEducationalYearsQueryApi, useSubjectsQueryApi, useUpdateCourseMutation, } from 'api';
import { ConfirmationDialog, LoadingWrapper, Select, TextFormItem, } from 'components/custom';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDialogMode } from 'utils/dialog-form';
import { onSubmitError, yup } from 'utils/forms';
import { getSelectOptions } from 'utils/input';
import ColorPicker, { getAvailableColors } from './ColorPicker';
const SaveCourseDialog = (props) => {
    const { activeCourse, close } = props;
    const { t } = useTranslation('timetable', {
        keyPrefix: 'steps.courses.cmp',
    });
    const id = React.useId();
    const { modeCreate, open } = useDialogMode(activeCourse);
    const { schoolYearId = '' } = useParams();
    const [namePlaceholder, setNamePlaceholder] = React.useState('');
    const schema = React.useMemo(() => yup.object({
        color: yup.string().label(t('ColorPicker.label')).required(),
        educationalYearId: yup
            .string()
            .label(t('SaveCourseDialog.educationalYearField.label'))
            .required(),
        name: yup
            .string()
            .label(t('SaveCourseDialog.nameField.label'))
            .transform((value) => value || namePlaceholder)
            .default(namePlaceholder),
        subjectId: yup
            .string()
            .label(t('SaveCourseDialog.subjectField.label'))
            .required(),
    }), [namePlaceholder, t]);
    const { handleSubmit, control, formState: { errors, isSubmitting }, reset, watch, } = useForm({
        resolver: yupResolver(schema),
    });
    React.useEffect(() => {
        reset(activeCourse !== null && activeCourse !== void 0 ? activeCourse : {});
    }, [activeCourse, reset]);
    const subjectsStatus = useSubjectsQueryApi();
    const educationalYearsStatus = useEducationalYearsQueryApi();
    const [createCourse, createCourseStatus] = useCreateCourseMutation();
    const [updateCourse, updateCourseStatus] = useUpdateCourseMutation();
    const mutationStatus = modeCreate ? createCourseStatus : updateCourseStatus;
    React.useEffect(() => {
        const subscription = watch((formValues) => {
            var _a, _b;
            const subject = subjectsStatus.data.find((v) => v.id === formValues.subjectId);
            const educationalYear = educationalYearsStatus.data.find((v) => v.id === formValues.educationalYearId);
            setNamePlaceholder(`${(_a = subject === null || subject === void 0 ? void 0 : subject.name) !== null && _a !== void 0 ? _a : ''} ${(_b = educationalYear === null || educationalYear === void 0 ? void 0 : educationalYear.name) !== null && _b !== void 0 ? _b : ''}`);
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [educationalYearsStatus.data, subjectsStatus.data, watch]);
    const onSuccess = () => {
        close();
        enqueueSnackbar(t('SaveCourseDialog.successMessage'));
    };
    const onSubmit = async (values) => {
        if (activeCourse === null || activeCourse === void 0 ? void 0 : activeCourse.id) {
            return updateCourse(getUpdateCourseMutationOptions({ ...values, id: activeCourse.id }))
                .then(onSuccess)
                .catch(onValidationError);
        }
        return createCourse(getCreateCourseMutationOptions({ ...values, schoolYearId }))
            .then(onSuccess)
            .catch(onValidationError);
    };
    const loading = isLoading([
        subjectsStatus,
        educationalYearsStatus,
        createCourseStatus,
        updateCourseStatus,
    ]);
    return (_jsx(ConfirmationDialog, { open: open, onClose: close, onTransitionExited: () => {
            mutationStatus.reset();
        }, titleProps: {
            Icon: modeCreate ? AddCircle : Edit,
            text: modeCreate
                ? t('SaveCourseDialog.addCourseTitle')
                : t('SaveCourseDialog.editCourseTitle'),
        }, cancelButtonProps: {
            onClick: close,
        }, confirmButtonProps: {
            disabled: isSubmitting || loading,
            form: id,
            type: 'submit',
        }, fullWidth: true, children: _jsx(LoadingWrapper, { status: [subjectsStatus, educationalYearsStatus], mutation: mutationStatus, children: _jsx("form", { id: id, onSubmit: (e) => {
                    handleSubmit(onSubmit)(e).catch(onSubmitError);
                }, children: _jsxs(Stack, { spacing: 2, children: [_jsxs(Box, { sx: { display: 'grid', gap: 2, gridTemplateColumns: '2fr 1fr' }, children: [_jsx(Controller, { control: control, name: "subjectId", render: ({ field }) => {
                                        var _a;
                                        return (_jsx(Select, { ...field, label: t('SaveCourseDialog.subjectField.label'), placeholder: t('SaveCourseDialog.subjectField.placeholder'), errorMessage: (_a = errors.subjectId) === null || _a === void 0 ? void 0 : _a.message, options: getSelectOptions(subjectsStatus.data) }));
                                    } }), _jsx(Controller, { control: control, name: "educationalYearId", render: ({ field }) => {
                                        var _a;
                                        return (_jsx(Select, { ...field, label: t('SaveCourseDialog.educationalYearField.label'), placeholder: t('SaveCourseDialog.educationalYearField.placeholder'), errorMessage: (_a = errors.educationalYearId) === null || _a === void 0 ? void 0 : _a.message, options: getSelectOptions(educationalYearsStatus.data) }));
                                    } })] }), _jsx(Controller, { control: control, name: "name", render: ({ field }) => {
                                var _a;
                                return (_jsx(TextFormItem, { ...field, label: t('SaveCourseDialog.nameField.label'), placeholder: namePlaceholder, errorMessage: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message }));
                            } }), _jsx(Controller, { control: control, defaultValue: getAvailableColors()[0], name: "color", render: ({ field }) => {
                                var _a;
                                return (_jsx(ColorPicker, { ...field, errorMessage: (_a = errors.color) === null || _a === void 0 ? void 0 : _a.message }));
                            } })] }) }) }) }));
};
export default SaveCourseDialog;
