import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Droppable, FileUploadDialog } from 'components/custom';
import { getFileList } from 'utils/file-upload';
const FileUploadDroppable = ({ children, lessonId, onUploadFinally, ...droppableProps }) => {
    const [fileList, setFileList] = React.useState(null);
    const handleFileUpload = (event) => {
        setFileList(getFileList(event));
    };
    const onFinally = () => {
        onUploadFinally === null || onUploadFinally === void 0 ? void 0 : onUploadFinally();
        setFileList(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Droppable, { ...droppableProps, onFileDrop: handleFileUpload, children: children }), _jsx(FileUploadDialog, { fileList: fileList, lessonId: lessonId, onFinally: onFinally })] }));
};
export default FileUploadDroppable;
