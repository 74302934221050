import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { getAccountTerminationConfirmationMutationOptions, useAccountTerminationConfirmationMutation, } from 'api';
import { Button, Illustration, LoadingWrapper } from 'components/custom';
import { useSidebarLayoutStyles } from 'layouts/Sidebar/styles';
import Image from 'public/img/illustrations/desk.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
const TerminationConfirmation = () => {
    const { t } = useTranslation('termination', {
        keyPrefix: 'terminationConfirmation',
    });
    const [searchParams] = useSearchParams();
    const { illustrationStyles } = useSidebarLayoutStyles();
    const [confirmed, setConfirmed] = React.useState(false);
    const [terminationMutationConfirmation, terminationMutationConfirmationStatus,] = useAccountTerminationConfirmationMutation();
    const onConfirmClick = () => {
        void terminationMutationConfirmation(getAccountTerminationConfirmationMutationOptions(searchParams));
        setConfirmed(true);
    };
    return (_jsxs(Stack, { alignItems: "center", justifyContent: "center", spacing: 2, width: "100%", children: [_jsx(Illustration, { image: Image, minHeightPx: 100, minWidthPx: 200, sx: illustrationStyles }), _jsx(Typography, { variant: "h5", color: "secondary", children: t('heading1') }), confirmed ? (_jsxs(LoadingWrapper, { status: terminationMutationConfirmationStatus, children: [_jsx(Typography, { align: "center", sx: { fontWeight: 700 }, children: t('success.paragraph1') }), _jsx(Typography, { align: "center", children: t('success.paragraph2') }), _jsx(Typography, { align: "center", children: t('success.paragraph3') })] })) : (_jsxs(_Fragment, { children: [_jsx(Typography, { align: "center", sx: { fontWeight: 700 }, children: t('confirm.paragraph1') }), _jsx(Typography, { align: "center", children: t('confirm.paragraph2') }), _jsx(Button, { variant: "contained", onClick: onConfirmClick, children: t('confirm.button') })] }))] }));
};
export default TerminationConfirmation;
