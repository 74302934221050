import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Stack, Typography } from '@mui/material';
import { Illustration, LoadingWrapper } from 'components/custom';
import { useLogoutHandler } from 'components/kratos';
import { useSidebarLayoutStyles } from 'layouts/Sidebar/styles';
import Image from 'public/img/illustrations/day_period/morning.svg';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setTitle } from 'utils/head';
import { LOGIN } from 'utils/links';
import { log, Severity } from 'utils/logger';
/**
 * Performs logout and displays logout successful page.
 */
const Logout = () => {
    const { t } = useTranslation('auth');
    const logout = useLogoutHandler();
    const [loading, setLoading] = React.useState(true);
    const { illustrationStyles } = useSidebarLayoutStyles();
    useEffect(() => {
        setTitle(t('head.logout.title'));
    }, [t]);
    useEffect(() => {
        logout()
            .catch((err) => {
            log('Logout error', {
                dump: err,
                severity: Severity.ERROR,
            });
        })
            .finally(() => {
            setLoading(false);
        });
    }, [logout]);
    return (_jsx(LoadingWrapper, { status: { loading }, children: _jsxs(Stack, { alignItems: "center", justifyContent: "center", width: "100%", spacing: 5, children: [_jsx(Illustration, { image: Image, minHeightPx: 100, minWidthPx: 200, sx: illustrationStyles }), _jsx(Typography, { variant: "h4", sx: { fontWeight: 700 }, children: t('body.step.logout.heading1') }), _jsx(Typography, { align: "center", variant: "h6", sx: { whiteSpace: 'pre-wrap' }, children: t('body.step.logout.heading2') }), _jsx(Button, { size: "large", fullWidth: true, variant: "contained", color: "secondary", href: LOGIN, sx: { maxWidth: '30rem' }, children: t('body.step.logout.loginButton') })] }) }));
};
export default Logout;
