import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Delete } from '@mui/icons-material';
import { TextField, Typography } from '@mui/material';
import { getDeleteSchoolMutationOptions } from 'api';
import { ConfirmationDialog, LoadingWrapper } from 'components/custom';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteSchoolMutation } from 'api/generated';
const SchoolManagementDeleteDialog = (props) => {
    const { schoolToDelete, close } = props;
    const { t } = useTranslation('schoolManagement', {
        keyPrefix: 'SchoolManagementDeleteDialog',
    });
    const [isDeleteConfirmed, setIsDeleteConfirmed] = React.useState(false);
    const [deleteSchool, statusDeleteSchool] = useDeleteSchoolMutation();
    const handleDeleteSchool = () => {
        if (isDeleteConfirmed && (schoolToDelete === null || schoolToDelete === void 0 ? void 0 : schoolToDelete.id)) {
            deleteSchool(getDeleteSchoolMutationOptions(schoolToDelete.id))
                .then(() => {
                close();
                enqueueSnackbar(t('successMessage'));
            })
                .catch(() => {
                enqueueSnackbar(t('errorMessage'), { variant: 'error' });
            });
        }
    };
    return (_jsx(ConfirmationDialog, { open: !!schoolToDelete, onClose: close, onTransitionExited: () => {
            statusDeleteSchool.reset();
        }, titleProps: {
            Icon: Delete,
            text: t('title', { schoolName: schoolToDelete === null || schoolToDelete === void 0 ? void 0 : schoolToDelete.name }),
        }, cancelButtonProps: {
            onClick: close,
        }, confirmButtonProps: {
            disabled: !isDeleteConfirmed,
            onClick: handleDeleteSchool,
        }, fullWidth: true, children: _jsxs(LoadingWrapper, { status: statusDeleteSchool, children: [_jsx(Typography, { variant: "body1", children: t('explanation') }), _jsx("br", {}), _jsx(Typography, { variant: "body1", children: t('instruction', { name: schoolToDelete === null || schoolToDelete === void 0 ? void 0 : schoolToDelete.name }) }), _jsx(TextField, { autoFocus: true, fullWidth: true, onChange: (event) => {
                        setIsDeleteConfirmed(event.currentTarget.value.trim() === (schoolToDelete === null || schoolToDelete === void 0 ? void 0 : schoolToDelete.name));
                    }, onKeyDown: (e) => {
                        if (e.key === 'Enter') {
                            handleDeleteSchool();
                        }
                    } })] }) }));
};
export default SchoolManagementDeleteDialog;
