import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CreditCard } from '@mui/icons-material';
import { Badge, Box, Card, CardActions, CardContent, CardHeader, Stack, Typography, } from '@mui/material';
import { getTerminationMutationOptions, useAccountPaymentPlanQueryApi, useCancelTerminationMutation, } from 'api';
import { onValidationError } from 'api/error';
import { Button, Illustration, LoadingWrapper } from 'components/custom';
import { format } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import DefaultImage from 'public/img/illustrations/desk.svg';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { getChangeStripeSubscriptionRedirectUrl, getStripeCustomerPortalRedirectUrl, } from 'utils/external-links';
import { setTitle } from 'utils/head';
const getExpirationNoteKey = (subscriptionStatus) => {
    switch (subscriptionStatus) {
        case 'TRIAL': {
            return 'expirationNote.endOfTrial';
        }
        case 'TRIAL_PAYMENT_METHOD_ADDED': {
            return 'expirationNote.paymentWithTrial';
        }
        case 'PAUSED': {
            return 'expirationNote.noSubscription';
        }
        case 'TERMINATION': {
            return 'expirationNote.endOfNoticePeriod';
        }
        case 'PAST_DUE': {
            return 'expirationNote.paymentIssue';
        }
        case 'INVALID_ACTIVE': {
            return 'expirationNote.invalidActive';
        }
        case 'INVALID_INACTIVE': {
            return 'expirationNote.invalidInactive';
        }
        default: {
            return '';
        }
    }
};
const getPrimaryButtonKey = (subscriptionStatus) => {
    switch (subscriptionStatus) {
        case 'TRIAL':
        case 'TRIAL_PAYMENT_METHOD_ADDED': {
            return 'buttons.selectMoreSpace';
        }
        default: {
            return 'buttons.subscriptionManagement';
        }
    }
};
const getSecondaryButtonKey = (subscriptionStatus) => {
    switch (subscriptionStatus) {
        case 'TRIAL':
        case 'PAUSED': {
            return 'buttons.buyNow';
        }
        default: {
            return 'buttons.customerPortal';
        }
    }
};
const PlansAndPayments = () => {
    var _a;
    const { t: headTranslate } = useTranslation('settings', {
        keyPrefix: 'head',
    });
    React.useEffect(() => {
        setTitle(headTranslate('title.plansAndPayments'));
    }, [headTranslate]);
    const { t } = useTranslation('settings', {
        keyPrefix: 'body.plansAndPayments',
    });
    const { user } = useOutletContext();
    const subscriptionQueryStatus = useAccountPaymentPlanQueryApi({
        id: user.id,
    });
    const { data: subscriptionData } = subscriptionQueryStatus;
    const subscriptionStatus = subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.status;
    const expirationNoteKey = getExpirationNoteKey(subscriptionStatus);
    const [cancelTermination, cancelTerminationStatus] = useCancelTerminationMutation();
    const cancelTerminationHandler = () => {
        cancelTermination(getTerminationMutationOptions(user.id))
            .then(async () => {
            return subscriptionQueryStatus.refetch();
        })
            .then(() => {
            enqueueSnackbar(t('terminationCancelledAlert'), { variant: 'info' });
        })
            .catch(onValidationError);
    };
    return (_jsx(LoadingWrapper, { status: subscriptionQueryStatus, mutation: cancelTerminationStatus, children: _jsx(Box, { children: _jsx(Badge, { badgeContent: _jsx(Typography, { fontWeight: 800, children: "!" }), color: "warning", invisible: !expirationNoteKey, overlap: "rectangular", children: _jsxs(Card, { children: [_jsx(CardHeader, { avatar: _jsx(CreditCard, {}), title: _jsx(Typography, { variant: "h6", children: t('cardTitle', {
                                    email: user.email,
                                    firstName: user.name.first,
                                    lastName: user.name.last,
                                }) }) }), _jsx(CardContent, { children: _jsxs(Stack, { spacing: 2, direction: "row", children: [_jsx(Illustration, { image: (_a = subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.imageUrl) !== null && _a !== void 0 ? _a : DefaultImage, minHeightPx: 64, minWidthPx: 100, sx: { backgroundPosition: 'top center' } }), _jsxs(Stack, { flexGrow: 1, children: [_jsxs(Stack, { spacing: 2, direction: "row", justifyContent: "space-between", flexGrow: 1, children: [_jsx(Typography, { variant: "h6", paragraph: true, children: subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.name }), _jsx(Typography, { variant: "h6", paragraph: true, children: subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.priceText })] }), _jsxs(Box, { children: [_jsx(Typography, { paragraph: true, children: subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.description }), expirationNoteKey && (_jsx(Typography, { color: "warning.main", children: _jsx(Trans, { i18nKey: expirationNoteKey, t: t, values: {
                                                                date: (subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.endDate)
                                                                    ? format(subscriptionData.endDate, 'dd. MMMM yyyy')
                                                                    : '',
                                                            } }) }))] })] })] }) }), (!!(subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.hasCustomerPortal) ||
                            !!(subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.isChangeable)) && (_jsxs(CardActions, { children: [subscriptionData.hasCustomerPortal && (_jsx(Button, { variant: "contained", href: getStripeCustomerPortalRedirectUrl(), color: "secondary", children: t(getSecondaryButtonKey(subscriptionStatus)) })), subscriptionData.isChangeable && (_jsx(Button, { variant: "contained", href: getChangeStripeSubscriptionRedirectUrl(), children: t(getPrimaryButtonKey(subscriptionStatus)) })), subscriptionData.status === 'TERMINATION' && (_jsx(Button, { color: "error", onClick: cancelTerminationHandler, sx: { marginLeft: 2 }, variant: "contained", children: t('buttons.cancelTermination') }))] }))] }) }) }) }));
};
export default PlansAndPayments;
