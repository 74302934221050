import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Button, LoadingWrapper, TimetableDataGrid } from 'components/custom';
import { AddCircle } from '@mui/icons-material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useHolidaysQueryApi } from 'api';
import SaveHolidayDialog from './SaveHolidayDialog';
import DeleteHolidayDialog from './DeleteHolidayDialog';
const HolidaysSection = () => {
    const { t } = useTranslation('timetable', {
        keyPrefix: 'steps.vacation.holidaySection',
    });
    const { schoolYearId = '' } = useParams();
    const [activeHoliday, setActiveHoliday] = React.useState(null);
    const [holidayToDelete, setHolidayToDelete] = React.useState(null);
    const status = useHolidaysQueryApi({ schoolYearId });
    return (_jsx(LoadingWrapper, { status: status, children: _jsxs(Stack, { spacing: 2, children: [_jsx(TimetableDataGrid, { "data-testid": "holidays", label: _jsx(Trans, { i18nKey: "title", t: t }), rows: status.data, onEditClick: setActiveHoliday, onDeleteClick: setHolidayToDelete, hasTimeRange: true, noItemsMessage: t('noItemsMessage') }), _jsx(Button, { "data-testid": "addHolidayButton", color: "secondary", startIcon: _jsx(AddCircle, {}), onClick: () => {
                        setActiveHoliday({});
                    }, sx: { alignSelf: 'end' }, children: t('addButton') }), _jsx(SaveHolidayDialog, { activeHoliday: activeHoliday, close: () => {
                        setActiveHoliday(null);
                    } }), _jsx(DeleteHolidayDialog, { holidayToDelete: holidayToDelete, setHolidayToDelete: setHolidayToDelete })] }) }));
};
export default HolidaysSection;
