import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Box, Button, Divider, FormHelperText, Stack, Typography, } from '@mui/material';
import { PasswordValidation, MIN_PASSWORD_LENGTH } from 'components/kratos/ui';
import { useTranslation } from 'react-i18next';
import { getLayoutItems } from './items';
import { LoadingWrapper, TextFormItem } from 'components/custom';
import { Mail } from '@mui/icons-material';
import { useProviderQuery } from 'api';
import * as yup from 'yup';
const SPACING = 2.5;
/**
 * Mapping of Kratos UI node name to key.
 * Keeps away Kratos naming from the template code.
 */
const nameToKeyMap = {
    method: 'submit',
    password: 'password',
    'traits.accepted_gtc': 'gtc',
    'traits.accepted_tos': 'tos',
    'traits.email': 'email',
    'traits.name.first': 'firstName',
    'traits.name.last': 'lastName',
};
export const RegistrationTemplate = ({ children, setValidity, context, resetFormValues, }) => {
    var _a, _b, _c, _d;
    const { t } = useTranslation('auth');
    const { t: tCommon } = useTranslation('common');
    const { customerType, setCustomerType } = context !== null && context !== void 0 ? context : {};
    const items = getLayoutItems(children, nameToKeyMap);
    const [errorMessage, setErrorMessage] = React.useState();
    const [providerQuery, providerQueryStatus] = useProviderQuery();
    const schema = React.useMemo(() => yup.string().email().label(t('body.flow.emailField.label')).required(), [t]);
    const onProviderQuerySuccess = (res) => {
        if (typeof res !== 'undefined') {
            setCustomerType === null || setCustomerType === void 0 ? void 0 : setCustomerType(res.is_school ? 'business' : 'individual');
        }
    };
    const onNextClick = async (e) => {
        var _a, _b, _c;
        e.preventDefault();
        e.stopPropagation();
        const emailValue = (_c = (_b = (_a = items.email) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : '';
        const isValid = await schema
            .validate(emailValue)
            .catch((err) => {
            setErrorMessage(err.message);
        });
        if (!isValid) {
            return;
        }
        setErrorMessage('');
        providerQuery({ email: emailValue }, onProviderQuerySuccess);
    };
    const onClearEmail = () => {
        setCustomerType === null || setCustomerType === void 0 ? void 0 : setCustomerType(undefined);
        resetFormValues();
    };
    return (_jsx(LoadingWrapper, { status: providerQueryStatus, children: _jsxs(Stack, { spacing: SPACING, children: [items.messages, _jsxs(Box, { hidden: !!customerType, children: [items.email, _jsx(FormHelperText, { error: !!errorMessage, children: errorMessage })] }), !customerType && (_jsx(_Fragment, { children: _jsx(Button, { color: "secondary", variant: "contained", type: "submit", onClick: (e) => {
                            void onNextClick(e);
                        }, children: tCommon('body.button.next') }) })), !!customerType && (_jsxs(_Fragment, { children: [_jsx(TextFormItem, { label: t('body.flow.emailField.label'), StartAdornmentIcon: Mail, value: (_b = (_a = items.email) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.value, InputProps: {
                                readOnly: true,
                            }, clearButtonAriaLabel: t('body.flow.emailField.clearEmail'), onClear: onClearEmail }), _jsxs(Stack, { direction: "row", spacing: 3, children: [items.firstName, items.lastName] }), items.tos, items.gtc, _jsx(Divider, {}), _jsx(Typography, { variant: "h5", color: "secondary.main", children: t('body.step.registration.heading2') }), _jsx(Typography, { children: t('body.step.registration.paragraph1') }), _jsx(Typography, { sx: { fontWeight: 700 }, children: t('body.step.registration.paragraph2', {
                                minLength: MIN_PASSWORD_LENGTH,
                            }) }), _jsxs(Box, { children: [items.password, _jsx(PasswordValidation, { setValidity: setValidity, passwordValue: (_d = (_c = items.password) === null || _c === void 0 ? void 0 : _c.props) === null || _d === void 0 ? void 0 : _d.value, spacing: SPACING })] }), items.rest, items.submit] }))] }) }));
};
