import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ListItemButton, ListItemIcon, ListItemText, MenuItem, } from '@mui/material';
const GridHeaderActionsCellItem = (props) => {
    const { href, icon, label, ...menuItemProps } = props;
    if (href) {
        return (_jsx(MenuItem, { ...menuItemProps, disableGutters: true, sx: { p: 0 }, children: _jsxs(ListItemButton, { disableGutters: true, LinkComponent: props.LinkComponent, href: href, sx: { px: 2, py: 0.75 }, children: [_jsx(ListItemIcon, { children: icon }), _jsx(ListItemText, { children: label })] }) }));
    }
    return (_jsxs(MenuItem, { ...menuItemProps, children: [_jsx(ListItemIcon, { children: icon }), _jsx(ListItemText, { children: label })] }));
};
export default GridHeaderActionsCellItem;
