import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { isPreviewSupported } from 'utils/file';
import { MimeTypes } from 'utils/mime-types';
import { appendParams } from 'utils/url';
export const Resource = ({ fileObject, }) => {
    const { t } = useTranslation('object', {
        keyPrefix: 'cmp.FileObjectPreview',
    });
    const { name, mimeType, downloadUrl } = fileObject;
    const url = appendParams(downloadUrl, { download: 'false' });
    if (isPreviewSupported(mimeType, 'image')) {
        return (_jsx("img", { src: url, alt: name, style: { height: '100%', objectFit: 'scale-down', width: '100%' } }));
    }
    if (isPreviewSupported(mimeType, 'object')) {
        return (_jsx("object", { width: "100%", height: "100%", data: url, type: MimeTypes.PDF, role: "document", children: t('noPdfViewerInstalled') }));
    }
    if (isPreviewSupported(mimeType, 'video')) {
        return (_jsx("video", { controls: true, preload: "metadata", src: url, style: {
                maxHeight: '100%',
                maxWidth: '100%',
            }, "data-testid": "VideoPreview" }));
    }
    if (isPreviewSupported(mimeType, 'audio')) {
        return (_jsx("audio", { controls: true, preload: "metadata", src: url, "data-testid": "AudioPreview" }));
    }
    if (isPreviewSupported(mimeType, 'text')) {
        return (_jsx("iframe", { width: "100%", height: "100%", sandbox: "", src: url, title: name }));
    }
    return null;
};
