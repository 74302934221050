import { Badge, Email, Lock, Person } from '@mui/icons-material';
import { differenceInMinutes } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { log, Severity } from 'utils/logger';
import { getGtcLink, getTosLink } from './links';
/**
 * Extracts context params from Kratos message.
 * @param message Message object.
 * @returns Context object.
 */
export const extractContext = (message) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (!message.context) {
        return {};
    }
    if (!isEmpty(message.context)) {
        return message.context;
    }
    switch (String(message.id)) {
        case '4000003': {
            return ((_b = (_a = /\D+(?<min_length>\d+)\D+(?<actual_length>\d+)/u.exec(message.text)) === null || _a === void 0 ? void 0 : _a.groups) !== null && _b !== void 0 ? _b : {});
        }
        case '4000017': {
            return ((_d = (_c = /\D+(?<max_length>\d+)\D+(?<actual_length>\d+)/u.exec(message.text)) === null || _c === void 0 ? void 0 : _c.groups) !== null && _d !== void 0 ? _d : {});
        }
        case '4000018':
        case '4000019':
        case '4000020':
        case '4000021': {
            return ((_f = (_e = /\D+(?<expected_length>\d+)\D+(?<actual_length>\d+)/u.exec(message.text)) === null || _e === void 0 ? void 0 : _e.groups) !== null && _f !== void 0 ? _f : {});
        }
        case '4000004': {
            return (_h = (_g = /.*(?<expected_format>".*")/u.exec(message.text)) === null || _g === void 0 ? void 0 : _g.groups) !== null && _h !== void 0 ? _h : {};
        }
        default: {
            return {};
        }
    }
};
/**
 * Returns a translated label for given checkbox node name.
 * @param name Node name.
 * @param defaultLabel Default label send from Kratos.
 * @param customerType Determines the text and url for terms and conditions
 * @returns Label text or a component with the label text.
 */
export const getCheckboxLabel = (name, defaultLabel, customerType) => {
    switch (name) {
        case 'traits.accepted_tos':
            return getTosLink(customerType);
        case 'traits.accepted_gtc':
            return getGtcLink(customerType);
        default:
            return defaultLabel;
    }
};
/**
 * Returns an icon for given node name.
 * @param name Node name.
 * @returns Icon.
 */
export const getIcon = (name) => {
    switch (name) {
        case 'email':
        case 'traits.email':
        case 'identifier':
            return Email;
        case 'password':
            return Lock;
        case 'traits.name.first':
        case 'traits.name.last':
            return Person;
        case 'code':
            return Badge;
        default:
            return undefined;
    }
};
export const getInputMode = (name) => {
    switch (name) {
        case 'email':
        case 'traits.email':
        case 'identifier':
            return 'email';
        default:
            return 'text';
    }
};
export const useMappings = () => {
    const { t: authTranslate } = useTranslation('auth');
    const { t: kratosTranslate } = useTranslation('kratos');
    const processContext = (context) => {
        if (context === undefined) {
            return undefined;
        }
        const result = {};
        // Kratos provides no types for context values.
        Object.entries(context).forEach(([key, value]) => {
            /*
             * Examples:
             * {expired_at: '2023-04-25T10:48:52.543500346Z'}
             * {privilegedSessionExpiresAt: "2023-02-24T12:55:18.19616375Z"}
             */
            switch (key) {
                case 'property': {
                    const text = typeof value === 'string'
                        ? authTranslate(`body.flow.context.property.${value}`, value)
                        : '';
                    if (!text || text === value) {
                        log(`Ory Kratos translation not found for context [${key}]: ${JSON.stringify(value)}`, {
                            dump: context,
                            severity: Severity.WARN,
                        });
                    }
                    result[key] = text;
                    break;
                }
                case 'expired_at':
                case 'privilegedSessionExpiresAt': {
                    result[key] = Math.abs(differenceInMinutes(new Date(value), new Date(), {
                        roundingMethod: 'round',
                    }));
                    break;
                }
                default: {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Kratos provides no types for context values.
                    result[key] = value;
                }
            }
        });
        return result;
    };
    /**
     * Returns message text for a generic validation error.
     * @param message Message object.
     * @returns Translated text or Ory Kratos text when no translation exists.
     */
    const getTextForGenericValidation = (message) => {
        if (message.text === 'value must be true') {
            return authTranslate('body.flow.genericValidationError.valueMustBeTrue');
        }
        if (message.text.endsWith('is not valid "email"')) {
            return authTranslate('body.flow.genericValidationError.invalidEmail');
        }
        log(`Ory Kratos missing generic validation translation for [${message.id}]: "${message.text}"`, {
            dump: message,
            severity: Severity.WARN,
        });
        return message.text;
    };
    /**
     * Returns message text for given message.
     * @param message Message object.
     * @returns Translated text or Ory Kratos text when no translation exists.
     */
    const getMessageText = (message) => {
        const id = String(message.id);
        const genericValidationErrorId = '4000001';
        const text = id === genericValidationErrorId
            ? getTextForGenericValidation(message)
            : kratosTranslate(id, processContext(extractContext(message)));
        if (text && text !== id) {
            return text;
        }
        log(`Ory Kratos translation not found for [${message.id}]: "${message.text}"`, {
            dump: message,
            severity: Severity.WARN,
        });
        return kratosTranslate('default', { errorCode: message.id });
    };
    /**
     * Returns a translated text for given button node.
     * @param node Node: Button, Anchor, Submit.
     * @param defaultLabel Default label send from Kratos.
     * @returns Translated text.
     */
    const getButtonText = (node, defaultLabel) => {
        var _a;
        const t = authTranslate;
        const labelId = String((_a = node.meta.label) === null || _a === void 0 ? void 0 : _a.id);
        switch (labelId) {
            case '1010001':
                // Sign in
                return t('body.flow.button.login', defaultLabel);
            case '1040001':
                // Sign up
                return t('body.flow.button.register', defaultLabel);
            case '1070003':
                // Save
                return t('body.flow.button.save', defaultLabel);
            case '1070005':
                // Submit
                return t('body.flow.button.submit', defaultLabel);
            case '1070007':
                // Resend Code
                return t('body.flow.button.resendCode', defaultLabel);
            case '1070009':
                // Continue
                return t('body.flow.button.continue', defaultLabel);
            default:
                return defaultLabel !== null && defaultLabel !== void 0 ? defaultLabel : '';
        }
    };
    /**
     * Returns a translated placeholder for given node name.
     * @param name Node name.
     * @returns Translated placeholder.
     */
    const getPlaceholder = (name) => {
        const t = authTranslate;
        switch (name) {
            case 'email':
            case 'traits.email':
            case 'identifier':
                return t('body.flow.emailField.placeholder');
            case 'password':
                return t('body.flow.passwordField.placeholder');
            case 'traits.name.first':
                return t('body.flow.firstNameField.placeholder');
            case 'traits.name.last':
                return t('body.flow.lastNameField.placeholder');
            case 'code':
                return t('body.flow.authCodeField.placeholder');
            default:
                return '';
        }
    };
    /**
     * Returns a translated label for given node name.
     * @param name Node name.
     * @param defaultLabel Default label send from Kratos.
     * @returns Translated label.
     */
    const getLabel = (name, defaultLabel) => {
        const t = authTranslate;
        switch (name) {
            case 'email':
            case 'traits.email':
            case 'identifier':
                return t('body.flow.emailField.label', defaultLabel);
            case 'password':
                return t('body.flow.passwordField.label', defaultLabel);
            case 'code':
                return t('body.flow.authCodeField.label', defaultLabel);
            default:
                return defaultLabel !== null && defaultLabel !== void 0 ? defaultLabel : '';
        }
    };
    return {
        getButtonText,
        getLabel,
        getMessageText,
        getPlaceholder,
    };
};
/**
 * Returns error text for given error ID.
 * @param errorId Error ID.
 * @param t Translation function with `auth` namespace.
 * @returns Translated error text.
 */
export const getErrorMsg = (errorId, t) => {
    switch (errorId) {
        case 'self_service_flow_return_to_forbidden':
            // The return_to address is not allowed.
            return t('body.flow.error.selfServiceFlowReturnToForbidden');
        case 'self_service_flow_expired':
            // Your interaction expired, please fill out the form again.
            return t('body.flow.error.selfServiceFlowExpired');
        case 'security_csrf_violation':
            // A security violation was detected, please fill out the form again.
            return t('body.flow.error.securityCsrfViolation');
        default:
            // Unexpected error.
            return t('body.flow.error.unexpectedError', { errorId });
    }
};
