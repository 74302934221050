import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { List } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Divider, FormLabel, Stack, Typography, } from '@mui/material';
import { TextFormatter } from 'components/custom';
import { useTranslation } from 'react-i18next';
const NotesReadOnly = ({ notes = '', homework = '', previousHomework = '', }) => {
    const { t } = useTranslation('lesson', { keyPrefix: 'cmp.Notes' });
    return (_jsxs(Card, { children: [_jsx(CardHeader, { avatar: _jsx(List, {}), title: _jsx(Typography, { children: t('header.title') }) }), _jsx(CardContent, { children: _jsxs(Stack, { spacing: 2, divider: _jsx(Divider, {}), children: [_jsxs(Box, { children: [_jsx(FormLabel, { sx: { fontWeight: 'bold' }, children: t('previousHomework') }), _jsx(Typography, { children: _jsx(TextFormatter, { text: previousHomework }) })] }), _jsxs(Box, { children: [_jsx(FormLabel, { sx: { fontWeight: 'bold' }, children: t('lessonNotes.label') }), _jsx(Typography, { children: _jsx(TextFormatter, { text: notes }) })] }), _jsxs(Box, { children: [_jsx(FormLabel, { sx: { fontWeight: 'bold' }, children: t('homework.label') }), _jsx(Typography, { children: _jsx(TextFormatter, { text: homework }) })] })] }) })] }));
};
export default NotesReadOnly;
