import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileObjectAssignDialog, FileUploadInput, LinkUploadDialog, } from 'components/custom';
import SplitButton from '../SplitButton';
const AddObjectSplitButton = (props) => {
    const { lessonId, refetchFileObjects } = props;
    const { t } = useTranslation('object', {
        keyPrefix: 'cmp.AddObjectSplitButton',
    });
    const [visibleDialog, setVisibleDialog] = React.useState(null);
    const fileInputRef = React.createRef();
    const optionKeys = ['fileUpload', 'linkUpload'];
    if (lessonId) {
        optionKeys.push('objectAssign');
    }
    const handleActionClick = (selectedOption) => {
        var _a;
        if (selectedOption === 'fileUpload') {
            (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
        }
        else {
            setVisibleDialog(selectedOption);
        }
    };
    const onFileUploadFinally = () => {
        refetchFileObjects();
        // Reset file field value so that uploading the same file is possible.
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };
    return (_jsxs(SplitButton, { optionKeys: optionKeys, handleActionClick: handleActionClick, t: t, children: [_jsx(FileUploadInput, { ref: fileInputRef, lessonId: lessonId, onUploadFinally: onFileUploadFinally }), _jsx(LinkUploadDialog, { lessonId: lessonId, isDialogOpen: visibleDialog === 'linkUpload', closeDialog: () => {
                    setVisibleDialog(null);
                }, onSuccess: refetchFileObjects }), lessonId && (_jsx(FileObjectAssignDialog, { lessonId: lessonId, isDialogOpen: visibleDialog === 'objectAssign', closeDialog: () => {
                    setVisibleDialog(null);
                }, onSuccess: refetchFileObjects }))] }));
};
export default AddObjectSplitButton;
