import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { Badge, Handshake, Mail } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { getAccountTerminationMutationOptions, isLoading, onValidationError, useAccountTerminationMutation, } from 'api';
import { Button, DatePicker, LoadingWrapper, Select, Textarea, TextFormItem, } from 'components/custom';
import { addWeeks, lastDayOfMonth } from 'date-fns';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { onSubmitError, yup } from 'utils/forms';
const MIN_TERMINATION_PERIOD_IN_WEEKS = 4;
const getEarliestTerminationDate = () => lastDayOfMonth(addWeeks(new Date(), MIN_TERMINATION_PERIOD_IN_WEEKS));
const TerminationForm = ({ navigateToTerminationLinkSent, }) => {
    const { t } = useTranslation('termination', { keyPrefix: 'terminationForm' });
    const schema = React.useMemo(() => yup.object({
        contractNumber: yup
            .string()
            .label(t('contractNumberField.label'))
            .required(),
        date: yup
            .date()
            .nullable()
            .label(t('terminationDateField.label'))
            .when('terminationType', {
            is: 'ordinary',
            then: (terminationDateSchema) => terminationDateSchema.required(),
            // eslint-disable-next-line sort-keys -- Logical flow
            otherwise: (terminationDateSchema) => terminationDateSchema,
        }),
        email: yup.string().email().label(t('emailField.label')).required(),
        reason: yup.string().label(t('terminationReasonField.label')),
        type: yup.string().label(t('terminationTypeField.label')).required(),
    }), [t]);
    const id = React.useId();
    const { control, formState: { errors, isSubmitting }, handleSubmit, setValue, watch, } = useForm({
        resolver: yupResolver(schema),
    });
    const [terminationMutation, terminationMutationStatus] = useAccountTerminationMutation();
    const onSubmit = async (values) => {
        return terminationMutation(getAccountTerminationMutationOptions(values))
            .then(navigateToTerminationLinkSent)
            .catch(onValidationError);
    };
    return (_jsx(Box, { children: _jsx(LoadingWrapper, { mutation: terminationMutationStatus, children: _jsxs(Stack, { spacing: 2.5, children: [_jsx(Typography, { variant: "h5", color: "secondary.main", children: t('heading1') }), _jsx(Typography, { sx: { fontWeight: 700 }, children: t('heading2') }), _jsx(Typography, { children: t('paragraph1') }), _jsx(Typography, { color: "secondary.main", sx: { fontWeight: 700 }, children: t('heading3') }), _jsx("form", { id: id, onSubmit: (e) => {
                            handleSubmit(onSubmit)(e).catch(onSubmitError);
                        }, children: _jsxs(Stack, { spacing: 2, children: [_jsx(Controller, { name: "email", control: control, render: ({ field }) => {
                                        var _a;
                                        return (_jsx(TextFormItem, { ...field, StartAdornmentIcon: Mail, label: t('emailField.label'), placeholder: t('emailField.placeholder'), errorMessage: (_a = errors.email) === null || _a === void 0 ? void 0 : _a.message }));
                                    } }), _jsx(Controller, { name: "contractNumber", control: control, render: ({ field }) => {
                                        var _a;
                                        return (_jsx(TextFormItem, { ...field, StartAdornmentIcon: Badge, label: t('contractNumberField.label'), placeholder: t('contractNumberField.placeholder'), errorMessage: (_a = errors.contractNumber) === null || _a === void 0 ? void 0 : _a.message }));
                                    } }), _jsxs(Stack, { direction: "row", spacing: 3, children: [_jsx(Controller, { name: "type", control: control, defaultValue: "ordinary", render: ({ field }) => {
                                                var _a;
                                                return (_jsx(Select, { ...field, onChange: (e) => {
                                                        field.onChange(e);
                                                        if (e.target.value === 'extraordinary') {
                                                            setValue('date', null);
                                                        }
                                                        else {
                                                            setValue('date', getEarliestTerminationDate());
                                                        }
                                                    }, label: t('terminationTypeField.label'), placeholder: t('terminationTypeField.placeholder'), errorMessage: (_a = errors.type) === null || _a === void 0 ? void 0 : _a.message, StartAdornmentIcon: Handshake, options: [
                                                        {
                                                            label: t('terminationTypeField.ordinaryTerminationOption'),
                                                            value: 'ordinary',
                                                        },
                                                        {
                                                            label: t('terminationTypeField.extraordinaryTerminationOption'),
                                                            value: 'extraordinary',
                                                        },
                                                    ] }));
                                            } }), _jsx(Controller, { name: "date", control: control, defaultValue: getEarliestTerminationDate(), render: ({ field }) => {
                                                var _a;
                                                return (_jsx(DatePicker, { ...field, onChange: (value) => {
                                                        field.onChange(lastDayOfMonth(value));
                                                    }, label: t('terminationDateField.label'), minDate: getEarliestTerminationDate(), disabled: watch('type') === 'extraordinary', placeholder: watch('type') === 'extraordinary'
                                                        ? t('terminationDateField.immediately')
                                                        : t('terminationDateField.placeholder'), errorMessage: (_a = errors.date) === null || _a === void 0 ? void 0 : _a.message, views: ['month', 'year'] }));
                                            } })] }), _jsx(Controller, { name: "reason", control: control, render: ({ field }) => {
                                        var _a;
                                        return (_jsx(Textarea, { ...field, label: t('terminationReasonField.label'), placeholder: t('terminationReasonField.placeholder'), errorMessage: (_a = errors.reason) === null || _a === void 0 ? void 0 : _a.message, minRows: 4 }));
                                    } }), _jsx(Button, { type: "submit", variant: "contained", color: "secondary", disabled: isSubmitting || isLoading(terminationMutationStatus), children: t('submitButton') })] }) })] }) }) }));
};
export default TerminationForm;
