import { NetworkStatus } from '@apollo/client';
import { isArray } from 'lodash-es';
/**
 * Checks if any of given statuses is in loading state.
 * @param status One or more statuses.
 * @returns `true` if any is in loading state; otherwise `false`.
 */
export const isLoading = (status) => {
    const all = isArray(status) ? status : [status];
    return !!all.find((itemStatus) => {
        return (itemStatus.loading ||
            (itemStatus.networkStatus &&
                (itemStatus.networkStatus === NetworkStatus.loading ||
                    itemStatus.networkStatus === NetworkStatus.refetch ||
                    itemStatus.networkStatus === NetworkStatus.fetchMore)));
    });
};
