import { isObject } from 'lodash-es';
import { useLocation, useNavigate } from 'react-router-dom';
export var RouterStateKeys;
(function (RouterStateKeys) {
    RouterStateKeys["AUTH"] = "auth";
})(RouterStateKeys || (RouterStateKeys = {}));
export const useRouterState = () => {
    const location = useLocation();
    const navigate = useNavigate();
    return {
        clearRouterState: () => {
            navigate('', { replace: true, state: undefined });
        },
        getStateObject: () => {
            return isObject(location.state) ? location.state : {};
        },
    };
};
/**
 * Replaces location hash.
 * This change is not pushed to the history.
 * This function doesn't trigger re-render.
 * @param hash String to use as hash in URL.
 */
export const replaceLocationHash = (hash) => {
    // Workaround for React Router v6 issue: https://github.com/remix-run/react-router/discussions/9851
    const [url] = window.location.href.split('#');
    window.location.replace(`${url}#${hash}`);
};
/**
 * Sets location hash.
 * This change is pushed to the history.
 * This function doesn't trigger re-render.
 * @param hash String to use as hash in URL.
 */
export const setLocationHash = (hash) => {
    // Workaround for React Router v6 issue: https://github.com/remix-run/react-router/discussions/9851
    window.location.hash = hash;
};
/**
 * Returns location hash without the leading `#` character.
 * @returns Location hash.
 */
export const getLocationHash = () => {
    return window.location.hash.substring(1);
};
/**
 * Returns location route containing path, query params and hash.
 * @returns Location route.
 */
export const getLocationRoute = () => {
    const { pathname, search, hash } = window.location;
    return pathname + search + hash;
};
