import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AddCircle, Close, More } from '@mui/icons-material';
import { Autocomplete, Box, Chip, ListItem, Stack, Typography, } from '@mui/material';
import { getUpdateObjectTagsMutationOptions, isLoading, useTagsQueryApi, useUpdateObjectTagsMutation, } from 'api';
import { Button, ConfirmationDialog, LoadingWrapper, TextFormItem, } from 'components/custom';
import { find, isEqual, isObject, uniqBy } from 'lodash-es';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
const LISTBOX_ROW_HEIGHT = 5.5;
const LISTBOX_ROWS_NUMBER = 2;
const TagManagementDialog = ({ objectToEditTags, onClose, open, translationNamespace, }) => {
    const { t } = useTranslation(translationNamespace, {
        keyPrefix: 'cmp.TagManagementDialog',
    });
    const [tagName, setTagName] = React.useState('');
    const [assignedTags, setAssignedTags] = React.useState([]);
    React.useEffect(() => {
        var _a;
        setAssignedTags((_a = objectToEditTags.tags) !== null && _a !== void 0 ? _a : []);
        setTagName('');
    }, [objectToEditTags]);
    const statusTags = useTagsQueryApi();
    const [updateObjectTags, statusUpdateObjectTags] = useUpdateObjectTagsMutation();
    const addTag = (tag) => {
        /*
         * Since version 1.5 we only allow new tags that are trimmed.
         * But legacy tags that are not trimmed have to be handled gracefully.
         */
        const trimmedTag = tag.trim();
        if (!trimmedTag) {
            return;
        }
        setTagName('');
        setAssignedTags((prevTags) => {
            // Skip add if the same value already exists.
            if (find(prevTags, ['value', trimmedTag])) {
                return prevTags;
            }
            if (tag !== trimmedTag && find(prevTags, ['value', tag])) {
                return prevTags;
            }
            // Re-use a tag that is already created but not assigned.
            let existingTag = find(statusTags.data, ['value', trimmedTag]);
            if (existingTag) {
                return [...prevTags, existingTag];
            }
            if (tag === trimmedTag) {
                return [...prevTags, { value: trimmedTag }];
            }
            existingTag = find(statusTags.data, ['value', tag]);
            if (existingTag) {
                return [...prevTags, existingTag];
            }
            return [...prevTags, { value: trimmedTag }];
        });
    };
    const unassignTag = (unassignedTag) => {
        setAssignedTags((prevTags) => prevTags.filter((tag) => {
            if (tag.id) {
                return unassignedTag.id !== tag.id;
            }
            return unassignedTag.value !== tag.value;
        }));
    };
    const handleSubmit = () => {
        if (!objectToEditTags.id) {
            return;
        }
        void updateObjectTags(getUpdateObjectTagsMutationOptions(objectToEditTags.id, assignedTags)).then(() => {
            enqueueSnackbar(t('alerts.success'), {
                variant: 'success',
            });
            onClose();
        });
    };
    return (_jsx(ConfirmationDialog, { titleProps: { Icon: AddCircle, text: t('title') }, cancelButtonProps: {
            onClick: onClose,
        }, confirmButtonProps: {
            disabled: !!tagName ||
                isEqual(assignedTags, objectToEditTags.tags) ||
                isLoading(statusUpdateObjectTags),
            onClick: handleSubmit,
        }, onClose: onClose, open: open, fullWidth: true, children: _jsx(LoadingWrapper, { mutation: statusUpdateObjectTags, status: statusTags, children: _jsxs(Stack, { spacing: 2, children: [_jsx(Autocomplete, { freeSolo: true, multiple: true, disableClearable: true, filterSelectedOptions: true, disablePortal: true, open: tagName.length > 0, onInputChange: (event, value) => {
                            setTagName(value);
                        }, inputValue: tagName, value: assignedTags, onChange: (e, value, reason) => {
                            if (reason === 'createOption') {
                                addTag(tagName);
                                return;
                            }
                            if (reason === 'selectOption') {
                                const selectedTags = value.filter((item) => isObject(item) && !!item.value && !!item.id);
                                const newTags = value.filter((item) => isObject(item) && !item.id);
                                setAssignedTags([...uniqBy(selectedTags, 'id'), ...newTags]);
                            }
                        }, options: statusTags.data, getOptionLabel: (option) => typeof option === 'string' ? option : option.value, renderInput: (params) => (_jsx(TextFormItem, { ...params, autoFocus: true, label: t('tagField.label'), InputProps: {
                                ...params.InputProps,
                                endAdornment: (_jsx(Button, { onClick: () => {
                                        addTag(tagName);
                                    }, color: "secondary", variant: "contained", children: t('tagField.button') })),
                                startAdornment: _jsx(More, { sx: { ml: 1 } }),
                            } })), renderOption: (props, option) => {
                            return (_jsx(ListItem, { ...props, sx: {
                                    '&.MuiAutocomplete-option': {
                                        pl: 1,
                                        pr: 1,
                                    },
                                    '&.MuiAutocomplete-option.Mui-focused': {
                                        backgroundColor: 'transparent',
                                    },
                                    width: 'auto',
                                }, children: _jsx(Chip, { color: "warning", variant: "outlined", label: option.value, onClick: () => {
                                        addTag(option.value);
                                    }, sx: {
                                        '.MuiAutocomplete-option:hover &, .Mui-focused &': {
                                            backgroundColor: (theme) => theme.palette.warning.main,
                                            color: (theme) => theme.palette.common.white,
                                        },
                                        cursor: 'inherit',
                                    } }) }));
                        }, slotProps: {
                            paper: {
                                sx: { boxShadow: 'none' },
                            },
                            popper: {
                                sx: {
                                    position: 'static !important',
                                    transform: 'none !important',
                                },
                            },
                        }, ListboxProps: {
                            sx: {
                                display: 'flex',
                                flexWrap: 'wrap',
                                maxHeight: (theme) => theme.spacing(LISTBOX_ROWS_NUMBER * LISTBOX_ROW_HEIGHT),
                                py: 0,
                            },
                        }, sx: {
                            '& .MuiInputBase-root.MuiInputBase-formControl': {
                                p: 0.25,
                                pl: 0,
                            },
                        } }), assignedTags.length ? (_jsxs(_Fragment, { children: [_jsx(Typography, { children: t('assignedTags') }), _jsx(Stack, { direction: "row", role: "list", spacing: 2, children: assignedTags.map((tag) => {
                                    var _a;
                                    return (_jsx(Box, { role: "listitem", children: _jsx(Chip, { color: "warning", clickable: false, label: tag.value, onDelete: () => {
                                                unassignTag(tag);
                                            }, deleteIcon: _jsx(Close, {}) }) }, (_a = tag.id) !== null && _a !== void 0 ? _a : tag.value));
                                }) })] })) : (_jsx(Typography, { children: t('noAssignedTags') }))] }) }) }));
};
export default TagManagementDialog;
