import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ExpandLess, ExpandMore, Whatshot } from '@mui/icons-material';
import { Collapse, Stack } from '@mui/material';
import { Button, Dialog } from 'components/custom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReleaseVersions } from './helpers';
import { Release } from './Release';
const WhatIsNewDialog = (props) => {
    const { t } = useTranslation('releases', { keyPrefix: 'cmp.Dialog' });
    const [expanded, setExpanded] = React.useState(false);
    const { latestVersion, versions } = useReleaseVersions();
    return (_jsx(Dialog, { ...props, maxWidth: "xs", fullWidth: true, titleProps: {
            Icon: Whatshot,
            text: t('title'),
        }, children: _jsxs(Stack, { spacing: 1, children: [_jsx(Release, { version: latestVersion }), _jsx(Button, { onClick: () => {
                        setExpanded(!expanded);
                    }, startIcon: expanded ? _jsx(ExpandLess, {}) : _jsx(ExpandMore, {}), children: expanded ? t('collapse') : t('expand') }), _jsx(Collapse, { in: expanded, unmountOnExit: true, sx: {
                        overflow: 'hidden',
                    }, children: versions.map((version, index) => {
                        // Skip latest release.
                        if (index === 0) {
                            return null;
                        }
                        return _jsx(Release, { version: version }, version);
                    }) })] }) }));
};
export default WhatIsNewDialog;
