import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Cancel, Edit, NoteAlt, TaskAlt } from '@mui/icons-material';
import { Card, CardActions, CardContent, CardHeader, Typography, } from '@mui/material';
import { getAccountNotesMutationOptions, useAccountNotesQueryApi, useUpdateAccountNotesMutation, } from 'api';
import { Button, LoadingWrapper, Textarea, TextFormatter, } from 'components/custom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useBeforeUnload } from 'react-router-dom';
import { LocalStorageKeys } from 'utils/local-storage';
import { useTemporaryStorageEditFlow } from 'utils/temporary-storage';
const Notes = ({ user }) => {
    const { t } = useTranslation('home', { keyPrefix: 'cmp.Notes' });
    const { editData, editMode, startEdit, stopEdit, onChange } = useTemporaryStorageEditFlow(LocalStorageKeys.NOTES);
    const textareaRef = React.useRef(null);
    const loadStatus = useAccountNotesQueryApi({ id: user.id });
    const [updateNotes, statusUpdateNotes] = useUpdateAccountNotesMutation();
    const notes = loadStatus.data;
    const handleEditClick = () => {
        startEdit(notes);
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    };
    const handleSaveClick = () => {
        void updateNotes(getAccountNotesMutationOptions(user.id, editData)).then(stopEdit);
    };
    const handleCancelClick = () => {
        stopEdit();
    };
    useBeforeUnload((event) => {
        if (editMode) {
            event.preventDefault();
            event.returnValue = false;
        }
    });
    return (_jsxs(Card, { "data-testid": "Notes", sx: { flexGrow: 1 }, children: [_jsx(CardHeader, { avatar: _jsx(NoteAlt, {}), title: t('header') }), _jsxs(LoadingWrapper, { status: loadStatus, mutation: statusUpdateNotes, children: [_jsxs(CardContent, { sx: {
                            display: 'flex',
                            flex: '1 1 10rem',
                            flexDirection: 'column',
                            overflow: 'hidden scroll',
                        }, children: [editMode ? null : (_jsx(Typography, { role: "note", color: notes ? 'text' : 'text.disabled', sx: { p: ({ layout }) => layout.textareaPaddingSpacing }, children: notes ? _jsx(TextFormatter, { text: notes }) : t('placeholder') })), _jsx(Textarea, { hidden: !editMode, onChange: onChange, placeholder: t('placeholder'), ref: textareaRef, value: editData })] }), _jsx(CardActions, { children: editMode ? (_jsxs(_Fragment, { children: [_jsx(Button, { color: "error", fullWidth: true, startIcon: _jsx(Cancel, {}), onClick: handleCancelClick, variant: "contained", children: t('buttons.cancel') }), _jsx(Button, { color: "secondary", fullWidth: true, startIcon: _jsx(TaskAlt, {}), onClick: handleSaveClick, variant: "contained", children: t('buttons.save') })] })) : (_jsx(Button, { color: "secondary", fullWidth: true, startIcon: _jsx(Edit, {}), onClick: handleEditClick, variant: "contained", children: t('buttons.edit') })) })] })] }));
};
export default Notes;
