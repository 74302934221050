import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Link, Typography } from '@mui/material';
const URL_REGEXP = /(?<url>https?:\/\/\S*)/gu;
/**
 * Converts URLs to links in plain text.
 * @param text Plain text that may contain URLs.
 * @returns A React Node that contains the processed text or null if text is empty.
 */
const TextFormatter = ({ text, }) => {
    if (!text) {
        return null;
    }
    const nodesArray = text.split(URL_REGEXP);
    const parsedNodes = nodesArray.map((node, i) => {
        if (URL_REGEXP.test(node)) {
            return (_jsx(Link, { color: "secondary", href: node, rel: "noopener noreferrer", target: "_blank", underline: "always", sx: { overflowWrap: 'break-word' }, children: node }, i));
        }
        return (_jsx(Typography, { whiteSpace: "pre-wrap", component: "span", sx: { overflowWrap: 'anywhere' }, children: node }, i));
    });
    return _jsx(_Fragment, { children: parsedNodes });
};
export default TextFormatter;
