import { jsx as _jsx } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { Cancel, DriveFileRenameOutline } from '@mui/icons-material';
import { ConfirmationDialog, TextFormItem } from 'components/custom';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { onSubmitError, yup } from 'utils/forms';
const NameEditorDialog = (props) => {
    const id = React.useId();
    const { cancelButtonProps, confirmButtonProps, label, value: initValue, onRename, titleProps, InputProps, ...cmpProps } = props;
    const schema = React.useMemo(() => yup.object({
        name: yup.string().label(label).required(),
    }), [label]);
    const { handleSubmit, control, formState: { errors, isSubmitting }, reset, } = useForm({
        resolver: yupResolver(schema),
    });
    React.useEffect(() => {
        reset({});
    }, [reset, cmpProps.open]);
    const onSubmit = (values) => {
        onRename(values.name);
    };
    return (_jsx(ConfirmationDialog, { fullWidth: true, maxWidth: "sm", titleProps: {
            Icon: DriveFileRenameOutline,
            ...titleProps,
        }, cancelButtonProps: {
            startIcon: _jsx(Cancel, {}),
            ...cancelButtonProps,
        }, confirmButtonProps: {
            disabled: isSubmitting,
            form: id,
            startIcon: _jsx(DriveFileRenameOutline, {}),
            type: 'submit',
            ...confirmButtonProps,
        }, ...cmpProps, children: _jsx("form", { id: id, onSubmit: (e) => {
                handleSubmit(onSubmit)(e).catch(onSubmitError);
            }, children: _jsx(Controller, { control: control, defaultValue: initValue, name: "name", render: ({ field }) => {
                    var _a;
                    return (_jsx(TextFormItem, { ...field, label: label, errorMessage: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message, InputProps: InputProps }));
                } }) }) }));
};
export default NameEditorDialog;
