import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, Typography } from '@mui/material';
import { CalendarViewWeek } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/custom';
import SchoolSuppliesImage from 'public/img/illustrations/school_supplies.svg';
import { SCHOOL_YEARS } from 'utils/links';
const EmptyTimetable = () => {
    const { t } = useTranslation('timetable', { keyPrefix: 'emptyTimetable' });
    return (_jsxs(Stack, { alignItems: "center", spacing: 4, children: [_jsx(Box, { sx: {
                    backgroundImage: `url(${SchoolSuppliesImage})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    height: '25rem',
                    position: 'relative',
                    width: '100%',
                } }), _jsx(Typography, { sx: { fontWeight: 700 }, variant: "h6", children: t('emptyTimetableText') }), _jsx(Button, { color: "secondary", href: SCHOOL_YEARS, startIcon: _jsx(CalendarViewWeek, {}), sx: { width: '10rem' }, variant: "contained", children: _jsx(Typography, { align: "center", children: t('createTimetableButton') }) })] }));
};
export default EmptyTimetable;
