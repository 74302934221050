import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
export const DragAndDropContext = React.createContext({
    dragAndDropContextData: {},
    setDragAndDropContextData: () => undefined,
});
const DragAndDropProvider = ({ children, }) => {
    const [dragAndDropContextData, setDragAndDropContextData] = React.useState({});
    return (_jsx(DragAndDropContext.Provider, { value: {
            dragAndDropContextData,
            setDragAndDropContextData,
        }, children: children }));
};
export default DragAndDropProvider;
