import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link as MuiLink, Stack } from '@mui/material';
import { Link } from 'components/custom';
import { useTranslation } from 'react-i18next';
import { HELP_AND_ADVICE, IMPRINT, LEGAL, PRIVACY_POLICY, } from 'utils/external-links';
import { ACCOUNT_TERMINATION } from 'utils/links';
import { useResponsiveness } from 'utils/responsiveness';
const Footer = ({ background = 'inherit', color = 'inherit', }) => {
    const { t } = useTranslation('common', { keyPrefix: 'body.navigation' });
    const { isExtraSmallViewport } = useResponsiveness();
    return (_jsxs(Stack, { component: "footer", direction: "row", flexWrap: "wrap", sx: {
            background,
            color,
            columnGap: 4,
            justifyContent: isExtraSmallViewport ? 'center' : 'end',
            px: 2,
            py: 1,
            rowGap: 1,
        }, children: [_jsx(MuiLink, { href: HELP_AND_ADVICE, target: "_blank", rel: "noopener", underline: "hover", children: t('helpAndAdvice') }), _jsx(MuiLink, { href: LEGAL, target: "_blank", rel: "noopener", underline: "hover", children: t('legal') }), _jsx(MuiLink, { href: IMPRINT, target: "_blank", rel: "noopener", underline: "hover", children: t('imprint') }), _jsx(MuiLink, { href: PRIVACY_POLICY, target: "_blank", rel: "noopener", underline: "hover", children: t('privacyPolicy') }), _jsx(Link, { href: ACCOUNT_TERMINATION, underline: "hover", children: t('terminateAccount') })] }));
};
export default Footer;
