import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddCircle, Circle, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { SKIP, useCoursesQueryApi, useEducationalYearsQueryApi, useSubjectsQueryApi, } from 'api';
import { Button, LoadingWrapper, TimetableDataGrid } from 'components/custom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import { setTitle } from 'utils/head';
import DeleteCourseDialog from './DeleteCourseDialog';
import SaveCourseDialog from './SaveCourseDialog';
const Courses = () => {
    const { t: headTranslate } = useTranslation('timetable', {
        keyPrefix: 'head',
    });
    React.useEffect(() => {
        setTitle(headTranslate('title.courses'));
    }, [headTranslate]);
    const { t } = useTranslation('timetable', {
        keyPrefix: 'steps.courses',
    });
    const { t: commonTranslation } = useTranslation('common');
    const [activeCourse, setActiveCourse] = React.useState(null);
    const [courseToDelete, setCourseToDelete] = React.useState(null);
    const { schoolYearId = '' } = useParams();
    const { handlePrev, handleNext } = useOutletContext();
    const coursesStatus = useCoursesQueryApi(schoolYearId ? { schoolYearId } : SKIP);
    const subjectsStatus = useSubjectsQueryApi();
    const educationalYearsStatus = useEducationalYearsQueryApi();
    return (_jsxs(LoadingWrapper, { status: [coursesStatus, subjectsStatus, educationalYearsStatus], children: [_jsxs(Stack, { spacing: 2, flexGrow: "1", children: [_jsxs(Stack, { flexGrow: "1", spacing: 2, children: [_jsx(TimetableDataGrid, { "data-testid": "courses", label: _jsx("strong", { children: t('sectionTitle') }), rows: coursesStatus.data, columns: [
                                    {
                                        field: 'educationalYearId',
                                        flex: 0.4,
                                        valueFormatter: ({ value, }) => {
                                            var _a;
                                            return (_a = educationalYearsStatus.data.find((ey) => ey.id === value)) === null || _a === void 0 ? void 0 : _a.name;
                                        },
                                    },
                                    {
                                        field: 'subjectId',
                                        flex: 0.4,
                                        valueFormatter: ({ value, }) => { var _a; return (_a = subjectsStatus.data.find((s) => s.id === value)) === null || _a === void 0 ? void 0 : _a.name; },
                                    },
                                    {
                                        field: 'color',
                                        flex: 0.2,
                                        renderCell: ({ row }) => (_jsx(Circle, { sx: { color: row.color } })),
                                    },
                                ], onEditClick: setActiveCourse, onDeleteClick: setCourseToDelete, noItemsMessage: t('noItemsMessage') }), _jsx(Button, { color: "secondary", "data-testid": "addCourse", startIcon: _jsx(AddCircle, {}), onClick: () => {
                                    setActiveCourse({});
                                }, sx: { alignSelf: 'end' }, children: t('addButton') })] }), _jsxs(Stack, { direction: "row", spacing: 2, justifyContent: "end", children: [_jsx(Button, { variant: "contained", "data-testid": "back", color: "error", onClick: handlePrev(schoolYearId), startIcon: _jsx(KeyboardDoubleArrowLeft, {}), children: commonTranslation('body.button.back') }), _jsx(Button, { variant: "contained", "data-testid": "next", color: "success", disabled: !coursesStatus.data.length, onClick: handleNext(schoolYearId), endIcon: _jsx(KeyboardDoubleArrowRight, {}), children: commonTranslation('body.button.next') })] })] }), _jsx(SaveCourseDialog, { activeCourse: activeCourse, close: () => {
                    setActiveCourse(null);
                } }), _jsx(DeleteCourseDialog, { courseToDelete: courseToDelete, setCourseToDelete: setCourseToDelete })] }));
};
export default Courses;
