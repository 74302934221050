import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { Illustration } from 'components/custom';
import Image from 'public/img/illustrations/desk.svg';
import { useTranslation } from 'react-i18next';
import { useSidebarLayoutStyles } from 'layouts/Sidebar/styles';
/**
 * Displays information that an account activation link has been sent per email.
 * This is the next step after registration form in submitted.
 */
const Activation = () => {
    const { t } = useTranslation('auth');
    const { formStyles, illustrationStyles } = useSidebarLayoutStyles();
    return (_jsxs(Stack, { justifyContent: "center", spacing: formStyles.spacing, children: [_jsx(Illustration, { image: Image, minHeightPx: 100, minWidthPx: 200, sx: {
                    ...illustrationStyles,
                    margin: '0 auto !important',
                } }), _jsx(Typography, { align: "center", variant: "h4", color: "secondary", children: t('body.step.activation.heading1') }), _jsx(Typography, { align: "center", sx: { fontWeight: 700 }, children: t('body.step.activation.paragraph1') }), _jsx(Typography, { align: "center", children: t('body.step.activation.paragraph2') })] }));
};
export default Activation;
