import { HttpStatusCodes } from 'utils/http-status-codes';
import { convertFlowTypeToLink, showError } from './skyla/helpers';
import { HOME } from 'utils/links';
import { getErrorMsg } from './skyla/mappings';
// A small function to help us deal with errors coming from fetching a flow.
export const handleGetFlowError = (navigate, flowType, resetFlow, t) => {
    return async (err) => {
        var _a, _b, _c, _d;
        const error = (_a = err.response) === null || _a === void 0 ? void 0 : _a.data.error;
        const errorId = (_b = error === null || error === void 0 ? void 0 : error.id) !== null && _b !== void 0 ? _b : '';
        /* eslint-disable line-comment-position, no-inline-comments -- Case with pass through */
        switch (errorId) {
            case 'session_already_available': // User is already signed in, let's redirect them home!
                navigate(HOME);
                return Promise.resolve();
            case 'browser_location_change_required': // Ory Kratos asked us to point the user to this URL.
            case 'session_aal2_required': // 2FA is enabled and enforced, but user did not perform 2fa yet!
            case 'session_refresh_required': // We need to re-authenticate to perform this action
                if ((_c = err.response) === null || _c === void 0 ? void 0 : _c.data.redirect_browser_to) {
                    window.location.assign(err.response.data.redirect_browser_to);
                }
                else {
                    showError(t('body.flow.error.missingRedirectParam'));
                }
                return Promise.resolve();
            case 'self_service_flow_return_to_forbidden': // The flow expired, let's request a new one.
            case 'self_service_flow_expired': // The flow expired, let's request a new one.
            case 'security_csrf_violation': // A CSRF violation occurred. Best to just refresh the flow!
            case 'security_identity_mismatch': // The requested item was intended for someone else. Let's request a new flow...
                showError(getErrorMsg(errorId, t));
                resetFlow(undefined);
                navigate(convertFlowTypeToLink(flowType));
                return Promise.resolve();
            default: {
                switch ((_d = err.response) === null || _d === void 0 ? void 0 : _d.status) {
                    case HttpStatusCodes.UNAUTHORIZED:
                    case HttpStatusCodes.GONE:
                        // The flow expired, let's request a new one.
                        resetFlow(undefined);
                        navigate(convertFlowTypeToLink(flowType));
                        return Promise.resolve();
                    default:
                        // We are not able to handle the error? Return it.
                        return Promise.reject(err);
                }
            }
            /* eslint-enable line-comment-position, no-inline-comments */
        }
    };
};
// A small function to help us deal with errors coming from initializing a flow.
export const handleFlowError = handleGetFlowError;
/**
 * Displays an error message if previous error handler don't handle an error.
 * @param t Translation function with error IDs.
 * @returns Rejected Promise with the error.
 */
export const handleErrorFallback = (t) => {
    return async (err) => {
        var _a, _b;
        const error = (_a = err.response) === null || _a === void 0 ? void 0 : _a.data.error;
        showError(getErrorMsg((_b = error === null || error === void 0 ? void 0 : error.id) !== null && _b !== void 0 ? _b : '', t), error);
        return Promise.reject(err);
    };
};
