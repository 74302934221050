import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { TextFormItem } from 'components/custom';
import { Lock } from '@mui/icons-material';
import { FormHelperText, Stack } from '@mui/material';
export const MIN_PASSWORD_LENGTH = 12;
export const checkPasswordStrength = (pw) => {
    const failedCriteria = [];
    if (pw.length < MIN_PASSWORD_LENGTH) {
        failedCriteria.push('LENGTH');
    }
    if (pw === pw.toUpperCase()) {
        failedCriteria.push('LOWER_CASE');
    }
    if (pw === pw.toLowerCase()) {
        failedCriteria.push('UPPER_CASE');
    }
    if (!/\d/u.test(pw)) {
        failedCriteria.push('NUMBER');
    }
    if (!/\W/u.test(pw)) {
        failedCriteria.push('SPECIAL_CHAR');
    }
    return failedCriteria;
};
export const PasswordValidation = ({ passwordValue = '', setValidity, spacing, }) => {
    const { t } = useTranslation('auth', { keyPrefix: 'body.flow' });
    const [passwordRepeatValue, setPasswordRepeatValue] = useState('');
    React.useEffect(() => {
        if (!passwordValue) {
            setPasswordRepeatValue('');
        }
    }, [passwordValue]);
    const isSamePassword = passwordValue === passwordRepeatValue;
    const notSamePasswordErrorMsg = isSamePassword
        ? ''
        : t('confirmPasswordField.error');
    const failedStrengthList = passwordValue
        ? checkPasswordStrength(passwordValue)
        : [];
    const strengthErrorMsg = failedStrengthList.map((key, index) => {
        const isFirstItem = index === 0;
        const isLastItem = index === failedStrengthList.length - 1;
        let prefix = ', ';
        if (isFirstItem) {
            prefix = `${t('passwordStrength.beginningOfSentence')} `;
        }
        else if (isLastItem) {
            prefix = ` ${t('passwordStrength.and')} `;
        }
        switch (key) {
            case 'LENGTH':
                return (prefix +
                    t('passwordStrength.length', { minLength: MIN_PASSWORD_LENGTH }));
            case 'LOWER_CASE':
                return prefix + t('passwordStrength.lowerCase');
            case 'UPPER_CASE':
                return prefix + t('passwordStrength.upperCase');
            case 'NUMBER':
                return prefix + t('passwordStrength.number');
            case 'SPECIAL_CHAR':
                return prefix + t('passwordStrength.specialChar');
            default:
                return prefix + t('passwordStrength.unknown');
        }
    });
    if (failedStrengthList.find((v) => v !== 'LENGTH')) {
        strengthErrorMsg.push(` ${t('passwordStrength.contain')}`);
    }
    if (strengthErrorMsg.length) {
        strengthErrorMsg.push('.');
    }
    setValidity(failedStrengthList.length === 0 && isSamePassword);
    return (_jsxs(Stack, { spacing: spacing, children: [_jsx(FormHelperText, { error: !!strengthErrorMsg, variant: "outlined", "data-testid": "passwordStrength", children: strengthErrorMsg }), _jsx(TextFormItem, { name: "password2", label: t('confirmPasswordField.label'), type: "password", placeholder: t('confirmPasswordField.placeholder'), StartAdornmentIcon: Lock, value: passwordRepeatValue, errorMessage: notSamePasswordErrorMsg, onChange: (event) => {
                    setPasswordRepeatValue(event.target.value);
                } })] }));
};
