import { jsx as _jsx } from "react/jsx-runtime";
import { getAddObjectsToLessonMutationOptions, useAddObjectsToLessonMutation, } from 'api';
import { FileObjectSelectionDialog } from 'components/custom';
const FileObjectAssignDialog = ({ lessonId, isDialogOpen: isDialogVisible, closeDialog, onSuccess, }) => {
    const [assignObjects, statusAssignObjects] = useAddObjectsToLessonMutation();
    const onConfirm = (selectionController) => {
        const selected = selectionController.getSelected();
        const ids = selected.map((fileObject) => fileObject.id);
        void assignObjects(getAddObjectsToLessonMutationOptions(lessonId, ids)).then(() => {
            closeDialog();
            selectionController.deselectAll();
            onSuccess();
        });
    };
    return (_jsx(FileObjectSelectionDialog, { open: isDialogVisible, lessonId: lessonId, onConfirm: onConfirm, onCancel: closeDialog, mutationStatus: statusAssignObjects }));
};
export default FileObjectAssignDialog;
