import { get } from 'lodash-es';
import { getApiUrl } from 'utils/config';
import { HttpStatusCodes } from 'utils/http-status-codes';
import { log, Severity } from 'utils/logger';
// eslint-disable-next-line @typescript-eslint/no-magic-numbers -- Hard coded file size limit
export const MAX_FILE_SIZE_IN_BYTES = 550 * 2 ** 20;
export var InternalErrorCodes;
(function (InternalErrorCodes) {
    InternalErrorCodes["FILE_SIZE_EXCEEDED"] = "FE-file-size-exceeded";
    InternalErrorCodes["STORAGE_QUOTA_EXCEEDED"] = "FE-storage-quota-exceeded";
})(InternalErrorCodes || (InternalErrorCodes = {}));
/**
 * XMLHttpRequest: readyState property
 * https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
 */
var ReadyState;
(function (ReadyState) {
    ReadyState[ReadyState["UNSENT"] = 0] = "UNSENT";
    ReadyState[ReadyState["OPENED"] = 1] = "OPENED";
    ReadyState[ReadyState["HEADERS_RECEIVED"] = 2] = "HEADERS_RECEIVED";
    ReadyState[ReadyState["LOADING"] = 3] = "LOADING";
    ReadyState[ReadyState["DONE"] = 4] = "DONE";
})(ReadyState || (ReadyState = {}));
const getErrorCode = (response) => {
    try {
        return get(JSON.parse(response), 'key', undefined);
    }
    catch (error) {
        return undefined;
    }
};
export const uploadFile = async (file, options) => {
    var _a;
    const xhr = new XMLHttpRequest();
    const abort = () => {
        xhr.abort();
    };
    if (file.size > MAX_FILE_SIZE_IN_BYTES) {
        const error = new Error('File too big.');
        (_a = options === null || options === void 0 ? void 0 : options.onError) === null || _a === void 0 ? void 0 : _a.call(options, {
            error,
            errorCode: InternalErrorCodes.FILE_SIZE_EXCEEDED,
            fileName: file.name,
        });
        return Promise.reject(error);
    }
    return new Promise((resolve, reject) => {
        file
            .arrayBuffer()
            .then((data) => {
            var _a;
            const maxPercent = 100;
            xhr.withCredentials = true;
            xhr.open('POST', `${getApiUrl()}/api/v1/object-data`);
            const mimeType = file.type === 'url/text' ? file.type : 'application/octet-stream';
            xhr.setRequestHeader('Content-Type', mimeType);
            xhr.setRequestHeader('Name', encodeURIComponent(file.name));
            Object.entries((_a = options === null || options === void 0 ? void 0 : options.headers) !== null && _a !== void 0 ? _a : []).forEach(([key, value]) => {
                xhr.setRequestHeader(key, value);
            });
            if (options === null || options === void 0 ? void 0 : options.onProgress) {
                xhr.upload.addEventListener('progress', (event) => {
                    var _a;
                    const progress = Math.floor((event.loaded / event.total) * maxPercent);
                    (_a = options.onProgress) === null || _a === void 0 ? void 0 : _a.call(options, progress, abort);
                });
            }
            xhr.addEventListener('readystatechange', () => {
                var _a, _b, _c;
                if (xhr.readyState !== ReadyState.DONE) {
                    return;
                }
                if (xhr.status === HttpStatusCodes.CREATED) {
                    (_a = options === null || options === void 0 ? void 0 : options.onProgress) === null || _a === void 0 ? void 0 : _a.call(options, maxPercent, abort);
                    (_b = options === null || options === void 0 ? void 0 : options.onSuccess) === null || _b === void 0 ? void 0 : _b.call(options, file);
                    resolve();
                    return;
                }
                const error = new Error(`${xhr.status}: ${xhr.statusText}`);
                (_c = options === null || options === void 0 ? void 0 : options.onError) === null || _c === void 0 ? void 0 : _c.call(options, {
                    error,
                    errorCode: getErrorCode(xhr.responseText),
                    fileName: file.name,
                    status: {
                        code: xhr.status,
                        text: xhr.statusText,
                    },
                });
                reject(error);
            });
            xhr.send(data);
        })
            .catch((error) => {
            var _a;
            log('File upload error', {
                dump: error,
                severity: Severity.ERROR,
            });
            if (error instanceof Error) {
                (_a = options === null || options === void 0 ? void 0 : options.onError) === null || _a === void 0 ? void 0 : _a.call(options, { error, fileName: file.name });
            }
            reject(error);
        });
    });
};
export const uploadFiles = async (fileList, options) => {
    var _a, _b, _c;
    const files = [...fileList];
    let fileIndex = 0;
    let errorsCount = 0;
    let isAbortCalled = false;
    let totalBytesUploaded = 0;
    const onFileProgress = (progress, abort) => {
        var _a;
        (_a = options === null || options === void 0 ? void 0 : options.onFileProgress) === null || _a === void 0 ? void 0 : _a.call(options, {
            file: files[fileIndex - 1],
            index: fileIndex,
            progress,
        }, () => {
            isAbortCalled = true;
            abort();
        });
    };
    const onFileSuccess = (file) => {
        var _a;
        totalBytesUploaded += file.size;
        (_a = options === null || options === void 0 ? void 0 : options.onFileSuccess) === null || _a === void 0 ? void 0 : _a.call(options, file);
    };
    const onFileError = (uploadError) => {
        var _a;
        (_a = options === null || options === void 0 ? void 0 : options.onFileError) === null || _a === void 0 ? void 0 : _a.call(options, uploadError);
    };
    for (const file of files) {
        fileIndex += 1;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Changes in closure.
        if (isAbortCalled) {
            return;
        }
        if ((_a = options === null || options === void 0 ? void 0 : options.beforeFileUpload) === null || _a === void 0 ? void 0 : _a.call(options, file, fileIndex, totalBytesUploaded)) {
            errorsCount += 1;
            // eslint-disable-next-line no-continue -- Skip this file.
            continue;
        }
        try {
            // eslint-disable-next-line no-await-in-loop -- Serializing file upload.
            await uploadFile(file, {
                headers: options === null || options === void 0 ? void 0 : options.headers,
                onError: onFileError,
                onProgress: onFileProgress,
                onSuccess: onFileSuccess,
            });
        }
        catch (error) {
            errorsCount += 1;
            // Swallow errors. Handling using onError for each file.
        }
    }
    if (errorsCount < 1) {
        (_b = options === null || options === void 0 ? void 0 : options.onSuccess) === null || _b === void 0 ? void 0 : _b.call(options);
    }
    (_c = options === null || options === void 0 ? void 0 : options.onFinally) === null || _c === void 0 ? void 0 : _c.call(options);
};
export const getFileList = (event) => {
    if ('files' in event.target) {
        return event.target.files;
    }
    if ('dataTransfer' in event) {
        return event.dataTransfer.files;
    }
    return null;
};
/**
 * Checks if the whole file list would pass the server validation.
 * This can be used to inform the user that some files cannot be uploaded,
 * before starting the upload process.
 * @param fileList File list.
 * @param storage User storage size.
 * @returns `true` if the whole file list should be taken in by the server.
 */
export const validateFileList = (fileList, storage) => {
    const files = [...fileList];
    if (files.some((file) => file.size > MAX_FILE_SIZE_IN_BYTES)) {
        return InternalErrorCodes.FILE_SIZE_EXCEEDED;
    }
    const freeSpace = storage.total - storage.used;
    const enoughSpace = files.reduce((acc, file) => acc + file.size, 0) <=
        freeSpace;
    if (!enoughSpace) {
        return InternalErrorCodes.STORAGE_QUOTA_EXCEEDED;
    }
    return true;
};
