/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable no-bitwise */
import theme from 'theme';
/*
 * Returns a text color with a high contrast for the given background color.
 * Source for the perceived brightness algorithm: https://www.w3.org/TR/AERT/#color-contrast
 * @param {string} backgroundColor in format #RRGGBB.
 * @returns {string} CSS color.
 */
export const getTextColor = (backgroundColor) => {
    const darkTextColor = theme.palette.text.primary;
    const lightTextColor = theme.palette.primary.contrastText;
    // About the middle between light and dark text
    const mediumBrightness = 142000;
    if (!backgroundColor) {
        return darkTextColor;
    }
    const rgb = parseInt(backgroundColor.slice(1), 16);
    if (Number.isNaN(rgb)) {
        return darkTextColor;
    }
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = rgb & 0xff;
    const brightness = r * 299 + g * 587 + b * 114;
    return brightness > mediumBrightness ? darkTextColor : lightTextColor;
};
