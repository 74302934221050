import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import Image from 'public/img/illustrations/desk.svg';
import { Illustration } from 'components/custom';
import { useTranslation } from 'react-i18next';
import { useSidebarLayoutStyles } from 'layouts/Sidebar/styles';
const TerminationLinkSent = () => {
    const { t } = useTranslation('termination', {
        keyPrefix: 'terminationLinkSent',
    });
    const { illustrationStyles } = useSidebarLayoutStyles();
    return (_jsxs(Stack, { alignItems: "center", justifyContent: "center", spacing: 2, width: "100%", children: [_jsx(Illustration, { image: Image, minHeightPx: 100, minWidthPx: 200, sx: illustrationStyles }), _jsx(Typography, { variant: "h5", color: "secondary", children: t('heading1') }), _jsx(Typography, { align: "center", sx: { fontWeight: 700 }, children: t('paragraph1') }), _jsx(Typography, { align: "center", children: t('paragraph2') })] }));
};
export default TerminationLinkSent;
