import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddCircle, Delete, Edit } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { getCreateTimetableSlotsMutationOptions, getUpdateTimetableSlotsMutationOptions, isLoading, onValidationError, useCreateTimetableSlotsMutation, useUpdateTimetableSlotsMutation, } from 'api';
import { Button, CheckboxFormItem, ConfirmationDialog, LoadingWrapper, Select, } from 'components/custom';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getSeqArray, getWeekDayName, HOURS_PER_DAY, LAST_DAY_OF_THE_WEEK, } from 'utils/date';
import { useDialogMode } from 'utils/dialog-form';
import { onSubmitError, yup } from 'utils/forms';
import { log, Severity } from 'utils/logger';
const hoursInDoubleLesson = 2;
const defaultSlot = {
    hourInDay: 1,
    lengthHours: 1,
    weekday: 1,
};
const defaultValues = {
    slots: [defaultSlot],
};
const schema = yup.object({
    slots: yup
        .array()
        .of(yup.object({
        hourInDay: yup.number().required().min(1),
        id: yup.string(),
        lengthHours: yup.number().required(),
        weekday: yup.number().required().min(1),
    }))
        .required(),
});
const SaveSlotDialog = (props) => {
    const { activeCourse, close, timetableId } = props;
    const { t } = useTranslation('timetable', {
        keyPrefix: 'steps.lessons.cmp.SaveSlotDialog',
    });
    const id = React.useId();
    const { modeCreate, open } = useDialogMode(activeCourse === null || activeCourse === void 0 ? void 0 : activeCourse.slots);
    const { handleSubmit, control, formState: { errors, isSubmitting }, getValues, reset, setValue, watch, } = useForm({
        resolver: yupResolver(schema),
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'slots',
    });
    const [addSlot, statusAddSlot] = useCreateTimetableSlotsMutation();
    const [editSlot, statusEditSlot] = useUpdateTimetableSlotsMutation();
    const mutationStatus = modeCreate ? statusAddSlot : statusEditSlot;
    React.useEffect(() => {
        var _a;
        if (activeCourse) {
            reset(((_a = activeCourse.slots) === null || _a === void 0 ? void 0 : _a.length) ? activeCourse : defaultValues);
        }
        else {
            remove();
        }
    }, [activeCourse, remove, reset]);
    const onSuccess = () => {
        close();
        enqueueSnackbar(t('successMessage'));
    };
    const onSubmit = async (values) => {
        if (!activeCourse) {
            log('No active couse', {
                scope: 'SaveSlotDialog',
                severity: Severity.ERROR,
            });
            return Promise.resolve();
        }
        if (modeCreate) {
            return addSlot(getCreateTimetableSlotsMutationOptions({
                courseId: activeCourse.id,
                slots: values.slots,
                timetableId,
            }))
                .then(onSuccess)
                .catch(onValidationError);
        }
        return editSlot(getUpdateTimetableSlotsMutationOptions({
            courseId: activeCourse.id,
            slots: values.slots,
            timetableId,
        }))
            .then(onSuccess)
            .catch(onValidationError);
    };
    const hourInDayOptions = React.useMemo(() => getSeqArray(HOURS_PER_DAY).map((value) => ({
        label: t('hourField.option', { slotNumber: value }),
        value,
    })), [t]);
    const weekdayOptions = React.useMemo(() => getSeqArray(LAST_DAY_OF_THE_WEEK).map((value) => ({
        label: getWeekDayName(value),
        value,
    })), []);
    const isLastLesson = (index) => {
        var _a;
        const lastHour = (_a = hourInDayOptions[hourInDayOptions.length - 1]) === null || _a === void 0 ? void 0 : _a.value;
        return watch(`slots.${index}.hourInDay`) === lastHour;
    };
    const loading = isLoading([statusAddSlot, statusEditSlot]);
    return (_jsx(ConfirmationDialog, { open: open, onClose: close, onTransitionExited: () => {
            mutationStatus.reset();
        }, titleProps: {
            Icon: modeCreate ? AddCircle : Edit,
            text: t('title'),
        }, cancelButtonProps: {
            onClick: close,
        }, confirmButtonProps: {
            disabled: isSubmitting || loading,
            form: id,
            type: 'submit',
        }, fullWidth: true, children: _jsx(LoadingWrapper, { mutation: mutationStatus, children: _jsx("form", { id: id, onSubmit: (e) => {
                    handleSubmit(onSubmit)(e).catch(onSubmitError);
                }, children: _jsxs(Stack, { spacing: 2, children: [_jsx(Typography, { variant: "h6", children: activeCourse === null || activeCourse === void 0 ? void 0 : activeCourse.name }), fields.map((slot, index) => (_jsxs(Box, { sx: {
                                display: 'grid',
                                gap: 2,
                                gridTemplateColumns: 'repeat(4, 1fr)',
                            }, children: [_jsx(Controller, { control: control, name: `slots.${index}.weekday`, render: ({ field }) => {
                                        var _a, _b, _c;
                                        return (_jsx(Select, { ...field, label: t('weekdayField.label'), placeholder: t('weekdayField.placeholder'), errorMessage: (_c = (_b = (_a = errors.slots) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.weekday) === null || _c === void 0 ? void 0 : _c.message, options: weekdayOptions }));
                                    } }), _jsx(Controller, { control: control, name: `slots.${index}.hourInDay`, render: ({ field }) => {
                                        var _a, _b, _c;
                                        return (_jsx(Select, { ...field, label: t('hourField.label'), placeholder: t('hourField.placeholder'), errorMessage: (_c = (_b = (_a = errors.slots) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.hourInDay) === null || _c === void 0 ? void 0 : _c.message, options: hourInDayOptions, onChange: (e) => {
                                                field.onChange(e);
                                                if (isLastLesson(index)) {
                                                    setValue(`slots.${index}.lengthHours`, 1);
                                                }
                                            } }));
                                    } }), _jsx(Stack, { justifyContent: "center", sx: {
                                        visibility: isLastLesson(index) ? 'hidden' : 'visible',
                                    }, children: _jsx(Controller, { control: control, name: `slots.${index}.lengthHours`, render: ({ field }) => (_jsx(CheckboxFormItem, { ...field, onChange: (e) => {
                                                setValue(`slots.${index}.lengthHours`, e.target.checked ? hoursInDoubleLesson : 1);
                                            }, checked: getValues(`slots.${index}.lengthHours`) ===
                                                hoursInDoubleLesson, label: t('doubleHourField'), sx: {
                                                justifyContent: 'end',
                                            } })) }) }), fields.length > 1 && (_jsx(Button, { color: "error", onClick: () => {
                                        remove(index);
                                    }, startIcon: _jsx(Delete, {}), children: t('deleteRepetition') }))] }, slot.id))), _jsx(Button, { color: "secondary", startIcon: _jsx(AddCircle, {}), onClick: () => {
                                append(defaultSlot);
                            }, sx: { alignSelf: 'end' }, children: t('addRepetition') })] }) }) }) }));
};
export default SaveSlotDialog;
