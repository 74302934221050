import { compareVersions } from 'compare-versions';
import { useTranslation } from 'react-i18next';
export const useReleaseVersions = () => {
    const { t } = useTranslation('releases');
    const releases = t('releases', {
        returnObjects: true,
    });
    const versions = Object.keys(releases)
        .sort(compareVersions)
        .reverse();
    const [latestVersion] = versions;
    return { latestVersion, versions };
};
