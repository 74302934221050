import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddCircle } from '@mui/icons-material';
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, } from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import React from 'react';
const SplitButton = (props) => {
    const { optionKeys, handleActionClick, t, children } = props;
    const [open, setOpen] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState('');
    const anchorRef = React.useRef(null);
    if (!selectedOption && optionKeys.length) {
        setSelectedOption(optionKeys[0]);
    }
    return (_jsxs("div", { children: [_jsxs(ButtonGroup, { variant: "contained", color: "secondary", ref: anchorRef, "aria-label": "Button group with a nested menu", children: [_jsx(Button, { startIcon: _jsx(AddCircle, {}), "data-testid": "actionButton", onClick: () => {
                            handleActionClick(selectedOption);
                        }, children: t(selectedOption) }), _jsx(Button, { size: "small", "aria-controls": open ? 'split-button-menu' : undefined, "aria-expanded": open ? 'true' : undefined, "aria-label": "open split button menu", "aria-haspopup": "menu", "data-testid": "openSplitButtonMenu", onClick: () => {
                            setOpen((prevOpen) => !prevOpen);
                        }, children: _jsx(ArrowDropDownIcon, {}) })] }), _jsx(Popper, { sx: {
                    zIndex: 1,
                }, open: open, anchorEl: anchorRef.current, transition: true, disablePortal: true, children: ({ TransitionProps, placement }) => (_jsx(Grow, { ...TransitionProps, style: {
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }, children: _jsx(Paper, { children: _jsx(ClickAwayListener, { onClickAway: () => {
                                setOpen(false);
                            }, children: _jsx(MenuList, { id: "split-button-menu", autoFocusItem: true, children: optionKeys.map((optionKey) => (_jsx(MenuItem, { "data-testid": optionKey, selected: optionKey === selectedOption, onClick: () => {
                                        setSelectedOption(optionKey);
                                        setOpen(false);
                                    }, children: t(optionKey) }, optionKey))) }) }) }) })) }), children] }));
};
export default SplitButton;
