import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ory } from 'components/kratos';
import { HttpStatusCodes } from 'utils/http-status-codes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HOME } from 'utils/links';
/**
 * Displays information about an authentication error.
 * This seems to be only used in development.
 */
const Error = () => {
    const [error, setError] = useState();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    // Get ?id=... from the URL
    const id = searchParams.get('id');
    useEffect(() => {
        // If we already have an error, do nothing.
        if (error) {
            return;
        }
        ory
            .getFlowError({ id: String(id) })
            .then(({ data }) => {
            setError(data);
        })
            .catch(async (err) => {
            var _a;
            /* eslint-disable line-comment-position, no-inline-comments -- Case with pass through */
            switch ((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) {
                case HttpStatusCodes.NOT_FOUND: // The error id could not be found. Let's just redirect home!
                case HttpStatusCodes.FORBIDDEN: // The error id could not be fetched due to e.g. a CSRF issue. Let's just redirect home!
                case HttpStatusCodes.GONE: // The error id expired. Let's just redirect home!
                    navigate(HOME);
                    return Promise.resolve();
                default:
                    return Promise.reject(err);
            }
            /* eslint-enable line-comment-position, no-inline-comments -- Case with pass through */
        });
    }, [id, navigate, error]);
    if (!error) {
        return null;
    }
    const indentSize = 2;
    return (_jsx("pre", { children: _jsx("code", { children: JSON.stringify(error, null, indentSize) }) }));
};
export default Error;
