import { FALLBACK_URL } from 'utils/external-links';
/**
 * Extracts URL from location.
 * @param name API name.
 * @returns URL.
 */
const getUrlFromLocation = (name) => {
    const { host, protocol } = window.location;
    const appPrefix = 'app.';
    if (host.startsWith(appPrefix)) {
        return `${protocol}//${name}.${host.substring(appPrefix.length)}`;
    }
    const meinPrefix = 'mein.';
    if (host.startsWith(meinPrefix)) {
        return `${protocol}//${name}.${host.substring(meinPrefix.length)}`;
    }
    return undefined;
};
/**
 * Returns URL for given params.
 * First from environment variables if set (development, testing).
 * Next from location if supported.
 * Fallbacks to hardcoded fallback URL.
 * @param urlFromEnv Environment variable.
 * @param name API name.
 * @returns URL.
 */
const getUrl = (urlFromEnv, name) => {
    if (urlFromEnv) {
        return urlFromEnv;
    }
    const urlFromLocation = getUrlFromLocation(name);
    if (urlFromLocation) {
        return urlFromLocation;
    }
    return FALLBACK_URL;
};
/**
 * Returns URL to auth API.
 * @returns URL.
 */
export const getAuthUrl = () => getUrl(process.env.SKYLA_ORY_KRATOS_URL, 'auth');
/**
 * Returns URL to Skyla BE API.
 * @returns URL.
 */
export const getApiUrl = () => getUrl(process.env.SKYLA_API_URL, 'api');
/**
 * Returns current environment
 * @returns Environment
 */
export const getEnvironment = () => {
    if (process.env.NODE_ENV === 'development' ||
        process.env.NODE_ENV === 'test') {
        return process.env.NODE_ENV;
    }
    const { host } = window.location;
    const hostParts = host.split('.');
    if (hostParts.includes('staging')) {
        return 'staging';
    }
    if (hostParts.includes('main')) {
        return 'main';
    }
    return 'production';
};
/**
 * Returns current FE version.
 * @returns Version from tag or commit hash.
 */
export const getVersion = () => { var _a; return (_a = process.env.VERSION) !== null && _a !== void 0 ? _a : ''; };
