import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Cancel, CheckCircle, Error, Info } from '@mui/icons-material';
import StyledAlert from './Styled';
const ICON_MAPPING = {
    error: _jsx(Cancel, {}),
    info: _jsx(Info, {}),
    success: _jsx(CheckCircle, {}),
    warning: _jsx(Error, {}),
};
const Alert = (props, ref) => {
    return _jsx(StyledAlert, { ref: ref, iconMapping: ICON_MAPPING, ...props });
};
export default React.forwardRef(Alert);
