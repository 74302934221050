import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
/**
 * Illustration component.
 * Provides a standardized way to display adaptive images.
 */
const Illustration = ({ image, minHeightPx, minWidthPx, sx, }) => {
    return (_jsx(Box, { sx: {
            backgroundPosition: 'center bottom',
            position: 'relative',
            ...sx,
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            minHeight: minHeightPx,
            minWidth: minWidthPx,
        } }));
};
export default Illustration;
