import { TimetableSlotsDocument, useTimetableSlotsQuery } from 'api/generated';
import { getBaseOptions } from 'api/wrappers';
import { findKey } from 'lodash-es';
const weekdayMap = {
    FRIDAY: 5,
    MONDAY: 1,
    SATURDAY: 6,
    SUNDAY: 7,
    THURSDAY: 4,
    TUESDAY: 2,
    WEDNESDAY: 3,
};
export const useTimetableSlotsQueryApi = (variables, options) => {
    const { data, ...rest } = useTimetableSlotsQuery(getBaseOptions(variables, options));
    const timetable = data === null || data === void 0 ? void 0 : data.timetable;
    const timetableSlots = timetable
        ? timetable.slots.map((slot) => ({
            courseId: slot.courseId,
            courseName: slot.course.name,
            hourInDay: slot.hourInDay,
            id: slot.id,
            lengthHours: slot.lengthHours,
            timetableId: timetable.id,
            weekday: weekdayMap[slot.weekday],
        }))
        : [];
    return { ...rest, data: timetableSlots };
};
const getTimetableSlotsMutationOptions = (timetableSlot) => ({
    refetchQueries: [TimetableSlotsDocument],
    variables: {
        input: {
            courseId: timetableSlot.courseId,
            slots: timetableSlot.slots.map((slot) => {
                return {
                    ...slot,
                    weekday: findKey(weekdayMap, (weekdayNo) => weekdayNo === slot.weekday),
                };
            }),
            timetableId: timetableSlot.timetableId,
        },
    },
});
export const getCreateTimetableSlotsMutationOptions = (timetableSlot) => getTimetableSlotsMutationOptions(timetableSlot);
export const getUpdateTimetableSlotsMutationOptions = (timetableSlot) => getTimetableSlotsMutationOptions(timetableSlot);
export const getDeleteTimetableSlotsMutationOptions = ({ courseId, timetableId, }) => ({
    refetchQueries: [TimetableSlotsDocument],
    variables: {
        input: {
            courseId,
            timetableId,
        },
    },
});
export const getUpdateTimetableSlotMutationOptions = (timetableSlot) => {
    return {
        variables: {
            input: {
                ...timetableSlot,
                weekday: findKey(weekdayMap, (weekdayNo) => weekdayNo === timetableSlot.weekday),
            },
        },
    };
};
export { useCreateTimetableSlotsMutation, useDeleteTimetableSlotsMutation, useUpdateTimetableSlotsMutation, useUpdateTimetableSlotMutation, } from 'api/generated';
