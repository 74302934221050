import { debounce } from 'lodash-es';
import React from 'react';
// Debounce timeout (ms).
const resizeWait = 100;
/**
 * Hook for window data.
 */
export const useWindow = () => {
    // This is used as a unique identifier that can be used to re-calculate element's dimension/position on window resize.
    const [windowSize, setWindowSize] = React.useState(`${window.innerWidth}x${window.innerHeight}`);
    // Debounce the resize event for better web browser performance.
    const onWindowResize = React.useMemo(() => debounce(() => {
        setWindowSize(`${window.innerWidth}x${window.innerHeight}`);
    }, resizeWait), []);
    React.useEffect(() => {
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, [onWindowResize]);
    return {
        windowSize,
    };
};
