import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormLabel, FormHelperText } from '@mui/material';
import StyledTextarea from './Styled';
import React from 'react';
const Textarea = (props, ref) => {
    const { errorMessage, label, ...cmpProps } = props;
    const isError = !!errorMessage;
    const id = React.useId();
    const errorId = React.useId();
    return (_jsxs(FormControl, { sx: {
            display: 'flex',
            flexGrow: 1,
            width: '100%',
        }, children: [_jsx(FormLabel, { htmlFor: id, children: label }), _jsx(StyledTextarea, { "aria-describedby": errorId, id: id, ...cmpProps, ref: ref }), _jsx(FormHelperText, { id: errorId, error: isError, children: errorMessage })] }));
};
export default React.forwardRef(Textarea);
