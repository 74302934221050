import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from 'components/custom';
import { useMappings } from '../skyla/mappings';
export const NodeAnchor = ({ attributes, node }) => {
    const { getButtonText } = useMappings();
    return (_jsx(Button, { "data-testid": `node/anchor/${attributes.id}`, onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
            window.location.href = attributes.href;
        }, fullWidth: true, size: "large", color: "secondary", variant: "contained", children: getButtonText(node, attributes.title.text) }));
};
