import { CoursesDocument, useCoursesQuery } from 'api/generated';
import { getBaseOptions } from 'api/wrappers';
export const useCoursesQueryApi = (variables, options) => {
    var _a, _b;
    const { data, ...rest } = useCoursesQuery(getBaseOptions(variables, options));
    const courses = ((_b = (_a = data === null || data === void 0 ? void 0 : data.schoolYear) === null || _a === void 0 ? void 0 : _a.courses) !== null && _b !== void 0 ? _b : [])
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));
    return { ...rest, data: courses };
};
export const getCreateCourseMutationOptions = (course) => ({
    refetchQueries: [CoursesDocument],
    variables: {
        input: course,
    },
});
export const getUpdateCourseMutationOptions = (course) => ({
    variables: {
        input: course,
    },
});
export const getDeleteCourseMutationOptions = (id) => ({
    refetchQueries: [CoursesDocument],
    variables: {
        id,
    },
});
export { useCreateCourseMutation } from 'api/generated';
export { useUpdateCourseMutation } from 'api/generated';
export { useDeleteCourseMutation } from 'api/generated';
