import { jsx as _jsx } from "react/jsx-runtime";
import { InputAdornment } from '@mui/material';
import { NameEditorDialog } from 'components/custom';
import { fileNameExtSplit } from 'utils/file';
const FileObjectNameEditorDialog = ({ value, onRename, ...props }) => {
    const [fileName, fileExtension] = fileNameExtSplit(value);
    const fileNameSuffix = fileExtension ? `.${fileExtension}` : '';
    return (_jsx(NameEditorDialog, { ...props, value: fileName, onRename: (fileNameWithoutExtension) => {
            onRename(fileNameWithoutExtension + fileNameSuffix);
        }, InputProps: {
            endAdornment: (_jsx(InputAdornment, { position: "end", children: fileNameSuffix })),
        } }));
};
export default FileObjectNameEditorDialog;
