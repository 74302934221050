export const HOME = '/';
export const DATA = '/data';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const REGISTRATION = '/registration';
export const ACCOUNT_TERMINATION = '/termination';
export const ACTIVATION = '/activation';
export const REGISTRATION_SUCCESSFUL = '/registration-successful';
export const PASSWORD_RECOVERY = '/recovery';
export const EMAIL_VERIFICATION = '/verification';
export const AUTH_ERROR = '/auth/error';
export const SETTINGS = '/settings';
export const ACCOUNT_DATA = '/settings/account-data';
export const PLANS_AND_PAYMENTS = '/settings/plans-and-payments';
export const TIMETABLE = '/timetable';
export const SCHOOL_YEARS = '/timetable/school-years';
export const VACATION = '/timetable/school-years/:schoolYearId/vacation';
export const COURSES = '/timetable/school-years/:schoolYearId/courses';
export const LESSONS = '/timetable/school-years/:schoolYearId/lessons';
export const ACCOUNT_MANAGEMENT = '/account-management';
export const SCHOOL_MANAGEMENT = '/school-management';
export const LESSON = '/home/lesson/:lessonId';
export const SHARED_LESSON = `/shared/lesson/:sharingToken`;
export const SHARED_LESSON_NOT_FOUND = '/shared-lesson-not-found';
export const getVacationUrl = (schoolYearId) => `/timetable/school-years/${schoolYearId}/vacation`;
export const getCoursesUrl = (schoolYearId) => `/timetable/school-years/${schoolYearId}/courses`;
export const getLessonUrl = (lessonId) => `/home/lesson/${lessonId}`;
export const getSharedLessonUrl = (lessonId) => `${location.origin}/shared/lesson/${lessonId}`;
export const getLessonsUrl = (schoolYearId) => `/timetable/school-years/${schoolYearId}/lessons`;
export const getLoginUrl = (options) => {
    const params = [];
    if (options === null || options === void 0 ? void 0 : options.refresh) {
        params.push('refresh=true');
    }
    if (options === null || options === void 0 ? void 0 : options.returnPath) {
        params.push(`return_to=${encodeURIComponent(options.returnPath)}`);
    }
    return LOGIN + (params.length > 0 ? `?${params.join('&')}` : '');
};
