import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getNodeId, isUiNodeInputAttributes } from '../integrations/ui';
import React from 'react';
import { Messages } from './Messages';
import { Node } from './Node';
import { Template } from '../skyla/template';
const emptyState = () => {
    return {};
};
export class Flow extends React.Component {
    constructor(props) {
        super(props);
        /**
         * Template data validation state.
         * Should be set by a template if data validation check fails.
         * Default `true` because there may be no template.
         */
        this.validTemplateData = true;
        this.initializeValues = (nodes = []) => {
            // Compute the values
            const values = emptyState();
            nodes.forEach((node) => {
                // This only makes sense for text nodes
                if (isUiNodeInputAttributes(node.attributes)) {
                    if (node.attributes.type === 'button' ||
                        node.attributes.type === 'submit') {
                        /*
                         * In order to mimic real HTML forms, we need to skip setting the value
                         * for buttons as the button value will (in normal HTML forms) only trigger
                         * if the user clicks it.
                         */
                        return;
                    }
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Ory client types
                    values[node.attributes.name] = node.attributes.value;
                }
            });
            // Set all the values!
            this.setState((state) => ({ ...state, values }));
        };
        this.filterNodes = () => {
            const { flow, only } = this.props;
            if (!flow) {
                return [];
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- UiNode uses `any`
            return flow.ui.nodes.filter(({ group }) => {
                if (!only) {
                    return true;
                }
                return group === 'default' || group === only;
            });
        };
        // Handles form submission
        this.handleSubmit = async (e) => {
            // Prevent all native handlers
            e.stopPropagation();
            e.preventDefault();
            // Prevent double submission!
            if (this.state.loading || !this.validTemplateData) {
                return Promise.resolve();
            }
            this.setState((state) => ({
                ...state,
                loading: true,
            }));
            return this.props.onSubmit(this.state.values).finally(() => {
                /*
                 * We wait for reconciliation and update the state after 50ms
                 * Done submitting - update loading status
                 */
                this.setState((state) => ({
                    ...state,
                    loading: false,
                }));
            });
        };
        /**
         * Setter for template validity, which is pass down to the template.
         * @param valid `true` for valid data; otherwise `false`.
         */
        this.setTemplateValidity = (valid) => {
            this.validTemplateData = valid;
        };
        /**
         * Reset all values when the form has more steps, like registration
         */
        this.resetFormValues = () => {
            this.initializeValues(this.filterNodes());
        };
        this.state = {
            loading: false,
            values: emptyState(),
        };
    }
    componentDidMount() {
        this.initializeValues(this.filterNodes());
    }
    componentDidUpdate(prevProps) {
        if (prevProps.flow !== this.props.flow) {
            // Flow has changed, reload the values!
            this.initializeValues(this.filterNodes());
        }
    }
    render() {
        const { context, hideGlobalMessages, flow, templateName, only } = this.props;
        const { values, loading } = this.state;
        // Filter the nodes - only show the ones we want
        const nodes = this.filterNodes();
        if (!flow) {
            /*
             * No flow was set yet? It's probably still loading...
             *
             * Nodes have only one element? It is probably just the CSRF Token
             * and the filter did not match any elements!
             */
            return null;
        }
        return (_jsx("form", { action: flow.ui.action, method: flow.ui.method, onSubmit: (event) => {
                void this.handleSubmit(event);
            }, children: _jsxs(Template, { name: templateName, context: context, setValidity: this.setTemplateValidity, resetFormValues: this.resetFormValues, only: only, children: [hideGlobalMessages ? null : _jsx(Messages, { messages: flow.ui.messages }), nodes.map((node, k) => {
                        const id = getNodeId(node);
                        return (_jsx(Node, { context: context, disabled: loading, node: node, value: values[id], dispatchSubmit: this.handleSubmit, setValue: async (value) => new Promise((resolve) => {
                                this.setState((state) => ({
                                    ...state,
                                    values: {
                                        ...state.values,
                                        [getNodeId(node)]: value,
                                    },
                                }), resolve);
                            }) }, `${id.toString()}-${k}`));
                    })] }) }));
    }
}
