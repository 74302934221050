import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link as MuiLink, ListItemIcon, MenuItem } from '@mui/material';
import React from 'react';
/**
 * GridActionsCellLinkItem component.
 * Implementation based on:
 * MUI X source
 * packages/grid/x-data-grid/src/components/cell/GridActionsCellItem.tsx
 */
const GridActionsCellLinkItem = React.forwardRef((props, ref) => {
    const { href, download, label, icon, divider } = props;
    return (_jsx(MenuItem, { ref: ref, divider: divider, disableGutters: true, sx: { p: 0 }, children: _jsxs(MuiLink, { href: href, download: download, rel: "noopener noreferrer", target: "_blank", underline: "none", sx: { display: 'flex', flexGrow: 1, px: 2, py: 0.75 }, children: [_jsx(ListItemIcon, { children: icon }), label] }) }));
});
export default GridActionsCellLinkItem;
