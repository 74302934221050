import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AttachFile, Info } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { isLoading, useLessonQueryApi } from 'api';
import { ConfirmationDialog, FileObjectFilters, FileObjectGrid, FileObjectSearch, LoadingWrapper, } from 'components/custom';
import { getDefaultFilters } from 'components/custom/FileObjectFilters';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/date';
import { log, Severity } from 'utils/logger';
const FileObjectSelectionDialog = (props) => {
    var _a;
    const { t } = useTranslation('object', {
        keyPrefix: 'cmp.FileObjectSelectionDialog',
    });
    const status = useLessonQueryApi({ id: props.lessonId });
    const [selectionController, setSelectionController] = React.useState(undefined);
    const [selectionExists, setSelectionExists] = React.useState(false);
    const [filterQueryOptions, setFilterQueryOptions] = React.useState(getDefaultFilters());
    const [searchQuery, setSearchQuery] = React.useState('');
    const [error, setError] = React.useState(undefined);
    const onConfirmClick = () => {
        if (selectionController) {
            props.onConfirm(selectionController);
        }
        else {
            log('Triggered confirm click without selection. Broken disabled status?', {
                severity: Severity.ERROR,
            });
        }
    };
    const onCancelClick = () => {
        selectionController === null || selectionController === void 0 ? void 0 : selectionController.deselectAll();
        props.onCancel();
    };
    const onSelectionChange = (ctrl) => {
        setSelectionController(ctrl);
        setSelectionExists(ctrl.count > 0);
    };
    const loading = isLoading(props.mutationStatus ? [status, props.mutationStatus] : status);
    const lesson = status.data;
    return (_jsx(ConfirmationDialog, { cancelButtonProps: {
            onClick: onCancelClick,
        }, confirmButtonProps: {
            children: t('addFileObjectToLessonButton.text', {
                count: (_a = selectionController === null || selectionController === void 0 ? void 0 : selectionController.count) !== null && _a !== void 0 ? _a : 0,
            }),
            disabled: !selectionExists || loading,
            onClick: onConfirmClick,
            startIcon: _jsx(AttachFile, {}),
        }, onClose: onCancelClick, titleProps: { text: t('title') }, open: props.open, fullWidth: true, maxWidth: "xl", PaperProps: {
            style: { height: '80vh' },
        }, "data-testid": "FileObjectSelectionDialog", children: _jsx(Box, { children: _jsx(LoadingWrapper, { status: status, mutation: props.mutationStatus, overlay: true, children: _jsxs(Stack, { spacing: 2, height: "100%", children: [_jsx(Stack, { direction: "row", spacing: 2, justifyContent: "space-between", children: _jsxs(Stack, { direction: "row", alignItems: "center", spacing: 1, children: [_jsx(Info, { color: "secondary" }), _jsx(Typography, { sx: { fontWeight: 700 }, color: "secondary", children: t('hintText', {
                                            courseName: lesson === null || lesson === void 0 ? void 0 : lesson.courseName,
                                            lessonDate: (lesson === null || lesson === void 0 ? void 0 : lesson.startDate) && formatDate(lesson.startDate),
                                        }) })] }) }), _jsx(FileObjectSearch, { onQueryChange: setSearchQuery, error: error }), _jsx(FileObjectFilters, { onFilterChange: setFilterQueryOptions }), _jsx(FileObjectGrid, { disableActions: true, filter: filterQueryOptions, onSelectionChange: onSelectionChange, onError: setError, searchQuery: searchQuery })] }) }) }) }));
};
export default FileObjectSelectionDialog;
