import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import { useSharedLessonQuery } from 'api/shared-lesson';
import { FileObjectPreview, LoadingWrapper } from 'components/custom';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { setTitle } from 'utils/head';
import Info from './Info';
import SharedLessonFileObjectGrid from './SharedLessonFileObjectGrid';
import { SHARED_LESSON_NOT_FOUND } from 'utils/links';
import NotesReadOnly from './Notes/NotesReadOnly';
import { formatDate, getDateFromApiString } from 'utils/date';
const SharedLesson = () => {
    var _a, _b;
    const { t } = useTranslation('lesson');
    const { sharingToken = '' } = useParams();
    const navigate = useNavigate();
    const [lessonQuery, lessonStatus] = useSharedLessonQuery();
    const lesson = lessonStatus.data;
    React.useEffect(() => {
        lessonQuery(sharingToken);
    }, [sharingToken, lessonQuery]);
    React.useEffect(() => {
        if (lessonStatus.error) {
            navigate(SHARED_LESSON_NOT_FOUND);
        }
    }, [lessonStatus.error, navigate]);
    useEffect(() => {
        if (lesson) {
            setTitle(`${lesson.course.name} - ${formatDate(getDateFromApiString(lesson.startDateTime))}`);
        }
        else {
            setTitle(t('head.title'));
        }
    }, [t, lesson]);
    const [fileObject, setFileObject] = React.useState(undefined);
    const closePreview = () => {
        setFileObject(undefined);
    };
    useEffect(() => {
        closePreview();
    }, [lesson === null || lesson === void 0 ? void 0 : lesson.sharingToken]);
    return (_jsx(LoadingWrapper, { status: lessonStatus, children: _jsx(Stack, { height: "100%", "data-testid": "LessonView", children: _jsxs(Stack, { spacing: 2, flexGrow: 1, children: [lesson && (_jsx(Info, { lesson: {
                            courseColor: lesson.course.color,
                            courseName: lesson.course.name,
                            educationalYearName: lesson.course.educationalYear.name,
                            schoolYearName: lesson.course.schoolYear.name,
                            startDate: new Date(lesson.startDateTime),
                            subjectName: lesson.course.subject.name,
                        }, isLessonShared: true })), _jsx(FileObjectPreview, { fileObject: fileObject && {
                            ...fileObject,
                            downloadUrl: fileObject.currentVersion.downloadUrl,
                            name: fileObject.currentVersion.name,
                        }, onClose: closePreview }), _jsxs(Stack, { flexGrow: 1, sx: { display: 'grid', gap: 2, gridTemplateColumns: '2fr 1fr' }, children: [_jsx(SharedLessonFileObjectGrid, { "data-testid": "sharedLessonViewObjectGrid", rows: (_a = lesson === null || lesson === void 0 ? void 0 : lesson.objects) !== null && _a !== void 0 ? _a : [], sharingToken: sharingToken, onNameClick: setFileObject }), _jsx(NotesReadOnly, { notes: lesson === null || lesson === void 0 ? void 0 : lesson.notes, homework: lesson === null || lesson === void 0 ? void 0 : lesson.homework, previousHomework: (_b = lesson === null || lesson === void 0 ? void 0 : lesson.previousLesson) === null || _b === void 0 ? void 0 : _b.homework })] })] }) }) }));
};
export default SharedLesson;
