import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DragHandle } from '@mui/icons-material';
import { Box, Portal } from '@mui/material';
import { useTheme } from '@mui/system';
import { motion } from 'framer-motion';
import { throttle, uniqueId } from 'lodash-es';
import React, { useState } from 'react';
import { useWindow } from 'utils/window';
/**
 * Interval for throttled drag event.
 */
const dragInterval = 100;
/**
 * Threshold of how much of next sibling height in percent has to be reached to
 * snap onto the sibling.
 */
const snapThreshold = 0.4;
/**
 * Factor for visually reduce the resize area.
 * For a slot that spans over two slots it should be 1/2.
 */
const reduceFactor = 0.5;
/**
 * Component specific class name for the drag handle.
 */
const dragHandleClassName = uniqueId('resize-');
/**
 * Limits the amount of hours a slot can have.
 */
const maxHourLength = 2;
const ResizableCourseSlot = ({ children, iconColor, onSizeChange, hoursLength, emptyNextCell, }) => {
    var _a, _b, _c;
    const blockExpand = hoursLength >= maxHourLength;
    const blockReduce = hoursLength <= 1;
    const { windowSize } = useWindow();
    const theme = useTheme();
    const outlineColor = theme.palette.primary.main;
    const outlineWidth = theme.spacing(theme.spacingFactors.half);
    const baseRef = React.useRef(null);
    const nextSibling = (_c = (_b = (_a = baseRef.current) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.nextElementSibling) !== null && _c !== void 0 ? _c : undefined;
    const nextSiblingEl = nextSibling instanceof HTMLElement ? nextSibling : undefined;
    const [resizing, setResizing] = useState(false);
    const [initHeight, setInitHeight] = React.useState(0);
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const [top, setTop] = React.useState(0);
    const [left, setLeft] = React.useState(0);
    const [possibleResize, setPossibleResize] = React.useState(false);
    const [sizeDiff, setSizeDiff] = React.useState(0);
    React.useEffect(() => {
        var _a;
        const rootEl = (_a = baseRef.current) === null || _a === void 0 ? void 0 : _a.parentElement;
        if (rootEl) {
            if (blockReduce && !emptyNextCell) {
                setPossibleResize(false);
                return;
            }
            setPossibleResize(!blockExpand || !blockReduce);
        }
    }, [
        baseRef,
        nextSiblingEl,
        blockExpand,
        blockReduce,
        hoursLength,
        emptyNextCell,
    ]);
    React.useEffect(() => {
        var _a;
        const rootEl = (_a = baseRef.current) === null || _a === void 0 ? void 0 : _a.parentElement;
        if (rootEl) {
            setInitHeight(rootEl.offsetHeight);
            setWidth(rootEl.offsetWidth);
            setHeight(rootEl.offsetHeight);
            setTop(rootEl.offsetTop);
            setLeft(rootEl.offsetLeft);
        }
    }, [baseRef, windowSize, hoursLength]);
    React.useEffect(() => {
        var _a;
        const rootEl = (_a = baseRef.current) === null || _a === void 0 ? void 0 : _a.parentElement;
        if (rootEl && !resizing) {
            setHeight(rootEl.offsetHeight);
        }
    }, [resizing, baseRef]);
    const onDrag = React.useMemo(() => throttle((event, info) => {
        var _a;
        const newHeight = info.point.y - top;
        // Handle resize constraints.
        if ((blockReduce && newHeight <= initHeight) ||
            (blockExpand && newHeight >= initHeight)) {
            setHeight(initHeight);
            setSizeDiff(0);
            return;
        }
        // Resize is reducing slot size.
        if (newHeight < initHeight - initHeight * snapThreshold) {
            setHeight(initHeight * reduceFactor);
            setSizeDiff(-1);
            return;
        }
        const siblingHeight = (_a = nextSiblingEl === null || nextSiblingEl === void 0 ? void 0 : nextSiblingEl.offsetHeight) !== null && _a !== void 0 ? _a : 0;
        const nextSpanHeight = initHeight + siblingHeight * snapThreshold;
        // Resize is expanding slot size.
        if (newHeight >= nextSpanHeight) {
            setHeight(initHeight + siblingHeight);
            setSizeDiff(1);
            return;
        }
        // Fallback to init size.
        setHeight(initHeight);
        setSizeDiff(0);
    }, dragInterval), [
        setHeight,
        initHeight,
        top,
        blockExpand,
        blockReduce,
        nextSiblingEl,
        setSizeDiff,
    ]);
    return (_jsxs(Box, { ref: baseRef, sx: {
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
            textOverflow: 'ellipsis',
            width: '100%',
        }, children: [children, possibleResize && (_jsx(motion.div, { className: dragHandleClassName, drag: "y", dragConstraints: { bottom: 0, left: 0, right: 0, top: 0 }, dragElastic: 0, dragSnapToOrigin: true, dragMomentum: false, onDrag: onDrag, onDragEnd: () => {
                    setResizing(false);
                    if (sizeDiff !== 0) {
                        onSizeChange === null || onSizeChange === void 0 ? void 0 : onSizeChange(sizeDiff);
                    }
                }, onDragStart: () => {
                    setResizing(true);
                }, style: {
                    bottom: 0,
                    cursor: 'row-resize',
                    height: '8px',
                    left: 0,
                    position: 'absolute',
                    right: 0,
                }, children: _jsx(DragHandle, { sx: {
                        color: iconColor !== null && iconColor !== void 0 ? iconColor : 'inherit',
                        marginTop: '1px',
                        transition: 'margin 0.2s',
                        [`.${dragHandleClassName}:hover &`]: {
                            marginTop: '-10px',
                        },
                    } }) })), resizing && (_jsx(Portal, { children: _jsx(Box, { sx: {
                        cursor: 'row-resize',
                        height,
                        left,
                        outlineColor,
                        outlineStyle: 'solid',
                        outlineWidth,
                        position: 'absolute',
                        top,
                        width,
                    } }) }))] }));
};
export default ResizableCourseSlot;
