import React from 'react';
const MUI_DEFAULT_OBJECT_PAGE_SIZE = 100;
/**
 * Provides handling of the pagingModel for objects.
 * @returns pagingModel: Getter for the pagingModel in GraphQL format
 * @returns setPagingModel: Setter that accepts MUI format
 */
export const useObjectPaginationModel = () => {
    const [pagingModel, setPagingModel] = React.useState({
        limit: MUI_DEFAULT_OBJECT_PAGE_SIZE,
        offset: 0,
    });
    const handlePagingModelChange = (model) => {
        const limit = model.pageSize;
        const offset = model.page * model.pageSize;
        setPagingModel({ limit, offset });
    };
    return [pagingModel, handlePagingModelChange];
};
/**
 * Provides handling of the sortModel for objects.
 * @returns sortModel: Getter for the sortModel in GraphQL format
 * @returns setSortModel: Setter that accepts MUI format
 */
export const useObjectSortModel = () => {
    const [sortModel, setSortModel] = React.useState([]);
    const handleSortModelChange = (modelArray) => {
        if (!modelArray.length) {
            setSortModel([]);
            return;
        }
        const [model] = modelArray;
        const order = model.sort === 'desc' ? 'DESC' : 'ASC';
        let field = 'NAME';
        if (model.field === 'sizeInBytes') {
            field = 'SIZE_IN_BYTES';
        }
        else if (model.field === 'lastUsage') {
            field = 'UPDATED_AT';
        }
        setSortModel([{ field, order }]);
    };
    return [sortModel, handleSortModelChange];
};
