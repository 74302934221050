import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { formatDateToDateTimeAttribute, getDayPeriod } from 'utils/date';
import { getGreeting, getIllustrationSrc, formatGreetingDate } from './helpers';
const Greeting = ({ name, translationNamespace, }) => {
    const { t } = useTranslation(translationNamespace);
    const [currentDateTime, setCurrentDateTime] = React.useState(new Date());
    const [dayPeriod, setDayPeriod] = React.useState(getDayPeriod(new Date()));
    React.useEffect(() => {
        const oneSecondInMilliseconds = 1000;
        const dateCheckInterval = setInterval(() => {
            const updatedDate = new Date();
            setCurrentDateTime(updatedDate);
            setDayPeriod(getDayPeriod(updatedDate));
        }, oneSecondInMilliseconds);
        return () => {
            clearInterval(dateCheckInterval);
        };
    }, []);
    return (_jsxs(Box, { sx: {
            boxShadow: (theme) => theme.boxShadow,
            display: 'flex',
            gap: 2,
            p: 2,
        }, children: [_jsx(Box, { sx: {
                    backgroundImage: `url(${getIllustrationSrc(dayPeriod)})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    position: 'relative',
                    width: '4rem',
                } }), _jsxs(Box, { children: [_jsx(Typography, { sx: { fontWeight: 700 }, children: _jsx("time", { "data-testid": "currentDateTime", dateTime: formatDateToDateTimeAttribute(currentDateTime), children: t('cmp.Greeting.currentDateTime', {
                                currentDateTime: formatGreetingDate(currentDateTime),
                            }) }) }), _jsxs(Typography, { variant: "h5", children: [_jsx(Typography, { variant: "h5", component: "span", color: "secondary.main", children: getGreeting(dayPeriod, t) }), "\u00A0", name] })] })] }));
};
export default Greeting;
