import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { School } from '@mui/icons-material';
import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { useLessonsQueryApi } from 'api';
import { LoadingWrapper, WeeklySchedule } from 'components/custom';
import { convertLessonsToSlots } from 'components/custom/WeeklySchedule';
import { useWidgetContext, Widget } from 'components/custom/WidgetProvider';
import { addBusinessDays, endOfDay, isToday, isWeekend, nextMonday, startOfDay, subBusinessDays, } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Navigation from './Navigation';
const widgetName = Widget.LESSONS;
const Lessons = () => {
    var _a;
    const { t } = useTranslation('lesson', { keyPrefix: 'cmp.LessonsWidget' });
    const today = new Date();
    const initialDate = isWeekend(today) ? nextMonday(today) : today;
    const [day, setDay] = React.useState(initialDate);
    const { widget, setWidget } = useWidgetContext();
    const contextDate = (widget === null || widget === void 0 ? void 0 : widget.active) === widgetName ? (_a = widget.params) === null || _a === void 0 ? void 0 : _a.date : undefined;
    React.useEffect(() => {
        if (contextDate) {
            setDay(contextDate);
            setWidget({
                active: widgetName,
            });
        }
    }, [contextDate, setDay, setWidget]);
    const status = useLessonsQueryApi({
        from: startOfDay(day),
        to: endOfDay(day),
    });
    const data = status.data.length
        ? convertLessonsToSlots(status.data)
        : [];
    const onPrevClick = () => {
        setDay((current) => {
            return subBusinessDays(current, 1);
        });
    };
    const onTodayClick = () => {
        setDay(new Date());
    };
    const onNextClick = () => {
        setDay((current) => {
            return addBusinessDays(current, 1);
        });
    };
    const todayDisabled = isToday(day) || isWeekend(today);
    return (_jsxs(Card, { "data-testid": "Lessons", sx: { flexGrow: 1 }, children: [_jsx(CardHeader, { avatar: _jsx(School, {}), title: t('header') }), _jsx(CardContent, { sx: { display: 'flex', flexDirection: 'column', flexGrow: 1 }, children: _jsx(LoadingWrapper, { status: status, children: _jsxs(Stack, { spacing: 2, children: [_jsx(Navigation, { todayDisabled: todayDisabled, onPrevClick: onPrevClick, onTodayClick: onTodayClick, onNextClick: onNextClick }), _jsx(WeeklySchedule, { data: data, dateOfMonday: startOfDay(day), onlyOneDay: true }), _jsx(Navigation, { todayDisabled: todayDisabled, onPrevClick: onPrevClick, onTodayClick: onTodayClick, onNextClick: onNextClick })] }) }) })] }));
};
export default Lessons;
