import { useApolloClient } from '@apollo/client';
import { AccountStorageDocument, ExtendedObjectsDocument, ObjectsDocument, useExtendedObjectsQuery, useObjectFiltersQuery, useObjectsQuery, } from 'api/generated';
import { extendVariables, getBaseOptions } from 'api/wrappers';
import { startOfToday } from 'date-fns';
import { getApiStringFromDate, getDateFromApiString } from 'utils/date';
export const useExtendedFileObjectsQueryApi = (variables, options) => {
    var _a, _b;
    const today = getApiStringFromDate(startOfToday());
    const { data, ...rest } = useExtendedObjectsQuery(getBaseOptions(extendVariables(variables, {
        lessonFilter: {
            nextByDate: today,
            previousByDate: today,
        },
    }), {
        notifyOnNetworkStatusChange: true,
        ...options,
    }));
    const extendedFileObjects = ((_a = data === null || data === void 0 ? void 0 : data.objectsWithPagination.objects) !== null && _a !== void 0 ? _a : []).map((fileObject) => {
        /*
         * Always take the first lesson.
         * If the previous lesson doesn't exist it will return only one item.
         *
         * Business logic:
         * Show the last usage from previous lesson
         * if it doesn't exist then from the next lesson.
         */
        const lesson = fileObject.lessons.length > 0 ? fileObject.lessons[0] : undefined;
        const lessons = fileObject.lessons.map((fileObjectLesson) => ({
            id: fileObjectLesson.id,
            course: {
                id: fileObjectLesson.course.id,
                name: fileObjectLesson.course.name,
                color: fileObjectLesson.course.color,
            },
            endDate: getDateFromApiString(fileObjectLesson.endDateTime),
            startDate: getDateFromApiString(fileObjectLesson.startDateTime),
        }));
        return {
            downloadUrl: fileObject.currentVersion.downloadUrl,
            educationalYearName: lesson === null || lesson === void 0 ? void 0 : lesson.course.educationalYear.name,
            id: fileObject.id,
            lastUsage: lesson && getDateFromApiString(lesson.startDateTime),
            lessons,
            mimeType: fileObject.mimeType,
            name: fileObject.currentVersion.name,
            sizeInBytes: fileObject.currentVersion.sizeInBytes,
            subjectName: lesson === null || lesson === void 0 ? void 0 : lesson.course.subject.name,
            tags: fileObject.tags,
        };
    });
    return {
        ...rest,
        data: extendedFileObjects,
        totalCount: (_b = data === null || data === void 0 ? void 0 : data.objectsWithPagination.totalCount) !== null && _b !== void 0 ? _b : 0,
    };
};
export const useFileObjectsQueryApi = (variables, options) => {
    var _a;
    const { data, ...rest } = useObjectsQuery(getBaseOptions(variables, {
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
        ...options,
    }));
    const fileObjects = ((_a = data === null || data === void 0 ? void 0 : data.objects) !== null && _a !== void 0 ? _a : []).map((fileObject) => ({
        id: fileObject.id,
        mimeType: fileObject.mimeType,
        name: fileObject.currentVersion.name,
    }));
    return { ...rest, data: fileObjects };
};
export const useLessonFileObjectsQueryApi = (variables, options) => {
    var _a, _b;
    const { data, ...rest } = useExtendedObjectsQuery(getBaseOptions(variables, options));
    const fileObjects = ((_a = data === null || data === void 0 ? void 0 : data.objectsWithPagination.objects) !== null && _a !== void 0 ? _a : []).map((fileObject) => {
        return {
            downloadUrl: fileObject.currentVersion.downloadUrl,
            id: fileObject.id,
            mimeType: fileObject.mimeType,
            name: fileObject.currentVersion.name,
            sizeInBytes: fileObject.currentVersion.sizeInBytes,
            tags: fileObject.tags,
        };
    });
    return {
        ...rest,
        data: fileObjects,
        totalCount: (_b = data === null || data === void 0 ? void 0 : data.objectsWithPagination.totalCount) !== null && _b !== void 0 ? _b : 0,
    };
};
export const useFileObjectsRefetch = () => {
    const client = useApolloClient();
    return () => {
        void client.refetchQueries({
            include: [
                ObjectsDocument,
                ExtendedObjectsDocument,
                AccountStorageDocument,
            ],
        });
    };
};
export { useUpdateObjectMutation } from 'api/generated';
export const getUpdateObjectMutationOptions = (input) => ({
    variables: { input },
});
export { useDeleteObjectsMutation } from 'api/generated';
export const getDeleteObjectsMutationOptions = (ids) => ({
    variables: { ids },
    refetchQueries: [
        ObjectsDocument,
        ExtendedObjectsDocument,
        AccountStorageDocument,
    ],
});
export const useObjectFiltersQueryApi = () => {
    return useObjectFiltersQuery();
};
