import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { List } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Divider, FormLabel, Stack, Typography, } from '@mui/material';
import { getLessonMutationOptions, useLessonQueryApi, useUpdateLessonMutation, } from 'api';
import { LoadingWrapper, Textarea, TextFormatter } from 'components/custom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useBeforeUnload, useParams } from 'react-router-dom';
import { LocalStorageKeys } from 'utils/local-storage';
import { useTemporaryStorageEditFlow } from 'utils/temporary-storage';
import Actions from './Actions';
const Notes = () => {
    var _a;
    const { lessonId = '' } = useParams();
    const tempStorageHomeworkKey = LocalStorageKeys.LESSON_HOMEWORK + lessonId;
    const tempStorageNotesKey = LocalStorageKeys.LESSON_NOTES + lessonId;
    const { editData: editLessonNotes, editMode: isLessonNotesEditing, onChange: onChangeNotes, startEdit: startEditNotes, stopEdit: stopEditNotes, } = useTemporaryStorageEditFlow(tempStorageNotesKey);
    const { editData: editHomework, editMode: isHomeworkEditing, onChange: onChangeHomework, startEdit: startEditHomework, stopEdit: stopEditHomework, } = useTemporaryStorageEditFlow(tempStorageHomeworkKey);
    const lessonNotesRef = React.useRef(null);
    const homeworkRef = React.useRef(null);
    const { t } = useTranslation('lesson', { keyPrefix: 'cmp.Notes' });
    const status = useLessonQueryApi({ id: lessonId });
    const [updateLesson, statusUpdateLesson] = useUpdateLessonMutation();
    const { notes = '', homework = '', id = '', previousHomework = '', } = (_a = status.data) !== null && _a !== void 0 ? _a : {};
    const handleEditLessonNotes = () => {
        startEditNotes(notes);
        if (lessonNotesRef.current) {
            lessonNotesRef.current.focus();
        }
    };
    const handleSaveLessonNotes = () => {
        void updateLesson(getLessonMutationOptions({ id, notes: editLessonNotes })).then(stopEditNotes);
    };
    const handleEditHomework = () => {
        startEditHomework(homework);
        if (homeworkRef.current) {
            homeworkRef.current.focus();
        }
    };
    const handleSaveHomework = () => {
        void updateLesson(getLessonMutationOptions({ homework: editHomework, id })).then(stopEditHomework);
    };
    useBeforeUnload((event) => {
        if (isLessonNotesEditing || isHomeworkEditing) {
            event.preventDefault();
            event.returnValue = false;
        }
    });
    return (_jsxs(Card, { children: [_jsx(CardHeader, { avatar: _jsx(List, {}), title: _jsx(Typography, { children: t('header.title') }) }), _jsx(CardContent, { children: _jsx(LoadingWrapper, { status: status, mutation: statusUpdateLesson, children: _jsxs(Stack, { spacing: 2, divider: _jsx(Divider, {}), children: [_jsxs(Box, { children: [_jsx(FormLabel, { sx: { fontWeight: 'bold' }, children: t('previousHomework') }), _jsx(Typography, { children: _jsx(TextFormatter, { text: previousHomework }) })] }), _jsxs(Stack, { children: [_jsx(Textarea, { ref: lessonNotesRef, hidden: !isLessonNotesEditing, label: _jsx(Typography, { component: "span", fontWeight: "bold", children: t('lessonNotes.label') }), minRows: 5, onChange: onChangeNotes, value: editLessonNotes }), isLessonNotesEditing ? null : (_jsx(Typography, { children: _jsx(TextFormatter, { text: notes }) })), _jsx(Actions, { isEditing: isLessonNotesEditing, onEdit: handleEditLessonNotes, onCancel: stopEditNotes, onSave: handleSaveLessonNotes })] }), _jsxs(Stack, { children: [_jsx(Textarea, { ref: homeworkRef, hidden: !isHomeworkEditing, label: _jsx(Typography, { component: "span", fontWeight: "bold", children: t('homework.label') }), minRows: 5, onChange: onChangeHomework, value: editHomework }), isHomeworkEditing ? null : (_jsx(Typography, { children: _jsx(TextFormatter, { text: homework }) })), _jsx(Actions, { isEditing: isHomeworkEditing, onEdit: handleEditHomework, onCancel: stopEditHomework, onSave: handleSaveHomework })] })] }) }) })] }));
};
export default Notes;
