import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { useTheme } from '@mui/system';
import { Tooltip } from 'components/custom';
import { DragAndDropContext } from 'components/custom/DragAndDropProvider';
import React from 'react';
const isOperationAllowed = (droppableOperationName, draggableOperationName = []) => {
    if (typeof draggableOperationName === 'string') {
        return draggableOperationName === droppableOperationName;
    }
    return draggableOperationName.includes(droppableOperationName);
};
const Droppable = ({ children, droppableId, droppableItemName, height = '100%', operationName, onFileDrop, outlineColor = 'primary', outlinePosition, tooltipTitle, width = '100%', zIndex = 0, }) => {
    const { dragAndDropContextData, setDragAndDropContextData } = React.useContext(DragAndDropContext);
    const droppableRef = React.useRef(null);
    const { pointerX, pointerY, droppableId: contextDroppableId, operationName: contextOperationName, } = dragAndDropContextData;
    const [isHovered, setIsHovered] = React.useState(false);
    const checkHover = React.useCallback((x = 0, y = 0) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const droppableTop = (_b = (_a = droppableRef.current) === null || _a === void 0 ? void 0 : _a.offsetTop) !== null && _b !== void 0 ? _b : 0;
        const droppableBottom = droppableTop + ((_d = (_c = droppableRef.current) === null || _c === void 0 ? void 0 : _c.offsetHeight) !== null && _d !== void 0 ? _d : 0);
        const droppableLeft = (_f = (_e = droppableRef.current) === null || _e === void 0 ? void 0 : _e.offsetLeft) !== null && _f !== void 0 ? _f : 0;
        const droppableRight = droppableLeft + ((_h = (_g = droppableRef.current) === null || _g === void 0 ? void 0 : _g.offsetWidth) !== null && _h !== void 0 ? _h : 0);
        return (x > droppableLeft &&
            x < droppableRight &&
            y > droppableTop &&
            y < droppableBottom);
    }, []);
    React.useEffect(() => {
        if (contextDroppableId !== droppableId) {
            setIsHovered(false);
        }
        if (contextDroppableId !== droppableId &&
            isOperationAllowed(operationName, contextOperationName) &&
            checkHover(pointerX, pointerY)) {
            setDragAndDropContextData((prevState) => ({
                ...prevState,
                droppableId,
                droppableItemName,
            }));
            setIsHovered(true);
        }
        if (contextDroppableId === droppableId && !checkHover(pointerX, pointerY)) {
            setDragAndDropContextData((prevState) => ({
                ...prevState,
                droppableId: null,
                droppableItemName: '',
            }));
            setIsHovered(false);
        }
        if (pointerX === undefined || pointerY === undefined) {
            setIsHovered(false);
        }
    }, [
        checkHover,
        pointerX,
        pointerY,
        operationName,
        contextOperationName,
        setDragAndDropContextData,
        droppableId,
        contextDroppableId,
        droppableItemName,
    ]);
    const theme = useTheme();
    const getOutlineColor = () => {
        if (outlineColor === 'white') {
            return theme.palette.common.white;
        }
        return theme.palette[outlineColor].main;
    };
    const outlineWidth = theme.spacing(theme.spacingFactors.half);
    const outlineOffset = outlinePosition === 'inside' ? `-${outlineWidth}` : 0;
    const onDrop = (e) => {
        e.preventDefault();
        onFileDrop === null || onFileDrop === void 0 ? void 0 : onFileDrop(e);
        setIsHovered(false);
    };
    const onDragOver = (e) => {
        e.preventDefault();
        if (!isHovered) {
            setIsHovered(true);
        }
    };
    return (_jsx(Box, { ref: droppableRef, sx: {
            height,
            outlineColor: isHovered ? getOutlineColor() : 'transparent',
            outlineOffset,
            outlineStyle: 'solid',
            outlineWidth,
            overflow: 'hidden',
            width,
            zIndex,
        }, ...(onFileDrop && {
            onDragLeave: () => {
                setIsHovered(false);
            },
            onDragOver,
            onDrop,
        }), children: tooltipTitle ? (_jsx(Tooltip, { open: isHovered, title: tooltipTitle, placement: "top", children: _jsx(Box, { children: children }) })) : (children) }));
};
export default Droppable;
