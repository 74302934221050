import { jsx as _jsx } from "react/jsx-runtime";
import { CalendarViewWeek } from '@mui/icons-material';
import { getUpdateObjectLessonsMutationOptions, isLoading, SKIP, useObjectLessonsQueryApi, useUpdateObjectLessonsMutation, } from 'api';
import { ConfirmationDialog, LoadingWrapper } from 'components/custom';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LessonAssignmentDialogContent from './LessonAssignmentDialogContent';
const LessonAssignmentDialog = ({ objectId, onClose, open, }) => {
    var _a, _b;
    const { t } = useTranslation('object', {
        keyPrefix: 'cmp.LessonAssignmentDialog',
    });
    const lessonsRef = React.useRef([]);
    const [addedLessonsCount, setAddedLessonsCount] = React.useState(0);
    const [removedLessonsCount, setRemovedLessonsCount] = React.useState(0);
    const objectLessonsStatus = useObjectLessonsQueryApi(objectId ? { id: objectId } : SKIP);
    const [updateObjectLessonsMutation, updateObjectLessonsMutationStatus] = useUpdateObjectLessonsMutation();
    const handleConfirm = () => {
        if (!addedLessonsCount && !removedLessonsCount) {
            onClose();
            return;
        }
        const lessonIds = lessonsRef.current
            .filter((lesson) => !('status' in lesson) || lesson.status !== 'removed')
            .map((lesson) => lesson.id);
        if (objectId) {
            void updateObjectLessonsMutation(getUpdateObjectLessonsMutationOptions(objectId, lessonIds)).then(() => {
                enqueueSnackbar(t('successMessage', {
                    addedCount: addedLessonsCount,
                    removedCount: removedLessonsCount,
                }), { variant: 'success' });
                onClose();
            });
        }
    };
    React.useEffect(() => {
        if (!objectId) {
            setAddedLessonsCount(0);
            setRemovedLessonsCount(0);
        }
    }, [objectId]);
    return (_jsx(ConfirmationDialog, { cancelButtonProps: { onClick: onClose }, confirmButtonProps: {
            children: t('confirmButton', {
                assignedCount: !addedLessonsCount && !removedLessonsCount
                    ? '0'
                    : (addedLessonsCount ? `+${addedLessonsCount}` : '') +
                        (addedLessonsCount && removedLessonsCount ? ', ' : '') +
                        (removedLessonsCount ? `-${removedLessonsCount}` : ''),
            }),
            disabled: isLoading(updateObjectLessonsMutationStatus),
            onClick: handleConfirm,
        }, onClose: onClose, titleProps: { Icon: CalendarViewWeek, text: t('title') }, open: open, fullWidth: true, maxWidth: "xl", PaperProps: {
            style: { height: '80vh' },
        }, children: _jsx(LoadingWrapper, { status: objectLessonsStatus, mutation: updateObjectLessonsMutationStatus, children: _jsx(LessonAssignmentDialogContent, { removedLessonsCount: removedLessonsCount, setRemovedLessonsCount: setRemovedLessonsCount, addedLessonsCount: addedLessonsCount, setAddedLessonsCount: setAddedLessonsCount, initialLessonsList: (_b = (_a = objectLessonsStatus.data) === null || _a === void 0 ? void 0 : _a.lessons) !== null && _b !== void 0 ? _b : [], objectId: objectId, lessonsRef: lessonsRef }) }) }));
};
export default LessonAssignmentDialog;
