import { isObject } from 'lodash-es';
import React from 'react';
export const mapBaseOptions = (options, mapDataFn) => {
    const baseOptions = {};
    if (options === null || options === void 0 ? void 0 : options.onCompleted) {
        const { onCompleted } = options;
        baseOptions.onCompleted = (query) => {
            onCompleted(mapDataFn(query));
        };
    }
    if (options === null || options === void 0 ? void 0 : options.variables) {
        baseOptions.variables = options.variables;
    }
    return baseOptions;
};
/**
 * Checks if given response JSON contains an REST API error.
 * @param responseData JSON data from response body.
 * @returns `true` when error; otherwise `false`.
 */
const isRestError = (responseData) => {
    return isObject(responseData) && 'message' in responseData;
};
/**
 * Creates an error instance with REST API error.
 * @param responseData JSON data from response body.
 * @returns REST API error.
 */
const createRestError = (responseData) => {
    const { message, key } = isRestError(responseData)
        ? responseData
        : { message: undefined, key: undefined };
    const error = new Error(message);
    error.key = key;
    return error;
};
/**
 * Hook for wrapping a request (query) to support operation status.
 * @param queryFn A function that returns a Promise which resolves to Response.
 * @returns Operation status with data.
 */
export const useRestQuery = (queryFn) => {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState(undefined);
    const [error, setError] = React.useState(undefined);
    const query = (params, onCompletion) => {
        setLoading(true);
        queryFn(params, onCompletion)
            .then(async (response) => {
            const responseBodyData = (await response.json());
            if (!response.ok) {
                setError(createRestError(responseBodyData));
                return undefined;
            }
            return responseBodyData;
        })
            .then((responseBody) => {
            setData(responseBody);
            onCompletion === null || onCompletion === void 0 ? void 0 : onCompletion(responseBody);
        })
            .catch((responseError) => {
            setError(responseError);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    const memoizedQuery = React.useCallback(query, [queryFn]);
    return [
        memoizedQuery,
        {
            loading,
            data,
            error,
        },
    ];
};
/**
 * Hook for wrapping a request (mutation) to support operation status.
 * @param mutateFn A function that returns a Promise which resolves to Response.
 * @returns Operation status.
 */
export const useRestMutation = (mutateFn) => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(undefined);
    const mutate = async (params) => {
        setLoading(true);
        return mutateFn(params)
            .then(async (response) => {
            const data = (await response.json());
            if (!response.ok) {
                const responseError = createRestError(data);
                setError(responseError);
                throw responseError;
            }
            return response;
        })
            .catch((responseError) => {
            setError(responseError);
            throw responseError;
        })
            .finally(() => {
            setLoading(false);
        });
    };
    const memoizedMutate = React.useCallback(mutate, [mutateFn]);
    return [memoizedMutate, { loading, error }];
};
export const SKIP = Symbol('skipQueryApi');
/**
 * Extends variables when SKIP is not used.
 * It will overwrite variables data.
 * @param variables Variables to extend.
 * @param extend Object that will extend the variables.
 */
export const extendVariables = (variables, extend) => {
    if (typeof variables === 'object') {
        return {
            ...variables,
            ...extend,
        };
    }
    return variables;
};
/**
 * Returns base options from type-safe parameters.
 * @param variables Query variables.
 * @param options Query options.
 * @returns Supported query options for Apollo Client.
 */
export const getBaseOptions = (variables, options) => {
    const skip = variables === SKIP;
    return {
        skip,
        variables: skip ? undefined : variables,
        ...(options !== null && options !== void 0 ? options : {}),
    };
};
