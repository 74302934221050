export var MimeTypes;
(function (MimeTypes) {
    MimeTypes["AAC"] = "audio/aac";
    MimeTypes["CSV"] = "text/csv";
    MimeTypes["FLAC"] = "audio/flac";
    MimeTypes["GIF"] = "image/gif";
    MimeTypes["HTML"] = "text/html";
    MimeTypes["JPG"] = "image/jpeg";
    MimeTypes["MOV"] = "video/quicktime";
    MimeTypes["MP3"] = "audio/mpeg";
    MimeTypes["MP4"] = "video/mp4";
    MimeTypes["PDF"] = "application/pdf";
    MimeTypes["PNG"] = "image/png";
    MimeTypes["SVG"] = "image/svg+xml";
    MimeTypes["TEXT"] = "text/plain";
    MimeTypes["URL"] = "url/text";
    MimeTypes["WAV"] = "audio/wav";
    MimeTypes["WEBA"] = "audio/webm";
    MimeTypes["WEBM"] = "video/webm";
    MimeTypes["WEBP"] = "image/webp";
})(MimeTypes || (MimeTypes = {}));
/**
 * Extracts type from MIME type.
 * @param mimeType Format: `type/subtype;parameter=value`.
 * @returns Format: `type`.
 */
export const extractType = (mimeType) => {
    const [type] = mimeType.split('/', 1);
    return type;
};
/**
 * Extracts pure MIME type without optional parameters.
 * @param mimeType Format: `type/subtype;parameter=value`.
 * @returns Format: `type/subtype`.
 */
export const extractPureMimeType = (mimeType) => {
    const [pureMimeType] = mimeType.split(';', 1);
    return pureMimeType;
};
/**
 * Checks if given MIME types are equal. Ignoring optional parameters.
 * @param mimeType1 The MIME type to compare.
 * @param mimeType2 The other MIME type to compare.
 * @returns `true` if both `type/subtype` values are the same.
 */
export const isEqualPureMimeType = (mimeType1, mimeType2) => {
    return extractPureMimeType(mimeType1) === extractPureMimeType(mimeType2);
};
