import { jsx as _jsx } from "react/jsx-runtime";
import { Card, CardContent } from '@mui/material';
import { useTimetableExistsQueryApi } from 'api';
import { LoadingWrapper } from 'components/custom';
import CalendarForDashboard from './CalendarForDashboard';
import EmptyTimetable from './EmptyTimetable';
const Timetable = () => {
    const status = useTimetableExistsQueryApi();
    return (_jsx(Card, { "data-testid": "Timetable", children: _jsx(CardContent, { sx: { flexGrow: 1 }, children: _jsx(LoadingWrapper, { status: status, children: status.data ? _jsx(CalendarForDashboard, {}) : _jsx(EmptyTimetable, {}) }) }) }));
};
export default Timetable;
