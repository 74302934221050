/**
 * Formats a number using language-sensitive representation.
 * @param num Number to format.
 * @returns Formatted number.
 */
export const formatNumber = (num) => {
    return num.toLocaleString('de');
};
/**
 * Formats bytes using the highest SI base unit for integers.
 * @param bytes Bytes.
 * @returns Formatted value with unit.
 */
export const formatBytes = (bytes) => {
    let quantity = bytes;
    const kilo = 1024;
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let unitIndex = 0;
    for (unitIndex = 0; quantity >= kilo && unitIndex + 1 < units.length; unitIndex++) {
        quantity /= kilo;
    }
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- Rounding numbers in decimal system
    quantity = Math.round(quantity * 10) / 10;
    if (quantity === kilo && unitIndex + 1 < units.length) {
        quantity = 1;
        unitIndex++;
    }
    return `${formatNumber(quantity)} ${units[unitIndex]}`;
};
/**
 * Pads given numbers with zeros.
 * @param num Number to pad.
 * @param targetLength Target length.
 */
export const padNumber = (num, targetLength = 2) => {
    return num.toString().padStart(targetLength, '0');
};
