import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { isLoading, useApiError } from 'api';
import { Alert } from 'components/custom';
import { isArray } from 'lodash-es';
import { getOffset } from 'utils/element';
import { LoadingMask } from './LoadMask';
const getErrors = (status) => {
    if (!isArray(status)) {
        return status.error ? [status.error] : [];
    }
    return status
        .map((item) => item.error)
        .filter((error) => !!error);
};
const LoadingWrapper = ({ children, fullScreen, status = { loading: false }, mutation, overlay, }) => {
    const { getErrorMessage } = useApiError();
    const statusLoading = isLoading(status);
    const mutationLoading = mutation ? isLoading(mutation) : false;
    const loading = statusLoading || mutationLoading;
    // This will cause that the children re-mount.
    if (!overlay && loading) {
        return _jsx(LoadingMask, { fullScreen: fullScreen });
    }
    const statusErrors = getErrors(status);
    const isStatusError = statusErrors.length > 0;
    const mutationErrors = mutation ? getErrors(mutation) : [];
    const isMutationError = mutationErrors.length > 0;
    const offset = getOffset(typeof overlay === 'boolean' ? undefined : overlay === null || overlay === void 0 ? void 0 : overlay.current);
    return (_jsxs(_Fragment, { children: [isStatusError &&
                statusErrors.map((error, index) => (_jsx(Alert, { severity: "error", children: getErrorMessage(error) }, [error.message, index].join()))), isMutationError &&
                mutationErrors.map((error, index) => (_jsx(Alert, { severity: "error", sx: { my: 2 }, children: getErrorMessage(error) }, [error.message, index].join()))), isStatusError ? null : children, overlay && loading && (_jsx(Box, { position: "absolute", top: offset.top, bottom: offset.bottom, left: offset.left, right: offset.right, display: "flex", justifyContent: "center", alignItems: "center", bgcolor: "rgba(255, 255, 255, 0.5)", children: _jsx(LoadingMask, { fullScreen: false }) }))] }));
};
export default LoadingWrapper;
