import { jsx as _jsx } from "react/jsx-runtime";
import { Box, IconButton } from '@mui/material';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { LocalStorageKeys } from 'utils/local-storage';
const Header = ({ drawerOpen, setDrawerOpen, ...props }) => {
    const { t } = useTranslation('common');
    const handleDrawerOpen = () => {
        setDrawerOpen(true);
        localStorage.setItem(LocalStorageKeys.DRAWER_MINI, '');
    };
    const handleDrawerClose = () => {
        setDrawerOpen(false);
        localStorage.setItem(LocalStorageKeys.DRAWER_MINI, '1');
    };
    return (_jsx(Box, { sx: { display: 'flex', justifyContent: 'flex-end' }, ...props, children: drawerOpen ? (_jsx(IconButton, { color: "secondary", onClick: handleDrawerClose, title: t('body.drawer.shrink'), children: _jsx(KeyboardDoubleArrowLeft, {}) })) : (_jsx(IconButton, { color: "secondary", onClick: handleDrawerOpen, title: t('body.drawer.expand'), children: _jsx(KeyboardDoubleArrowRight, {}) })) }));
};
const StyledHeader = styled(Header)(({ theme, drawerOpen }) => ({
    '& .MuiIconButton-root': {
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        },
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 0,
        color: theme.palette.common.white,
        padding: 1,
        width: drawerOpen ? 'auto' : '100%',
    },
}));
export default StyledHeader;
