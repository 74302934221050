import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, Typography } from '@mui/material';
import { useSchoolYearQueryApi } from 'api';
import { LoadingWrapper } from 'components/custom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { formatDate } from 'utils/date';
const SchoolYearSection = () => {
    const { t } = useTranslation('timetable', {
        keyPrefix: 'steps.vacation.schoolYearSection',
    });
    const { schoolYearId = '' } = useParams();
    const schoolYearStatus = useSchoolYearQueryApi({ id: schoolYearId });
    return (_jsx(LoadingWrapper, { status: schoolYearStatus, children: _jsxs(Stack, { spacing: 2, children: [_jsx(Typography, { color: "secondary", variant: "h6", sx: { fontWeight: 700 }, children: t('title') }), schoolYearStatus.data ? (_jsxs(Box, { sx: {
                        display: 'grid',
                        gap: 1,
                        gridTemplateColumns: 'repeat(2, max-content)',
                    }, children: [_jsxs("span", { children: [t('firstSchoolDayField.label'), ": "] }), _jsx("span", { children: formatDate(schoolYearStatus.data.startDate) }), _jsxs("span", { children: [t('lastSchoolDayField.label'), ": "] }), _jsx("span", { children: formatDate(schoolYearStatus.data.endDate) })] })) : null] }) }));
};
export default SchoolYearSection;
