import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, ListItemText } from '@mui/material';
const FilterBase = ({ filter, onChange, options, onClear, renderSelect, setter, }) => {
    const handleChange = (e) => {
        if ('value' in e.target && Array.isArray(e.target.value)) {
            onChange === null || onChange === void 0 ? void 0 : onChange(e);
            setter(e.target.value);
            return;
        }
        onClear === null || onClear === void 0 ? void 0 : onClear(e);
        setter([]);
    };
    const renderOptionWithCheckbox = (option) => {
        return (_jsxs(_Fragment, { children: [_jsx(Checkbox, { checked: filter.includes(option.value) }), _jsx(ListItemText, { primary: option.label, primaryTypographyProps: {
                        sx: {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                    } })] }));
    };
    const renderValue = (selected) => {
        if (Array.isArray(selected)) {
            return options
                .filter((o) => selected.includes(o.value))
                .map((o) => o.label)
                .join(', ');
        }
        return '';
    };
    return renderSelect({
        SelectProps: { multiple: true, renderValue },
        onChange: handleChange,
        onClear: handleChange,
        options,
        renderOption: renderOptionWithCheckbox,
        value: filter,
    });
};
export default FilterBase;
