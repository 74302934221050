import { jsx as _jsx } from "react/jsx-runtime";
import { Tab } from '@mui/material';
import { upperFirst } from 'lodash-es';
import React from 'react';
const WidgetTabRoot = ({ Icon, name, active, onClick, ...tabProps }) => {
    return (_jsx(Tab, { id: `widget-tab-${name}`, "aria-controls": `widget-tabpanel-${name}`, icon: _jsx(Icon, { color: active ? 'secondary' : 'primary' }), sx: {
            minWidth: (theme) => theme.layout.closedWidgetDrawerWidth,
            width: (theme) => theme.layout.closedWidgetDrawerWidth,
        }, onClick: () => {
            onClick(name);
        }, ...tabProps, "data-testid": `WidgetTab${upperFirst(name)}` }));
};
export const WidgetTab = React.forwardRef((props, ref) => _jsx(WidgetTabRoot, { ref: ref, ...props }));
