import { jsx as _jsx } from "react/jsx-runtime";
import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache, } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { getApiUrl } from 'utils/config';
import { HttpStatusCodes } from 'utils/http-status-codes';
import { getLoginUrl } from 'utils/links';
import { log, Severity } from 'utils/logger';
import { getLocationRoute } from 'utils/routing';
import { isValidationError } from './error';
const getApolloClientConfig = ({ navigate, }) => {
    const link = createHttpLink({
        credentials: 'include',
        uri: `${getApiUrl()}/api/query`,
    });
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        var _a, _b;
        if (graphQLErrors) {
            if (((_b = (_a = graphQLErrors[0]) === null || _a === void 0 ? void 0 : _a.extensions) === null || _b === void 0 ? void 0 : _b.code) ===
                'auth.session.reauthenticationRequired') {
                navigate(getLoginUrl({
                    refresh: true,
                    returnPath: getLocationRoute(),
                }));
                return;
            }
            log('Apollo GraphQL error', {
                dump: graphQLErrors,
                severity: isValidationError(graphQLErrors)
                    ? Severity.WARN
                    : Severity.ERROR,
            });
        }
        if (networkError &&
            'statusCode' in networkError &&
            networkError.statusCode === HttpStatusCodes.UNAUTHORIZED) {
            navigate(getLoginUrl({
                returnPath: getLocationRoute(),
            }));
            return;
        }
        if (networkError) {
            log('Apollo network error', {
                dump: networkError,
                severity: Severity.ERROR,
            });
        }
    });
    return new ApolloClient({
        cache: new InMemoryCache(),
        link: from([errorLink, link]),
        defaultOptions: {
            query: { fetchPolicy: 'network-only' },
            watchQuery: { fetchPolicy: 'cache-and-network' },
        },
    });
};
export const Api = () => {
    const navigate = useNavigate();
    const client = React.useMemo(() => {
        return getApolloClientConfig({ navigate });
    }, [navigate]);
    return (_jsx(ApolloProvider, { client: client, children: _jsx(Outlet, {}) }));
};
