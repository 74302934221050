import { jsx as _jsx } from "react/jsx-runtime";
import { List, ListItem } from '@mui/material';
import { useAccountStorageQueryApi } from 'api';
import { CloudStatus, UsedStorage } from 'components/custom';
const StorageWidget = ({ drawerOpen, userId, }) => {
    var _a;
    const storagePollInterval = 90000;
    const storageStatus = useAccountStorageQueryApi({ id: userId }, { pollInterval: storagePollInterval });
    const { used, total } = (_a = storageStatus.data) !== null && _a !== void 0 ? _a : { total: 0, used: 0 };
    return drawerOpen ? (_jsx(CloudStatus, { storageUsed: used, storageTotal: total })) : (_jsx(List, { children: _jsx(ListItem, { sx: { justifyContent: 'center' }, children: _jsx(UsedStorage, { used: used, total: total, variant: "mini" }) }) }));
};
export default StorageWidget;
