import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Whatshot } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalStorageKeys } from 'utils/local-storage';
import { useReleaseVersions } from './helpers';
import WhatIsNewDialog from './WhatIsNewDialog';
const WhatIsNewButton = () => {
    const { t } = useTranslation('releases');
    const [whatIsNewVisibility, setWhatIsNewVisibility] = React.useState(false);
    const [badgeVisibility, setBadgeVisibility] = React.useState(false);
    const { latestVersion } = useReleaseVersions();
    const lastViewedVersion = localStorage.getItem(LocalStorageKeys.WHAT_IS_NEW_VERSION);
    React.useEffect(() => {
        const sameVersion = lastViewedVersion === latestVersion;
        setBadgeVisibility(!sameVersion);
        if (!sameVersion) {
            if (whatIsNewVisibility) {
                localStorage.setItem(LocalStorageKeys.WHAT_IS_NEW_VERSION, latestVersion);
            }
        }
    }, [whatIsNewVisibility, lastViewedVersion, latestVersion]);
    return (_jsxs(_Fragment, { children: [_jsx(Badge, { variant: "dot", overlap: "circular", color: "warning", invisible: !badgeVisibility, children: _jsx(IconButton, { color: "primary", onClick: () => {
                        setWhatIsNewVisibility(true);
                    }, title: t('cmp.Button.text'), children: _jsx(Whatshot, {}) }) }), _jsx(WhatIsNewDialog, { open: whatIsNewVisibility, onClose: () => {
                    setWhatIsNewVisibility(false);
                } })] }));
};
export default WhatIsNewButton;
