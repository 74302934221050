import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { eachDayOfInterval, nextFriday, nextMonday } from 'date-fns';
import { useMemo } from 'react';
import { convertIntervalToSlot, getSeqArray, HOURS_PER_DAY, LAST_DAY_OF_THE_WEEK, } from 'utils/date';
import DayOfTheWeekCell from './DayOfTheWeekCell';
import HourInDayCell from './HourInDayCell';
import CourseDashboardCells from './CourseDashboardCells';
import CoursePlannerCells from './CoursePlannerCells';
export const convertLessonsToSlots = (lessons) => {
    return lessons.map((lesson) => {
        const { id, startDate, endDate, course: { name, color }, } = lesson;
        return {
            color,
            name,
            slotId: id,
            ...convertIntervalToSlot({
                end: endDate,
                start: startDate,
            }),
        };
    });
};
const WeeklySchedule = ({ data, dateOfMonday = null, onDeleteCourseSuccess, onPlanCourseSuccess, isPlanCourseView = false, onHourLengthChange, onlyOneDay = false, }) => {
    const daysOfTheWeek = useMemo(() => {
        const start = dateOfMonday !== null && dateOfMonday !== void 0 ? dateOfMonday : nextMonday(Date.now());
        const end = onlyOneDay ? start : nextFriday(start);
        return eachDayOfInterval({
            end,
            start,
        }).map((date, index) => (_jsx(DayOfTheWeekCell, { date: dateOfMonday && date, day: index + 1 }, index)));
    }, [dateOfMonday, onlyOneDay]);
    const hoursInDay = useMemo(() => getSeqArray(HOURS_PER_DAY).map((value, index) => (_jsx(HourInDayCell, { slotNumber: value }, index))), []);
    const courses = useMemo(() => {
        if (isPlanCourseView) {
            return (_jsx(CoursePlannerCells, { data: data, onDeleteCourseSuccess: onDeleteCourseSuccess, onHourLengthChange: onHourLengthChange, onPlanCourseSuccess: onPlanCourseSuccess }));
        }
        return (_jsx(CourseDashboardCells, { data: data, startDate: dateOfMonday !== null && dateOfMonday !== void 0 ? dateOfMonday : nextMonday(Date.now()), onlyOneDay: onlyOneDay }));
    }, [
        data,
        dateOfMonday,
        onlyOneDay,
        isPlanCourseView,
        onDeleteCourseSuccess,
        onPlanCourseSuccess,
        onHourLengthChange,
    ]);
    return (_jsxs(Box, { sx: {
            display: 'grid',
            gridTemplateColumns: `minmax(50px, 75px) repeat(${onlyOneDay ? 1 : LAST_DAY_OF_THE_WEEK}, minmax(0, 1fr))`,
            gridTemplateRows: `repeat(${HOURS_PER_DAY + 1}, minmax(50px, auto))`,
        }, role: "grid", children: [daysOfTheWeek, hoursInDay, courses] }));
};
export default WeeklySchedule;
