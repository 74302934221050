import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button } from 'components/custom';
import { useTranslation } from 'react-i18next';
const Navigation = ({ onPrevClick, onNextClick, onTodayClick, todayDisabled = false, }) => {
    const { t } = useTranslation('lesson', { keyPrefix: 'cmp.LessonsWidget' });
    return (_jsxs(Stack, { direction: "row", justifyContent: "space-between", marginTop: "auto", children: [_jsx(Button, { color: "secondary", startIcon: _jsx(ArrowBack, {}), onClick: onPrevClick, children: t('previousDay') }), _jsx(Button, { color: "secondary", onClick: onTodayClick, disabled: todayDisabled, children: t('today') }), _jsx(Button, { color: "secondary", endIcon: _jsx(ArrowForward, {}), onClick: onNextClick, children: t('nextDay') })] }));
};
export default Navigation;
