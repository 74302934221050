import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Logout } from '@mui/icons-material';
import { Box, Button, Stack, Toolbar } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { SKIP, useAccountPaymentPlanQueryApi } from 'api';
import { Footer, LoadingWrapper } from 'components/custom';
import { useSession } from 'components/kratos';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ACCOUNT_DATA, LOGOUT, PLANS_AND_PAYMENTS } from 'utils/links';
import { LocalStorageKeys } from 'utils/local-storage';
import NavDrawer from './NavDrawer';
import { WhatIsNewButton } from './WhatIsNew';
import WidgetDrawer from './WidgetDrawer';
/**
 * A list of routes that is available without a subscription plan.
 */
const inactiveSubscriptionRoutes = [PLANS_AND_PAYMENTS, ACCOUNT_DATA];
const Layout = () => {
    var _a, _b;
    const [drawerOpen, setDrawerOpen] = React.useState(!localStorage.getItem(LocalStorageKeys.DRAWER_MINI));
    const { t } = useTranslation('common');
    const { enqueueSnackbar } = useSnackbar();
    const sessionStatus = useSession();
    const { user } = sessionStatus;
    const subscriptionStatus = useAccountPaymentPlanQueryApi(user ? { id: user.id } : SKIP);
    const endDate = ((_a = subscriptionStatus.data) === null || _a === void 0 ? void 0 : _a.endDate) &&
        format(subscriptionStatus.data.endDate, 'dd. MMMM yyyy');
    const location = useLocation();
    const navigate = useNavigate();
    const route = location.pathname;
    const subStatus = (_b = subscriptionStatus.data) === null || _b === void 0 ? void 0 : _b.status;
    React.useEffect(() => {
        const limitAccess = subStatus === 'PAUSED' ||
            subStatus === 'PAST_DUE' ||
            subStatus === 'INVALID_INACTIVE';
        if (limitAccess && !inactiveSubscriptionRoutes.includes(route)) {
            navigate(PLANS_AND_PAYMENTS, { replace: true });
        }
    }, [route, navigate, subStatus]);
    React.useEffect(() => {
        if (subStatus === 'PAUSED') {
            enqueueSnackbar(t('body.subscriptionInactiveAlert'), {
                autoHideDuration: null,
                key: 'subscriptionInactive',
                preventDuplicate: true,
                variant: 'error',
            });
            return;
        }
        if (subStatus === 'TRIAL') {
            enqueueSnackbar(t('body.subscriptionTrialAlert', { date: endDate }), {
                autoHideDuration: 20000,
                key: 'subscriptionTrial',
                preventDuplicate: true,
                variant: 'warning',
            });
            return;
        }
        if (subStatus === 'TERMINATION') {
            enqueueSnackbar(t('body.subscriptionTerminatedAlert', { date: endDate }), {
                autoHideDuration: 20000,
                key: 'subscriptionTerminated',
                preventDuplicate: true,
                variant: 'warning',
            });
        }
    }, [t, enqueueSnackbar, subStatus, endDate]);
    return (_jsx(LoadingWrapper, { fullScreen: true, status: [sessionStatus, subscriptionStatus], children: _jsxs(Box, { sx: { display: 'flex', flexGrow: 1 }, children: [user && (_jsx(NavDrawer, { drawerOpen: drawerOpen, setDrawerOpen: setDrawerOpen, user: user })), _jsx(MuiAppBar, { color: "inherit", position: "fixed", sx: {
                        boxShadow: 'none',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                    }, children: _jsx(Toolbar, { children: _jsxs(Stack, { direction: "row", spacing: 1, children: [_jsx(WhatIsNewButton, {}), _jsx(Button, { startIcon: _jsx(Logout, {}), color: "secondary", href: LOGOUT, children: t('body.logout') })] }) }) }), _jsxs(Box, { sx: { display: 'flex', flexGrow: 1 }, children: [_jsxs(Box, { sx: { display: 'flex', flexDirection: 'column', flexGrow: 1 }, children: [_jsx(Box, { component: "main", sx: {
                                        flexGrow: 1,
                                        pb: 2,
                                        pt: 8,
                                        px: 2,
                                    }, children: _jsx(Outlet, { context: { user } }) }), _jsx(Footer, {})] }), user && _jsx(WidgetDrawer, { user: user })] })] }) }));
};
export default Layout;
