import { jsx as _jsx } from "react/jsx-runtime";
import { createTheme, responsiveFontSizes } from '@mui/material';
import Link from 'components/custom/Link';
import { ExpandMore } from '@mui/icons-material';
import { deDE as coreDeDE } from '@mui/material/locale';
import { deDE as dataGridBgBG } from '@mui/x-data-grid';
import { deDE as datePickerDeDE } from '@mui/x-date-pickers/locales';
import DosisExtraLight from 'public/fonts/Dosis-ExtraLight.woff2';
import DosisLight from 'public/fonts/Dosis-Light.woff2';
import DosisRegular from 'public/fonts/Dosis-Regular.woff2';
import DosisMedium from 'public/fonts/Dosis-Medium.woff2';
import DosisSemiBold from 'public/fonts/Dosis-SemiBold.woff2';
import DosisBold from 'public/fonts/Dosis-Bold.woff2';
import DosisExtraBold from 'public/fonts/Dosis-ExtraBold.woff2';
// Sort by size
/* eslint-disable sort-keys */
const breakpointsOptions = {
    values: {
        xs: 0,
        sm: 900,
        md: 1200,
        lg: 1900,
        xl: 2500,
    },
};
/* eslint-enable sort-keys */
// Sort by numerical value
/* eslint-disable sort-keys */
const spacingFactors = {
    quarter: 0.25,
    half: 0.5,
    one: 1,
    two: 2,
    four: 4,
    six: 6,
    eight: 8,
    twelve: 12,
    sixteen: 16,
    twenty: 20,
    twentyFour: 24,
    thirtyTwo: 32,
    forty: 40,
};
/* eslint-enable sort-keys */
const colors = {
    coralRed: '#ef434e',
    cultured: '#f1f7f9',
    easternBlue: '#19a0b0',
    iceberg: '#d2e4ea',
    prussianBlue: '#022b3e',
    viridianGreen: '#008667',
    white: '#ffffff',
    yellowOrange: '#ff9110',
};
let theme = createTheme({
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    breakpoints: breakpointsOptions,
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    '&.Mui-expanded': {
                        marginBottom: 0,
                    },
                },
            },
        },
        MuiAccordionSummary: {
            defaultProps: {
                expandIcon: _jsx(ExpandMore, {}),
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    margin: 'auto',
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: Link,
            },
        },
        MuiCard: {
            styleOverrides: {
                root: ({ theme: muiTheme }) => ({
                    boxShadow: muiTheme.boxShadow,
                    display: 'flex',
                    flexDirection: 'column',
                }),
            },
        },
        MuiChip: {
            styleOverrides: {
                deleteIcon: ({ ownerState, theme: muiTheme }) => ({
                    ...(ownerState.variant === 'filled' && {
                        fill: muiTheme.palette.common.white,
                    }),
                    fontSize: '1rem',
                }),
                root: ({ ownerState, theme: muiTheme }) => ({
                    ...(ownerState.variant === 'filled' && {
                        color: muiTheme.palette.common.white,
                    }),
                    fontWeight: 700,
                }),
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: ({ theme: muiTheme }) => ({
                    padding: muiTheme.spacing(2),
                    '& > :not(:first-of-type)': {
                        marginLeft: muiTheme.spacing(2),
                    },
                }),
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                content: {
                    marginTop: 'auto',
                },
                root: ({ theme: muiTheme }) => ({
                    borderBottom: `1px solid ${colors.iceberg}`,
                    padding: `${muiTheme.spacing(2)} 0 ${muiTheme.spacing(1)} 0`,
                    margin: `0 ${muiTheme.spacing(2)}`,
                }),
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: ({ theme: muiTheme }) => ({
                    color: muiTheme.palette.primary.main,
                }),
            },
        },
        MuiCircularProgress: {
            defaultProps: {
                color: 'secondary',
            },
            styleOverrides: {
                root: () => ({
                    margin: 'auto',
                }),
            },
        },
        MuiCssBaseline: {
            styleOverrides: `
        @font-face {
          font-family: "Dosis";
          src: url('${DosisExtraLight}') format("woff");
          font-weight: 200;
          font-display: swap;
          font-style: normal;
        }

        @font-face {
          font-family: "Dosis";
          src: url('${DosisLight}') format("woff");
          font-weight: 300;
          font-display: swap;
          font-style: normal;
        }

        @font-face {
          font-family: "Dosis";
          src: url('${DosisRegular}') format("woff");
          font-weight: 400;
          font-display: swap;
          font-style: normal;
        }

        @font-face {
          font-family: "Dosis";
          src: url('${DosisMedium}') format("woff");
          font-weight: 500;
          font-display: swap;
          font-style: normal;
        }

        @font-face {
          font-family: "Dosis";
          src: url('${DosisSemiBold}') format("woff");
          font-weight: 600;
          font-display: swap;
          font-style: normal;
        }

        @font-face {
          font-family: "Dosis";
          src: url('${DosisBold}') format("woff");
          font-weight: 700;
          font-display: swap;
          font-style: normal;
        }

        @font-face {
          font-family: "Dosis";
          src: url('${DosisExtraBold}') format("woff");
          font-weight: 800;
          font-display: swap;
          font-style: normal;
        }

        .MuiStack-root > input[type="hidden"] {
          margin-top: 0
        }
      `,
        },
        MuiDialog: {
            styleOverrides: {
                paper: ({ theme: muiTheme }) => ({
                    padding: muiTheme.spacing(1),
                }),
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: ({ theme: muiTheme }) => ({
                    width: muiTheme.layout.openNavDrawerWidth,
                }),
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: colors.prussianBlue,
                    fontSize: '0.9rem',
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: ({ theme: muiTheme }) => ({
                    height: muiTheme.spacing(1),
                }),
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: ({ theme: muiTheme }) => ({
                    color: 'inherit',
                    minWidth: muiTheme.spacing(4),
                }),
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: ({ theme: muiTheme }) => ({
                    [muiTheme.breakpoints.up('sm')]: {
                        minHeight: muiTheme.spacing(6),
                    },
                }),
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: ({ theme: muiTheme }) => ({
                    backgroundColor: muiTheme.palette.action.disabled,
                    color: muiTheme.palette.text.primary,
                    fontSize: '0.8rem',
                }),
                arrow: ({ theme: muiTheme }) => ({
                    '&:before': {
                        backgroundColor: muiTheme.palette.action.disabled,
                    },
                }),
            },
        },
    },
    layout: {
        closedNavDrawerWidth: '5rem',
        closedWidgetDrawerWidth: '3rem',
        openNavDrawerWidth: '15rem',
        openWidgetDrawerWidth: '25rem',
        textareaPaddingSpacing: 1,
    },
    palette: {
        action: {
            disabled: colors.iceberg,
            focus: colors.iceberg,
            hover: colors.iceberg,
            selected: colors.easternBlue,
        },
        contrastThreshold: 3,
        error: {
            contrastText: colors.white,
            main: colors.coralRed,
        },
        inactive: {
            contrastText: colors.easternBlue,
            main: colors.cultured,
        },
        info: {
            contrastText: colors.white,
            main: colors.easternBlue,
        },
        primary: {
            contrastText: colors.white,
            light: colors.iceberg,
            main: colors.prussianBlue,
        },
        secondary: {
            contrastText: colors.white,
            light: colors.iceberg,
            main: colors.easternBlue,
        },
        success: {
            contrastText: colors.white,
            main: colors.viridianGreen,
        },
        text: {
            disabled: colors.iceberg,
            primary: colors.prussianBlue,
            secondary: colors.easternBlue,
        },
        tonalOffset: 0.2,
        warning: {
            contrastText: colors.white,
            main: colors.yellowOrange,
        },
    },
    shape: {
        borderRadius: 0,
    },
    spacingFactors,
    typography: {
        body1: {
            lineHeight: 1.3,
        },
        body2: {
            lineHeight: 1.3,
        },
        button: {
            fontWeight: 700,
            textTransform: 'none',
        },
        fontFamily: "'Dosis', 'Arial', sans-serif",
        fontSize: 13,
        fontWeightRegular: 500,
    },
}, dataGridBgBG, coreDeDE, datePickerDeDE);
theme = responsiveFontSizes(theme, {
    breakpoints: breakpointsOptions.values
        ? Object.keys(breakpointsOptions.values)
        : undefined,
});
export default theme;
