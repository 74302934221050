import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddCircle } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { getCreateSchoolYearForRegionMutationOptions, isLoading, onValidationError, useCreateSchoolYearForRegionMutation, } from 'api';
import { ConfirmationDialog, LoadingWrapper, Select, TextFormItem, } from 'components/custom';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { onSubmitError, yup } from 'utils/forms';
import predefinedSchoolYears from './school-years.json';
const getRegionOptions = () => {
    return predefinedSchoolYears
        .map((region) => ({
        label: region.name,
        value: region.regionKey,
    }))
        .sort((a, b) => a.label.localeCompare(b.label));
};
const getSchoolYearOptions = (regionKey) => {
    const state = predefinedSchoolYears.find((region) => region.regionKey === regionKey);
    if (state) {
        return state.schoolYears.map((schoolYear) => ({
            label: schoolYear,
            value: schoolYear,
        }));
    }
    return [];
};
const SchoolYearTemplateDialog = (props) => {
    const { open, close } = props;
    const { t } = useTranslation('timetable', {
        keyPrefix: 'steps.schoolYears.cmp.SchoolYearTemplateDialog',
    });
    const id = React.useId();
    const [namePlaceholder, setNamePlaceholder] = React.useState('');
    const [selectedRegionKey, setSelectedRegionKey] = React.useState('');
    const schema = React.useMemo(() => yup.object({
        name: yup
            .string()
            .label(t('nameField.label'))
            .transform((value) => value || namePlaceholder)
            .default(namePlaceholder),
        regionKey: yup.string().label(t('regionField.label')).required(),
        schoolYearKey: yup
            .string()
            .label(t('schoolYearField.label'))
            .required(),
    }), [namePlaceholder, t]);
    const { handleSubmit, control, formState: { errors, isSubmitting }, reset, watch, } = useForm({
        resolver: yupResolver(schema),
    });
    const regionOptions = getRegionOptions();
    const schoolYearOptions = getSchoolYearOptions(selectedRegionKey);
    const [createSchoolYear, statusCreateSchoolYear] = useCreateSchoolYearForRegionMutation();
    React.useEffect(() => {
        const subscription = watch((formValues) => {
            var _a, _b;
            const region = regionOptions.find((option) => option.value === formValues.regionKey);
            const schoolYear = schoolYearOptions.find((option) => option.value === formValues.schoolYearKey);
            setNamePlaceholder(`${(_a = region === null || region === void 0 ? void 0 : region.label) !== null && _a !== void 0 ? _a : ''} ${(_b = schoolYear === null || schoolYear === void 0 ? void 0 : schoolYear.label) !== null && _b !== void 0 ? _b : ''}`);
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [regionOptions, schoolYearOptions, watch]);
    React.useEffect(() => {
        if (open) {
            setSelectedRegionKey('');
            reset({});
        }
    }, [open, reset]);
    const onSuccess = () => {
        close();
        enqueueSnackbar(t('successMessage'));
    };
    const onSubmit = async (values) => {
        return createSchoolYear(getCreateSchoolYearForRegionMutationOptions(values))
            .then(onSuccess)
            .catch(onValidationError);
    };
    const loading = isLoading([statusCreateSchoolYear]);
    return (_jsx(ConfirmationDialog, { open: open, onClose: close, onTransitionExited: () => {
            statusCreateSchoolYear.reset();
        }, titleProps: {
            Icon: AddCircle,
            text: t('addSchoolYearTitle'),
        }, cancelButtonProps: {
            onClick: close,
        }, confirmButtonProps: {
            disabled: isSubmitting || loading,
            form: id,
            type: 'submit',
        }, fullWidth: true, children: _jsx(LoadingWrapper, { mutation: statusCreateSchoolYear, children: _jsx("form", { id: id, onSubmit: (e) => {
                    handleSubmit(onSubmit)(e).catch(onSubmitError);
                }, children: _jsxs(Stack, { spacing: 2, children: [_jsxs(Box, { sx: { display: 'grid', gap: 2, gridTemplateColumns: '2fr 1fr' }, children: [_jsx(Controller, { control: control, name: "regionKey", render: ({ field }) => {
                                        var _a;
                                        return (_jsx(Select, { ...field, label: t('regionField.label'), placeholder: t('regionField.placeholder'), errorMessage: (_a = errors.regionKey) === null || _a === void 0 ? void 0 : _a.message, options: regionOptions, onChange: (event) => {
                                                field.onChange(event);
                                                setSelectedRegionKey(event.target.value);
                                            } }));
                                    } }), _jsx(Controller, { control: control, name: "schoolYearKey", render: ({ field }) => {
                                        var _a;
                                        return (_jsx(Select, { ...field, disabled: !selectedRegionKey, label: t('schoolYearField.label'), placeholder: t('schoolYearField.placeholder'), errorMessage: (_a = errors.schoolYearKey) === null || _a === void 0 ? void 0 : _a.message, options: schoolYearOptions }));
                                    } })] }), _jsx(Controller, { control: control, name: "name", render: ({ field }) => {
                                var _a;
                                return (_jsx(TextFormItem, { ...field, label: t('nameField.label'), placeholder: namePlaceholder, errorMessage: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message }));
                            } })] }) }) }) }));
};
export default SchoolYearTemplateDialog;
