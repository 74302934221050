import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Stack, Typography } from '@mui/material';
import { Link } from 'components/custom';
import { useResponsiveness } from 'utils/responsiveness';
const SidePanel = (props) => {
    const { isExtraSmallViewport } = useResponsiveness();
    return (_jsx(Stack, { alignItems: "center", sx: {
            backgroundColor: props.backgroundColor,
            backgroundImage: props.backgroundImage,
            backgroundRepeat: 'repeat',
            position: 'relative',
            ...(isExtraSmallViewport
                ? {
                    height: '35vh',
                    minHeight: '20rem',
                    width: '100%',
                }
                : {
                    height: '100%',
                    maxWidth: '35rem',
                    minWidth: '20rem',
                    width: '40%',
                }),
        }, children: _jsxs(Stack, { ...(isExtraSmallViewport
                ? { justifyContent: 'space-between' }
                : { justifyContent: 'center', spacing: 8 }), alignItems: "center", sx: {
                height: '100%',
                position: 'absolute',
                width: '100%',
                ...(isExtraSmallViewport ? { p: '5vh' } : { p: 8 }),
            }, children: [_jsx(Typography, { align: "center", color: "common.white", variant: "h2", sx: { fontWeight: 800 }, children: props.title }), _jsx(Typography, { align: "center", color: "common.white", variant: "h4", children: props.slogan }), _jsx(Button, { size: "large", variant: "contained", sx: { maxWidth: '20rem', width: '100%' }, ...props.buttonProps }), props.linkProps && _jsx(Link, { ...props.linkProps })] }) }));
};
export default SidePanel;
