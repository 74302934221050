import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Close, DragHandle, Image, OpenInNew } from '@mui/icons-material';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { Tooltip } from 'components/custom';
import { motion } from 'framer-motion';
import { clamp, uniqueId } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFileIcon } from 'utils/file-icons';
import { appendParams } from 'utils/url';
import { Resource } from './Resource';
const PREVIEW_INITIAL_HEIGHT_PX = 300;
const PREVIEW_MIN_HEIGHT_PX = 100;
const PREVIEW_MAX_HEIGHT_PX = 4000;
/**
 * Component specific class name for the drag handle.
 */
const dragHandleClassName = uniqueId('resize-');
const FileObjectPreview = ({ fileObject, onClose, }) => {
    const { t } = useTranslation('object', {
        keyPrefix: 'cmp.FileObjectPreview',
    });
    const [height, setHeight] = React.useState(PREVIEW_INITIAL_HEIGHT_PX);
    const [resizing, setResizing] = React.useState(false);
    const Icon = fileObject && getFileIcon(fileObject.mimeType);
    const onDrag = (event, info) => {
        setHeight(clamp(height + info.delta.y, PREVIEW_MIN_HEIGHT_PX, PREVIEW_MAX_HEIGHT_PX));
    };
    const onCloseClick = () => {
        setHeight(PREVIEW_INITIAL_HEIGHT_PX);
        onClose();
    };
    return (_jsxs(Box, { "data-testid": "FilePreview", sx: {
            backgroundColor: (theme) => fileObject
                ? theme.palette.common.white
                : theme.palette.action.disabled,
            boxShadow: fileObject ? (theme) => theme.boxShadow : 'none',
            transition: (theme) => theme.transitions.create('background-color', {
                duration: theme.transitions.duration.standard,
            }),
        }, children: [!fileObject && (_jsxs(Box, { sx: {
                    alignItems: 'center',
                    display: 'flex',
                    p: 1,
                }, children: [_jsx(Image, { fontSize: "large", sx: { mr: 1 } }), _jsx(Typography, { children: t('noFileSelected') })] })), fileObject && (_jsxs(Box, { sx: {
                    alignItems: 'center',
                    borderBottomColor: (theme) => theme.palette.action.disabled,
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 2,
                    mx: 2,
                }, children: [_jsxs(Box, { sx: {
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            ml: 1,
                        }, children: [Icon && _jsx(Icon, { fontSize: "medium" }), _jsx(Typography, { marginLeft: 1, fontSize: "large", children: fileObject.name })] }), _jsxs(Box, { children: [_jsx(Tooltip, { title: t('openInNewWindowTooltip'), children: _jsx(IconButton, { href: appendParams(fileObject.downloadUrl, {
                                        download: 'false',
                                    }), target: "_blank", children: _jsx(OpenInNew, {}) }) }), _jsx(Tooltip, { title: t('closeTooltip'), children: _jsx(IconButton, { onClick: onCloseClick, children: _jsx(Close, {}) }) })] })] })), _jsx(Collapse, { in: !!fileObject, unmountOnExit: true, sx: {
                    overflow: 'hidden',
                }, children: _jsx(Box, { sx: {
                        alignItems: 'center',
                        display: 'flex',
                        height,
                        justifyContent: 'center',
                        p: 2,
                        pointerEvents: resizing ? 'none' : 'auto',
                        width: '100%',
                    }, children: fileObject && _jsx(Resource, { fileObject: fileObject }) }) }), _jsx(motion.div, { className: dragHandleClassName, drag: "y", dragConstraints: { bottom: 0, left: 0, right: 0, top: 0 }, dragElastic: 0, dragMomentum: false, onDrag: onDrag, onDragEnd: () => {
                    setResizing(false);
                }, onDragStart: () => {
                    setResizing(true);
                }, style: {
                    cursor: 'row-resize',
                    textAlign: 'center',
                }, hidden: !fileObject, children: _jsx(DragHandle, { sx: {
                        opacity: 0,
                        transition: (theme) => theme.transitions.create('opacity', {
                            duration: theme.transitions.duration.short,
                        }),
                        [`.${dragHandleClassName}:hover &`]: {
                            opacity: 1,
                        },
                    } }) })] }));
};
export default FileObjectPreview;
