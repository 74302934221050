import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress, LinearProgress, Typography, } from '@mui/material';
import { Tooltip } from 'components/custom';
import { useTranslation } from 'react-i18next';
import { formatBytes, formatNumber } from 'utils/format';
import { calcPercentage } from 'utils/math';
/**
 * Normalizes the percent value to be used in progress bar.
 * @param storageUsedPercent Used storage in percent.
 * @returns Used storage between 1 and 100 percent.
 */
const getProgressValue = (storageUsedPercent) => {
    if (storageUsedPercent < 0) {
        return 0;
    }
    if (storageUsedPercent > 0 && storageUsedPercent < 1) {
        return 1;
    }
    const maxProgressValue = 100;
    if (storageUsedPercent > maxProgressValue) {
        return maxProgressValue;
    }
    return Math.floor(storageUsedPercent);
};
const getProgressColor = (storageUsedPercent) => {
    const almostFull = 98;
    const prettyFull = 90;
    if (storageUsedPercent > almostFull) {
        return 'error';
    }
    if (storageUsedPercent > prettyFull) {
        return 'warning';
    }
    return 'secondary';
};
const UsedStorage = ({ variant = 'standard', used, total, }) => {
    const { t } = useTranslation('object', { keyPrefix: 'cmp.UsedStorage' });
    const storageUsedPercent = calcPercentage(used, total);
    return (_jsx(Tooltip, { title: t('usedOfTotalTooltip', {
            total: formatBytes(total),
            used: formatBytes(used),
        }), describeChild: true, children: variant === 'standard' ? (_jsx(Box, { sx: { flexGrow: 1 }, children: _jsxs(Box, { sx: { alignItems: 'center', display: 'flex' }, children: [_jsx(Box, { sx: { mr: 1, width: '100%' }, children: _jsx(LinearProgress, { color: getProgressColor(storageUsedPercent), value: getProgressValue(storageUsedPercent), variant: "determinate" }) }), _jsx(Box, { children: _jsx(Typography, { color: "text.secondary", "data-testid": "usedStoragePercent", children: `${formatNumber(storageUsedPercent)}%` }) })] }) })) : (_jsxs(Box, { sx: {
                display: 'inline-flex',
                justifyContent: 'center',
                position: 'relative',
            }, children: [_jsx(CircularProgress, { sx: {
                        color: (theme) => theme.palette.action.disabled,
                    }, size: 30, role: "none", variant: "determinate", value: 100 }), _jsx(CircularProgress, { color: getProgressColor(storageUsedPercent), value: getProgressValue(storageUsedPercent), size: 30, variant: "determinate", sx: {
                        position: 'absolute',
                    } }), _jsx(Box, { sx: {
                        alignItems: 'center',
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        left: 0,
                        position: 'absolute',
                        right: 0,
                        top: 0,
                    }, children: _jsx(Typography, { variant: "caption", component: "div", color: "text.secondary", children: `${formatNumber(storageUsedPercent)}%` }) })] })) }));
};
export default UsedStorage;
