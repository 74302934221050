import { jsx as _jsx } from "react/jsx-runtime";
import { Alert } from 'components/custom';
import { useMappings } from '../skyla/mappings';
export const Message = ({ message }) => {
    const { getMessageText } = useMappings();
    const text = getMessageText(message);
    return _jsx(Alert, { severity: message.type, children: text });
};
export const Messages = ({ messages, }) => {
    if (!messages) {
        // No messages? Do nothing.
        return null;
    }
    return (_jsx("div", { children: messages.map((message) => (_jsx(Message, { message: message }, message.id))) }));
};
