import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { Illustration } from 'components/custom';
import { useSidebarLayoutStyles } from 'layouts/Sidebar/styles';
import Image from 'public/img/illustrations/desk.svg';
import { useTranslation } from 'react-i18next';
/**
 * Displays registration successful text.
 * This is the next step after account activation.
 */
const RegistrationSuccessful = () => {
    const { t } = useTranslation('auth');
    const { formStyles, illustrationStyles } = useSidebarLayoutStyles();
    return (_jsxs(Stack, { alignItems: "center", justifyContent: "center", spacing: formStyles.spacing, children: [_jsx(Illustration, { image: Image, minHeightPx: 100, minWidthPx: 200, sx: illustrationStyles }), _jsx(Typography, { variant: "h4", color: "secondary", children: t('body.step.registration-successful.heading1') }), _jsx(Typography, { align: "center", children: t('body.step.registration-successful.paragraph1') })] }));
};
export default RegistrationSuccessful;
