import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormLabel, FormControl, RadioGroup, Radio, FormHelperText, } from '@mui/material';
import { Check, Circle } from '@mui/icons-material';
export const getAvailableColors = () => {
    return [
        '#19a0b0',
        '#022b3e',
        '#ef434e',
        '#008667',
        '#ff9110',
        '#a81178',
        '#d3d4d9',
        '#afe5d8',
        '#ffe310',
        '#d98ec1',
    ];
};
const ColorPicker = (props, ref) => {
    const { errorMessage, ...restProps } = props;
    const { t } = useTranslation('timetable', {
        keyPrefix: 'steps.courses.cmp.ColorPicker',
    });
    const { t: colorNames } = useTranslation('colors', {
        keyPrefix: 'colorNames',
    });
    const isError = !!errorMessage;
    const id = React.useId();
    const errorId = React.useId();
    return (_jsxs(FormControl, { children: [_jsx(FormLabel, { id: id, children: t('label') }), _jsx(RadioGroup, { "aria-labelledby": id, "aria-describedby": errorId, name: "color", row: true, ref: ref, ...restProps, children: getAvailableColors().map((color) => (_jsx(Radio, { checkedIcon: _jsx(Check, { sx: {
                            backgroundColor: color,
                            borderRadius: '50%',
                            color: 'common.white',
                        } }), icon: _jsx(Circle, { sx: {
                            backgroundColor: color,
                            borderRadius: '50%',
                            color,
                        } }), value: color, inputProps: { 'aria-label': colorNames(color.slice(1)) } }, color))) }), _jsx(FormHelperText, { id: errorId, error: isError, children: errorMessage })] }));
};
export default React.forwardRef(ColorPicker);
