import { jsx as _jsx } from "react/jsx-runtime";
import StyledDrawer from './Styled';
const Drawer = ({ paperSx, width, sx, ...rest }) => {
    return (_jsx(StyledDrawer, { ...rest, sx: {
            ...sx,
            width: (theme) => width(theme),
            // eslint-disable-next-line sort-keys -- Css selector
            '& .MuiDrawer-paper': {
                width: (theme) => width(theme),
                ...paperSx,
            },
        } }));
};
export default Drawer;
