import { jsx as _jsx } from "react/jsx-runtime";
import { SnackbarProvider as NativeSnackbarProvider } from 'notistack';
import { SnackbarAlert } from 'components/custom';
const SnackbarProvider = (props) => {
    return (_jsx(NativeSnackbarProvider, { anchorOrigin: { horizontal: 'right', vertical: 'bottom' }, Components: {
            default: SnackbarAlert,
            error: SnackbarAlert,
            info: SnackbarAlert,
            success: SnackbarAlert,
            warning: SnackbarAlert,
        }, ...props }));
};
export default SnackbarProvider;
