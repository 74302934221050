import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
const Link = React.forwardRef((props, ref) => {
    const { href, ...muiLinkProps } = props;
    const to = href !== null && href !== void 0 ? href : '';
    return _jsx(MuiLink, { component: RouterLink, ref: ref, ...muiLinkProps, to: to });
});
Link.displayName = 'Link';
export default Link;
