import React from 'react';
import { isEmpty } from 'lodash-es';
var DialogModes;
(function (DialogModes) {
    DialogModes["EDIT"] = "edit";
    DialogModes["CREATE"] = "create";
    DialogModes["HIDE"] = "hide";
})(DialogModes || (DialogModes = {}));
/**
 * Provides dialog modes handling.
 * @param data Data object for detecting dialog mode.
 * @returns Dialog helper properties.
 */
export const useDialogMode = (data) => {
    const mode = React.useRef(DialogModes.HIDE);
    if (data) {
        mode.current = isEmpty(data) ? DialogModes.CREATE : DialogModes.EDIT;
    }
    return {
        modeCreate: mode.current === DialogModes.CREATE,
        modeEdit: mode.current === DialogModes.EDIT,
        open: !!data,
    };
};
