import { endOfDay, format, formatRFC3339, getDay, getHours, isAfter, isWithinInterval, startOfDay, } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { de } from 'date-fns/locale';
import { padNumber } from 'utils/format';
import i18n from 'utils/i18n';
export const DATE_LOCALE_MAPPING = { de };
/**
 * Formats a date for the user.
 * Use this function whenever displaying a date
 * to have the same date format in the UI.
 * @param date Date object.
 * @returns Formatted date string.
 */
export const formatDate = (date) => {
    return format(date, 'dd.MM.yyyy');
};
/**
 * A function that always returns the first argument.
 * @param date Date object.
 * @returns Period of the day.
 */
export const getDayPeriod = (date) => {
    const hours = getHours(date);
    const midnight = 0;
    const noon = 12;
    const eveningStart = 18;
    if (hours >= midnight && hours < noon) {
        return 'morning';
    }
    if (hours >= noon && hours < eveningStart) {
        return 'afternoon';
    }
    return 'evening';
};
/**
 * School's time zone.
 * In the future this should be delivered from API.
 */
const schoolTimeZone = 'Europe/Berlin';
// prettier-ignore
/**
 * Defined here:
 * https://confluence.x-ion.org/display/SKY/Stundenplan+MVP
 * Hours in Berlin GMT+2.
 * In the future this should be delivered from API.
 */
const slotHourMapping = [
    /* eslint-disable @typescript-eslint/no-magic-numbers -- Lesson hours */
    [[8, 0], [8, 45]],
    [[8, 45], [9, 30]],
    [[10, 0], [10, 45]],
    [[10, 45], [11, 30]],
    [[12, 0], [12, 45]],
    [[12, 45], [13, 30]],
    [[14, 0], [14, 45]],
    [[14, 45], [15, 30]],
    [[16, 0], [16, 45]],
    [[16, 45], [17, 30]],
    /* eslint-enable @typescript-eslint/no-magic-numbers */
];
/**
 * Returns formatted slot hours for given slot number.
 * @param slot Slot number.
 * @returns Formatted slot hours in school local time.
 */
export const getSlotHours = (slot) => {
    const [startSlot, endSlot] = slotHourMapping[slot - 1];
    return [
        startSlot.map((val) => padNumber(val)).join(':'),
        endSlot.map((val) => padNumber(val)).join(':'),
    ];
};
/**
 * Returns slot time (school timezone) for given date.
 * @param value Date or Unix timestamp.
 * @returns Slot time.
 */
const getSlotTime = (value) => {
    const date = new Date(value);
    const time = formatInTimeZone(date, schoolTimeZone, 'H:m');
    const [hours, minutes] = time.split(':').map((val) => parseInt(val, 10));
    return [hours, minutes];
};
/**
 * Check if given slot times are the same.
 * @param slotTimeA Slot time.
 * @param slotTimeB Slot time.
 * @returns `true` if same; otherwise `false`.
 */
const isSameSlotTime = (slotTimeA, slotTimeB) => {
    return slotTimeA[0] === slotTimeB[0] && slotTimeA[1] === slotTimeB[1];
};
/**
 * Returns slot number for given date.
 * @param date Date object or Unix timestamp.
 * @param start Set `true` for slot start or `false` for slot end time.
 * @returns Slot number (index staring from 1).
 */
export const getSlotNumber = (date, start = true) => {
    const slotTime = getSlotTime(date);
    return (slotHourMapping.findIndex(([startHour, endHour]) => isSameSlotTime(start ? startHour : endHour, slotTime)) + 1);
};
/**
 * Returns slot number and length that fit in the given interval.
 * @param interval Interval.
 * @returns Start slot number (index staring from 1) and length.
 */
const getSlotHourWithDuration = (interval) => {
    const startSlot = getSlotNumber(interval.start);
    const endSlot = getSlotNumber(interval.end, false);
    return [startSlot, endSlot - startSlot + 1];
};
/**
 * Converts Interval to Slot.
 * @param interval Interval in UTC.
 * @returns Slot.
 */
export const convertIntervalToSlot = (interval) => {
    const [hourInDay, lengthHours] = getSlotHourWithDuration(interval);
    return {
        hourInDay,
        lengthHours,
        weekday: getDay(interval.start),
    };
};
/**
 * Converts Date to API date string.
 * @param date Date object.
 * @returns Date string in API format (RFC-3339)
 */
export const getApiStringFromDate = (date) => formatRFC3339(date);
/**
 * Converts API date string to Date.
 * @param dateString Date string in API format (RFC-3339).
 * @param time Option to change the time.
 * @returns Date object.
 */
export const getDateFromApiString = (dateString, time) => {
    const date = new Date(dateString);
    switch (time) {
        case 'endOfDay':
            return endOfDay(date);
        case 'startOfDay':
            return startOfDay(date);
        default:
            return date;
    }
};
export const HOURS_PER_DAY = 10;
export const FIRST_DAY_OF_THE_WEEK = 1;
export const LAST_DAY_OF_THE_WEEK = 5;
export const isHourCellCode = (code) => /^[1-5]-(?:[1-9]|10)$/u.test(code);
/**
 * Returns a sequential array of given length.
 * @param length Array's length.
 * @param startAt Sequence staring number.
 * @returns Array with sequential numbers.
 */
export const getSeqArray = (length, startAt = 1) => [...Array(length).keys()].map((value) => value + startAt);
const weekdaysMapping = [
    i18n.t('common:body.weekdays.monday'),
    i18n.t('common:body.weekdays.tuesday'),
    i18n.t('common:body.weekdays.wednesday'),
    i18n.t('common:body.weekdays.thursday'),
    i18n.t('common:body.weekdays.friday'),
    i18n.t('common:body.weekdays.saturday'),
    i18n.t('common:body.weekdays.sunday'),
];
export const getWeekDayName = (weekday) => weekdaysMapping[weekday - 1];
/**
 * Returns the item for the heuristically most relevant time period.
 * That is the current time period, if it exists.
 * Or the next upcoming time period, if one exists.
 * Otherwise, the last completed time period.
 * @param items Items of time periods that are sorted by `startDate`.
 * @returns Item that matches the time period logic.
 */
export const findMostRelevantTimePeriod = (items) => {
    var _a, _b;
    if (!items.length) {
        return undefined;
    }
    const now = new Date();
    return ((_b = (_a = items.find((period) => isWithinInterval(now, { end: period.endDate, start: period.startDate }))) !== null && _a !== void 0 ? _a : items.find((period) => isAfter(period.startDate, now))) !== null && _b !== void 0 ? _b : items.reduce((a, b) => (a.endDate > b.endDate ? a : b)));
};
/**
 * Formats a date time for the user.
 * Use this function whenever displaying a date time
 * to have the same date time format in the UI.
 * @param date Date object.
 * @param includeWeekDay Set `true` to include week day.
 * @returns Formatted date time string.
 */
export const formatDateTime = (date, includeWeekDay = false) => {
    const result = [format(date, 'dd.MM.yyyy')];
    const slotNum = getSlotNumber(date);
    if (slotNum) {
        result.push(`, ${slotNum}. Std`);
    }
    if (includeWeekDay) {
        result.push(format(date, ' (EEEEEE)'));
    }
    return result.join('');
};
/**
 * Returns date in format which is a correct datetime attribute for HTML time element.
 * @param date Date object.
 * @returns Date in datetime attribute compatible format.
 */
export const formatDateToDateTimeAttribute = (date) => format(date, 'yyyy-MM-dd HH:mm');
