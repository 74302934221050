import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { GENERAL_TERMS_AND_CONDITIONS, GENERAL_TERMS_AND_CONDITIONS_BUSINESS, TERMS_AND_CONDITIONS, TERMS_AND_CONDITIONS_BUSINESS, } from 'utils/external-links';
/**
 * Terms of service link with translation.
 * Used as label for checkbox in registration page.
 * Contains different text and link dependent on customerType
 */
const TosLink = ({ customerType }) => {
    const { t } = useTranslation('auth');
    const href = customerType === 'individual'
        ? TERMS_AND_CONDITIONS
        : TERMS_AND_CONDITIONS_BUSINESS;
    return (_jsx(Trans, { i18nKey: "body.flow.termsAcceptedCheckbox", t: t, components: {
            termsLink: (_jsx(Link, { href: href, sx: { textDecorationThickness: '0.1em' }, target: "_blank" })),
        } }));
};
/**
 * General Terms & Conditions link with translation.
 * Used as label for checkbox in registration page.
 * Contains different text and link dependent on customerType
 */
const GtcLink = ({ customerType }) => {
    const { t } = useTranslation('auth');
    const href = customerType === 'individual'
        ? GENERAL_TERMS_AND_CONDITIONS
        : GENERAL_TERMS_AND_CONDITIONS_BUSINESS;
    const translationKey = customerType === 'individual'
        ? 'body.flow.gtcAcceptedCheckbox'
        : 'body.flow.gtcAcceptedCheckboxBusiness';
    return (_jsx(Trans, { i18nKey: translationKey, t: t, components: {
            termsLink: (_jsx(Link, { href: href, sx: { textDecorationThickness: '0.1em' }, target: "_blank" })),
        } }));
};
/**
 * Returns TosLink react node.
 * This way TSX is not needed in mappings.
 */
export const getTosLink = (customerType) => {
    return _jsx(TosLink, { customerType: customerType });
};
/**
 * Returns GtcLink react node.
 * This way TSX is not needed in mappings.
 */
export const getGtcLink = (customerType) => {
    return _jsx(GtcLink, { customerType: customerType });
};
