import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { Button, Link, LoadingWrapper } from 'components/custom';
import { Flow, handleErrorFallback, handleFlowError, handleGetFlowError, ory, useLogoutHandler, } from 'components/kratos';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setTitle } from 'utils/head';
import { HttpStatusCodes } from 'utils/http-status-codes';
import { HOME, LOGIN, PASSWORD_RECOVERY } from 'utils/links';
import { RouterStateKeys } from 'utils/routing';
import { useSidebarLayoutStyles } from 'layouts/Sidebar/styles';
/**
 * Displays login form.
 */
const Login = () => {
    const { t } = useTranslation('auth');
    const [flow, setFlow] = useState();
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();
    // Get ?flow=... from the URL
    const [searchParams] = useSearchParams();
    const returnTo = searchParams.get('return_to');
    const flowId = searchParams.get('flow');
    /*
     * Refresh means we want to refresh the session. This is needed, for example, when we want to update the password
     * of a user.
     */
    const refresh = searchParams.get('refresh');
    /*
     * AAL = Authorization Assurance Level. This implies that we want to upgrade the AAL, meaning that we want
     * to perform two-factor authentication/verification.
     */
    const aal = searchParams.get('aal');
    /*
     * This might be confusing, but we want to show the user an option
     * to sign out if they are performing two-factor authentication!
     */
    const onLogout = useLogoutHandler();
    useEffect(() => {
        setTitle(t('head.login.title'));
    }, [t]);
    useEffect(() => {
        // If we already have a flow, do nothing.
        if (flow) {
            return;
        }
        setLoading(true);
        // If ?flow=.. was in the URL, we fetch it
        if (flowId) {
            ory
                .getLoginFlow({ id: String(flowId) })
                .then(({ data }) => {
                setFlow(data);
            })
                .catch(handleGetFlowError(navigate, 'login', setFlow, t))
                .catch(handleErrorFallback(t))
                .finally(() => {
                setLoading(false);
            });
            return;
        }
        // Otherwise we initialize it
        ory
            .createBrowserLoginFlow({
            aal: aal ? String(aal) : undefined,
            refresh: Boolean(refresh),
            returnTo: returnTo ? String(returnTo) : undefined,
        })
            .then(({ data }) => {
            setFlow(data);
        })
            .catch(handleFlowError(navigate, 'login', setFlow, t))
            .catch(handleErrorFallback(t))
            .finally(() => {
            setLoading(false);
        });
    }, [flowId, navigate, aal, refresh, returnTo, flow, t]);
    const onSubmit = async (values) => {
        setLoading(true);
        /*
         * On submission, add the flow ID to the URL but do not navigate. This prevents the user loosing
         * his data when she/he reloads the page.
         */
        navigate(`${LOGIN}?flow=${String(flow === null || flow === void 0 ? void 0 : flow.id)}`, { replace: true });
        return (ory
            .updateLoginFlow({
            flow: String(flow === null || flow === void 0 ? void 0 : flow.id),
            updateLoginFlowBody: values,
        })
            // We logged in successfully! Let's bring the user home.
            .then(async () => {
            if (flow === null || flow === void 0 ? void 0 : flow.return_to) {
                // Handle local redirect.
                if (flow.return_to.startsWith('/')) {
                    // Replace the location, so that user cannot go back to the login flow.
                    navigate(flow.return_to, {
                        replace: true,
                        state: { [RouterStateKeys.AUTH]: true },
                    });
                }
                else {
                    window.location.href = flow.return_to;
                }
                return Promise.resolve();
            }
            navigate(HOME);
            return Promise.resolve();
        })
            .catch(handleFlowError(navigate, 'login', setFlow, t))
            .catch(async (err) => {
            var _a;
            // If the previous handler did not catch the error it's most likely a form validation error
            if (((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === HttpStatusCodes.BAD_REQUEST) {
                // Yup, it is!
                setFlow(err.response.data);
                return Promise.resolve();
            }
            return Promise.reject(err);
        })
            .catch(handleErrorFallback(t))
            .finally(() => {
            setLoading(false);
        }));
    };
    const { formStyles } = useSidebarLayoutStyles();
    return (_jsx(LoadingWrapper, { status: { loading }, children: _jsxs(Stack, { justifyContent: "center", spacing: formStyles.spacing, width: "100%", children: [_jsx(Typography, { align: "center", variant: "h5", sx: { fontWeight: 700 }, children: t('body.step.login.heading1') }), _jsx(Flow, { onSubmit: onSubmit, flow: flow }), (aal !== null && aal !== void 0 ? aal : refresh) ? (_jsx(Button, { onClick: () => {
                        void onLogout();
                    }, children: t('body.step.login.logoutButton') })) : (_jsx(Link, { align: "center", href: PASSWORD_RECOVERY, children: t('body.step.login.forgotPasswordButton') }))] }) }));
};
export default Login;
