import { jsx as _jsx } from "react/jsx-runtime";
import { Search } from '@mui/icons-material';
import { useApiError } from 'api';
import { TextFormItem } from 'components/custom';
import { debounce } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
const AUTO_SEARCH_MIN_LENGTH = 3;
const AUTO_SEARCH_DELAY_MS = 1200;
const FileObjectSearch = ({ error, initialQuery, onQueryChange, }) => {
    const { t } = useTranslation('object', {
        keyPrefix: 'cmp.FileObjectSearch',
    });
    const onQueryChangeThrottled = React.useMemo(() => debounce(onQueryChange, AUTO_SEARCH_DELAY_MS), [onQueryChange]);
    const [query, setQuery] = React.useState(initialQuery !== null && initialQuery !== void 0 ? initialQuery : '');
    const { getErrorMessage } = useApiError();
    React.useEffect(() => () => {
        onQueryChange('');
    }, [onQueryChange]);
    return (_jsx(TextFormItem, { name: "textQuery", placeholder: t('placeholder'), value: query, onChange: (event) => {
            const { value } = event.target;
            setQuery(value);
            if (value.length >= AUTO_SEARCH_MIN_LENGTH || value.length === 0) {
                onQueryChangeThrottled(value);
            }
        }, errorMessage: error ? getErrorMessage(error) : '', StartAdornmentIcon: Search, inputProps: {
            inputMode: 'search',
            onKeyDown: (event) => {
                if (event.code === 'Enter') {
                    onQueryChangeThrottled.cancel();
                    onQueryChange(query);
                }
            },
        }, clearButtonAriaLabel: t('clear'), onClear: () => {
            onQueryChangeThrottled.cancel();
            setQuery('');
            onQueryChange('');
        } }));
};
export default FileObjectSearch;
