import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setTitle } from 'utils/head';
import { useSearchParams } from 'react-router-dom';
import { LoadingWrapper } from 'components/custom';
import TerminationForm from './TerminationForm';
import TerminationLinkSent from './TerminationLinkSent';
import TerminationConfirmation from './TerminationConfirmation';
const AccountTermination = () => {
    const { t } = useTranslation('termination');
    const [loading, setLoading] = React.useState(true);
    const [searchParams] = useSearchParams();
    const [currentTerminationStep, setCurrentTerminationStep] = React.useState(0);
    const navigateToTerminationLinkSent = () => {
        const terminationLinkSentIndex = 1;
        setCurrentTerminationStep(terminationLinkSentIndex);
    };
    const navigateToTerminationConfirmation = () => {
        const terminationConfirmationIndex = 2;
        setCurrentTerminationStep(terminationConfirmationIndex);
    };
    const TERMINATION_STEPS = [
        _jsx(TerminationForm, { navigateToTerminationLinkSent: navigateToTerminationLinkSent }),
        _jsx(TerminationLinkSent, {}),
        _jsx(TerminationConfirmation, {}),
    ];
    useEffect(() => {
        setTitle(t('head.title'));
        if (searchParams.has('token')) {
            navigateToTerminationConfirmation();
        }
        setLoading(false);
    }, [searchParams, t]);
    return (_jsx(LoadingWrapper, { status: { loading }, children: TERMINATION_STEPS[currentTerminationStep] }));
};
export default AccountTermination;
