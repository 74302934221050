import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Dashboard, Home } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { setTitle } from 'utils/head';
import { Footer } from 'components/custom';
import { HOME } from 'utils/links';
import { HOMEPAGE } from 'utils/external-links';
const PageNotFound = () => {
    const { t } = useTranslation('404');
    useEffect(() => {
        setTitle(t('head.title'));
    }, [t]);
    return (_jsxs(Box, { sx: {
            display: 'grid',
            gridTemplateRows: '1fr auto',
            height: '100vh',
            width: '100%',
        }, children: [_jsxs(Stack, { alignItems: "center", justifyContent: "center", spacing: 4, children: [_jsx(Typography, { color: "secondary", fontWeight: "bold", variant: "h1", children: t('body.title') }), _jsx(Typography, { variant: "h4", children: t('body.message') }), _jsxs(Stack, { direction: "row", spacing: 1, children: [_jsx(Button, { variant: "contained", color: "secondary", href: HOMEPAGE, startIcon: _jsx(Home, {}), children: t('body.button.homepage') }), _jsx(Button, { variant: "contained", color: "secondary", href: HOME, startIcon: _jsx(Dashboard, {}), children: t('body.button.dashboard') })] })] }), _jsx(Footer, {})] }));
};
export default PageNotFound;
