import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { Footer, SidePanel } from 'components/custom';
import PrimaryImage from 'public/img/side_panel_bg_primary.svg';
import SecondaryImage from 'public/img/side_panel_bg_secondary.svg';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { EMAIL_VERIFICATION, LOGIN, REGISTRATION } from 'utils/links';
import { useResponsiveness } from 'utils/responsiveness';
import { useSidebarLayoutStyles } from './styles';
const SidebarLayout = ({ variant }) => {
    const { t } = useTranslation('sidebar');
    const { isExtraSmallViewport } = useResponsiveness();
    const getLayoutDirection = () => {
        if (!isExtraSmallViewport) {
            return 'row';
        }
        if (variant === 'login') {
            return 'column';
        }
        return 'column-reverse';
    };
    const { formStyles } = useSidebarLayoutStyles();
    return (_jsxs(Box, { sx: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            minWidth: '100vw',
            position: 'relative',
        }, children: [_jsxs(Box, { sx: {
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: getLayoutDirection(),
                    flexGrow: 1,
                }, children: [variant === 'registration' && (_jsx(SidePanel, { backgroundColor: "primary.main", backgroundImage: `url(${SecondaryImage})`, slogan: t('body.registration.sidePanel.slogan'), title: t('body.registration.sidePanel.title'), buttonProps: {
                            children: t('body.registration.sidePanel.button'),
                            color: 'secondary',
                            href: REGISTRATION,
                        }, linkProps: {
                            align: 'center',
                            children: t('body.registration.sidePanel.link'),
                            color: 'common.white',
                            href: EMAIL_VERIFICATION,
                        } })), _jsx(Box, { sx: {
                            display: 'flex',
                            flexGrow: formStyles.flexGrow,
                            margin: 'auto',
                            maxWidth: '45rem',
                            minWidth: '20rem',
                            py: formStyles.py,
                            width: '50%',
                        }, children: _jsx(Outlet, {}) }), variant === 'login' && (_jsx(SidePanel, { backgroundColor: "secondary.main", backgroundImage: `url(${PrimaryImage})`, slogan: t('body.login.sidePanel.slogan'), title: t('body.login.sidePanel.title'), buttonProps: {
                            children: t('body.login.sidePanel.button'),
                            color: 'primary',
                            href: LOGIN,
                        } }))] }), _jsx(Footer, {})] }));
};
export default SidebarLayout;
