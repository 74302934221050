import { MimeTypes, extractPureMimeType } from 'utils/mime-types';
/**
 * Splits a file name with extension to file name without extension and extension.
 * @param fileNameWithExtension File name with extension.
 * @returns Tuple with file name and file extension.
 */
export const fileNameExtSplit = (fileNameWithExtension) => {
    const dotIndex = fileNameWithExtension.lastIndexOf('.');
    const fileName = dotIndex > 0
        ? fileNameWithExtension.substring(0, dotIndex)
        : fileNameWithExtension;
    const fileExtension = dotIndex > 0 ? fileNameWithExtension.substring(dotIndex + 1) : '';
    return [fileName, fileExtension];
};
const previewSupportedImageMimeTypes = [
    MimeTypes.GIF,
    MimeTypes.JPG,
    MimeTypes.PNG,
    MimeTypes.SVG,
    MimeTypes.WEBP,
];
const previewSupportedObjectMimeTypes = [MimeTypes.PDF];
const previewSupportedIframeMimeTypes = [
    MimeTypes.TEXT,
    MimeTypes.HTML,
    MimeTypes.CSV,
];
const previewSupportedVideoMimeTypes = [
    MimeTypes.MOV,
    MimeTypes.MP4,
    MimeTypes.WEBM,
];
const previewSupportedAudioMimeTypes = [
    MimeTypes.AAC,
    MimeTypes.FLAC,
    MimeTypes.MP3,
    MimeTypes.WAV,
    MimeTypes.WEBA,
];
const previewSupportedMimeTypes = [
    ...previewSupportedImageMimeTypes,
    ...previewSupportedObjectMimeTypes,
    ...previewSupportedIframeMimeTypes,
    ...previewSupportedVideoMimeTypes,
    ...previewSupportedAudioMimeTypes,
];
/**
 * Checks if given MIME type can be opened in the supported web browsers.
 * @param mimeType MIME type.
 * @param group Group to narrow down.
 * @returns If supported `true`; otherwise `false`.
 */
export const isPreviewSupported = (mimeType, group) => {
    const pureMimeType = extractPureMimeType(mimeType);
    switch (group) {
        case 'image': {
            return previewSupportedImageMimeTypes.includes(pureMimeType);
        }
        case 'object': {
            return previewSupportedObjectMimeTypes.includes(pureMimeType);
        }
        case 'audio': {
            return previewSupportedAudioMimeTypes.includes(pureMimeType);
        }
        case 'video': {
            return previewSupportedVideoMimeTypes.includes(pureMimeType);
        }
        case 'text': {
            return previewSupportedIframeMimeTypes.includes(pureMimeType);
        }
        default: {
            return previewSupportedMimeTypes.includes(pureMimeType);
        }
    }
};
