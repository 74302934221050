import { HolidaysDocument, useHolidaysQuery } from 'api/generated';
import { getBaseOptions } from 'api/wrappers';
import { getApiStringFromDate, getDateFromApiString } from 'utils/date';
export const useHolidaysQueryApi = (variables, options) => {
    var _a, _b;
    const { data, ...rest } = useHolidaysQuery(getBaseOptions(variables, options));
    const holidays = ((_b = (_a = data === null || data === void 0 ? void 0 : data.schoolYear) === null || _a === void 0 ? void 0 : _a.holidays) !== null && _b !== void 0 ? _b : [])
        .slice()
        .sort((a, b) => (a.date > b.date ? 1 : -1))
        .map((holiday) => ({
        ...holiday,
        date: getDateFromApiString(holiday.date),
    }));
    return { ...rest, data: holidays };
};
export const getCreateHolidayMutationOptions = (holiday) => ({
    refetchQueries: [HolidaysDocument],
    variables: {
        input: {
            ...holiday,
            date: getApiStringFromDate(holiday.date),
        },
    },
});
export const getUpdateHolidayMutationOptions = (holiday) => ({
    variables: {
        input: {
            ...holiday,
            date: getApiStringFromDate(holiday.date),
        },
    },
});
export const getDeleteHolidayMutationOptions = (holidayId) => ({
    refetchQueries: [HolidaysDocument],
    variables: {
        id: holidayId,
    },
});
export { useCreateHolidayMutation, useUpdateHolidayMutation, useDeleteHolidayMutation, } from 'api/generated';
