import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FileUploadDialog } from 'components/custom';
import React from 'react';
import { getFileList } from 'utils/file-upload';
const FileUploadInput = React.forwardRef((props, fileInputRef) => {
    const { lessonId, onUploadFinally } = props;
    const [fileList, setFileList] = React.useState(null);
    const handleFileUpload = (event) => {
        setFileList(getFileList(event));
    };
    const onFinally = () => {
        onUploadFinally === null || onUploadFinally === void 0 ? void 0 : onUploadFinally();
        setFileList(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx("input", { ref: fileInputRef, type: "file", onChange: handleFileUpload, "data-testid": "fileInput", hidden: true, multiple: true }), _jsx(FileUploadDialog, { lessonId: lessonId, onFinally: onFinally, fileList: fileList })] }));
});
export default FileUploadInput;
