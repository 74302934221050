import { jsx as _jsx } from "react/jsx-runtime";
import { Draggable } from 'components/custom';
import { getTextColor } from 'utils/color';
import { DragAndDropOperationNames, DroppableIds } from 'utils/drag-and-drop';
import { Box, Typography } from '@mui/material';
import { isHourCellCode } from 'utils/date';
const isWeeklyScheduleCourse = (course) => {
    return !('slots' in course);
};
const DraggableCourseSlot = ({ courseWithSlots, onDeleteCourseSuccess, onPlanCourseSuccess, }) => {
    const onDragSuccess = (droppableId) => {
        if (isHourCellCode(droppableId)) {
            onPlanCourseSuccess(droppableId, courseWithSlots);
        }
        if (isWeeklyScheduleCourse(courseWithSlots) &&
            onDeleteCourseSuccess &&
            droppableId === DroppableIds.DELETE_LESSON) {
            onDeleteCourseSuccess(courseWithSlots);
        }
    };
    return (_jsx(Box, { sx: {
            backgroundColor: courseWithSlots.color,
            color: getTextColor(courseWithSlots.color),
            height: '100%',
            overflow: 'hidden',
            p: 1,
            width: '100%',
        }, children: _jsx(Draggable, { Ghost: _jsx(Box, { sx: {
                    backgroundColor: courseWithSlots.color,
                    color: getTextColor(courseWithSlots.color),
                    p: 2,
                }, children: courseWithSlots.name }), onDragSuccess: onDragSuccess, operationName: [
                DragAndDropOperationNames.PLAN_COURSE,
                ...(onDeleteCourseSuccess
                    ? [DragAndDropOperationNames.DELETE_LESSON]
                    : []),
            ], children: _jsx(Typography, { component: "span", sx: {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }, title: courseWithSlots.name, children: courseWithSlots.name }) }) }));
};
export default DraggableCourseSlot;
