import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CalendarToday, Clear, Inventory, More, Stairs, } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Typography, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useObjectFiltersQueryApi } from 'api';
import { ConditionalWrapper, FilterBase, LoadingWrapper, Select, Tooltip, } from 'components/custom';
import React from 'react';
import { useTranslation } from 'react-i18next';
export const getDefaultFilters = () => ({
    educationalYearIDs: [],
    schoolYearIDs: [],
    subjectIDs: [],
    tagIDs: [],
});
const FileObjectFilters = ({ onFilterChange, orientation = 'horizontal', }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { t } = useTranslation('object', {
        keyPrefix: 'cmp.FileObjectFilters',
    });
    const [schoolYearFilter, setSchoolYearFilter] = React.useState([]);
    const [subjectFilter, setSubjectFilter] = React.useState([]);
    const [educationalYearFilter, setEducationalYearFilter] = React.useState([]);
    const [tagFilter, setTagFilter] = React.useState([]);
    const status = useObjectFiltersQueryApi();
    const schoolYearOptions = [
        { label: t('schoolYearField.noSchoolYearOption'), value: 'NULL' },
        ...((_b = (_a = status.data) === null || _a === void 0 ? void 0 : _a.schoolYears) !== null && _b !== void 0 ? _b : []),
    ];
    const subjectsOptions = [
        { label: t('subjectField.noSubjectOption'), value: 'NULL' },
        ...((_d = (_c = status.data) === null || _c === void 0 ? void 0 : _c.subjects) !== null && _d !== void 0 ? _d : []),
    ];
    const educationalYearOptions = [
        { label: t('educationalYearField.noEducationalYearOption'), value: 'NULL' },
        ...((_f = (_e = status.data) === null || _e === void 0 ? void 0 : _e.educationalYears) !== null && _f !== void 0 ? _f : []),
    ];
    const tagOptions = [
        { label: t('tagField.noTagOption'), value: 'NULL' },
        ...((_h = (_g = status.data) === null || _g === void 0 ? void 0 : _g.tags) !== null && _h !== void 0 ? _h : []),
    ];
    React.useEffect(() => {
        onFilterChange({
            educationalYearIDs: educationalYearFilter,
            schoolYearIDs: schoolYearFilter,
            subjectIDs: subjectFilter,
            tagIDs: tagFilter,
        });
    }, [
        onFilterChange,
        schoolYearFilter,
        subjectFilter,
        educationalYearFilter,
        tagFilter,
    ]);
    const theme = useTheme();
    const isVerticalLayout = useMediaQuery(theme.breakpoints.down('md')) || orientation === 'vertical';
    const id = React.useId();
    const clearAllFilters = (e) => {
        e.stopPropagation();
        setEducationalYearFilter([]);
        setSchoolYearFilter([]);
        setSubjectFilter([]);
        setTagFilter([]);
    };
    return (_jsx(ConditionalWrapper, { condition: isVerticalLayout, wrapper: (children) => (_jsxs(Accordion, { children: [_jsxs(AccordionSummary, { "aria-controls": `filters-content-${id}`, id: `filters-header-${id}`, sx: {
                        '& .MuiAccordionSummary-content': {
                            justifyContent: 'space-between',
                            pr: 2,
                        },
                    }, children: [_jsx(Typography, { fontWeight: "bold", children: t('title') }), _jsx(Tooltip, { title: t('clearAllButton'), children: _jsx(IconButton, { "aria-label": t('clearAllButton'), onClick: clearAllFilters, sx: { p: 0 }, children: _jsx(Clear, {}) }) })] }), _jsx(AccordionDetails, { children: children })] })), children: _jsx(LoadingWrapper, { status: status, children: _jsxs(Box, { sx: {
                    display: 'grid',
                    ...(isVerticalLayout
                        ? {
                            boxShadow: 'none',
                            gap: 1,
                        }
                        : {
                            alignItems: 'end',
                            boxShadow: theme.boxShadow,
                            gap: theme.spacingFactors.two,
                            gridTemplateColumns: 'repeat(4, 1fr) auto',
                            p: 1,
                        }),
                }, children: [_jsx(FilterBase, { filter: schoolYearFilter, setter: setSchoolYearFilter, options: schoolYearOptions, renderSelect: (props) => (_jsx(Select, { ...props, label: t('schoolYearField.label'), placeholder: t('schoolYearField.placeholder'), name: "schoolYear", clearButtonAriaLabel: t('schoolYearField.clear'), InputProps: {
                                startAdornment: (_jsx(CalendarToday, { sx: { mr: 1 }, color: "secondary" })),
                            } })) }), _jsx(FilterBase, { filter: subjectFilter, setter: setSubjectFilter, options: subjectsOptions, renderSelect: (props) => (_jsx(Select, { ...props, label: t('subjectField.label'), placeholder: t('subjectField.placeholder'), name: "subject", clearButtonAriaLabel: t('subjectField.clear'), InputProps: {
                                startAdornment: _jsx(Inventory, { sx: { mr: 1 } }),
                            } })) }), _jsx(FilterBase, { filter: educationalYearFilter, setter: setEducationalYearFilter, options: educationalYearOptions, renderSelect: (props) => (_jsx(Select, { ...props, label: t('educationalYearField.label'), placeholder: t('educationalYearField.placeholder'), name: "educationalYear", clearButtonAriaLabel: t('educationalYearField.clear'), InputProps: {
                                startAdornment: _jsx(Stairs, { sx: { mr: 1 }, color: "success" }),
                            } })) }), _jsx(FilterBase, { filter: tagFilter, setter: setTagFilter, options: tagOptions, renderSelect: (props) => (_jsx(Select, { ...props, label: t('tagField.label'), placeholder: t('tagField.placeholder'), name: "tag", clearButtonAriaLabel: t('tagField.clear'), InputProps: {
                                startAdornment: (_jsx(More, { sx: { fill: theme.palette.warning.main, mr: 1 } })),
                            } })) }), !isVerticalLayout && (_jsx(Tooltip, { title: t('clearAllButton'), children: _jsx(IconButton, { "aria-label": t('clearAllButton'), onClick: clearAllFilters, sx: { mb: 0.5, ml: -1 }, children: _jsx(Clear, {}) }) }))] }) }) }));
};
export default FileObjectFilters;
