import { jsx as _jsx } from "react/jsx-runtime";
import { getDeleteVacationMutationOptions, isLoading, useDeleteVacationMutation, } from 'api';
import { DeletionConfirmationDialog, LoadingWrapper } from 'components/custom';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
const DeleteVacationDialog = (props) => {
    const { vacationToDelete, setVacationToDelete } = props;
    const { t } = useTranslation('timetable', {
        keyPrefix: 'steps.vacation.cmp.DeleteVacationDialog',
    });
    const [deleteVacation, deleteVacationStatus] = useDeleteVacationMutation();
    const handleDelete = () => {
        if (vacationToDelete === null || vacationToDelete === void 0 ? void 0 : vacationToDelete.id) {
            void deleteVacation(getDeleteVacationMutationOptions(vacationToDelete.id)).then(() => {
                setVacationToDelete(null);
                enqueueSnackbar(t('successMessage'));
            });
        }
    };
    return (_jsx(DeletionConfirmationDialog, { open: !!(vacationToDelete === null || vacationToDelete === void 0 ? void 0 : vacationToDelete.id), onClose: () => {
            setVacationToDelete(null);
        }, titleProps: {
            text: t('title'),
        }, cancelButtonProps: {
            onClick: () => {
                setVacationToDelete(null);
            },
        }, confirmButtonProps: {
            children: t('actions.delete'),
            disabled: isLoading(deleteVacationStatus),
            onClick: handleDelete,
        }, children: _jsx(LoadingWrapper, { mutation: deleteVacationStatus, children: t('text', {
                name: vacationToDelete === null || vacationToDelete === void 0 ? void 0 : vacationToDelete.name,
            }) }) }));
};
export default DeleteVacationDialog;
