import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from 'components/custom';
import { getNodeLabel } from '../integrations/ui';
import { filterValue } from '../skyla/helpers';
import { useMappings } from '../skyla/mappings';
export const NodeInputSubmit = ({ node, attributes, setValue, disabled, dispatchSubmit, }) => {
    var _a, _b;
    const { getButtonText } = useMappings();
    return (_jsx(_Fragment, { children: _jsx(Button, { name: attributes.name, onClick: (e) => {
                // Prevent double submit issues.
                e.stopPropagation();
                e.preventDefault();
                // On click, we set this value, and once set, dispatch the submission!
                void setValue(filterValue(attributes.value)).then(async () => dispatchSubmit(e));
            }, value: (_b = (_a = filterValue(attributes.value)) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '', disabled: attributes.disabled || disabled, type: "submit", fullWidth: true, size: "large", color: "secondary", variant: "contained", children: getButtonText(node, getNodeLabel(node)) }) }));
};
