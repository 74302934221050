import { useSubjectsQuery } from 'api/generated';
import { getBaseOptions } from 'api/wrappers';
export const useSubjectsQueryApi = (variables, options) => {
    var _a;
    const { data, ...rest } = useSubjectsQuery(getBaseOptions(variables !== null && variables !== void 0 ? variables : {}, {
        ...options,
        fetchPolicy: 'cache-first',
    }));
    const subjects = (_a = data === null || data === void 0 ? void 0 : data.subjects) !== null && _a !== void 0 ? _a : [];
    return { ...rest, data: subjects };
};
