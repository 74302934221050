import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
const LabelValueItem = (props) => {
    const { label, value, ...boxProps } = props;
    const { t } = useTranslation('common');
    const id = React.useId();
    return (_jsxs(Box, { ...boxProps, children: [_jsxs(Typography, { component: "span", id: id, children: [label, t('body.labelSeparator')] }), ' ', _jsx(Typography, { component: "span", "aria-labelledby": id, children: _jsx("i", { children: value !== null && value !== void 0 ? value : '-' }) })] }));
};
export default LabelValueItem;
