import { jsx as _jsx } from "react/jsx-runtime";
import { DataGrid } from 'components/custom';
import { useTranslation } from 'react-i18next';
import { Chip, IconButton, Stack } from '@mui/material';
import { formatDateTime } from 'utils/date';
import { AddCircle, Close, RemoveCircle } from '@mui/icons-material';
const resolveChipColor = (status) => {
    switch (status) {
        case 'added':
            return 'success';
        case 'removed':
            return 'error';
        default:
            return 'primary';
    }
};
const resolveChipIcon = (status) => {
    switch (status) {
        case 'added':
            return _jsx(AddCircle, {});
        case 'removed':
            return _jsx(RemoveCircle, {});
        default:
            return undefined;
    }
};
const LessonAssignmentDataGrid = (props) => {
    const { t } = useTranslation('object', {
        keyPrefix: 'cmp.LessonAssignmentDataGrid',
    });
    const columns = [
        {
            field: 'name',
            flex: 0.2,
            headerName: t('column.header.courseName'),
        },
        {
            field: 'lessons',
            flex: 0.8,
            headerName: t('column.header.lessons'),
            renderCell: (params) => (_jsx(Stack, { direction: "row", flexWrap: "wrap", children: params.row.lessons.map((lesson) => (_jsx(Chip, { label: formatDateTime(lesson.startDate, true), icon: resolveChipIcon('status' in lesson ? lesson.status : 'existing'), color: resolveChipColor('status' in lesson ? lesson.status : 'existing'), onDelete: () => {
                        props.onLessonChipClick(lesson, 'status' in lesson && lesson.status === 'removed'
                            ? 'add'
                            : 'remove');
                    }, sx: { m: 0.25 } }, lesson.id))) })),
            sortable: false,
        },
        {
            field: 'removeAllLessons',
            headerName: '',
            renderCell: (params) => (_jsx(IconButton, { onClick: () => {
                    props.onRemoveRowClick(params.row.id);
                }, "aria-label": t('column.header.removeAllLessons'), sx: { margin: 'auto' }, children: _jsx(Close, {}) })),
            sortable: false,
            width: 50,
        },
    ];
    return (_jsx(DataGrid, { getRowHeight: () => 'auto', columns: columns, rows: props.rows, sx: { '& .MuiDataGrid-cell': { py: 1 } } }));
};
export default LessonAssignmentDataGrid;
