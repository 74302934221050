import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { throttle } from 'lodash-es';
import React from 'react';
import { DragIndicator } from '@mui/icons-material';
import { motion, useDragControls } from 'framer-motion';
import { Box, Portal, Stack } from '@mui/material';
import { DragAndDropContext } from 'components/custom/DragAndDropProvider';
/**
 * Interval for throttled drag event.
 */
const dragInterval = 100;
const Draggable = ({ children, Ghost, onDragSuccess, operationName, }) => {
    const { dragAndDropContextData, setDragAndDropContextData } = React.useContext(DragAndDropContext);
    const { pointerX, pointerY } = dragAndDropContextData;
    const controls = useDragControls();
    const [isDragging, setIsDragging] = React.useState(false);
    const startDrag = (event) => {
        controls.start(event, { snapToCursor: true });
    };
    const onDrag = React.useMemo(() => throttle((event, info) => {
        if (!isDragging) {
            setIsDragging(true);
        }
        if (pointerX !== info.point.x || pointerY !== info.point.y) {
            setDragAndDropContextData((prevState) => ({
                ...prevState,
                operationName,
                pointerX: info.point.x,
                pointerY: info.point.y,
            }));
        }
    }, dragInterval, { trailing: false }), [pointerX, pointerY, isDragging, operationName, setDragAndDropContextData]);
    const onDragEnd = () => {
        setIsDragging(false);
        const { droppableId, droppableItemName } = dragAndDropContextData;
        if (droppableId) {
            onDragSuccess(droppableId, droppableItemName);
        }
        setDragAndDropContextData({});
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, { alignItems: "center", direction: "row", spacing: 1, onPointerDown: startDrag, sx: {
                    cursor: 'move',
                    height: '100%',
                    maxWidth: '100%',
                    touchAction: 'none',
                    userSelect: 'none',
                }, children: [_jsx(DragIndicator, {}), children] }), _jsx(Portal, { children: _jsx(Box, { sx: {
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }, children: _jsx(motion.div, { drag: true, onDrag: onDrag, dragControls: controls, onDragEnd: onDragEnd, dragSnapToOrigin: true, dragPropagation: true, style: {
                            position: 'absolute',
                            visibility: isDragging ? 'visible' : 'hidden',
                            zIndex: 'inherit',
                        }, children: _jsx(Box, { sx: {
                                backgroundColor: (theme) => theme.palette.common.white,
                                opacity: 0.8,
                            }, children: Ghost }) }) }) })] }));
};
export default Draggable;
