import { getApiUrl } from '../../utils/config';
import { useRestQuery } from '../wrappers';
export const createProviderMutation = async (params) => {
    return fetch(`${getApiUrl()}/api/v1/provider/check`, {
        body: JSON.stringify(params),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
};
export const useProviderQuery = () => {
    return useRestQuery(createProviderMutation);
};
