import MuiStepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
const StyledStepper = styled(MuiStepper)(({ theme: { palette, spacing } }) => {
    const BORDER_WIDTH = spacing(0.25);
    const STEPPER_ICON_SIZE_REM = 2.2;
    const borderMixin = {
        borderWidth: BORDER_WIDTH,
        borderStyle: 'solid',
        borderColor: palette.secondary.main,
    };
    return {
        '& .MuiStepLabel-iconContainer': {
            ...borderMixin,
            borderRadius: '50%',
            backgroundColor: palette.common.white,
            color: palette.secondary.main,
            '& .MuiSvgIcon-root': {
                fontSize: `${STEPPER_ICON_SIZE_REM}rem`,
                padding: spacing(1),
            },
            '&.Mui-active, &.Mui-completed': {
                backgroundColor: palette.secondary.main,
                color: palette.common.white,
            },
            '&.Mui-disabled': {
                backgroundColor: palette.inactive.main,
                color: palette.inactive.contrastText,
            },
        },
        '& .MuiStepConnector-line': borderMixin,
        '& .MuiStepConnector-root': {
            top: `calc(${STEPPER_ICON_SIZE_REM / 2}rem - ${BORDER_WIDTH})`,
        },
        '& .MuiStepLabel-root .MuiStepLabel-label': {
            color: palette.text.primary,
            fontWeight: 500,
        },
        '& .MuiStepLabel-label.Mui-active': {
            color: palette.secondary.main,
            fontWeight: 700,
        },
    };
});
export default StyledStepper;
