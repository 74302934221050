import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from 'components/custom';
import { log, Severity } from 'utils/logger';
import { getNodeLabel } from '../integrations/ui';
import { filterValue } from '../skyla/helpers';
import { useMappings } from '../skyla/mappings';
export const NodeInputButton = ({ node, attributes, setValue, disabled, dispatchSubmit, }) => {
    var _a, _b;
    const { getButtonText } = useMappings();
    // Some attributes have dynamic JavaScript - this is for example required for WebAuthn.
    const onClick = (e) => {
        const value = filterValue(attributes.value);
        if (value !== attributes.value) {
            // Add Sentry, log attributes.name and attributes.value.
            log(`Unsupported value for node "${attributes.name}"`, {
                dump: attributes.value,
                severity: Severity.ERROR,
            });
        }
        void setValue(value).then(async () => dispatchSubmit(e));
    };
    return (_jsx(_Fragment, { children: _jsx(Button, { name: attributes.name, onClick: onClick, value: (_b = (_a = filterValue(attributes.value)) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '', disabled: attributes.disabled || disabled, fullWidth: true, size: "large", color: "secondary", variant: "contained", children: getButtonText(node, getNodeLabel(node)) }) }));
};
