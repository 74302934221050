import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { Footer, LoadingWrapper } from 'components/custom';
import NavDrawer from 'layouts/Default/NavDrawer';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { LocalStorageKeys } from 'utils/local-storage';
const SharedLayout = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(!localStorage.getItem(LocalStorageKeys.DRAWER_MINI));
    return (_jsx(LoadingWrapper, { fullScreen: true, children: _jsxs(Box, { sx: { display: 'flex', flexGrow: 1 }, children: [_jsx(NavDrawer, { drawerOpen: drawerOpen, setDrawerOpen: setDrawerOpen }), _jsxs(Box, { sx: { display: 'flex', flexDirection: 'column', flexGrow: 1 }, children: [_jsx(Box, { component: "main", sx: {
                                flexGrow: 1,
                                p: 2,
                            }, children: _jsx(Outlet, {}) }), _jsx(Footer, {})] })] }) }));
};
export default SharedLayout;
