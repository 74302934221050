import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FileCopy } from '@mui/icons-material';
import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { FileObjectFilters, FileObjectLessons, FileObjectSearch, Tooltip, } from 'components/custom';
import { getDefaultFilters } from 'components/custom/FileObjectFilters';
import { useWidgetContext, Widget } from 'components/custom/WidgetProvider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DraggableFileObjectGrid from './DraggableFileObjectGrid';
const widgetName = Widget.FILES;
const ObjectsWidget = () => {
    var _a, _b;
    const { t } = useTranslation('object', {
        keyPrefix: 'cmp.DraggableFileObjectGrid',
    });
    const [searchQuery, setSearchQuery] = React.useState('');
    const [error, setError] = React.useState(undefined);
    const [filterQueryOptions, setFilterQueryOptions] = React.useState(getDefaultFilters());
    const [selectedFileId, setSelectedFileId] = React.useState(undefined);
    const onFileSelectionChange = (fileId) => {
        setSelectedFileId(fileId);
    };
    const { widget, setWidget } = useWidgetContext();
    const contextFileId = (widget === null || widget === void 0 ? void 0 : widget.active) === widgetName ? (_a = widget.params) === null || _a === void 0 ? void 0 : _a.fileId : undefined;
    const contextRowIndex = (widget === null || widget === void 0 ? void 0 : widget.active) === widgetName ? (_b = widget.params) === null || _b === void 0 ? void 0 : _b.rowIndex : undefined;
    React.useEffect(() => {
        if (contextFileId) {
            setSelectedFileId(contextFileId);
            setWidget({
                active: widgetName,
            });
        }
    }, [contextFileId, setSelectedFileId, setWidget]);
    return (_jsxs(Card, { "data-testid": "ObjectsWidget", sx: { flexGrow: 1 }, children: [_jsx(CardHeader, { avatar: _jsx(FileCopy, {}), action: _jsx(Tooltip, { title: t('hint') }), title: t('cardTitle') }), _jsx(CardContent, { sx: { display: 'flex', flexDirection: 'column', flexGrow: 1 }, children: _jsxs(Stack, { flexGrow: 1, spacing: 2, children: [_jsx(FileObjectSearch, { onQueryChange: setSearchQuery, error: error }), _jsx(FileObjectFilters, { orientation: "vertical", onFilterChange: setFilterQueryOptions }), _jsx(DraggableFileObjectGrid, { filter: filterQueryOptions, searchQuery: searchQuery, onError: setError, onFileSelectionChange: onFileSelectionChange, selectedFileId: contextFileId, selectedRowIndex: contextRowIndex }), _jsx(FileObjectLessons, { fileId: selectedFileId })] }) })] }));
};
export default ObjectsWidget;
