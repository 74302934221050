import { EMAIL_VERIFICATION, HOME, LOGIN, PASSWORD_RECOVERY, REGISTRATION, SETTINGS, } from 'utils/links';
import { log, Severity } from 'utils/logger';
import { enqueueSnackbar } from 'notistack';
/**
 * Returns value with supported type or `undefined`.
 * @param value Value defined in Ory client types as `any`.
 * @returns Value with supported type.
 */
export const filterValue = (value) => {
    switch (typeof value) {
        case 'boolean':
        case 'number':
        case 'string':
        case 'undefined':
            return value;
        default:
            return undefined;
    }
};
/**
 * Converts flow type to link.
 * @param flowType Ory Kratos flow type.
 * @returns Link to the flow type.
 */
export const convertFlowTypeToLink = (flowType) => {
    switch (flowType) {
        case 'login':
            return LOGIN;
        case 'registration':
            return REGISTRATION;
        case 'recovery':
            return PASSWORD_RECOVERY;
        case 'verification':
            return EMAIL_VERIFICATION;
        case 'settings':
            return SETTINGS;
        default:
            return HOME;
    }
};
/**
 * Displays error message and logs it as warning or error.
 * @param msg Error message.
 * @param dump Whatever should be additionally logged. Escalates severity to error.
 */
export const showError = (msg, dump) => {
    if (dump) {
        log(msg, {
            dump: JSON.stringify(dump),
            severity: Severity.ERROR,
        });
    }
    else {
        log(msg, {
            severity: Severity.WARN,
        });
    }
    enqueueSnackbar(msg, {
        variant: 'error',
    });
};
