import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useFileObjectsRefetch, useLessonQueryApi } from 'api';
import { Button, FileObjectPreview, FileUploadDroppable, LoadingWrapper, ShareLessonButton, } from 'components/custom';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { formatDate } from 'utils/date';
import { DragAndDropOperationNames } from 'utils/drag-and-drop';
import { setTitle } from 'utils/head';
import { getLessonUrl } from 'utils/links';
import Info from './Info';
import LessonFileObjectGrid from './LessonFileObjectGrid';
import Notes from './Notes';
import AddObjectSplitButton from 'components/custom/AddObjectSplitButton';
const Lesson = () => {
    var _a, _b, _c;
    const { t } = useTranslation('lesson');
    const { lessonId = '' } = useParams();
    const status = useLessonQueryApi({ id: lessonId });
    const refetchFileObjects = useFileObjectsRefetch();
    const prevLessonId = (_a = status.data) === null || _a === void 0 ? void 0 : _a.previousLessonId;
    const nextLessonId = (_b = status.data) === null || _b === void 0 ? void 0 : _b.nextLessonId;
    const lesson = status.data;
    useEffect(() => {
        if (lesson) {
            setTitle(`${lesson.courseName} - ${formatDate(lesson.startDate)}`);
        }
        else {
            setTitle(t('head.title'));
        }
    }, [t, lesson]);
    const [fileObject, setFileObject] = React.useState(undefined);
    const closePreview = () => {
        setFileObject(undefined);
    };
    useEffect(() => {
        closePreview();
    }, [lesson === null || lesson === void 0 ? void 0 : lesson.id]);
    const onUnlinked = (ids) => {
        if (fileObject && ids.includes(fileObject.id)) {
            closePreview();
        }
    };
    return (_jsx(LoadingWrapper, { status: status, children: _jsxs(Stack, { height: "100%", "data-testid": "LessonView", children: [_jsxs(Stack, { spacing: 2, flexGrow: 1, children: [lesson && _jsx(Info, { lesson: lesson }), _jsx(FileObjectPreview, { fileObject: fileObject, onClose: closePreview }), _jsxs(Stack, { flexGrow: 1, sx: { display: 'grid', gap: 2, gridTemplateColumns: '2fr 1fr' }, children: [_jsxs(Stack, { spacing: 2, children: [_jsxs(Stack, { direction: "row", spacing: 2, justifyContent: "end", children: [_jsx(ShareLessonButton, { lessonId: lessonId, sharingEndDateTime: lesson === null || lesson === void 0 ? void 0 : lesson.sharingEndDateTime, sharingToken: lesson === null || lesson === void 0 ? void 0 : lesson.sharingToken }), _jsx(AddObjectSplitButton, { lessonId: lessonId, refetchFileObjects: refetchFileObjects })] }), _jsx(FileUploadDroppable, { lessonId: lessonId, droppableId: lessonId, droppableItemName: (_c = status.data) === null || _c === void 0 ? void 0 : _c.courseName, operationName: DragAndDropOperationNames.ASSIGN_OBJECT_TO_LESSON, onUploadFinally: refetchFileObjects, outlinePosition: "outside", children: _jsx(LessonFileObjectGrid, { "data-testid": "lessonViewObjectGrid", lessonId: lessonId, nextLessonId: nextLessonId, onNameClick: setFileObject, onUnlinked: onUnlinked }) })] }), _jsx(Notes, {})] })] }), _jsxs(Stack, { direction: "row", justifyContent: "space-between", marginTop: "auto", children: [_jsx(Button, { color: "secondary", disabled: !prevLessonId, startIcon: _jsx(ArrowBack, {}), href: prevLessonId && getLessonUrl(prevLessonId), children: t('body.bottomNav.back') }), _jsx(Button, { color: "secondary", disabled: !nextLessonId, endIcon: _jsx(ArrowForward, {}), href: nextLessonId && getLessonUrl(nextLessonId), children: t('body.bottomNav.next') })] })] }) }));
};
export default Lesson;
