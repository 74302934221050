import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddCircle, Edit } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { getCreateSchoolYearMutationOptions, getUpdateSchoolYearMutationOptions, isLoading, onValidationError, useCreateSchoolYearMutation, } from 'api';
import { useUpdateSchoolYearMutation } from 'api/generated';
import { ConfirmationDialog, DatePicker, LoadingWrapper, TextFormItem, } from 'components/custom';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDialogMode } from 'utils/dialog-form';
import { onSubmitError, yup } from 'utils/forms';
const SaveSchoolYearDialog = (props) => {
    const { activeSchoolYear, close } = props;
    const { t } = useTranslation('timetable', {
        keyPrefix: 'steps.schoolYears.cmp.SaveSchoolYearDialog',
    });
    const id = React.useId();
    const { modeCreate, open } = useDialogMode(activeSchoolYear);
    const schema = React.useMemo(() => yup.object({
        name: yup.string().label(t('nameField.label')).required(),
        startDate: yup.date().label(t('startField.label')).required(),
        // eslint-disable-next-line sort-keys -- sorted by field occurrence
        endDate: yup.date().label(t('endField.label')).required(),
    }), [t]);
    const { handleSubmit, control, formState: { errors, isSubmitting }, reset, } = useForm({
        resolver: yupResolver(schema),
    });
    const [createSchoolYear, statusCreateSchoolYear] = useCreateSchoolYearMutation();
    const [updateSchoolYear, statusUpdateSchoolYear] = useUpdateSchoolYearMutation();
    const mutationStatus = modeCreate
        ? statusCreateSchoolYear
        : statusUpdateSchoolYear;
    React.useEffect(() => {
        reset(activeSchoolYear !== null && activeSchoolYear !== void 0 ? activeSchoolYear : {});
    }, [activeSchoolYear, reset]);
    const handleSuccess = () => {
        close();
        enqueueSnackbar(t('successMessage'));
    };
    const onSubmit = async (values) => {
        if (activeSchoolYear === null || activeSchoolYear === void 0 ? void 0 : activeSchoolYear.id) {
            return updateSchoolYear(getUpdateSchoolYearMutationOptions({
                id: activeSchoolYear.id,
                name: values.name,
            }))
                .then(handleSuccess)
                .catch(onValidationError);
        }
        return createSchoolYear(getCreateSchoolYearMutationOptions(values))
            .then(handleSuccess)
            .catch(onValidationError);
    };
    const loading = isLoading([
        statusCreateSchoolYear,
        statusUpdateSchoolYear,
    ]);
    return (_jsx(ConfirmationDialog, { open: open, onClose: close, onTransitionExited: () => {
            mutationStatus.reset();
        }, titleProps: {
            Icon: modeCreate ? AddCircle : Edit,
            text: modeCreate ? t('addSchoolYearTitle') : t('editSchoolYearTitle'),
        }, cancelButtonProps: {
            onClick: close,
        }, confirmButtonProps: {
            disabled: isSubmitting || loading,
            form: id,
            type: 'submit',
        }, fullWidth: true, children: _jsx(LoadingWrapper, { mutation: mutationStatus, children: _jsx("form", { id: id, onSubmit: (e) => {
                    handleSubmit(onSubmit)(e).catch(onSubmitError);
                }, children: _jsxs(Stack, { spacing: 2, children: [_jsx(Controller, { control: control, name: "name", render: ({ field }) => {
                                var _a;
                                return (_jsx(TextFormItem, { ...field, label: t('nameField.label'), placeholder: t('nameField.placeholder'), errorMessage: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message }));
                            } }), modeCreate && (_jsxs(_Fragment, { children: [_jsx(Controller, { control: control, name: "startDate", render: ({ field }) => {
                                        var _a;
                                        return (_jsx(DatePicker, { ...field, label: t('startField.label'), errorMessage: (_a = errors.startDate) === null || _a === void 0 ? void 0 : _a.message }));
                                    } }), _jsx(Controller, { control: control, name: "endDate", render: ({ field }) => {
                                        var _a;
                                        return (_jsx(DatePicker, { ...field, label: t('endField.label'), errorMessage: (_a = errors.endDate) === null || _a === void 0 ? void 0 : _a.message }));
                                    } })] }))] }) }) }) }));
};
export default SaveSchoolYearDialog;
