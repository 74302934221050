import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { Link, LoadingWrapper } from 'components/custom';
import { Flow, handleErrorFallback, handleFlowError, ory, } from 'components/kratos';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setTitle } from 'utils/head';
import { HttpStatusCodes } from 'utils/http-status-codes';
import { LOGIN, PASSWORD_RECOVERY } from 'utils/links';
import { useSidebarLayoutStyles } from 'layouts/Sidebar/styles';
/**
 * Displays account recovery form.
 */
const Recovery = () => {
    const { t } = useTranslation('auth');
    const [flow, setFlow] = useState();
    const [loading, setLoading] = React.useState(true);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    // Get ?flow=... from the URL
    const flowId = searchParams.get('flow');
    const returnTo = searchParams.get('return_to');
    useEffect(() => {
        setTitle(t('head.recovery.title'));
    }, [t]);
    useEffect(() => {
        // If we already have a flow, do nothing.
        if (flow) {
            return;
        }
        setLoading(true);
        // If ?flow=.. was in the URL, we fetch it
        if (flowId) {
            ory
                .getRecoveryFlow({ id: String(flowId) })
                .then(({ data }) => {
                setFlow(data);
            })
                .catch(handleFlowError(navigate, 'recovery', setFlow, t))
                .catch(handleErrorFallback(t))
                .finally(() => {
                setLoading(false);
            });
            return;
        }
        // Otherwise we initialize it
        ory
            .createBrowserRecoveryFlow()
            .then(({ data }) => {
            setFlow(data);
        })
            .catch(handleFlowError(navigate, 'recovery', setFlow, t))
            .catch(handleErrorFallback(t))
            .finally(() => {
            setLoading(false);
        });
    }, [flowId, navigate, returnTo, flow, t]);
    const onSubmit = async (values) => {
        setLoading(true);
        /*
         * On submission, add the flow ID to the URL but do not navigate. This prevents the user loosing
         * his data when she/he reloads the page.
         */
        navigate(`${PASSWORD_RECOVERY}?flow=${String(flow === null || flow === void 0 ? void 0 : flow.id)}`, {
            replace: true,
        });
        return ory
            .updateRecoveryFlow({
            flow: String(flow === null || flow === void 0 ? void 0 : flow.id),
            updateRecoveryFlowBody: values,
        })
            .then(({ data }) => {
            // Form submission was successful, show the message to the user!
            setFlow(data);
        })
            .catch(handleFlowError(navigate, 'recovery', setFlow, t))
            .catch(async (err) => {
            var _a;
            // Status code 400 implies the form validation had an error
            if (((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === HttpStatusCodes.BAD_REQUEST) {
                setFlow(err.response.data);
                return Promise.resolve();
            }
            throw err;
        })
            .catch(handleErrorFallback(t))
            .finally(() => {
            setLoading(false);
        });
    };
    const { formStyles } = useSidebarLayoutStyles();
    return (_jsx(LoadingWrapper, { status: { loading }, children: _jsxs(Stack, { spacing: formStyles.spacing, justifyContent: "center", children: [_jsx(Typography, { align: "center", variant: "h5", sx: { fontWeight: 700 }, children: t('body.step.recovery.heading1') }), _jsx(Typography, { align: "center", children: t('body.step.recovery.requestResetPasswordLink.paragraph1') }), _jsx(Flow, { onSubmit: onSubmit, flow: flow }), _jsx(Link, { align: "center", href: LOGIN, children: t('body.step.recovery.loginLink') })] }) }));
};
export default Recovery;
