import { has } from 'lodash-es';
export var Severity;
(function (Severity) {
    Severity["ERROR"] = "error";
    Severity["INFO"] = "log";
    Severity["WARN"] = "warn";
})(Severity || (Severity = {}));
/**
 * Logs into the console.
 * @param msg Message.
 * @param options Options.
 * @param options.severity Severity.
 * @param options.scope Scope.
 * @param options.dump Andy data added to the logs.
 */
export const log = (msg, options) => {
    var _a;
    const severity = (_a = options === null || options === void 0 ? void 0 : options.severity) !== null && _a !== void 0 ? _a : Severity.INFO;
    const message = (options === null || options === void 0 ? void 0 : options.scope) ? `[${options.scope}]: ${msg}` : msg;
    const params = [message];
    if (has(options !== null && options !== void 0 ? options : {}, 'dump')) {
        params.push(options === null || options === void 0 ? void 0 : options.dump);
    }
    // eslint-disable-next-line no-console -- Logger implementation
    console[severity].apply(globalThis, params);
};
/**
 * Logs an exception into the console.
 * @param exception Exception.
 */
export const captureException = (exception) => {
    const severity = Severity.ERROR;
    if (exception instanceof Error) {
        log(exception.message, {
            dump: exception,
            severity,
        });
        return;
    }
    log('Exception:', {
        dump: exception,
        severity,
    });
};
