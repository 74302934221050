import { jsx as _jsx } from "react/jsx-runtime";
import * as link from 'utils/links';
import { useSession } from 'components/kratos';
import { hasUserRole } from 'utils/session';
import { LoadingWrapper } from 'components/custom';
import { Outlet, useLocation } from 'react-router-dom';
import PageNotFound from 'pages/page-not-found';
const RestrictedRouteLayout = () => {
    const session = useSession();
    const { user } = session;
    const location = useLocation();
    let hasPermission = false;
    switch (location.pathname) {
        case link.ACCOUNT_MANAGEMENT:
            hasPermission = hasUserRole(user, 'account-manager');
            break;
        case link.SCHOOL_MANAGEMENT:
            hasPermission = hasUserRole(user, 'school-manager');
            break;
        default:
            hasPermission = false;
    }
    return (_jsx(LoadingWrapper, { fullScreen: true, status: [session], children: hasPermission ? _jsx(Outlet, {}) : _jsx(PageNotFound, {}) }));
};
export default RestrictedRouteLayout;
