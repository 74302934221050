import { ApolloError } from '@apollo/client/errors';
import i18n from 'utils/i18n';
import { log, Severity } from 'utils/logger';
import { de } from 'yup-locales';
import * as yup from 'yup';
const YUP_LOCALE_MAPPING = { de };
yup.setLocale(YUP_LOCALE_MAPPING[i18n.language]);
export { yup };
/**
 * Error handler for submit operation.
 * It swallows all errors. Only logs unexpected ones.
 * @param error Error parameter for `handleSubmit` catch.
 */
export const onSubmitError = (error) => {
    if (!(error instanceof Error)) {
        log('Caught parameter is not an error', {
            dump: error,
            scope: 'onSubmitError',
            severity: Severity.ERROR,
        });
        return;
    }
    if (!(error instanceof ApolloError)) {
        log(error.toString(), {
            dump: error,
            scope: 'onSubmitError',
            severity: Severity.ERROR,
        });
    }
};
