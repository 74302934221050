import { jsx as _jsx } from "react/jsx-runtime";
import { getDeleteHolidayMutationOptions, isLoading, useDeleteHolidayMutation, } from 'api';
import { DeletionConfirmationDialog, LoadingWrapper } from 'components/custom';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
const DeleteHolidayDialog = (props) => {
    const { holidayToDelete, setHolidayToDelete } = props;
    const { t } = useTranslation('timetable', {
        keyPrefix: 'steps.vacation.cmp.DeleteHolidayDialog',
    });
    const [deleteHoliday, deleteHolidayStatus] = useDeleteHolidayMutation();
    const handleDelete = () => {
        if (holidayToDelete === null || holidayToDelete === void 0 ? void 0 : holidayToDelete.id) {
            void deleteHoliday(getDeleteHolidayMutationOptions(holidayToDelete.id)).then(() => {
                setHolidayToDelete(null);
                enqueueSnackbar(t('successMessage'));
            });
        }
    };
    return (_jsx(DeletionConfirmationDialog, { open: !!(holidayToDelete === null || holidayToDelete === void 0 ? void 0 : holidayToDelete.id), onClose: () => {
            setHolidayToDelete(null);
        }, titleProps: {
            text: t('title'),
        }, cancelButtonProps: {
            onClick: () => {
                setHolidayToDelete(null);
            },
        }, confirmButtonProps: {
            children: t('actions.delete'),
            disabled: isLoading(deleteHolidayStatus),
            onClick: handleDelete,
        }, children: _jsx(LoadingWrapper, { mutation: deleteHolidayStatus, children: t('text', {
                name: holidayToDelete === null || holidayToDelete === void 0 ? void 0 : holidayToDelete.name,
            }) }) }));
};
export default DeleteHolidayDialog;
