import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { getNodeLabel } from '../integrations/ui';
import { CheckboxFormItem } from 'components/custom';
import { getCheckboxLabel, useMappings } from '../skyla/mappings';
export const NodeInputCheckbox = ({ node, attributes, setValue, disabled, context = { customerType: 'individual' }, }) => {
    const { getMessageText } = useMappings();
    // Render a checkbox.s
    return (_jsx(_Fragment, { children: _jsx(CheckboxFormItem, { name: attributes.name, defaultChecked: attributes.value === true, onChange: (e) => {
                void setValue(e.target.checked);
            }, disabled: attributes.disabled || disabled, label: getCheckboxLabel(attributes.name, getNodeLabel(node), context.customerType), errorMessage: node.messages
                .map((message) => getMessageText(message))
                .join('\n'), required: attributes.required }) }));
};
