import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { DraggableCourseSlot, Droppable, ResizableCourseSlot, } from 'components/custom';
import { getTextColor } from 'utils/color';
import { FIRST_DAY_OF_THE_WEEK, LAST_DAY_OF_THE_WEEK, HOURS_PER_DAY, } from 'utils/date';
import { DragAndDropOperationNames } from 'utils/drag-and-drop';
const CourseCell = ({ course, day, hour, onDeleteCourseSuccess, onPlanCourseSuccess, onHourLengthChange, emptyNextCell, }) => {
    var _a, _b;
    const textColor = getTextColor(course === null || course === void 0 ? void 0 : course.color);
    const onSizeChange = (diff) => {
        if (course) {
            onHourLengthChange === null || onHourLengthChange === void 0 ? void 0 : onHourLengthChange(diff, course);
        }
    };
    return (_jsxs(Box, { role: "gridcell", title: course === null || course === void 0 ? void 0 : course.name, sx: {
            background: (_a = course === null || course === void 0 ? void 0 : course.color) !== null && _a !== void 0 ? _a : 'none',
            border: 'solid 1px',
            borderColor: 'secondary.light',
            display: 'grid',
            gridColumn: day + 1,
            gridRow: `${hour + 1} / span ${(_b = course === null || course === void 0 ? void 0 : course.lengthHours) !== null && _b !== void 0 ? _b : 1}`,
            overflow: 'hidden',
            placeItems: 'center',
            textAlign: 'center',
        }, children: [course && onPlanCourseSuccess && (_jsx(ResizableCourseSlot, { hoursLength: course.lengthHours, iconColor: textColor, onSizeChange: onSizeChange, emptyNextCell: emptyNextCell, children: _jsx(DraggableCourseSlot, { courseWithSlots: course, onPlanCourseSuccess: onPlanCourseSuccess, onDeleteCourseSuccess: onDeleteCourseSuccess }) })), !course && (_jsx(Droppable, { droppableId: `${day}-${hour}`, operationName: DragAndDropOperationNames.PLAN_COURSE, outlineColor: "primary", outlinePosition: "inside" }))] }));
};
const CoursePlannerCells = ({ data, onPlanCourseSuccess, onDeleteCourseSuccess, onHourLengthChange, }) => {
    var _a;
    const result = [];
    for (let day = FIRST_DAY_OF_THE_WEEK; day <= LAST_DAY_OF_THE_WEEK; day++) {
        for (let hour = 1; hour <= HOURS_PER_DAY;) {
            const course = data.find((c) => c.weekday === day && c.hourInDay === hour);
            const emptyNextCell = hour < HOURS_PER_DAY
                ? !data.find((currentCourse) => currentCourse.weekday === day &&
                    currentCourse.hourInDay === hour + 1)
                : false;
            result.push(_jsx(CourseCell, { course: course, day: day, hour: hour, onPlanCourseSuccess: onPlanCourseSuccess, onDeleteCourseSuccess: onDeleteCourseSuccess, onHourLengthChange: onHourLengthChange, emptyNextCell: emptyNextCell }, `${day}-${hour}`));
            hour += (_a = course === null || course === void 0 ? void 0 : course.lengthHours) !== null && _a !== void 0 ? _a : 1;
        }
    }
    return result;
};
export default CoursePlannerCells;
