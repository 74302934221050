import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { Illustration } from 'components/custom';
import { Flow, ory } from 'components/kratos';
import Image from 'public/img/illustrations/desk.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HttpStatusCodes } from 'utils/http-status-codes';
import { ACTIVATION, EMAIL_VERIFICATION, HOME, REGISTRATION_SUCCESSFUL, } from 'utils/links';
import { useSidebarLayoutStyles } from 'layouts/Sidebar/styles';
/**
 * Displays an email verification form,
 * where the user enters the code send per email.
 * This is only used when verification with code is enabled in Kratos.
 * Per default this shouldn't be visible for the user,
 * because Skyla uses verification with link.
 *
 * This page can be accessed directly to resend the activation e-mail.
 */
const Verification = () => {
    const { t } = useTranslation('auth');
    const [flow, setFlow] = useState();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    // Get ?flow=... from the URL
    const flowId = searchParams.get('flow');
    useEffect(() => {
        // If we already have a flow, do nothing.
        if (flow) {
            return;
        }
        // If ?flow=.. was in the URL, we fetch it
        if (flowId) {
            ory
                .getVerificationFlow({ id: String(flowId) })
                .then(({ data }) => {
                setFlow(data);
            })
                .catch(async (err) => {
                var _a;
                /* eslint-disable line-comment-position, no-inline-comments -- Case with pass through */
                switch ((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) {
                    case HttpStatusCodes.GONE: // Status code 410 means the request has expired - so let's load a fresh flow!
                    case HttpStatusCodes.FORBIDDEN: // Status code 403 implies some other issue (e.g. CSRF) - let's reload!
                        navigate(EMAIL_VERIFICATION);
                        return Promise.resolve();
                    default:
                        throw err;
                }
                /* eslint-enable line-comment-position, no-inline-comments -- Case with pass through */
            });
            return;
        }
        // Otherwise we initialize it
        ory
            .createBrowserVerificationFlow({
            returnTo: HOME,
        })
            .then(({ data }) => {
            setFlow(data);
        })
            .catch(async (err) => {
            var _a;
            switch ((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) {
                case HttpStatusCodes.BAD_REQUEST:
                    // Status code 400 implies the user is already signed in
                    navigate(HOME);
                    return Promise.resolve();
                default:
                    throw err;
            }
        });
    }, [flowId, navigate, flow]);
    const onSubmit = async (values) => {
        /*
         * On submission, add the flow ID to the URL but do not navigate. This prevents the user loosing
         * their data when they reload the page.
         */
        navigate(`${EMAIL_VERIFICATION}?flow=${String(flow === null || flow === void 0 ? void 0 : flow.id)}`, {
            replace: true,
        });
        return ory
            .updateVerificationFlow({
            flow: String(flow === null || flow === void 0 ? void 0 : flow.id),
            updateVerificationFlowBody: values,
        })
            .then(async () => {
            navigate(values.method === 'link' ? ACTIVATION : REGISTRATION_SUCCESSFUL);
            return Promise.resolve();
        })
            .catch(async (err) => {
            var _a, _b;
            switch ((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) {
                case HttpStatusCodes.BAD_REQUEST: {
                    // Status code 400 implies the form validation had an error
                    setFlow(err.response.data);
                    return Promise.resolve();
                }
                case HttpStatusCodes.GONE: {
                    const newFlowID = (_b = err.response.data
                        .use_flow_id) !== null && _b !== void 0 ? _b : '';
                    /*
                     * On submission, add the flow ID to the URL but do not navigate. This prevents the user loosing
                     * their data when they reload the page.
                     */
                    navigate(`${EMAIL_VERIFICATION}?flow=${newFlowID}`, {
                        replace: true,
                    });
                    return ory
                        .getVerificationFlow({
                        id: newFlowID,
                    })
                        .then(async ({ data }) => {
                        setFlow(data);
                        return Promise.resolve();
                    });
                }
                default: {
                    throw err;
                }
            }
        });
    };
    const { formStyles, illustrationStyles } = useSidebarLayoutStyles();
    return (_jsxs(Stack, { marginX: "auto", justifyContent: "center", spacing: formStyles.spacing, children: [_jsx(Illustration, { image: Image, minHeightPx: 100, minWidthPx: 200, sx: {
                    ...illustrationStyles,
                    margin: '0 auto !important',
                } }), _jsx(Typography, { align: "center", variant: "h4", color: "secondary", children: t('body.step.verification.heading1') }), !flowId && (_jsx(Typography, { align: "center", sx: { fontWeight: 700 }, children: t('body.step.verification.paragraph1') })), !flowId && (_jsx(Typography, { align: "center", sx: { fontWeight: 700 }, children: t('body.step.verification.paragraph2') })), _jsx(Flow, { onSubmit: onSubmit, flow: flow })] }));
};
export default Verification;
