import { useVacationsQuery, VacationsDocument } from 'api/generated';
import { getBaseOptions } from 'api/wrappers';
import { getApiStringFromDate, getDateFromApiString } from 'utils/date';
export const useVacationsQueryApi = (variables, options) => {
    var _a;
    const { data, ...rest } = useVacationsQuery(getBaseOptions(variables, options));
    const vacations = ((_a = data === null || data === void 0 ? void 0 : data.vacations) !== null && _a !== void 0 ? _a : [])
        .slice()
        .sort((a, b) => (a.startDate > b.startDate ? 1 : -1))
        .map((vacation) => ({
        ...vacation,
        endDate: getDateFromApiString(vacation.endDate, 'endOfDay'),
        startDate: getDateFromApiString(vacation.startDate),
    }));
    return { ...rest, data: vacations };
};
export const getCreateVacationMutationOptions = (vacation) => ({
    refetchQueries: [VacationsDocument],
    variables: {
        input: {
            ...vacation,
            endDate: getApiStringFromDate(vacation.endDate),
            startDate: getApiStringFromDate(vacation.startDate),
        },
    },
});
export const getUpdateVacationMutationOptions = (vacation) => ({
    variables: {
        input: {
            ...vacation,
            endDate: getApiStringFromDate(vacation.endDate),
            startDate: getApiStringFromDate(vacation.startDate),
        },
    },
});
export const getDeleteVacationMutationOptions = (id) => ({
    refetchQueries: [VacationsDocument],
    variables: {
        id,
    },
});
export { useCreateVacationMutation, useUpdateVacationMutation, useDeleteVacationMutation, } from 'api/generated';
