import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, } from '@mui/material';
import { Link } from 'components/custom';
import { useMatch } from 'react-router-dom';
/**
 * Extracts a route match pattern to the root,
 * so that it can match with all its children.
 * Example: /settings/timetable -> /settings/timetable/*
 * @param href Navigation link.
 * @returns Match pattern.
 */
const getRootMatchPattern = (href) => {
    const limit = 3;
    const rootPath = href.split('/', limit).join('/');
    return `${rootPath}/*`;
};
const TabsNavigationItem = ({ href = '', Icon, text, }) => {
    const matchExact = useMatch(href);
    const matchRoot = useMatch(getRootMatchPattern(href));
    const isActive = !!matchExact || !!matchRoot;
    return (_jsx(ListItem, { disablePadding: true, children: _jsxs(ListItemButton, { "aria-current": isActive ? 'page' : false, component: Link, href: href, title: text, sx: {
                '&:hover': {
                    backgroundColor: isActive ? 'secondary.main' : null,
                },
                backgroundColor: isActive ? 'secondary.main' : 'inactive.main',
                borderColor: 'transparent',
                borderRightColor: 'primary.main',
                borderStyle: 'solid',
                borderWidth: (theme) => theme.spacing(theme.spacingFactors.quarter),
                color: isActive ? 'common.white' : 'primary.main',
            }, children: [_jsx(ListItemIcon, { children: _jsx(Icon, {}) }), _jsx(ListItemText, { primary: text, primaryTypographyProps: { noWrap: true } })] }) }));
};
export default TabsNavigationItem;
