/*
 * This is a partial copy of `@ory/integrations/ui`:
 * https://github.com/ory/integrations/blob/main/src/ui/index.ts
 * The package `@ory/integrations` has a dependency to `next`, but none of the UI functions require NextJS.
 * As long as there is no package from Ory with the UI functions for React this copy should be used.
 */
/* eslint-disable -- ESLint is disabled in this file to keep the original formatting */
/**
 * Returns the node's label.
 *
 * @param node
 * @return label
 */
export const getNodeLabel = (node) => {
    var _a, _b, _c;
    const attributes = node.attributes;
    if (isUiNodeAnchorAttributes(attributes)) {
        return attributes.title.text;
    }
    if (isUiNodeImageAttributes(attributes)) {
        return ((_a = node.meta.label) === null || _a === void 0 ? void 0 : _a.text) || '';
    }
    if (isUiNodeInputAttributes(attributes)) {
        if ((_b = attributes.label) === null || _b === void 0 ? void 0 : _b.text) {
            return attributes.label.text;
        }
    }
    return ((_c = node.meta.label) === null || _c === void 0 ? void 0 : _c.text) || '';
};
/**
 * A TypeScript type guard for nodes of the type <a>
 *
 * @param attrs
 */
export function isUiNodeAnchorAttributes(attrs) {
    return attrs.node_type === 'a';
}
/**
 * A TypeScript type guard for nodes of the type <img>
 *
 * @param attrs
 */
export function isUiNodeImageAttributes(attrs) {
    return attrs.node_type === 'img';
}
/**
 * A TypeScript type guard for nodes of the type <input>
 *
 * @param attrs
 */
export function isUiNodeInputAttributes(attrs) {
    return attrs.node_type === 'input';
}
/**
 * A TypeScript type guard for nodes of the type <span>{text}</span>
 *
 * @param attrs
 */
export function isUiNodeTextAttributes(attrs) {
    return attrs.node_type === 'text';
}
/**
 * A TypeScript type guard for nodes of the type <script>
 *
 * @param attrs
 */
export function isUiNodeScriptAttributes(attrs) {
    return attrs.node_type === 'script';
}
/**
 * Returns a node's ID.
 *
 * @param attributes
 */
export function getNodeId({ attributes }) {
    if (isUiNodeInputAttributes(attributes)) {
        return attributes.name;
    }
    else {
        return attributes.id;
    }
}
