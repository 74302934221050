import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'components/custom';
import { Cancel, Edit, TaskAlt } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
const Actions = ({ isEditing, onEdit, onSave, onCancel, }) => {
    const { t } = useTranslation('common');
    return isEditing ? (_jsxs(Stack, { direction: "row", spacing: 2, justifyContent: "end", children: [_jsx(Button, { color: "error", startIcon: _jsx(Cancel, {}), onClick: onCancel, children: t('body.button.cancel') }), _jsx(Button, { color: "secondary", startIcon: _jsx(TaskAlt, {}), onClick: onSave, children: t('body.button.save') })] })) : (_jsx(Button, { color: "secondary", startIcon: _jsx(Edit, {}), onClick: onEdit, sx: { alignSelf: 'end' }, children: t('body.button.edit') }));
};
export default Actions;
