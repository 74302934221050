import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'components/custom';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMatch } from 'react-router-dom';
/**
 * Extracts a route match pattern to the root,
 * so that it can match with all its children.
 * Example: /settings/account-data -> /settings/*
 * @param href Navigation link.
 * @returns Match pattern.
 */
const getRootMatchPattern = (href) => {
    const limit = 2;
    const rootPath = href.split('/', limit).join('/');
    return `${rootPath}/*`;
};
const NavigationItem = ({ expanded, href = '', Icon, text, ...props }) => {
    const matchExact = useMatch(href);
    const matchRoot = useMatch(getRootMatchPattern(href));
    const isActive = !!matchExact || !!matchRoot;
    return (_jsx(ListItem, { disablePadding: true, ...props, children: _jsxs(ListItemButton, { href: href, component: Link, selected: isActive, "aria-current": isActive ? 'page' : false, children: [_jsx(ListItemIcon, { ...(expanded ? {} : { title: text }), children: _jsx(Icon, {}) }), expanded && (_jsx(ListItemText, { primary: text, primaryTypographyProps: { noWrap: true } }))] }) }));
};
const StyledNavigationItem = styled(NavigationItem)(({ theme: { spacing, palette }, expanded, }) => {
    const scalingFactor = 0.5;
    return {
        '& .MuiListItemButton-root': {
            '&.Mui-selected': {
                backgroundColor: 'transparent',
                borderLeftColor: palette.secondary.main,
                color: palette.secondary.main,
            },
            borderLeft: `transparent solid ${spacing(scalingFactor)}`,
            borderRight: `transparent solid ${spacing(scalingFactor)}`,
            justifyContent: expanded ? 'flex-start' : 'center',
        },
        '& .MuiListItemIcon-root': {
            minWidth: !expanded && 0,
        },
        '& .MuiListItemText-root': {
            margin: 0,
        },
    };
});
export default StyledNavigationItem;
