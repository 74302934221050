import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { formatDate, formatDateToDateTimeAttribute } from 'utils/date';
export const Release = ({ version }) => {
    const { t } = useTranslation('releases');
    const transProps = { t, tOptions: { keySeparator: '::', nsSeparator: '--' } };
    const releases = t('releases', {
        returnObjects: true,
    });
    const release = releases[version];
    const date = release.date ? parseISO(release.date) : undefined;
    const { text, list } = release;
    const title = `${t('product')} ${version}`;
    return (_jsxs(Box, { children: [_jsx(Typography, { fontSize: "larger", children: title }), date && (_jsx(Typography, { color: "secondary.main", fontWeight: "bold", children: _jsx("time", { dateTime: formatDateToDateTimeAttribute(date), children: formatDate(date) }) })), text && (_jsx(Typography, { fontSize: "smaller", children: _jsx(Trans, { ...transProps, i18nKey: `releases::${version}::text` }) })), list && list.length > 0 ? (_jsx(Typography, { component: "div", children: _jsx("ul", { children: list.map((item, index) => {
                        return (_jsx("li", { children: _jsx(Trans, { ...transProps, i18nKey: `releases::${version}::list::${index}` }) }, index));
                    }) }) })) : null] }));
};
