import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Book, CalendarViewWeek, Dashboard, Group, LocationCity, ManageAccounts, } from '@mui/icons-material';
import { Divider, List } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as links from 'utils/links';
import NavigationItem from './NavigationItem';
import { hasUserRole } from 'utils/session';
const Navigation = ({ drawerOpen, user, }) => {
    const { t } = useTranslation('common');
    if (!user) {
        return (_jsx(NavigationItem, { text: t('body.navigation.home'), href: links.HOME, Icon: Dashboard, expanded: drawerOpen }));
    }
    return (_jsxs("nav", { children: [_jsxs(List, { children: [_jsx(NavigationItem, { text: t('body.navigation.home'), href: links.HOME, Icon: Dashboard, expanded: drawerOpen }), _jsx(NavigationItem, { text: t('body.navigation.data'), href: links.DATA, Icon: Book, expanded: drawerOpen })] }), _jsx(Divider, { variant: "middle" }), _jsxs(List, { children: [_jsx(NavigationItem, { text: t('body.navigation.settings'), href: links.ACCOUNT_DATA, Icon: ManageAccounts, expanded: drawerOpen }), _jsx(NavigationItem, { text: t('body.navigation.timetable'), href: links.SCHOOL_YEARS, Icon: CalendarViewWeek, expanded: drawerOpen }), hasUserRole(user, 'account-manager') && (_jsx(NavigationItem, { text: t('body.navigation.accountManagement'), href: links.ACCOUNT_MANAGEMENT, Icon: Group, expanded: drawerOpen })), hasUserRole(user, 'school-manager') && (_jsx(NavigationItem, { text: t('body.navigation.schoolManagement'), href: links.SCHOOL_MANAGEMENT, Icon: LocationCity, expanded: drawerOpen }))] })] }));
};
export default Navigation;
