import { has, isObject } from 'lodash-es';
/**
 * Checks if a user has the specified role.
 * @param user The user.
 * @param role The role that should get checked.
 * @returns `true` if the user has the role; otherwise `false`.
 */
export const hasUserRole = (user, role) => {
    if (!user) {
        return false;
    }
    return user.roles.includes(role);
};
/**
 * Checks if traits contain user data.
 * @param traits Session identity traits.
 * @returns `true` for user traits; otherwise `false`.
 */
export const hasUserTraits = (traits) => {
    return isObject(traits) && has(traits, 'name');
};
/**
 * Formats user's name.
 * @param user User object.
 * @returns User's name.
 */
export const formatUserName = (user) => {
    const { first, last } = user.name;
    return `${first} ${last}`;
};
