import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Box, List } from '@mui/material';
import { AccountBalance, Person } from '@mui/icons-material';
import TabsNavigationItem from './TabsNavigationItem';
import * as links from 'utils/links';
const TabsNavigation = () => {
    const { t } = useTranslation('settings', {
        keyPrefix: 'cmp.TabsNavigation',
    });
    return (_jsx(Box, { component: "nav", sx: {
            backgroundColor: (theme) => theme.palette.common.white,
            pb: 2,
            position: 'sticky',
            top: (theme) => theme.spacing(theme.spacingFactors.six),
            zIndex: 1,
        }, children: _jsxs(List, { disablePadding: true, sx: {
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, 1fr))',
            }, children: [_jsx(TabsNavigationItem, { href: links.ACCOUNT_DATA, Icon: Person, text: t('accountData') }), _jsx(TabsNavigationItem, { href: links.PLANS_AND_PAYMENTS, Icon: AccountBalance, text: t('plansAndPayments') })] }) }));
};
export default TabsNavigation;
