import { jsx as _jsx } from "react/jsx-runtime";
import { isObject } from 'lodash-es';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { log, Severity } from 'utils/logger';
import { getVersion } from 'utils/config';
/**
 * Interval in which the remote version on the server will be checked.
 */
const remoteVersionLoadIntervalMs = 600000;
/**
 * Interval ID for remote version checks.
 */
let remoteVersionLoadIntervalId = 0;
/**
 * The latest version on the server.
 * At the beginning the same as the loaded version.
 */
let remoteVersion = getVersion();
/**
 * Returns the last seen remote version.
 * @returns Version.
 */
export const getRemoteVersion = () => remoteVersion;
/**
 * Loads the remote version.
 */
const loadRemoteVersion = () => {
    log('Checking for FE updates...');
    fetch(`/version.json?_dc=${Date.now()}`)
        .then(async (response) => {
        return response.json();
    })
        .then((json) => {
        if (isObject(json) && 'web' in json) {
            remoteVersion = json.web;
        }
        else {
            log('Wrong FE update format', {
                dump: json,
                severity: Severity.ERROR,
            });
        }
    })
        .catch((error) => {
        log('FE updates check failed', {
            dump: error,
            severity: Severity.ERROR,
        });
    });
};
/**
 * Starts the update checker.
 */
const startUpdateChecker = () => {
    if (!remoteVersionLoadIntervalId) {
        remoteVersionLoadIntervalId = window.setInterval(loadRemoteVersion, remoteVersionLoadIntervalMs);
    }
};
/**
 * Updater component updates the FE to the version on the server.
 * A periodic check fetches the version on the server.
 * When the route changes it performs a page reload to force loading new FE version.
 * The update happens only on route change, because they are idempotent operations,
 * so no user data will be lost.
 * Location hash changes are ignored, because they are used to reflect temporary UI changes.
 */
export const Updater = () => {
    const location = useLocation();
    const currentRoute = location.pathname;
    const [lastRoute, setLastRoute] = React.useState(currentRoute);
    React.useLayoutEffect(() => {
        if (lastRoute !== currentRoute) {
            const currentVersion = getVersion();
            if (remoteVersion !== currentVersion) {
                log(`Updating FE "${currentVersion}" to version "${remoteVersion}"`);
                window.location.reload();
            }
            setLastRoute(currentRoute);
        }
    }, [currentRoute, lastRoute]);
    React.useEffect(() => {
        startUpdateChecker();
    }, []);
    return _jsx(Outlet, {});
};
