import { TagsDocument, useTagFilterOptionsQuery, useTagsQuery, } from 'api/generated';
export const useTagsQueryApi = () => {
    var _a;
    const { data, ...rest } = useTagsQuery();
    return { data: (_a = data === null || data === void 0 ? void 0 : data.tags) !== null && _a !== void 0 ? _a : [], ...rest };
};
export const useTagFilterOptionsQueryApi = () => {
    var _a;
    const { data, ...rest } = useTagFilterOptionsQuery();
    return { data: (_a = data === null || data === void 0 ? void 0 : data.tags) !== null && _a !== void 0 ? _a : [], ...rest };
};
export const getUpdateObjectTagsMutationOptions = (objectId, tags) => ({
    variables: {
        objectId,
        tags,
    },
    refetchQueries: [TagsDocument],
});
export { useUpdateObjectTagsMutation } from 'api/generated';
