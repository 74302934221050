import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Delete, Edit } from '@mui/icons-material';
import { Box, IconButton, Radio, Stack, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { Button, DataGrid, Tooltip } from 'components/custom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/date';
const TimetableDataGrid = ({ columns = [], compactButtons = false, 'data-testid': dataTestId, hasTimeRange, rows, nameColumn = { field: 'name', flex: 0.6 }, onDeleteClick, onEditClick, onSelectClick, label, noItemsMessage, rowSelectionModel, isDeleteDisabled, ...props }) => {
    const { t } = useTranslation('common', { keyPrefix: 'body' });
    const apiRef = useGridApiRef();
    const columnsWithActions = [nameColumn, ...columns];
    const onRowSelectionModelChange = (newRowSelectionModel) => {
        if (!newRowSelectionModel.length) {
            return;
        }
        const selectedRows = apiRef.current.getSelectedRows();
        const newSingleSelection = newRowSelectionModel.find((id) => !selectedRows.get(id));
        const selectedItemData = rows.find((item) => item.id === newSingleSelection);
        if (selectedItemData) {
            onSelectClick === null || onSelectClick === void 0 ? void 0 : onSelectClick(selectedItemData.id);
        }
    };
    const getTimeRange = (row) => {
        if (row.date) {
            return formatDate(row.date);
        }
        const startDate = row.startDate ? formatDate(row.startDate) : '';
        const endDateExp = row.endDate
            ? ` ${t('to')} ${formatDate(row.endDate)}`
            : '';
        return startDate + endDateExp;
    };
    if (hasTimeRange) {
        columnsWithActions.push({
            field: 'timeRange',
            flex: 0.5,
            renderCell: ({ row }) => getTimeRange(row),
        });
    }
    const compactButtonWidth = 50;
    const labelledButtonWidth = 100;
    if (onEditClick) {
        columnsWithActions.push({
            field: 'edit',
            renderCell: (params) => compactButtons ? (_jsx(Tooltip, { title: t('button.edit'), children: _jsx(IconButton, { "aria-label": t('button.edit'), onClick: () => {
                        onEditClick(params.row);
                    }, children: _jsx(Edit, { color: "secondary" }) }) })) : (_jsx(Button, { color: "secondary", startIcon: _jsx(Edit, {}), onClick: () => {
                    onEditClick(params.row);
                }, children: t('button.edit') })),
            sortable: false,
            width: compactButtons ? compactButtonWidth : labelledButtonWidth,
        });
    }
    if (onDeleteClick) {
        columnsWithActions.push({
            field: 'delete',
            renderCell: (params) => {
                var _a;
                const disabled = (_a = isDeleteDisabled === null || isDeleteDisabled === void 0 ? void 0 : isDeleteDisabled(params.row.id)) !== null && _a !== void 0 ? _a : false;
                return compactButtons ? (_jsx(Tooltip, { title: t('button.delete'), children: _jsx(Box, { children: _jsx(IconButton, { disabled: disabled, "aria-label": t('button.delete'), onClick: () => {
                                onDeleteClick(params.row);
                            }, color: "error", children: _jsx(Delete, {}) }) }) })) : (_jsx(Button, { disabled: disabled, color: "error", startIcon: _jsx(Delete, {}), onClick: () => {
                        onDeleteClick(params.row);
                    }, children: t('button.delete') }));
            },
            sortable: false,
            width: compactButtons ? compactButtonWidth : labelledButtonWidth,
        });
    }
    const id = React.useId();
    return (_jsxs(Stack, { "data-testid": dataTestId, spacing: 2, display: "grid", gridTemplateRows: "auto 1fr", height: "100%", width: "100%", sx: { p: 0.5 }, children: [_jsx(Typography, { id: id, color: "secondary", variant: "h6", children: label }), rows.length ? (_jsx(DataGrid, { "aria-labelledby": id, hideFooter: true, apiRef: apiRef, columns: columnsWithActions, rows: rows, sx: {
                    '& .MuiDataGrid-row:nth-of-type(odd)': {
                        backgroundColor: (theme) => theme.palette.inactive.main,
                        // eslint-disable-next-line sort-keys -- nesting
                        '&:hover': {
                            backgroundColor: (theme) => theme.palette.action.hover,
                        },
                    },
                    border: 'none',
                    fontSize: '0.9rem',
                }, slots: {
                    baseCheckbox: Radio,
                    columnHeaders: React.forwardRef(() => null),
                }, ...props, ...(onSelectClick && {
                    checkboxSelection: true,
                    disableRowSelectionOnClick: false,
                    onRowSelectionModelChange,
                    rowSelectionModel,
                }) })) : (_jsx(Typography, { "data-testid": `${dataTestId}Empty`, children: noItemsMessage }))] }));
};
export default TimetableDataGrid;
