import { useResponsiveness } from 'utils/responsiveness';
export const useSidebarLayoutStyles = () => {
    const { isExtraSmallViewport, isLargeViewport } = useResponsiveness();
    const formStyles = isExtraSmallViewport
        ? {
            flexGrow: 1,
            py: '5vh',
            spacing: 2,
        }
        : {
            flexGrow: 0,
            py: 6,
            spacing: 5,
        };
    const illustrationStyles = {
        height: isLargeViewport ? '15vh' : '20%',
        width: '40%',
    };
    return { formStyles, illustrationStyles };
};
