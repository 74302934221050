import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Square } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, } from '@mui/material';
import { isLoading, SKIP, useObjectLessonsQueryApi } from 'api';
import { Link } from 'components/custom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { formatDateTime } from 'utils/date';
import { getFileIcon } from 'utils/file-icons';
import { getLessonUrl } from 'utils/links';
const FileObjectLessons = ({ fileId, }) => {
    var _a;
    const { t } = useTranslation('object', {
        keyPrefix: 'cmp.FileObjectLessons',
    });
    const { pathname } = useLocation();
    const [expanded, setExpanded] = React.useState(false);
    const objectLessonsStatus = useObjectLessonsQueryApi(fileId ? { id: fileId } : SKIP);
    const { name, mimeType, lessons } = (_a = objectLessonsStatus.data) !== null && _a !== void 0 ? _a : {};
    const lessonsAvailable = !!lessons && lessons.length > 0;
    const Icon = mimeType ? getFileIcon(mimeType) : undefined;
    React.useEffect(() => {
        setExpanded(lessonsAvailable);
    }, [lessonsAvailable, setExpanded]);
    const id = React.useId();
    return (_jsxs(Accordion, { disabled: !lessonsAvailable, expanded: expanded, onChange: (event, newExpanded) => {
            setExpanded(newExpanded);
        }, children: [_jsx(AccordionSummary, { "aria-controls": `lessons-content-${id}`, id: `lessons-header-${id}`, children: _jsxs(Box, { sx: {
                        alignContent: 'space-between',
                        alignItems: 'center',
                        display: 'flex',
                    }, children: [_jsx(Typography, { fontWeight: "bold", mr: 1, children: t('title') }), isLoading(objectLessonsStatus) && (_jsx(CircularProgress, { size: 20, "aria-busy": true }))] }) }), _jsx(AccordionDetails, { children: lessons && (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { alignItems: 'center', display: 'flex' }, children: [Icon ? _jsx(Icon, {}) : null, _jsx(Typography, { ml: 1, children: name })] }), _jsx(List, { disablePadding: true, dense: true, children: lessons.map((lesson, index) => {
                                const lessonUrl = getLessonUrl(lesson.id);
                                return (_jsxs(ListItem, { disablePadding: true, disableGutters: true, children: [_jsx(ListItemIcon, { children: _jsx(Square, { fontSize: "large", htmlColor: lesson.course.color }) }), _jsx(ListItemButton, { component: Link, href: lessonUrl, selected: lessonUrl === pathname, children: _jsx(ListItemText, { primary: lesson.course.name, secondary: formatDateTime(lesson.startDate, true) }) })] }, index));
                            }) })] })) })] }));
};
export default FileObjectLessons;
