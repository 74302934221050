import { useApolloClient } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HttpStatusCodes } from 'utils/http-status-codes';
import { LOGIN, LOGOUT } from 'utils/links';
import { log, Severity } from 'utils/logger';
import { hasUserTraits } from 'utils/session';
import { removeAllData as removeAllTemporaryData } from 'utils/temporary-storage';
import ory from './sdk';
/**
 * Returns a function which will log the user out.
 * @returns A function that performs the logout.
 */
export const useLogoutHandler = () => {
    const navigate = useNavigate();
    const client = useApolloClient();
    const { t } = useTranslation('auth', { keyPrefix: 'body.step.logout' });
    return React.useCallback(async () => {
        return ory
            .createBrowserLogoutFlow()
            .then(async ({ data }) => {
            return ory
                .updateLogoutFlow({ token: data.logout_token })
                .then(async () => {
                removeAllTemporaryData();
                return client
                    .clearStore()
                    .then(() => undefined)
                    .finally(() => {
                    navigate(LOGOUT);
                });
            });
        })
            .catch(async (err) => {
            var _a;
            if (((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === HttpStatusCodes.UNAUTHORIZED) {
                // User not logged in.
                return Promise.resolve();
            }
            enqueueSnackbar(t('logoutError'), { variant: 'error' });
            return Promise.reject(err);
        });
    }, [navigate, t, client]);
};
/**
 * Hook for loading user session.
 * This should be used only in default layout.
 * @returns Session data with load state.
 */
export const useSession = () => {
    const [session, setSession] = React.useState(undefined);
    const [user, setUser] = React.useState(undefined);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const navigate = useNavigate();
    React.useEffect(() => {
        if (!loading) {
            return;
        }
        ory
            .toSession()
            .then((response) => {
            const sessionData = response.data;
            setSession(sessionData);
            return sessionData;
        })
            .then((sessionData) => {
            if (sessionData.identity &&
                hasUserTraits(sessionData.identity.traits)) {
                let roles = [];
                const metadata = sessionData.identity.metadata_public;
                if (metadata &&
                    'roles' in metadata &&
                    Array.isArray(metadata.roles)) {
                    ({ roles } = metadata);
                }
                setUser({
                    email: sessionData.identity.traits.email,
                    id: sessionData.identity.id,
                    name: sessionData.identity.traits.name,
                    roles,
                });
            }
            else {
                log('Cannot find user identity', {
                    dump: sessionData,
                    severity: Severity.ERROR,
                });
            }
        })
            .catch(async (err) => {
            var _a;
            setError(err);
            switch ((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) {
                case HttpStatusCodes.FORBIDDEN:
                /*
                 * This is a legacy error code thrown. See code 422 for more details.
                 */
                // eslint-disable-next-line no-fallthrough -- Comment in case
                case HttpStatusCodes.UNAUTHORIZED:
                    // Redirect to login page, the user is not logged in.
                    removeAllTemporaryData();
                    navigate(LOGIN);
                    return Promise.resolve();
                case HttpStatusCodes.UNPROCESSABLE_ENTITY:
                    /*
                     * This status code is returned when we are trying to
                     * validate a session which has not yet completed
                     * its second factor.
                     */
                    navigate(`${LOGIN}?aal=aal2`);
                    return Promise.resolve();
                default:
                    // Something else happened!
                    return Promise.reject(err);
            }
        })
            .finally(() => {
            setLoading(false);
        });
    }, [loading, navigate]);
    return { error: error !== null && error !== void 0 ? error : undefined, loading, session, user };
};
