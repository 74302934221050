import { ApolloError } from '@apollo/client/errors';
import { has, isObject, noop } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { log, Severity } from 'utils/logger';
/**
 * Checks if given error is a REST API response error.
 * @param error Error object.
 * @returns `true` for REST response error; otherwise `false.
 */
const isRestResponseError = (error) => {
    return isObject(error) && 'message' in error;
};
/**
 * Checks if given error is a GraphQL API response error.
 * @param error Error object.
 * @returns `true` for GraphQL API response error; otherwise `false.
 */
const isGqlApiError = (error) => {
    return (has(error, 'extensions.code') && typeof error.extensions.code === 'string');
};
/**
 * Normalizes an API error object by extracting the error code and message.
 * @param error Error object.
 * @returns API error object.
 */
const getApiError = (error) => {
    try {
        // GraphQL API
        if (error instanceof ApolloError) {
            const gqlError = error.graphQLErrors[0];
            const message = gqlError.message;
            if (isGqlApiError(gqlError)) {
                return {
                    code: gqlError.extensions.code,
                    message,
                };
            }
        }
        // REST API
        if (isRestResponseError(error)) {
            return {
                code: error.key,
                message: error.message,
            };
        }
        return undefined;
    }
    catch (err) {
        return undefined;
    }
};
/**
 * Extracts the error message from an API error.
 * @param error Error object.
 * @param t Translate function.
 * @param fallback Fallback message.
 */
const getErrorMessage = (error, t, fallback) => {
    const apiError = getApiError(error);
    // Nothing that can be used in API response.
    if (!apiError) {
        log('Unhandled API error', {
            dump: error,
        });
        return fallback;
    }
    // Use error code.
    if (apiError.code) {
        const message = t(apiError.code);
        // Check for missing translation.
        if (message) {
            return message;
        }
        else {
            log(`Not translation for API error code: ${apiError.code}`, {
                severity: Severity.WARN,
            });
        }
    }
    // Use API message (will be probably in english).
    if (apiError.message) {
        // If the message has to be used due to missing code.
        if (!apiError.code) {
            log(`Missing error code for error message: ${apiError.message}`, {
                severity: Severity.WARN,
            });
        }
        return apiError.message;
    }
    log('Unknown API error response', {
        dump: error,
        severity: Severity.WARN,
    });
    return fallback;
};
/**
 * Hook for getting the translated API error.
 * @returns A collection of functions.
 */
export const useApiError = () => {
    const { t } = useTranslation(['api', 'common']);
    return {
        getErrorMessage: (error) => {
            return getErrorMessage(error, t, t('body.error.unexpected', { ns: 'common' }));
        },
    };
};
export const useOperationErrorHandler = () => {
    const { t } = useTranslation('api');
    const { enqueueSnackbar } = useSnackbar();
    return (error) => {
        if (error instanceof Error) {
            const msg = getErrorMessage(error, t, t('fallbackOperationError'));
            enqueueSnackbar(msg, {
                variant: 'error',
            });
        }
        else {
            log('Not an Error instance!', {
                dump: error,
                severity: Severity.ERROR,
            });
        }
    };
};
/**
 * Checks if given error is a validation error.
 * @param apolloError Error object or an array of GraphQL errors.
 * @returns `true` for validation error, otherwise `false`.
 */
export const isValidationError = (apolloError) => {
    const errors = apolloError instanceof ApolloError
        ? apolloError.graphQLErrors
        : apolloError;
    return !!errors.find((error) => isGqlApiError(error) &&
        error.extensions.code !== 'common.unexpected_server_error');
};
/**
 * Suppresses validation errors.
 * Use in cases where the validation errors are handled locally.
 */
export const onValidationError = noop;
