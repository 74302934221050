import { jsx as _jsx } from "react/jsx-runtime";
import { TextFormItem } from 'components/custom';
import { getIcon, getInputMode, useMappings } from '../skyla/mappings';
export const NodeInputDefault = (props) => {
    var _a;
    const { getMessageText, getPlaceholder, getLabel } = useMappings();
    const { node, attributes, value = '', setValue, disabled } = props;
    const error = node.messages
        .map((message) => message.type === 'error' ? getMessageText(message) : '')
        .join('\n');
    const icon = getIcon(attributes.name);
    // Render a generic text input field.
    return (_jsx(TextFormItem, { label: getLabel(attributes.name, (_a = node.meta.label) === null || _a === void 0 ? void 0 : _a.text), onChange: (e) => {
            void setValue(e.target.value);
        }, type: attributes.type, name: attributes.name, value: value, disabled: attributes.disabled || disabled, errorMessage: error, placeholder: getPlaceholder(attributes.name), required: attributes.required, StartAdornmentIcon: icon, inputProps: {
            inputMode: getInputMode(attributes.name),
        } }));
};
