import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddCircle, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useSchoolYearsQueryApi } from 'api';
import { Button, LoadingWrapper, TimetableDataGrid, Tooltip, } from 'components/custom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { findMostRelevantTimePeriod } from 'utils/date';
import { setTitle } from 'utils/head';
import { getLocationHash, replaceLocationHash } from 'utils/routing';
import DeleteSchoolYearDialog from './DeleteSchoolYearDialog';
import SaveSchoolYearDialog from './SaveSchoolYearDialog';
import SchoolYearTemplateDialog from './SchoolYearTemplateDialog';
const SchoolYears = () => {
    const { t: headTranslate } = useTranslation('timetable', {
        keyPrefix: 'head',
    });
    React.useEffect(() => {
        setTitle(headTranslate('title.schoolYears'));
    }, [headTranslate]);
    const { t } = useTranslation('timetable', {
        keyPrefix: 'steps.schoolYears',
    });
    const { t: commonTranslation } = useTranslation('common');
    const { handleNext } = useOutletContext();
    const schoolYearIdParam = getLocationHash();
    const [selectedSchoolYear, setSelectedSchoolYear] = React.useState(schoolYearIdParam);
    const selectSchoolYearId = (schoolYearId) => {
        replaceLocationHash(schoolYearId);
        setSelectedSchoolYear(schoolYearId);
    };
    const onDataChangeSetSelectedSchoolYear = (schoolYears) => {
        var _a, _b;
        if (selectedSchoolYear &&
            schoolYears.find((schoolYear) => schoolYear.id === selectedSchoolYear)) {
            return;
        }
        selectSchoolYearId((_b = (_a = findMostRelevantTimePeriod(schoolYears)) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '');
    };
    const status = useSchoolYearsQueryApi({
        onCompleted: onDataChangeSetSelectedSchoolYear,
    });
    const [activeSchoolYear, setActiveSchoolYear] = React.useState(null);
    const [schoolYearToDelete, setSchoolYearToDelete] = React.useState(null);
    const [openSchoolYearTemplateDialog, setOpenSchoolYearTemplateDialog] = React.useState(false);
    return (_jsx(LoadingWrapper, { status: status, children: _jsxs(Stack, { spacing: 2, flexGrow: "1", justifyContent: "space-between", children: [_jsxs(Stack, { spacing: 2, children: [status.data.length ? (_jsx(Typography, { children: t('explanation') })) : null, _jsx(TimetableDataGrid, { "data-testid": "schoolYears", label: _jsx("strong", { children: t('sectionTitle') }), rows: status.data, hasTimeRange: true, onDeleteClick: (schoolYear) => {
                                setSchoolYearToDelete(schoolYear);
                            }, onEditClick: (schoolYear) => {
                                setActiveSchoolYear(schoolYear);
                            }, onSelectClick: selectSchoolYearId, rowSelectionModel: [selectedSchoolYear], noItemsMessage: t('noItemsMessage') }), _jsx(SaveSchoolYearDialog, { close: () => {
                                setActiveSchoolYear(null);
                            }, activeSchoolYear: activeSchoolYear, setSelectedSchoolYear: selectSchoolYearId }), _jsx(DeleteSchoolYearDialog, { schoolYearToDelete: schoolYearToDelete, setSchoolYearToDelete: setSchoolYearToDelete }), _jsx(SchoolYearTemplateDialog, { open: openSchoolYearTemplateDialog, close: () => {
                                setOpenSchoolYearTemplateDialog(false);
                            } }), _jsx(Tooltip, { title: t('addTemplateSchoolYearButtonTooltip'), placement: "left", children: _jsx(Button, { color: "secondary", "data-testid": "addSchoolYearTemplate", onClick: () => {
                                    setOpenSchoolYearTemplateDialog(true);
                                }, startIcon: _jsx(AddCircle, {}), sx: { alignSelf: 'end' }, children: t('addTemplateSchoolYearButton') }) }), _jsx(Tooltip, { title: t('addCustomSchoolYearButtonTooltip'), placement: "left", children: _jsx(Button, { color: "secondary", "data-testid": "addSchoolYear", onClick: () => {
                                    setActiveSchoolYear({});
                                }, startIcon: _jsx(AddCircle, {}), sx: { alignSelf: 'end' }, children: t('addCustomSchoolYearButton') }) })] }), _jsx(Stack, { direction: "row", justifyContent: "end", children: _jsx(Button, { variant: "contained", color: "success", disabled: !selectedSchoolYear, onClick: handleNext(selectedSchoolYear), endIcon: _jsx(KeyboardDoubleArrowRight, {}), "data-testid": "next", children: commonTranslation('body.button.next') }) })] }) }));
};
export default SchoolYears;
