import { SchoolYearsDocument, useSchoolYearQuery, useSchoolYearsQuery, } from 'api/generated';
import { getBaseOptions, mapBaseOptions } from 'api/wrappers';
import { getApiStringFromDate, getDateFromApiString } from 'utils/date';
export const useSchoolYearsQueryApi = (options) => {
    const mapData = (data) => {
        var _a;
        return ((_a = data === null || data === void 0 ? void 0 : data.schoolYears) !== null && _a !== void 0 ? _a : [])
            .slice()
            .sort((a, b) => (a.startDate > b.startDate ? 1 : -1))
            .map((schoolYear) => ({
            ...schoolYear,
            endDate: getDateFromApiString(schoolYear.endDate, 'endOfDay'),
            startDate: getDateFromApiString(schoolYear.startDate),
        }));
    };
    const baseOptions = mapBaseOptions(options, mapData);
    const { data, ...rest } = useSchoolYearsQuery(baseOptions);
    return { ...rest, data: mapData(data) };
};
export const useSchoolYearQueryApi = (variables, options) => {
    const { data, ...rest } = useSchoolYearQuery(getBaseOptions(variables, options));
    const schoolYearApiData = data === null || data === void 0 ? void 0 : data.schoolYear;
    const schoolYear = schoolYearApiData
        ? {
            ...schoolYearApiData,
            endDate: getDateFromApiString(schoolYearApiData.endDate, 'endOfDay'),
            startDate: getDateFromApiString(schoolYearApiData.startDate),
        }
        : undefined;
    return {
        ...rest,
        data: schoolYear,
    };
};
export const getCreateSchoolYearMutationOptions = (schoolYear) => ({
    refetchQueries: [SchoolYearsDocument],
    variables: {
        input: {
            ...schoolYear,
            endDate: getApiStringFromDate(schoolYear.endDate),
            startDate: getApiStringFromDate(schoolYear.startDate),
        },
    },
});
export const getUpdateSchoolYearMutationOptions = (schoolYear) => ({
    variables: {
        input: {
            id: schoolYear.id,
            name: schoolYear.name,
        },
    },
});
export const getDeleteSchoolYearMutationOptions = (id) => ({
    refetchQueries: [SchoolYearsDocument],
    variables: {
        id,
    },
});
export const getCreateSchoolYearForRegionMutationOptions = (input) => ({
    refetchQueries: [SchoolYearsDocument],
    variables: {
        input,
    },
});
export { useCreateSchoolYearMutation, useCreateSchoolYearForRegionMutation, useDeleteSchoolYearMutation, } from 'api/generated';
