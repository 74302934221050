import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const AccountDataFragmentDoc = gql `
    fragment AccountData on Account {
  id
  notes
  storage {
    capacityInBytes
    usedInBytes
  }
}
    `;
export const BaseObjectFragmentDoc = gql `
    fragment BaseObject on Object {
  id
  mimeType
  currentVersion {
    id
    name
    downloadUrl
    sizeInBytes
  }
}
    `;
export const ExtendedObjectLessonFragmentDoc = gql `
    fragment ExtendedObjectLesson on Lesson {
  id
  endDateTime
  startDateTime
  course {
    id
    name
    color
    subject {
      id
      name
    }
    educationalYear {
      id
      name
    }
  }
}
    `;
export const BaseSchoolFragmentDoc = gql `
    fragment BaseSchool on School {
  id
  name
  storageSizeBytes
  seats
  seatsUsed
  status
  domains
}
    `;
export const BaseSchoolYearFragmentDoc = gql `
    fragment BaseSchoolYear on SchoolYear {
  id
  name
  startDate
  endDate
}
    `;
export const BaseTimetableSlotFragmentDoc = gql `
    fragment BaseTimetableSlot on TimetableSlot {
  id
  courseId
  weekday
  hourInDay
  lengthHours
}
    `;
export const AccountStorageDocument = gql `
    query AccountStorage($id: ID!) {
  account(id: $id) {
    id
    storage {
      total: capacityInBytes
      used: usedInBytes
    }
  }
}
    `;
/**
 * __useAccountStorageQuery__
 *
 * To run a query within a React component, call `useAccountStorageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountStorageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountStorageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountStorageQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(AccountStorageDocument, options);
}
export function useAccountStorageLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(AccountStorageDocument, options);
}
export function useAccountStorageSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(AccountStorageDocument, options);
}
export const AccountDocument = gql `
    query Account($id: ID!) {
  account(id: $id) {
    ...AccountData
  }
}
    ${AccountDataFragmentDoc}`;
/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(AccountDocument, options);
}
export function useAccountLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(AccountDocument, options);
}
export function useAccountSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(AccountDocument, options);
}
export const UpdateAccountDocument = gql `
    mutation UpdateAccount($account: UpdateAccount!) {
  updateAccount(input: $account) {
    ...AccountData
  }
}
    ${AccountDataFragmentDoc}`;
/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateAccountDocument, options);
}
export const ContractNumberDocument = gql `
    query ContractNumber($id: ID!) {
  account(id: $id) {
    id
    contractNumber
  }
}
    `;
/**
 * __useContractNumberQuery__
 *
 * To run a query within a React component, call `useContractNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractNumberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractNumberQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(ContractNumberDocument, options);
}
export function useContractNumberLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(ContractNumberDocument, options);
}
export function useContractNumberSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(ContractNumberDocument, options);
}
export const AccountPaymentPlanDocument = gql `
    query AccountPaymentPlan($id: ID!) {
  account(id: $id) {
    id
    activeSubscription {
      id
      name
      description
      endDate
      isChangeable
      hasCustomerPortal
      imageUrl
      status
      priceText
    }
  }
}
    `;
/**
 * __useAccountPaymentPlanQuery__
 *
 * To run a query within a React component, call `useAccountPaymentPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPaymentPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPaymentPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountPaymentPlanQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(AccountPaymentPlanDocument, options);
}
export function useAccountPaymentPlanLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(AccountPaymentPlanDocument, options);
}
export function useAccountPaymentPlanSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(AccountPaymentPlanDocument, options);
}
export const CancelTerminationDocument = gql `
    mutation CancelTermination($accountId: ID!) {
  cancelTermination(accountId: $accountId)
}
    `;
/**
 * __useCancelTerminationMutation__
 *
 * To run a mutation, you first call `useCancelTerminationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTerminationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTerminationMutation, { data, loading, error }] = useCancelTerminationMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useCancelTerminationMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CancelTerminationDocument, options);
}
export const CoursesDocument = gql `
    query Courses($schoolYearId: UUID!) {
  schoolYear(id: $schoolYearId) {
    id
    courses {
      id
      name
      color
      educationalYearId
      subjectId
    }
  }
}
    `;
/**
 * __useCoursesQuery__
 *
 * To run a query within a React component, call `useCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesQuery({
 *   variables: {
 *      schoolYearId: // value for 'schoolYearId'
 *   },
 * });
 */
export function useCoursesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CoursesDocument, options);
}
export function useCoursesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CoursesDocument, options);
}
export function useCoursesSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(CoursesDocument, options);
}
export const CreateCourseDocument = gql `
    mutation CreateCourse($input: CreateCourseInput!) {
  createCourse(input: $input) {
    id
    name
    color
    educationalYearId
    subjectId
  }
}
    `;
/**
 * __useCreateCourseMutation__
 *
 * To run a mutation, you first call `useCreateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseMutation, { data, loading, error }] = useCreateCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateCourseDocument, options);
}
export const UpdateCourseDocument = gql `
    mutation UpdateCourse($input: UpdateCourseInput!) {
  updateCourse(input: $input) {
    id
    name
    color
    educationalYearId
    subjectId
  }
}
    `;
/**
 * __useUpdateCourseMutation__
 *
 * To run a mutation, you first call `useUpdateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseMutation, { data, loading, error }] = useUpdateCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourseMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateCourseDocument, options);
}
export const DeleteCourseDocument = gql `
    mutation DeleteCourse($id: ID!) {
  deleteCourse(id: $id)
}
    `;
/**
 * __useDeleteCourseMutation__
 *
 * To run a mutation, you first call `useDeleteCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseMutation, { data, loading, error }] = useDeleteCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCourseMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DeleteCourseDocument, options);
}
export const EducationalYearsDocument = gql `
    query EducationalYears($filter: EducationalYearFilter) {
  educationalYears(filter: $filter) {
    id
    name
  }
}
    `;
/**
 * __useEducationalYearsQuery__
 *
 * To run a query within a React component, call `useEducationalYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEducationalYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEducationalYearsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEducationalYearsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(EducationalYearsDocument, options);
}
export function useEducationalYearsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(EducationalYearsDocument, options);
}
export function useEducationalYearsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(EducationalYearsDocument, options);
}
export const HolidaysDocument = gql `
    query Holidays($schoolYearId: UUID!) {
  schoolYear(id: $schoolYearId) {
    id
    holidays {
      id
      name
      date
    }
  }
}
    `;
/**
 * __useHolidaysQuery__
 *
 * To run a query within a React component, call `useHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHolidaysQuery({
 *   variables: {
 *      schoolYearId: // value for 'schoolYearId'
 *   },
 * });
 */
export function useHolidaysQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(HolidaysDocument, options);
}
export function useHolidaysLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(HolidaysDocument, options);
}
export function useHolidaysSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(HolidaysDocument, options);
}
export const CreateHolidayDocument = gql `
    mutation CreateHoliday($input: CreateHoliday!) {
  createHoliday(input: $input) {
    id
    name
    date
  }
}
    `;
/**
 * __useCreateHolidayMutation__
 *
 * To run a mutation, you first call `useCreateHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHolidayMutation, { data, loading, error }] = useCreateHolidayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHolidayMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateHolidayDocument, options);
}
export const UpdateHolidayDocument = gql `
    mutation UpdateHoliday($input: UpdateHoliday!) {
  updateHoliday(input: $input) {
    id
    name
    date
  }
}
    `;
/**
 * __useUpdateHolidayMutation__
 *
 * To run a mutation, you first call `useUpdateHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHolidayMutation, { data, loading, error }] = useUpdateHolidayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHolidayMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateHolidayDocument, options);
}
export const DeleteHolidayDocument = gql `
    mutation DeleteHoliday($id: ID!) {
  deleteHoliday(id: $id)
}
    `;
/**
 * __useDeleteHolidayMutation__
 *
 * To run a mutation, you first call `useDeleteHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHolidayMutation, { data, loading, error }] = useDeleteHolidayMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHolidayMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DeleteHolidayDocument, options);
}
export const LessonsDocument = gql `
    query Lessons($from: String!, $to: String!) {
  lessons(from: $from, to: $to) {
    id
    startDateTime
    endDateTime
    course {
      id
      name
      color
    }
  }
}
    `;
/**
 * __useLessonsQuery__
 *
 * To run a query within a React component, call `useLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useLessonsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(LessonsDocument, options);
}
export function useLessonsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(LessonsDocument, options);
}
export function useLessonsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(LessonsDocument, options);
}
export const LessonDocument = gql `
    query Lesson($id: ID!) {
  lesson(id: $id) {
    id
    course {
      id
      name
      color
      educationalYear {
        id
        name
      }
      schoolYear {
        id
        name
      }
      subject {
        id
        name
      }
    }
    startDateTime
    endDateTime
    homework
    notes
    sharingEndDateTime
    sharingToken
    nextLesson {
      id
    }
    previousLesson {
      id
      homework
    }
  }
}
    `;
/**
 * __useLessonQuery__
 *
 * To run a query within a React component, call `useLessonQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLessonQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(LessonDocument, options);
}
export function useLessonLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(LessonDocument, options);
}
export function useLessonSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(LessonDocument, options);
}
export const UpdateLessonDocument = gql `
    mutation UpdateLesson($input: UpdateLesson!) {
  updateLesson(input: $input) {
    id
    notes
    homework
    sharingEndDateTime
    sharingToken
  }
}
    `;
/**
 * __useUpdateLessonMutation__
 *
 * To run a mutation, you first call `useUpdateLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLessonMutation, { data, loading, error }] = useUpdateLessonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLessonMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateLessonDocument, options);
}
export const RemoveObjectsFromLessonDocument = gql `
    mutation RemoveObjectsFromLesson($lessonId: ID!, $objectIds: [ID!]!) {
  removeObjectsFromLesson(lessonId: $lessonId, objectIds: $objectIds)
}
    `;
/**
 * __useRemoveObjectsFromLessonMutation__
 *
 * To run a mutation, you first call `useRemoveObjectsFromLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveObjectsFromLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeObjectsFromLessonMutation, { data, loading, error }] = useRemoveObjectsFromLessonMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      objectIds: // value for 'objectIds'
 *   },
 * });
 */
export function useRemoveObjectsFromLessonMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(RemoveObjectsFromLessonDocument, options);
}
export const AddObjectsToLessonDocument = gql `
    mutation AddObjectsToLesson($lessonId: ID!, $objectIds: [ID!]!) {
  addObjectsToLesson(lessonId: $lessonId, objectIds: $objectIds)
}
    `;
/**
 * __useAddObjectsToLessonMutation__
 *
 * To run a mutation, you first call `useAddObjectsToLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddObjectsToLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addObjectsToLessonMutation, { data, loading, error }] = useAddObjectsToLessonMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      objectIds: // value for 'objectIds'
 *   },
 * });
 */
export function useAddObjectsToLessonMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(AddObjectsToLessonDocument, options);
}
export const MoveObjectsToAnotherLessonDocument = gql `
    mutation MoveObjectsToAnotherLesson($oldLessonId: ID!, $newLessonId: ID!, $objectIds: [ID!]!) {
  removeObjectsFromLesson(lessonId: $oldLessonId, objectIds: $objectIds)
  addObjectsToLesson(lessonId: $newLessonId, objectIds: $objectIds)
}
    `;
/**
 * __useMoveObjectsToAnotherLessonMutation__
 *
 * To run a mutation, you first call `useMoveObjectsToAnotherLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveObjectsToAnotherLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveObjectsToAnotherLessonMutation, { data, loading, error }] = useMoveObjectsToAnotherLessonMutation({
 *   variables: {
 *      oldLessonId: // value for 'oldLessonId'
 *      newLessonId: // value for 'newLessonId'
 *      objectIds: // value for 'objectIds'
 *   },
 * });
 */
export function useMoveObjectsToAnotherLessonMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(MoveObjectsToAnotherLessonDocument, options);
}
export const ExtendedObjectsDocument = gql `
    query ExtendedObjects($filter: ObjectsFilter!, $lessonFilter: LessonFilter) {
  objectsWithPagination(filter: $filter) {
    totalCount
    objects {
      ...BaseObject
      tags {
        id
        value
      }
      lessons(filter: $lessonFilter) {
        ...ExtendedObjectLesson
      }
    }
  }
}
    ${BaseObjectFragmentDoc}
${ExtendedObjectLessonFragmentDoc}`;
/**
 * __useExtendedObjectsQuery__
 *
 * To run a query within a React component, call `useExtendedObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExtendedObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExtendedObjectsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      lessonFilter: // value for 'lessonFilter'
 *   },
 * });
 */
export function useExtendedObjectsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(ExtendedObjectsDocument, options);
}
export function useExtendedObjectsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(ExtendedObjectsDocument, options);
}
export function useExtendedObjectsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(ExtendedObjectsDocument, options);
}
export const ObjectsDocument = gql `
    query Objects($filter: ObjectsFilter!) {
  objects(filter: $filter) {
    ...BaseObject
  }
}
    ${BaseObjectFragmentDoc}`;
/**
 * __useObjectsQuery__
 *
 * To run a query within a React component, call `useObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useObjectsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(ObjectsDocument, options);
}
export function useObjectsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(ObjectsDocument, options);
}
export function useObjectsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(ObjectsDocument, options);
}
export const ObjectDocument = gql `
    query Object($id: ID!) {
  object(id: $id) {
    ...BaseObject
    lessons {
      ...ExtendedObjectLesson
      endDateTime
    }
  }
}
    ${BaseObjectFragmentDoc}
${ExtendedObjectLessonFragmentDoc}`;
/**
 * __useObjectQuery__
 *
 * To run a query within a React component, call `useObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useObjectQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(ObjectDocument, options);
}
export function useObjectLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(ObjectDocument, options);
}
export function useObjectSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(ObjectDocument, options);
}
export const UpdateObjectDocument = gql `
    mutation UpdateObject($input: UpdateObject!) {
  updateObject(input: $input) {
    ...BaseObject
    tags {
      id
      value
    }
  }
}
    ${BaseObjectFragmentDoc}`;
/**
 * __useUpdateObjectMutation__
 *
 * To run a mutation, you first call `useUpdateObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateObjectMutation, { data, loading, error }] = useUpdateObjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateObjectMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateObjectDocument, options);
}
export const UpdateObjectLessonsDocument = gql `
    mutation UpdateObjectLessons($input: UpdateObjectLessons!) {
  updateObjectLessons(input: $input) {
    ...BaseObject
    lessons {
      ...ExtendedObjectLesson
    }
  }
}
    ${BaseObjectFragmentDoc}
${ExtendedObjectLessonFragmentDoc}`;
/**
 * __useUpdateObjectLessonsMutation__
 *
 * To run a mutation, you first call `useUpdateObjectLessonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateObjectLessonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateObjectLessonsMutation, { data, loading, error }] = useUpdateObjectLessonsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateObjectLessonsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateObjectLessonsDocument, options);
}
export const DeleteObjectsDocument = gql `
    mutation DeleteObjects($ids: [ID!]!) {
  deleteObjects(ids: $ids)
}
    `;
/**
 * __useDeleteObjectsMutation__
 *
 * To run a mutation, you first call `useDeleteObjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteObjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteObjectsMutation, { data, loading, error }] = useDeleteObjectsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteObjectsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DeleteObjectsDocument, options);
}
export const UpdateObjectTagsDocument = gql `
    mutation UpdateObjectTags($objectId: ID!, $tags: [UpdateObjectTag!]!) {
  updateObjectTags(objectId: $objectId, tags: $tags) {
    ...BaseObject
    tags {
      id
      value
    }
  }
}
    ${BaseObjectFragmentDoc}`;
/**
 * __useUpdateObjectTagsMutation__
 *
 * To run a mutation, you first call `useUpdateObjectTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateObjectTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateObjectTagsMutation, { data, loading, error }] = useUpdateObjectTagsMutation({
 *   variables: {
 *      objectId: // value for 'objectId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpdateObjectTagsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateObjectTagsDocument, options);
}
export const ObjectFiltersDocument = gql `
    query ObjectFilters {
  schoolYears(filter: {usedByObjectsOnly: true}) {
    value: id
    label: name
  }
  educationalYears(filter: {usedByObjectsOnly: true}) {
    value: id
    label: name
  }
  subjects(filter: {usedByObjectsOnly: true}) {
    value: id
    label: name
  }
  tags {
    value: id
    label: value
  }
}
    `;
/**
 * __useObjectFiltersQuery__
 *
 * To run a query within a React component, call `useObjectFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useObjectFiltersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(ObjectFiltersDocument, options);
}
export function useObjectFiltersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(ObjectFiltersDocument, options);
}
export function useObjectFiltersSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(ObjectFiltersDocument, options);
}
export const SchoolsDocument = gql `
    query Schools {
  schools {
    ...BaseSchool
  }
}
    ${BaseSchoolFragmentDoc}`;
/**
 * __useSchoolsQuery__
 *
 * To run a query within a React component, call `useSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSchoolsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(SchoolsDocument, options);
}
export function useSchoolsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(SchoolsDocument, options);
}
export function useSchoolsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(SchoolsDocument, options);
}
export const CreateSchoolDocument = gql `
    mutation CreateSchool($input: CreateSchool!) {
  createSchool(input: $input) {
    ...BaseSchool
  }
}
    ${BaseSchoolFragmentDoc}`;
/**
 * __useCreateSchoolMutation__
 *
 * To run a mutation, you first call `useCreateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolMutation, { data, loading, error }] = useCreateSchoolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSchoolMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateSchoolDocument, options);
}
export const DeleteSchoolDocument = gql `
    mutation DeleteSchool($id: ID!) {
  deleteSchool(id: $id)
}
    `;
/**
 * __useDeleteSchoolMutation__
 *
 * To run a mutation, you first call `useDeleteSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchoolMutation, { data, loading, error }] = useDeleteSchoolMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSchoolMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DeleteSchoolDocument, options);
}
export const UpdateSchoolDocument = gql `
    mutation UpdateSchool($input: UpdateSchool!) {
  updateSchool(input: $input) {
    ...BaseSchool
  }
}
    ${BaseSchoolFragmentDoc}`;
/**
 * __useUpdateSchoolMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolMutation, { data, loading, error }] = useUpdateSchoolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSchoolMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateSchoolDocument, options);
}
export const SchoolYearDocument = gql `
    query SchoolYear($id: UUID!) {
  schoolYear(id: $id) {
    ...BaseSchoolYear
  }
}
    ${BaseSchoolYearFragmentDoc}`;
/**
 * __useSchoolYearQuery__
 *
 * To run a query within a React component, call `useSchoolYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolYearQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchoolYearQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(SchoolYearDocument, options);
}
export function useSchoolYearLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(SchoolYearDocument, options);
}
export function useSchoolYearSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(SchoolYearDocument, options);
}
export const SchoolYearsDocument = gql `
    query SchoolYears($filter: SchoolYearFilter) {
  schoolYears(filter: $filter) {
    ...BaseSchoolYear
  }
}
    ${BaseSchoolYearFragmentDoc}`;
/**
 * __useSchoolYearsQuery__
 *
 * To run a query within a React component, call `useSchoolYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolYearsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSchoolYearsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(SchoolYearsDocument, options);
}
export function useSchoolYearsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(SchoolYearsDocument, options);
}
export function useSchoolYearsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(SchoolYearsDocument, options);
}
export const CreateSchoolYearDocument = gql `
    mutation CreateSchoolYear($input: CreateSchoolYear) {
  createSchoolYear(input: $input) {
    ...BaseSchoolYear
  }
}
    ${BaseSchoolYearFragmentDoc}`;
/**
 * __useCreateSchoolYearMutation__
 *
 * To run a mutation, you first call `useCreateSchoolYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolYearMutation, { data, loading, error }] = useCreateSchoolYearMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSchoolYearMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateSchoolYearDocument, options);
}
export const UpdateSchoolYearDocument = gql `
    mutation UpdateSchoolYear($input: UpdateSchoolYear!) {
  updateSchoolYear(input: $input) {
    ...BaseSchoolYear
  }
}
    ${BaseSchoolYearFragmentDoc}`;
/**
 * __useUpdateSchoolYearMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolYearMutation, { data, loading, error }] = useUpdateSchoolYearMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSchoolYearMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateSchoolYearDocument, options);
}
export const DeleteSchoolYearDocument = gql `
    mutation DeleteSchoolYear($id: UUID!) {
  deleteSchoolYear(id: $id)
}
    `;
/**
 * __useDeleteSchoolYearMutation__
 *
 * To run a mutation, you first call `useDeleteSchoolYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchoolYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchoolYearMutation, { data, loading, error }] = useDeleteSchoolYearMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSchoolYearMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DeleteSchoolYearDocument, options);
}
export const CreateSchoolYearForRegionDocument = gql `
    mutation CreateSchoolYearForRegion($input: CreateSchoolYearForRegion) {
  createSchoolYearForRegion(input: $input) {
    ...BaseSchoolYear
  }
}
    ${BaseSchoolYearFragmentDoc}`;
/**
 * __useCreateSchoolYearForRegionMutation__
 *
 * To run a mutation, you first call `useCreateSchoolYearForRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolYearForRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolYearForRegionMutation, { data, loading, error }] = useCreateSchoolYearForRegionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSchoolYearForRegionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateSchoolYearForRegionDocument, options);
}
export const SubjectsDocument = gql `
    query Subjects($filter: SubjectFilter) {
  subjects(filter: $filter) {
    id
    name
  }
}
    `;
/**
 * __useSubjectsQuery__
 *
 * To run a query within a React component, call `useSubjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubjectsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSubjectsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(SubjectsDocument, options);
}
export function useSubjectsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(SubjectsDocument, options);
}
export function useSubjectsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(SubjectsDocument, options);
}
export const TagsDocument = gql `
    query Tags {
  tags {
    id
    value
  }
}
    `;
/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TagsDocument, options);
}
export function useTagsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TagsDocument, options);
}
export function useTagsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(TagsDocument, options);
}
export const TagFilterOptionsDocument = gql `
    query TagFilterOptions {
  tags {
    id
    name: value
  }
}
    `;
/**
 * __useTagFilterOptionsQuery__
 *
 * To run a query within a React component, call `useTagFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagFilterOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagFilterOptionsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TagFilterOptionsDocument, options);
}
export function useTagFilterOptionsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TagFilterOptionsDocument, options);
}
export function useTagFilterOptionsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(TagFilterOptionsDocument, options);
}
export const TimetableSlotsDocument = gql `
    query TimetableSlots($timetableId: ID!) {
  timetable(id: $timetableId) {
    id
    slots {
      ...BaseTimetableSlot
      course {
        id
        name
      }
    }
  }
}
    ${BaseTimetableSlotFragmentDoc}`;
/**
 * __useTimetableSlotsQuery__
 *
 * To run a query within a React component, call `useTimetableSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimetableSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimetableSlotsQuery({
 *   variables: {
 *      timetableId: // value for 'timetableId'
 *   },
 * });
 */
export function useTimetableSlotsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TimetableSlotsDocument, options);
}
export function useTimetableSlotsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TimetableSlotsDocument, options);
}
export function useTimetableSlotsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(TimetableSlotsDocument, options);
}
export const CreateTimetableSlotsDocument = gql `
    mutation CreateTimetableSlots($input: CreateTimetableSlots!) {
  createTimetableSlots(input: $input) {
    ...BaseTimetableSlot
  }
}
    ${BaseTimetableSlotFragmentDoc}`;
/**
 * __useCreateTimetableSlotsMutation__
 *
 * To run a mutation, you first call `useCreateTimetableSlotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimetableSlotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimetableSlotsMutation, { data, loading, error }] = useCreateTimetableSlotsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimetableSlotsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateTimetableSlotsDocument, options);
}
export const UpdateTimetableSlotsDocument = gql `
    mutation UpdateTimetableSlots($input: UpdateTimetableSlots!) {
  updateTimetableSlots(input: $input) {
    ...BaseTimetableSlot
  }
}
    ${BaseTimetableSlotFragmentDoc}`;
/**
 * __useUpdateTimetableSlotsMutation__
 *
 * To run a mutation, you first call `useUpdateTimetableSlotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimetableSlotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimetableSlotsMutation, { data, loading, error }] = useUpdateTimetableSlotsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimetableSlotsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateTimetableSlotsDocument, options);
}
export const DeleteTimetableSlotsDocument = gql `
    mutation DeleteTimetableSlots($input: DeleteTimetableSlots!) {
  deleteTimetableSlots(input: $input)
}
    `;
/**
 * __useDeleteTimetableSlotsMutation__
 *
 * To run a mutation, you first call `useDeleteTimetableSlotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimetableSlotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimetableSlotsMutation, { data, loading, error }] = useDeleteTimetableSlotsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTimetableSlotsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DeleteTimetableSlotsDocument, options);
}
export const UpdateTimetableSlotDocument = gql `
    mutation UpdateTimetableSlot($input: UpdateTimetableSlot!) {
  updateTimetableSlot(input: $input) {
    ...BaseTimetableSlot
  }
}
    ${BaseTimetableSlotFragmentDoc}`;
/**
 * __useUpdateTimetableSlotMutation__
 *
 * To run a mutation, you first call `useUpdateTimetableSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimetableSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimetableSlotMutation, { data, loading, error }] = useUpdateTimetableSlotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimetableSlotMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateTimetableSlotDocument, options);
}
export const TimetablesDocument = gql `
    query Timetables($schoolYearId: UUID!) {
  timetables(schoolYearId: $schoolYearId) {
    id
    name
    startDate
    endDate
  }
}
    `;
/**
 * __useTimetablesQuery__
 *
 * To run a query within a React component, call `useTimetablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimetablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimetablesQuery({
 *   variables: {
 *      schoolYearId: // value for 'schoolYearId'
 *   },
 * });
 */
export function useTimetablesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TimetablesDocument, options);
}
export function useTimetablesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TimetablesDocument, options);
}
export function useTimetablesSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(TimetablesDocument, options);
}
export const TimetableExistsDocument = gql `
    query TimetableExists {
  timetableExists
}
    `;
/**
 * __useTimetableExistsQuery__
 *
 * To run a query within a React component, call `useTimetableExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimetableExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimetableExistsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimetableExistsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TimetableExistsDocument, options);
}
export function useTimetableExistsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TimetableExistsDocument, options);
}
export function useTimetableExistsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(TimetableExistsDocument, options);
}
export const CreateTimetableDocument = gql `
    mutation CreateTimetable($input: CreateTimetable!) {
  createTimetable(input: $input) {
    id
    name
    startDate
    endDate
  }
}
    `;
/**
 * __useCreateTimetableMutation__
 *
 * To run a mutation, you first call `useCreateTimetableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimetableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimetableMutation, { data, loading, error }] = useCreateTimetableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimetableMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateTimetableDocument, options);
}
export const UpdateTimetableDocument = gql `
    mutation UpdateTimetable($input: UpdateTimetable!) {
  updateTimetable(input: $input) {
    id
    name
    startDate
    endDate
  }
}
    `;
/**
 * __useUpdateTimetableMutation__
 *
 * To run a mutation, you first call `useUpdateTimetableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimetableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimetableMutation, { data, loading, error }] = useUpdateTimetableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimetableMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateTimetableDocument, options);
}
export const DeleteTimetableDocument = gql `
    mutation DeleteTimetable($id: ID!) {
  deleteTimetable(id: $id)
}
    `;
/**
 * __useDeleteTimetableMutation__
 *
 * To run a mutation, you first call `useDeleteTimetableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimetableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimetableMutation, { data, loading, error }] = useDeleteTimetableMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTimetableMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DeleteTimetableDocument, options);
}
export const VacationsDocument = gql `
    query Vacations($schoolYearId: UUID!) {
  vacations(schoolYearId: $schoolYearId) {
    id
    name
    startDate
    endDate
  }
}
    `;
/**
 * __useVacationsQuery__
 *
 * To run a query within a React component, call `useVacationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVacationsQuery({
 *   variables: {
 *      schoolYearId: // value for 'schoolYearId'
 *   },
 * });
 */
export function useVacationsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(VacationsDocument, options);
}
export function useVacationsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(VacationsDocument, options);
}
export function useVacationsSuspenseQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery(VacationsDocument, options);
}
export const CreateVacationDocument = gql `
    mutation CreateVacation($input: CreateVacation!) {
  createVacation(input: $input) {
    id
    name
    startDate
    endDate
  }
}
    `;
/**
 * __useCreateVacationMutation__
 *
 * To run a mutation, you first call `useCreateVacationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVacationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVacationMutation, { data, loading, error }] = useCreateVacationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVacationMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateVacationDocument, options);
}
export const UpdateVacationDocument = gql `
    mutation UpdateVacation($input: UpdateVacation!) {
  updateVacation(input: $input) {
    id
    name
    startDate
    endDate
  }
}
    `;
/**
 * __useUpdateVacationMutation__
 *
 * To run a mutation, you first call `useUpdateVacationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVacationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVacationMutation, { data, loading, error }] = useUpdateVacationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVacationMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateVacationDocument, options);
}
export const DeleteVacationDocument = gql `
    mutation DeleteVacation($id: ID!) {
  deleteVacation(id: $id)
}
    `;
/**
 * __useDeleteVacationMutation__
 *
 * To run a mutation, you first call `useDeleteVacationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVacationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVacationMutation, { data, loading, error }] = useDeleteVacationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVacationMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DeleteVacationDocument, options);
}
