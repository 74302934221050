import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import { LessonFilters } from 'components/custom';
import LessonAssignmentDataGrid from './LessonAssignmentDataGrid';
import { groupBy } from 'lodash-es';
import React from 'react';
const LessonAssignmentDialogContent = ({ addedLessonsCount, initialLessonsList, lessonsRef, objectId, removedLessonsCount, setAddedLessonsCount, setRemovedLessonsCount, }) => {
    const [lessonFilter, setLessonFilter] = React.useState([]);
    const [lessonsList, setLessonsList] = React.useState(initialLessonsList);
    React.useEffect(() => {
        if (!objectId) {
            setLessonsList([]);
            setLessonFilter([]);
        }
    }, [objectId]);
    lessonsRef.current = lessonsList;
    const groupedLessons = groupBy(lessonsList, 'course.id');
    const coursesList = Object.keys(groupedLessons).map((courseId) => ({
        id: courseId,
        lessons: groupedLessons[courseId],
        name: groupedLessons[courseId][0].course.name,
    }));
    const updateLessonsList = (lesson, operation) => {
        const isInitialLesson = initialLessonsList.some((initialLesson) => initialLesson.id === lesson.id);
        if (operation === 'add' && isInitialLesson) {
            setLessonFilter([...lessonFilter, lesson.id]);
            setRemovedLessonsCount(removedLessonsCount - 1);
            setLessonsList([
                ...lessonsList.filter((l) => l.id !== lesson.id),
                { ...lesson, status: 'existing' },
            ]);
        }
        if (operation === 'add' && !isInitialLesson) {
            setLessonFilter([...lessonFilter, lesson.id]);
            setAddedLessonsCount(addedLessonsCount + 1);
            setLessonsList([...lessonsList, { ...lesson, status: 'added' }]);
        }
        if (operation === 'remove' && isInitialLesson) {
            setLessonFilter([...lessonFilter.filter((l) => l !== lesson.id)]);
            setRemovedLessonsCount(removedLessonsCount + 1);
            setLessonsList([
                ...lessonsList.filter((l) => l.id !== lesson.id),
                { ...lesson, status: 'removed' },
            ]);
        }
        if (operation === 'remove' && !isInitialLesson) {
            setLessonFilter([
                ...lessonFilter.filter((lessonId) => lessonId !== lesson.id),
            ]);
            setAddedLessonsCount(addedLessonsCount - 1);
            setLessonsList([
                ...lessonsList.filter((l) => l.id !== lesson.id),
            ]);
        }
    };
    const removeAllCourseLessons = (courseId) => {
        var _a, _b;
        const courseLessons = (_b = (_a = coursesList.find((course) => course.id === courseId)) === null || _a === void 0 ? void 0 : _a.lessons) !== null && _b !== void 0 ? _b : [];
        const initialCourseLessonsToRemove = courseLessons.filter((l) => !('status' in l) || l.status === 'existing');
        const addedCourseLessonsToRemove = courseLessons.filter((l) => 'status' in l && l.status === 'added');
        setLessonsList([
            ...lessonsList.filter((l) => l.course.id !== courseId),
            ...initialLessonsList
                .filter((lesson) => lesson.course.id === courseId)
                .map((l) => ({
                ...l,
                status: 'removed',
            })),
        ]);
        setAddedLessonsCount(addedLessonsCount - addedCourseLessonsToRemove.length);
        setRemovedLessonsCount(removedLessonsCount + initialCourseLessonsToRemove.length);
        setLessonFilter([]);
    };
    const addAllCourseLessons = (courseId, allCourseLessons) => {
        var _a, _b;
        const courseLessons = (_b = (_a = coursesList.find((course) => course.id === courseId)) === null || _a === void 0 ? void 0 : _a.lessons) !== null && _b !== void 0 ? _b : [];
        setAddedLessonsCount(addedLessonsCount + allCourseLessons.length - lessonFilter.length);
        const previouslyRemoved = courseLessons.filter((l) => 'status' in l && l.status === 'removed');
        setRemovedLessonsCount(removedLessonsCount - previouslyRemoved.length);
        setLessonsList([
            ...lessonsList.filter((l) => l.course.id !== courseId),
            ...allCourseLessons.map((l) => {
                const isInitialLesson = initialLessonsList.some((initialLesson) => initialLesson.id === l.id);
                return {
                    ...l,
                    status: isInitialLesson ? 'existing' : 'added',
                };
            }),
        ]);
        setLessonFilter(allCourseLessons.map((l) => l.id));
    };
    return (_jsxs(Stack, { height: "100%", spacing: 2, children: [_jsx(LessonFilters, { lessonsList: lessonsList, onLessonOptionClick: updateLessonsList, lessonFilter: lessonFilter, setLessonFilter: setLessonFilter, onClearClick: removeAllCourseLessons, addAllCourseLessons: addAllCourseLessons }), _jsx(LessonAssignmentDataGrid, { rows: coursesList, onLessonChipClick: updateLessonsList, onRemoveRowClick: removeAllCourseLessons })] }));
};
export default LessonAssignmentDialogContent;
