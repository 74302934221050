import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
const openedMixin = (theme) => ({
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
    }),
});
const closedMixin = (theme) => ({
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
    }),
});
const StyledDrawer = styled(MuiDrawer)(({ theme, open }) => {
    const styles = open ? openedMixin(theme) : closedMixin(theme);
    return {
        ...styles,
        '& .MuiDrawer-paper': {
            ...styles,
            boxShadow: theme.boxShadow,
        },
    };
});
export default StyledDrawer;
